<template>
  <div>

    <!-- Creator  -->
    <v-row>
      <v-col>
        <slot name="creator" :request="request">

        </slot>
      </v-col>
    </v-row>

    <!-- Actions   -->
    <v-row v-if="!hide_actions">
      <v-col class="d-flex justify-space-between">
        <div class="d-flex width-full">

          <!-- Add Button  -->
          <slot name="add" :request="request">
            <template v-if="add_is_visible">
              <v-btn color="primary" elevation="0" large class="rounded-lg" :to="{name:'AddIdea'}">
                {{ __('Add') }}
              </v-btn>
              <space/>
            </template>
          </slot>

          <!-- Search -->
          <items-search :request="request" :columns="['title']"></items-search>

        </div>

        <space/>

        <div class="d-flex">

          <!-- Limit -->
          <items-limit :request="request"/>
          <space/>

          <!--   Filters button     -->
          <v-btn class="rounded-lg border-light" outlined @click="show_filters = !show_filters" large>
            <v-icon left>mdi-filter-variant</v-icon>
            {{ __("Filters") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Filters -->
    <v-row v-show="show_filters" v-if="!hide_filters">
      <v-col>
        <ideas-filters
            @reset="get"
            :request="request"
            v-bind="filters_attrs"
        >
        </ideas-filters>
      </v-col>
    </v-row>

    <!-- Loading -->
    <loader cols="12" col_sm="6" col_md="4" col_lg="3" col_xl="2" v-if="request.isLoading()" :count="loader_count"/>

    <!-- List -->
    <div class="py-5" v-else>

      <!-- Normal List -->
      <v-row v-if="!masonry">
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item, key) in request.data" v-bind:key="key">
          <idea
              :data="item"
              v-bind="item_attrs"
              @archived="request.reload()"
              @edited="request.reload()"
              :masonry="masonry"
          />
        </v-col>
      </v-row>

      <!--  Masonry List    -->
      <v-row v-else-if="request.data">
        <v-col>

          <!--     Screen SM and UP     -->
          <div class="hidden-xs-only">
            <masonry :cols="4" :gutter="15">
              <div v-for="(item, index) in request.data" :key="index">
                <idea
                    class="mb-3"
                    :data="item"
                    v-bind="item_attrs"
                    @archived="request.reload()"
                    @edited="request.reload()"
                    :masonry="masonry"
                />
              </div>
            </masonry>
          </div>

          <!--     Screen XS     -->
          <div class="hidden-sm-and-up">
            <masonry :cols="2" :gutter="15">
              <div v-for="(item, index) in request.data" :key="index">
                <idea
                    class="mb-3"
                    :data="item"
                    v-bind="item_attrs"
                    @archived="request.reload()"
                    @edited="request.reload()"
                    :masonry="masonry"
                />
              </div>
            </masonry>
          </div>
        </v-col>
      </v-row>

      <div>
        <!--   Not found data   -->
        <not-found-data v-if="!request.data" style="padding-top: 130px !important; padding-bottom: 130px !important"/>

        <!-- Load More -->
        <load-more pagination v-if="!hide_load_more" :request="request"/>
      </div>

    </div>

  </div>
</template>

<script>

import {GetRequest} from "../../models/GetRequest";

import IdeasFilters from "./IdeasFilters";
import Idea from "./Idea";
import LoadMore from "../app/LoadMore";
import Space from "../app/Space";
import Loader from "../app/Loader";
import NotFoundData from "../app/NotFoundData";
import ItemsSearch from "../items/ItemsSearch";
import {Parameters} from "../../models/Parameters";
import ItemsLimit from "../items/ItemsLimit";

export default {
  name: "Ideas",
  components: {
    ItemsLimit,
    ItemsSearch,
    NotFoundData,
    Loader,
    Space,
    LoadMore,
    Idea,
    IdeasFilters
  },
  props: {
    api: {
      default: 'get_ideas',
      type: String,
    },
    api_params: {
      default: null,
      type: Array,
    },
    limit: {
      default: null,
      type: [String, Number],
    },
    sort: {
      default: 'date_created',
      type: String,
    },
    fields: {
      default: '*,*.*',
      type: String,
    },
    room_id: {
      default: null,
      type: String,
    },
    creator: {
      default: null,
      type: String,
    },
    add_by: {
      default: null,
      type: String,
    },
    status: {
      default: null,
      type: String,
    },
    parameters: {
      default: null,
      type: Parameters,
    },

    add_is_visible: {
      default: false,
      type: Boolean,
    },
    masonry: {
      default: false,
      type: Boolean,
    },
    item_attrs: {
      default: null,
      type: Object,
    },
    filters_attrs: {
      default: null,
      type: Object,
    },
    loader_count: {
      default: 12,
      type: [String, Number],
    },
    disabled_auto: {
      default: false,
      type: Boolean,
    },
    filter_room: {
      default: true,
      type: Boolean,
    },
    hide_actions: {
      default: false,
      type: Boolean,
    },
    hide_filters: {
      default: false,
      type: Boolean,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    creator_selector: {
      default: false,
      type: Boolean,
    },
    filter_status: {
      default: true,
      type: Boolean,
    },
    without: {
      default: null,
      type: Array,
    },
  },
  computed: {},
  data() {
    return {
      request: new GetRequest(),
      show_filters: false,
      options: {
        width: 270,
        padding: {
          2: 8,
          default: 8
        },
      },
    }
  },
  mounted() {
    this.set();
    if (!this.disabled_auto) this.get();
  },
  watch: {
    'without'() {
      this.get();
    },
    'status'() {
      this.get();
    },
    'room_id'() {
      this.get();
    },
    'creator'() {
      this.get();
    },
    'api_params'() {
      this.get();
    },
    'api'() {
      this.get();
    },
    'sort'() {
      this.get();
    },
    'fields'() {
      this.get();
    },
    'filters'() {
      this.get();
    },
    '$route.query.page'() {
      try {
        if (!this.$route.query || !this.$route.query.page) return;
        this.get();
      } catch (e) {
        console.log(e);
      }
    },
  },
  methods: {
    set() {
      this.setApi();
      this.setPage();
      this.setFilters();
      this.setParameters();
    },
    setApi() {
      this.request = new GetRequest(this.api, this.api_params);
    },
    setParameters() {
      this.request.setParameters(this.parameters)
      this.request.setLimit(this.limit)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
    },
    setFilters() {
      if (this.add_by) this.request.addFilter('add_by', this.add_by);
      if (this.creator) this.request.addFilter('user_created', this.creator);
      if (this.room_id) this.request.addFilter('room_id', this.room_id);
      if (this.status) this.request.addFilter('status', this.status);
      this.request.setNotIn('id', this.without)
    },
    setPage() {
      try {
        this.request.setPage(this.$route.query.page)
      } catch (e) {
        this.request.setPage(1)
      }
    },
    get() {
      this.request.reset();
      this.set();
      this.request.get();
    }
  }
}
</script>


<style>

.style-item {
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  background: #F5F5F5;
}

.style-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: block;
}
</style>

<template>
  <div>
    <!-- Desktop & Tablet   -->
    <draggable class="hidden-xs-only" v-model="items" @input="$emit('dragged', items)">
      <transition-group>
        <template v-for="(item, key) in items">
          <ideas-editor-row
              :key="'ideas-editor-row-'+item.id+key"
              :project_id="project_id"
              :number="key + 1"
              v-model="items[key]"
              @remove="remove(key)"
              @update="e => update(e, key)"
              v-bind="$attrs"
          >
          </ideas-editor-row>
        </template>
      </transition-group>
    </draggable>

    <!-- Mobile -->
    <draggable class="hidden-sm-and-up" v-model="items" @input="$emit('dragged', items)" :disabled="true">
      <transition-group>
        <template v-for="(item, key) in items">
          <ideas-editor-row
              :key="'ideas-editor-row-'+item.id+key"
              :project_id="project_id"
              :number="key + 1"
              v-model="items[key]"
              @remove="remove(key)"
              @update="e => update(e, key)"
              @selected-for-cover="selectedCover(key)"
              v-bind="$attrs"
          >
          </ideas-editor-row>
        </template>
      </transition-group>
    </draggable>


    <!--  Add  -->
    <div class="text-center py-6">
      <v-card
          width="120"
          height="120"
          class="rounded-lg ma-1 overflow-hidden pointer grey lighten-3 mx-auto"
          style="border:2px dashed rgba(0, 0, 0, 0.1) !important;"
          elevation="0"
          @click="add"
          :disabled="disabled"
      >
        <v-card-text>
          <div class="px-1 align-center text-center pt-5">
            <div>
              <v-icon size="45">mdi-plus</v-icon>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="py-3">
        {{ __('AddMoreImage') }}
      </div>
      <slot name="subtitle-add"></slot>
    </div>
  </div>
</template>

<script>
import IdeasEditorRow from "@/components/ideas/IdeasEditorRow";
import draggable from 'vuedraggable'

export default {
  name: "IdeasEditor",
  components: {IdeasEditorRow, draggable},
  props: {
    data: {
      default: null,
      type: Array,
    },
    project_id: {
      default: null,
      type: String,
    },
    disabled: {
      default: null,
      type: Boolean,
    },
  },
  data() {
    return {
      items: [],
    }
  },
  mounted() {
    this.set();
  },
  methods: {
    set() {
      this.items = this.data;
    },
    remove(key) {
      this.$delete(this.items, key)
    },
    update(data, key) {
      this.$set(this.items, key, data)
    },
    add() {
      if (!this._is_array(this.items)) this.items = [];
      this.items.push({
        'title': null,
        'room_id': null,
        'photography_credit': null,
        'color_id': null,
        'pictures': [],
      });
      this.$emit('add')
    },

    selectedCover(index) {
      try {
        let temp = this.items[index];
        this.$delete(this.items, index)
        this.items.unshift(temp);
      } catch (e) {
        console.log(e);
      }
    },


  }
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width">
    <div :class="title_classes">{{ __('Address') }}</div>

    <v-combobox
        :items="items()"
        :value="value"
        :search-input.sync="input"
        :placeholder="__('SelectAddress')"
        @input="e => change(e)"
        class="custom-selector-border font-size-14 rounded-lg"
        outlined
        dense
        v-bind="$attrs"
        return-object
    >

    </v-combobox>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";

export default {
  name: "AddressSelector",
  components: {},
  props: {
    value: {
      default: null,
      type: String,
    },
    title_classes: {
      default: 'font-weight-bold',
      type: String,
    }
  },
  data() {
    return {
      request: new GetRequest('get_address_list'),
      place_request: new GetRequest('get_place'),
      input: null,
    }
  },
  watch: {
    input() {
      this.get();
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    getPlace(place_id) {
      this.place_request.is_loading = false;
      this.place_request.setThen((response) => {
        if (!response || !response.data) return null;
        if (!response.data.formatted_address) return null;
        
        this.$emit('input', response.data.formatted_address);
        this.$emit('input-object', response.data);
      });
      this.place_request.setParam('place_id', place_id);
      this.place_request.setParam('fields', null);
      this.place_request.row();
    },
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      if (!this.input) return;
      this.request.is_loading = false;
      this.request.setParam('input', this.input);
      this.request.setLimit(1000);
      this.request.hideError();
      this.request.row();
    },
    items() {
      try {
        let items = [];
        if (!this.request.data || !this.request.data.predictions) return;
        this.request.data.predictions.forEach((item) => {
          items.push({
            text: item.description,
            value: item.description,
            place_id: item.place_id,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    },
    change(result) {
      try {
        if (result && typeof result === 'object') return this.getPlace(result.place_id)
        this.$emit('input', result)
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
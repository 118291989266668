<template>
  <div class="mb-12">
    <auth-google @authorised="authorised" v-bind="$attrs"/>

    <auth-form   @authorised="authorised" v-bind="$attrs"/>
<!--    <auth-form v-if="_develop()" @authorised="authorised" v-bind="$attrs"/>-->
  </div>

</template>

<script>
import AuthForm from "./AuthForm";
import {GetRequest} from "../../models/GetRequest";
import AuthGoogle from "./AuthGoogle";

export default {
  name: "Auth",
  components: {AuthForm, AuthGoogle},
  props: {
    dont_redirect: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      request: new GetRequest('get_projects'),
    }
  },
  methods: {
    authorised() {
      this.$emit('then')
      if (this.dont_redirect) return;
      this.redirect();
    },
    redirect() {
      try {
        if (!this._access('client')) throw 'Is Not Client!';
        if (!this._permission('projects')) throw 'Has Not Permission!';
        this.request.setThen(() => this.checkProjects());
        this.request.get();
      } catch (e) {
        this.toPanel()
      }
    },
    checkProjects() {
      try {
        if (this.request.total !== 1) throw 'Has not one project!';
        this.$router.push({name: 'Project', params: {id: this.request.data[0].id}});
      } catch (e) {
        this.toPanel()
      }
    },
    toPanel(){
      try{
        if (!this._permission('projects')) throw 'Has not permission to projects!';
        this.$router.push({name: 'Panel'});
      }catch (e){
        this.$router.push({name: 'Profile'});
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div v-if="project_id">
    <upload @then="uploaded" v-bind="$attrs"/>
  </div>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";
import Upload from "./Upload";

export default {
  name: "Drive",
  components: {Upload},
  props: {
    project_id: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      request: new PostRequest('upload_plans'),

    }
  },
  mounted() {
    this.request.setApiParams([this.project_id])
  },
  methods: {
    uploaded(response) {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        let file_id = response.data.id;
        this.request.setBody([file_id]);
        this.request.patch();
        this.$emit('uploaded', file_id)
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./AddressSelector.vue?vue&type=template&id=271a2fa4&scoped=true&"
import script from "./AddressSelector.vue?vue&type=script&lang=js&"
export * from "./AddressSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "271a2fa4",
  null
  
)

export default component.exports
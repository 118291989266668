<template>
  <div>
    <v-btn id="profile-go-top" href="#main" elevation="0" fab  style="display: none;">
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "ProfileGoTop",
  mounted() {
    window.addEventListener('scroll', () => {
      try {
        if (300 < window.scrollY) return document.getElementById('profile-go-top').style.display = null;
        document.getElementById('profile-go-top').style.display = 'none'
      } catch (e) {
        console.log(e);
      }
    })
  }
}
</script>

<style>
#profile-go-top {
  position: fixed !important;
  z-index: 999;
  right: 50px;
  bottom: 150px;

}
</style>
<template>
  <div>
    <v-divider class="grey lighten-1 mx-3"/>

    <show-more open_default>
      <template #title>
        <div class="font-weight-bold primary--text">{{ __('EditAccountInformation') }}</div>
      </template>

      <template #more>
        <v-textarea class="px-3 rounded-lg font-size-14" rows="13" outlined v-model="request.body.description"/>
      </template>

    </show-more>
  </div>
</template>

<script>

import {PostRequest} from "@/models/PostRequest";
import ShowMore from "@/components/app/ShowMore";

export default {
  name: "ProfileFormAbout",
  components: {ShowMore},
  props: {
    request: {
      default: null,
      type: PostRequest,
    },
  },

}
</script>

<style scoped>

</style>
<template>
  <div class="profile-form-info">
    <v-divider class="mx-3 grey lighten-1"/>
    <show-more open_default>
      <template #title>
        <div class="font-weight-bold primary--text">{{ __('EditBusinessInformation') }}</div>
      </template>

      <template #more>
        <div class="px-3">
          <v-row class="hide-field-details">

            <v-col cols="12" sm="6">
              <div class="mb-1 font-size-14">{{ __('BusinessName') }}</div>
              <v-text-field
                  v-model="request.body.business_name"
                  class="rounded-lg font-size-14"
                  outlined
                  required
                  dense
              />
            </v-col>

            <v-col cols="12" sm="6">
              <div class="mb-1 font-size-14">{{ __('LicenseNumber') }}</div>
              <v-text-field
                  v-model="request.body.license_number"
                  class="rounded-lg font-size-14"
                  outlined
                  required
                  dense
              />
            </v-col>

            <v-col cols="12">
              <div class="hidden-sm-and-down grey mb-6 lighten-3 pa-3 pa-md-9 overflow-hidden" style="border-radius: 9px !important;">
                <profile-from-info-services :request="request"/>
              </div>
              <div class="hidden-md-and-up mb-6 lighten-3 overflow-hidden" style="border-radius: 9px !important;">
                <profile-from-info-services :request="request"/>
              </div>
            </v-col>

          </v-row>
        </div>
      </template>
    </show-more>
  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import {PostRequest} from "@/models/PostRequest";
import FromListSelector from "@/components/selectors/FromListSelector";
import CurrencySelector from "@/components/selectors/CurrencySelector";
import ExpertisesSectionLists from "@/components/expertises/ExpertisesSectionLists";
import ExpertiseSelector from "@/components/selectors/ExpertiseSelector";
import JobExpertisesSelector from "@/components/selectors/JobExpertisesSelector";
import Space from "@/components/app/Space";
import ShowMore from "@/components/app/ShowMore";
import ProfileFromInfoServices from "@/components/profiles/ProfileFromInfoServices";

export default {
  name: "ProfileFromInfo",
  components: {
    ProfileFromInfoServices,
    ShowMore,
    Space,
    JobExpertisesSelector,
    ExpertiseSelector, ExpertisesSectionLists, CurrencySelector, FromListSelector, AssetsImage
  },
  props: {
    request: {
      default: null,
      type: PostRequest,
    },
  },
  mounted() {
    if (!this._is_object(this.request.body.typical_job_cost))
      this.request.body.typical_job_cost = {
        min: null,
        max: null
      };
  },
  methods: {}

}
</script>

<style>
.profile-form-info .v-input--is-dirty {
  padding: 0 !important;
}

.profile-form-info .v-messages {
  display: none !important;
}

.profile-form-info .font-size-14 .v-label {
  color: black !important;
}

</style>
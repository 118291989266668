<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col cols="12">

        <profile-form :id="$route.params.id"/>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Profile from "@/components/profiles/Profile";
import ProfileForm from "@/components/profiles/ProfileForm";

export default {
  name: "ProfessionalProfileForm",
  components: {ProfileForm, Profile},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

import Vue from "vue";
import {_t} from "../tools/Utils";
import app from "../tools/App";
import {Permission} from "../models/Permission";
import auth from "../tools/Auth";

Vue.mixin({
    methods: {
        __(key, lang = '') {
            return _t(key, lang)
        },
        _is_object(data, check_has_key = true) {
            try {
                let result = data && typeof data === 'object';
                if (check_has_key) result = result && Object.keys(data).length !== 0
                return result;
            } catch (e) {
                return false;
            }
        },
        _is_array(data, check_length = true) {
            try {
                let result = data && Array.isArray(data);
                if (check_length) result = result && data.length !== 0
                return result;
            } catch (e) {
                return false;
            }
        },
        _develop() {
            try {
                return window.location.hostname === 'localhost';
            } catch (e) {
                return false;
            }
        },
        _access(role_key, from_storage = false) {
            try {
                if (!role_key) return true;
                let user = this.$store.state.user_info;
                if (from_storage) user = auth.getUserInfo();

                let role = user.role;
                if (typeof role === 'object') role = role.id;
                return role === app.roles[role_key];
            } catch (e) {
                return false;
            }
        },
        _permission(key) {
            try {
                return Permission.has(key)
            } catch (e) {
                return false;
            }
        },
        _owner(user_id) {
            try {
                if (!user_id) return false;
                if (typeof user_id === 'object') user_id = user_id.id;
                let id = this.$store.state.user_info.id;
                return id === user_id;
            } catch (e) {
                return false;
            }
        },
        _link(url) {
            try {
                return url.startsWith('http') ? url : 'http://' + url;
            } catch (e) {
                return url;
            }
        },
        _role(key) {
            try {
                return app.roles[key];
            } catch (e) {
                return null;
            }
        },
    },
    computed: {
        _user() {
            try {
                return this.$store.state.user_info;
            } catch (e) {
                return false;
            }
        },
    }
});

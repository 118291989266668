<template>
  <div v-if="data" class="d-flex justify-space-between align-center mt-3">
    <div class="text-truncate">
      <span v-for="(item, index) in breadcrumbs_items" :key="'title-item-'+key">
        <span v-if="!item.href" :title="item.text" class="grey--text">{{item.text}}</span>
        <a v-else :href="item.href" :title="item.text">{{item.text}}</a>
        <span v-if=" index  < breadcrumbs_items.length">&nbsp;/&nbsp;</span>
      </span>
    </div>
    <div class="text-right d-flex justify-end">

      <unlock
          unique_key="project-breadcrumbs-unlock-package"
          v-if="!_access('pro')"
          :project_id="data.id"
          @then="$emit('reload')"
      >
        <div>
          <v-btn class="hidden-md-and-up" icon :title="__('Unlock')">
            <v-icon>mdi-lock-open-variant-outline</v-icon>
          </v-btn>
          <v-btn class="hidden-sm-and-down px-2" small text :title="__('Unlock')">
            <v-icon>mdi-lock-open-variant-outline</v-icon>
            <span>{{ __('Unlock') }}</span>
          </v-btn>
        </div>
      </unlock>


      <!--  Add Package Button    -->
      <add-package
          unique_key="project-breadcrumbs-add-package"
          v-if="!_access('pro')"
          :project_id="data.id"
          @then="$emit('reload')"
          :without="['subscription']"
          hide_free
          hide_subscription
          modal
      >
        <div>
          <v-btn class="hidden-md-and-up" icon :title="__('AddPackage')">
            <v-icon>mdi-package-variant-closed-plus</v-icon>
          </v-btn>

          <v-btn class="hidden-sm-and-down px-2" small text :title="__('AddPackage')">
            <v-icon>mdi-package-variant-closed-plus</v-icon>
            <span>{{ __('AddPackage') }}</span>
          </v-btn>
        </div>

      </add-package>


      <!--  Add Job    -->
      <project-add-job v-if="_access('admin') && !is_lead" :data="data" @then="$emit('reload')">
        <div>
          <v-btn class="hidden-md-and-up" icon :title="__('AddJob')">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-btn class="hidden-sm-and-down px-2" small text :title="__('AddJob')">
            <v-icon>mdi-plus</v-icon>
            <span>{{ __('AddJob') }}</span>
          </v-btn>
        </div>
      </project-add-job>


      <project-menu :data="data" @reload="$emit('reload')"/>

    </div>

  </div>
</template>

<script>

import ProjectMenu from "./ProjectMenu";
import AddPackage from "../packages/AddPackage";
import Unlock from "../packages/Unlock";
import ProjectAddJob from "./ProjectAddJob";

export default {
  name: "ProjectBreadcrumbs",
  components: {ProjectAddJob, Unlock, AddPackage, ProjectMenu},
  props: {
    data: {
      default: null,
      type: Object,
    },
    is_lead: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      dialog: null,
    }
  },
  computed: {
    title() {
      try {
        return this.data.title;
      } catch (e) {
        return null;
      }
    },

    breadcrumbs_items() {
      return [
        {
          text: this.__('Projects'),
          disabled: false,
          href: '/panel/projects',
        },
        {
          text: this.title,
          disabled: true,
        },
      ]
    },
  },
  methods: {
    get() {
    }
  }
}
</script>

<style scoped>

</style>
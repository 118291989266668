<template>
  <v-card v-if="data && data.id" class="rounded-lg overflow-hidden" flat>

    <div class="secondary rounded-lg overflow-hidden">

      <router-link :to="{name: route_name, params: { id: data.id }}">
        <assets-image :data="data.picture" height="290">
          <div class="ma-2">
            <v-chip small :color="color()" v-if="show_subscription_type && data.subscription_type !== 'expired'">
              {{ __(data.subscription_type) }}
            </v-chip>
            <span v-if="show_subscription_type">&nbsp;</span>
            <v-chip color="yellow" small v-if="(_access('admin') || _access('client')) && data.is_expired">
              <v-icon color="yellow darken-3" small>mdi-alert-circle</v-icon>&nbsp;
              {{ __('Expired') }}
            </v-chip>
          </div>
        </assets-image>
      </router-link>


      <!--  Title  -->
      <v-list-item class="mt-1">
        <v-list-item-title class="title" :title="data.title">
          <router-link :to="{name:route_name, params: { id: data.id }}" class="text-decoration-none text--text">

            {{ data.title ? data.title : __('None') }}
          </router-link>
        </v-list-item-title>

        <v-list-item-action>
          <project-menu :data="data" @reload="$emit('reload')"/>
        </v-list-item-action>
      </v-list-item>


      <v-card-text>
        <div>
          {{ __('By') }}
          <full-name :data="data.client_id"/>
        </div>

        <div>
          {{ __('CreatedAt') }} :
          <date-text :data="data.date_created"/>
        </div>

        <div>
          {{ __('Status') }} :
          <project-status :data="data"/>
        </div>

        <div style="height: 40px">
          <tags outlined :data="data.personal_tags" horizontal></tags>
        </div>

        <div v-if="hide_buttons && data.status === 'lead'" class="d-flex">
          <v-btn small text disabled :title="__('Unlock')">
            <v-icon>mdi-lock-open-variant-outline</v-icon>
            <span>{{ __('Unlock') }}</span>
          </v-btn>

          <add-package
              unique_key="project-add-package"
              :project_id="data.id"
              @then="$emit('reload')"
              :without="['subscription']"
              modal
              hide_free
              hide_subscription
          >
            <v-btn small text :title="__('AddPackage')">
              <v-icon>mdi-package-variant-closed-plus</v-icon>
              <span>{{ __('AddPackage') }}</span>
            </v-btn>
          </add-package>
        </div>
      </v-card-text>
    </div>

    <!--  Links  -->
    <div v-if="!hide_buttons">
      <google-links :data="data.google_drive_links" list/>
    </div>

  </v-card>
</template>

<script>

import FullName from "../account/FullName";
import GoogleLinks from "../links/GoogleLinks";
import DateText from "../app/DateText";
import AssetsImage from "../assets/Image";
import ProjectMenu from "./ProjectMenu";
import ProjectStatus from "./ProjectStatus";
import Tags from "../tags/Tags";
import AddPackage from "../packages/AddPackage";

export default {
  name: "LeadItem",
  components: {
    AddPackage, Tags, ProjectStatus, ProjectMenu, AssetsImage, DateText, GoogleLinks, FullName
  },
  props: {
    data: {
      default: null,
      type: Object
    },
    hide_buttons: {
      default: false,
      type: Boolean
    },
    route_name: {
      default: 'Project',
      type: String
    },
    show_subscription_type: {
      default: false,
      type: Boolean
    },
  },
  computed: {},
  data: () => {
    return {
      show_more: false,
    }
  },
  mounted() {

  },
  methods: {
    color() {
      try {
        if (this.data.subscription_type === 'trial') return 'red lighten-3';
        if (this.data.subscription_type === 'essential') return 'amber lighten-4';
        if (this.data.subscription_type === 'ultimate') return 'green lighten-4';
        return 'secondary';
      } catch (e) {
        return 'secondary';
      }
    }
  },
}
</script>

<style scoped>

</style>
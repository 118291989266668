<template>
  <div v-if="data && items()" class="px-0">
    <v-card class="grey lighten-4 rounded-lg" flat>
      <v-card-title class="title">
        {{  title ? title : __('DesignPackages') }}
      </v-card-title>

      <div class="pa-3" v-if="items()">
        <div v-for='(pack, index) in items()' :key="'project-package-services-pack-'+index">
          <div v-for='(item, key) in pack' :key="'project-package-services-item-'+key">
            <packages-service :data="item" class="py-3"/>
          </div>
        </div>
      </div>

      <not-found-data v-else style="padding-top: 130px !important; padding-bottom: 130px !important"/>
    </v-card>
  </div>
</template>

<script>

import NotFoundData from "../app/NotFoundData";
import PackagesService from "../packages/PackagesServices";

export default {
  name: "ProjectPackagesServices",
  components: {PackagesService, NotFoundData,},
  props: {
    data: {
      default: null,
      type: Object,
    },
    title: {
      default: null,
      type: String,
    },
    type: {
      default: 'design',
      type: String,
    }
  },
  methods: {
    items() {
      try {
        let result = [];
        this.data.packages.forEach((item) => {
          if (item.package_id.type !== this.type) return null;
          result.push(item.package_services);
        })
        if (result.length === 0) return null;
        return result;
      } catch (e) {
        return null;
      }
    }
  }
}
</script>

<style scoped>

</style>
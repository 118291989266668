<template>
  <div >
    <div class="hidden-sm-and-down">
      <div class="d-flex   mb-2 justify-space-between" style="line-height: 50px">
        <div>
          <h3 class="text--disabled font-size-18">
            <span>{{ __('ReviewsFor') }} {{ data.business_name }}&nbsp;</span>
            <span v-if="request.total" class="primary--text">({{ request.total }})</span>
          </h3>
        </div>
        <div style="max-width: 190px">
          <reviews-actions :request="request"/>
        </div>
      </div>
    </div>

    <v-divider class="mt-5 mt-sm-0"/>

    <!-- Loading   -->
    <loader v-if="request.isLoading()" cols="12" col_sm="12" count="3" type="card"/>

    <div v-else>

      <!--  List  -->
      <template v-for="(item, key) in request.data">
        <review
            v-if="_is_object(item)"
            :key="'review-'+key+item.id"
            :data="item"
            :hide_reply="!_owner(data.pro_id)"
            @edit="write"
            @delete="(e) => remove(e, true)"
            @delete-reply="(e) => remove(e, false)"
            @reply="e => write(e, true)"
        />
      </template>

      <v-col cols="12" class="py-5 text-center">
        <!--  Load More  -->
        <load-more :request="request" :title="__('LoadMoreComments')" outlined block/>

        <!--  Not Found    -->
        <not-found-data v-if="!request.data"/>
      </v-col>
    </div>

  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import LoadMore from "@/components/app/LoadMore";
import NotFoundData from "@/components/app/NotFoundData";
import Loader from "@/components/app/Loader";
import Review from "@/components/reviews/Review";
import ReviewsActions from "@/components/reviews/ReviewsActions";
import {DeleteRequest} from "@/models/DeleteRequest";

export default {
  name: "ProfileReviews",
  components: {ReviewsActions, Review, Loader, NotFoundData, LoadMore},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      request: new GetRequest('get_items'),
      delete_request: new DeleteRequest('item'),
    }
  },
  mounted() {
    if (!this.data) return;
    this.request.data = this.data.reviews;
    this.get();
  },
  methods: {
    remove(data, decrease_total = true) {
      try {
        if (!data) return;
        this.delete_request.setMessage(this.__('deleted'))
        this.delete_request.setApiParams(['pro_profile_review', data.id])
        this.delete_request.setThen(() => {
          if (decrease_total) this.request.total --;
        });
        this.delete_request.delete();
      } catch (e) {
        console.log(e);
      }
    },
    write(data, is_reply = false) {
      try {
        if (!this._is_object(data)) return;

        let query = {review_id: data.id};
        if (is_reply) query = {reply_to: data.id};

        this.$router.push({
          'name': 'ProfessionalProfileReview',
          'params': {id: this.data.id},
          'query': query
        })
      } catch (e) {
        console.log(e);
      }
    },
    get() {
      if (!this._is_object(this.data)) return;
      this.request.setLimit(4);
      this.request.setApiParams(['pro_profile_review', this.data.id])
      this.request.addFilter('reply_to', {"_null": true})
      this.request.addFilter('pro_profile_id', this.data.id)
      this.request.setFields('*,pro_profile_id.*,replies.pro_profile_id.*,images.directus_files_id,user_created.id,user_created.full_name,user_created.avatar,images.directus_files_id,replies.*,replies.images.directus_files_id,replies.user_created.full_name,replies.user_created.id,replies.user_created.avatar')
      this.request.setSort('total_score', '-')
      this.request.get()
    }

  }

}
</script>

<style scoped>

</style>
<template>
  <div class="show-more">
    <v-list-item @click="show_more  = !show_more" class="py-0">
      <v-list-item-title>
        <slot name="title">

        </slot>
      </v-list-item-title>
      <v-list-item-action>
        <v-btn small icon>
          <v-icon color="primary" v-if="show_more">mdi-chevron-up</v-icon>
          <v-icon color="primary" v-else>mdi-chevron-down</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>


    <div v-show="show_more">
      <slot name="more"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowMore",
  props:{
    open_default:{
      type: Boolean,
      default:false,
    }
  },
  data(){
    return {
      show_more: false,
    }
  },
  mounted() {
    this.show_more = this.open_default;
  }
}
</script>

<style  >
.show-more .v-list-item--link:before {
  background: transparent !important;
}
</style>
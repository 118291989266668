<template>
  <v-container class="container-max-width">
    <v-row>
      <v-col cols="12">
        <profile-blog-form :profile_id="$route.params.id" :blog_id="$route.params.blog_id"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ProfileBlogForm from "@/components/profiles/ProfileBlogForm";

export default {
  name: "ProfessionalBlogForm",
  components: {ProfileBlogForm},
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped>

</style>

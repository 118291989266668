import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,[_c(VCard,{staticClass:"border-light rounded-lg overflow-hidden",attrs:{"outlined":""}},[_c('form',{ref:"upload-form",on:{"submit":function($event){$event.preventDefault();return _vm.request.post()}}},[(_vm.request.isLoading() || _vm.user_info_request.isLoading())?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('div',{staticClass:"pt-6 pb-3 px-3 text-center"},[_c('router-link',{staticClass:"mt-1 mx-auto",attrs:{"to":{name:'Home'}}},[_c('logo')],1),_c('h2',{staticClass:"font-weight-medium mt-2"},[_vm._v(_vm._s(_vm.__('SelectRole')))]),_c('div',[_vm._v(_vm._s(_vm.__('ToRenotive')))])],1),_c(VCardText,[_c(VCard,{staticClass:"grey lighten-4 pa-6 text-center mb-3 pointer",attrs:{"flat":""},on:{"click":function($event){return _vm.select('client')}}},[_vm._v(" "+_vm._s(_vm.__("Client"))+" ")]),_c(VCard,{staticClass:"grey lighten-4 pa-6 text-center mb-3 pointer",attrs:{"flat":""},on:{"click":function($event){return _vm.select('pro')}}},[_vm._v(" "+_vm._s(_vm.__("Pro"))+" ")])],1),_c(VCardActions,{staticClass:"justify-end px-4 pb-6"})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>

    <template v-if="request.is_loading">
      <loading/>
    </template>

    <template v-else-if="item">
      <v-row>
        <v-col cols="12">
          <profile-breadcrumbs :data="item.pro_profile_id" :advanced_items="breadcrumbs_items"/>
        </v-col>
        <v-col cols="12" >
          <div class="project-title text-center mb-2">
            <div class="px-3 pb-2 text-h5 font-head d-inline-block main-footer-title">{{ item.title }}</div>
            <div class="pt-3 d-flex align-center justify-center text-center">
              <div>
                <v-list-item class="px-0">
                  <v-list-item-avatar>
                    <avatar :data="item.user_created" color="secondary" size="35"/>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span>{{ item.user_created.full_name }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <div class="px-3 primary--text">
                |
              </div>
              <date-text :data="item.date_created" class="text--disabled" use_format/>
            </div>
          </div>

          <assets-image :data="item.pictures" class="rounded-lg"/>

          <div v-html="item.text" class="el-tiptap-editor__content py-6"/>

          <space height="30"/>
          <div class="text-center py-3 font-weight-bold">{{ __('OtherStoriesFromThisUser') }}</div>
          <space height="15"/>

          <div class="px-3">
            <profile-stories
                :data="item.pro_profile_id"
                :without="[item.id]"
                :loader_count="4"
                limit="15"
                hide_search
                hide_title
                horizontal
                hide_load_more
            />
          </div>
          <space height="45"/>
        </v-col>
      </v-row>

    </template>

    <template v-else>
      <not-found-data class="py-16"/>
    </template>

  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import Loading from "@/components/app/Loading";
import PortfolioProjectHeader from "@/components/portfolio/PortfolioProjectHeader";
import PortfolioProjectText from "@/components/portfolio/PortfolioProjectText";
import PortfolioProjectPictures from "@/components/portfolio/PortfolioProjectPictures";
import ProfileProjects from "@/components/profiles/ProfileProjects";
import NotFoundData from "@/components/app/NotFoundData";
import ProfileActions from "@/components/profiles/ProfileActions";
import ProfileContact from "@/components/profiles/ProfileContact";
import ProfileBreadcrumbs from "@/components/profiles/ProfileBreadcrumbs";
import Space from "@/components/app/Space";
import DateText from "@/components/app/DateText";
import Avatar from "@/components/account/Avatar";
import AssetsImage from "@/components/assets/Image";
import ProfileStories from "@/components/profiles/ProfileStories";

export default {
  name: "PortfolioBlog",
  components: {
    ProfileStories,
    AssetsImage,
    Avatar,
    DateText,
    Space,
    ProfileBreadcrumbs,
    ProfileContact,
    ProfileActions,
    NotFoundData,
    ProfileProjects,
    PortfolioProjectPictures,
    PortfolioProjectText,
    PortfolioProjectHeader,
    Loading,
  },
  props: {
    id: {
      default: null,
      type: [String, Number],
    },

  },
  watch: {
    'id'() {
      this.get()
    },
    'without'() {
      this.get()
    }
  },
  data() {
    return {
      request: new GetRequest('item'),
    }
  },
  computed: {
    breadcrumbs_items() {
      try {
        return [
          {
            text: this.item.title,
            disabled: true
          }
        ];
      } catch (e) {
        return [];
      }
    },
    item() {
      return this.request.data;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      if (!this.id) return;
      this.request.setApiParams(['blog', this.id]);
      this.request.setFields('*,pictures.directus_files_id, pro_profile_id.*, user_created.*')
      this.request.setThen((data) => this.$emit('onload', data));
      this.request.row();
    }
  }
}
</script>

<style  >

</style>
<template>
  <v-app id="panel-app">

    <!--  App Bar  -->
    <v-app-bar
        fixed
        app
        color="background"
        elevation="0"
        clipped-left
        height="80"
    >
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link to="/">
          <logo/>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <account/>
    </v-app-bar>

    <!-- Drawer   -->
    <v-navigation-drawer
        clipped
        v-model="drawer"
        width="290"
        app
    >

      <v-list>
        <v-list-item-group color="primary">
          <template v-for="(item, key) in menu()">
            <v-list-item
                v-if="(item.not_role) ? !_access(item.not_role) : _access(item.role)"
                :key="'header-drawer-'+key"
                :to="item.to"
                :disabled="!item.to"
                active-class="secondary "
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>

    </v-navigation-drawer>

    <!--  Main  -->
    <v-main class="pb-7">
      <router-view></router-view>
    </v-main>

  </v-app>

</template>

<script>
import Logo from "../app/Logo";
import Account from "../account/Account";

export default {
  name: 'PanelLayout',
  components: {Account, Logo},
  computed: {},

  data() {
    return {
      drawer: null,
    }
  },
  created() {
  },
  methods: {
    items() {
      return {
        'projects': {
          title: this.__('Projects'),
          to: {name: 'Projects'},
          icon: 'mdi-archive-cog'
        },
        'professionals': {
          title: this.__('Professionals'),
          to: {name: 'Professionals'},
          icon: 'mdi-account-hard-hat',
        },
        'clients': {
          title: this.__('Clients'),
          to: {name: 'Clients'},
          icon: 'mdi-account-box',
        },
        'ideas': {
          title: this.__('Ideas'),
          to: {name: 'Ideas'},
          icon: 'mdi-lightbulb',
        },
        'lead': {
          title: this.__('Lead'),
          to: {name: 'Lead'},
          icon: 'mdi-archive',
        },
        'leadjobs': {
          title: this.__('LeadJobs'),
          to: {name: 'LeadJobs'},
          icon: 'mdi-hammer-screwdriver',
        },
        'users': {
          title: this.__('Users'),
          to: {name: 'Users'},
          icon: 'mdi-account',
        },
        'blogs': {
          title: this.__('Blogs'),
          to: {name: 'Blogs'},
          icon: 'mdi-bookshelf',
        },
        'settings': {
          title: this.__('Settings'),
          to: {name: 'Settings'},
          icon: 'mdi-cog',
        }
      };
    },
    menu() {
      let items = this.items();
      let result = [];
      Object.keys(items).forEach((key) => {
        if (!this._permission(key)) return;
        result.push(items[key]);
      })
      return result;
    }
  }
}
</script>

<style>
#panel-app .v-navigation-drawer__border {
  background-color: rgba(0, 0, 0, 0.05);
  width: 1px;
  border: none;
  outline: none;
}
</style>

<template>
  <div v-if="data && data.id">
    <v-list-item two-line :value="data.id">
      <v-list-item-avatar size="60">
        <v-avatar color="secondary" size="50">
          <assets-image v-if="data.avatar" :data="data.avatar" width="30"/>
          <v-icon v-else :data="data.avatar">mdi-account</v-icon>
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title :class="{'pointer': show_info, 'mb-2':true}" @click="open()">
          {{ data.first_name }}
          {{ data.last_name }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="Array.isArray(data.expertises) && data.expertises.length !== 0">
          <span v-for="(item, key) in data.expertises" :key="item.id">
            <span v-if="item.expertise_id && item.expertise_id.title">
              {{ item.expertise_id.title }}
              <span v-if="key !== data.expertises.length -1">,</span>
            </span>
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <div class="d-flex align-center">
          <slot name="actions"></slot>
          <div v-if="show_buttons">

            <v-btn
                :disabled="!data.telegram_id"
                icon
                :href="'https://t.me/' + data.telegram_id"
                target="_blank"
                :title="__('Telegram')"
            >
              <v-icon>mdi-chat</v-icon>
            </v-btn>

            <v-btn :disabled="!data.email" icon :href="'mailto:' + data.email" target="_blank" :title="__('Email')">
              <v-icon>mdi-email</v-icon>
            </v-btn>

            <v-btn
                :disabled="!data.phone_number"
                icon
                :href="'tel:' + data.phone_number"
                target="_blank"
                :title="__('Call')"
            >
              <v-icon>mdi-phone</v-icon>
            </v-btn>

          </div>

          <user-menu v-if="show_menu" :data="data" hide_archive hide_edit/>
        </div>
      </v-list-item-action>
    </v-list-item>

    <!-- More Info   -->
    <modal max_width="390" v-model="dialog">

      <template v-slot:button>

      </template>

      <template v-slot:title>
        <span>{{ __("Professional") }}</span>
      </template>

      <template v-slot:main>
        <div class="px-3">

          <div class="d-flex align-center justify-space-between pt-3 pb-2">
            <v-avatar color="secondary" size="50">
              <assets-image v-if="data.avatar" :data="data.avatar" width="30"/>
              <v-icon v-else :data="data.avatar">mdi-account</v-icon>
            </v-avatar>
            <space/>
            <div class="font-weight-bold">
              {{ data.first_name }}
              {{ data.last_name }}
            </div>
            <v-spacer/>
            <user-menu :data="data" hide_archive hide_edit/>
            <v-btn icon @click="dialog = false" class="grey lighten-4">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <div class="pa-2">

            <div class="py-2">
              {{ __('Email') }} :
              {{ data.email }}
            </div>

            <div class="py-2">
              {{ __('PhoneNumber') }} :
              {{ data.phone_number }}
            </div>

            <div class="py-2">
              {{ __('TelegramId') }} :
              @{{ data.telegram_id }}
            </div>


            <div class="py-2">
              {{ __('Expertises') }} :
              <span v-for="(item, key) in data.expertises" :key="item.id">
                <span v-if="item.expertise_id && item.expertise_id.title">
                  {{ item.expertise_id.title }}
                  <span v-if="key !== data.expertises.length -1">,</span>
                </span>
              </span>
            </div>


            <div class="py-2" v-if="!_access('client')">
              {{ __('Status') }} :
              {{ data.status }}
            </div>

            <div class="py-3" v-if="!request.isLoading()">
              <professional-note :data="getNoteData()"/>
            </div>
            <div class="py-3" v-else>
              <loading margin="90px auto"/>
            </div>
            <div class="py-2 mt-4 ">
              <v-btn type="link" outlined :href="portfolio_link()" target="_blank">
                <span>{{ __('DownloadPortfolio') }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:actions>

      </template>
    </modal>
  </div>
</template>

<script>

import AssetsImage from "@/components/assets/Image";
import UserMenu from "../users/UserMenu";
import Modal from "../modal/Modal";
import Space from "../app/Space";
import urls from "../../tools/Urls";
import ProfessionalNote from "./ProfessionalNote";
import {GetRequest} from "../../models/GetRequest";
import Loading from "../app/Loading";

export default {
  name: "ProfessionalListItem",
  components: {Loading, ProfessionalNote, Space, Modal, UserMenu, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    show_buttons: {
      default: false,
      type: Boolean
    },
    show_menu: {
      default: true,
      type: Boolean
    },
    show_info: {
      default: true,
      type: Boolean
    },
  },
  data() {
    return {
      dialog: null,
      request: new GetRequest('get_professional_info')
    }
  },
  mounted() {
    if (!this.data) return;
    this.request.setApiParams([this.data.id])
  },
  methods: {
    portfolio_link() {
      try {
        if ((this.data.portfolio !== null) && (this.data.portfolio.length > 0))
          return urls.api('assets_url', [this.data.portfolio]);
        else
          return ''
      } catch (e) {
        return '';
      }
    },
    open() {
      if (!this.show_info) return;
      this.dialog = true
      this.request.row();
    },
    getNoteData() {
      try {
        if (this.request.data) return this.request.data;
        return this.data;
      } catch (e) {
        return this.data;
      }
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div v-if="_is_object(data)">
    <v-row class="align-center py-3">

      <!--  Drag Icon    -->
      <v-col cols="2" sm="1" md="2">
        <div class="d-flex">
          <div>
            <v-icon>mdi-drag</v-icon>
          </div>
          <space width="20" class="hidden-sm-and-down"/>
          <div >
            <div class="d-flex">
              <v-avatar color="primary" class="background--text" size="25">
                <span style="margin-top:3px;">{{ number }}</span>
              </v-avatar>
              <space class="hidden-sm-and-down"/>
              <div class="hidden-sm-and-down">
                <div class="font-weight-bold">{{ data.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </v-col>

      <!--  Image    -->
      <v-col cols="4" sm="3" md="2">
        <div class="px-0 px-sm-1 px-md-3">
          <assets-image :data="data.pictures" class="rounded-lg" height="120" width="120"/>
        </div>
      </v-col>

      <!--  Info    -->
      <v-col cols="6" sm="5" class="primary--text">
        <div class="text-truncate">
          <span class="text--disabled">{{ __('ImageTitle') }} :</span>
          <span>{{ data.title }}</span>
        </div>
        <div v-if="_is_object(data.room_id)" class="text-truncate">
          <span class="text--disabled">{{ __('Room') }} : </span>
          <span>{{ data.room_id.title }}</span>
        </div>
        <div class="text-truncate">
          <span class="text--disabled">{{ __('PhotographyCredit') }} : </span>
          <span>{{ data.photography_credit }}</span>
        </div>
        <div v-if="_is_object(data.color_id)" class="d-flex align-center text-truncate">
          <div class="text--disabled">{{ __('Color') }} :</div>&nbsp;
          <v-avatar :color="data.color_id.value" size="18"/>&nbsp;
          <div>{{ data.color_id.name }}</div>
        </div>

      </v-col>

      <!--   Actions   -->
      <v-col cols="12"  sm="3">
        <div class="d-flex justify-end">

          <v-btn
              v-if="1 < number"
              class="px-2 px-md-3 hidden-sm-and-up rounded-lg"
              color="primary"
              max-width="250"
              min-width="auto"
              outlined
              @click="$emit('selected-for-cover')"
          >
            <span class="font-size-12">{{ __('SelectCover') }}</span>
          </v-btn>

          <space class="hidden-md-and-up"/>

          <div class="d-flex">
            <v-btn
                class="px-2 px-md-3  rounded-lg"
                color="grey"
                max-width="250"
                min-width="auto"
                outlined
                @click="remove"
                :disabled="request.isLoading()"
            >
              <v-icon small>mdi-delete</v-icon>
              <span class="hidden-sm-and-down">
                &nbsp; <loading-dots  :request="request" :text="__('Delete') "/>
              </span>

            </v-btn>

            <space/>

            <v-btn
                class="px-2 px-md-3  rounded-lg"
                color="primary"
                max-width="250"
                min-width="auto"
                outlined
                @click="$emit('edit')"
            >
              <v-icon small>mdi-pencil-outline</v-icon>
              <span class="hidden-sm-and-down">&nbsp;{{ __('Edit') }}</span>
            </v-btn>
          </div>


        </div>


      </v-col>


    </v-row>

  </div>
</template>

<script>
import Space from "@/components/app/Space";
import AssetsImage from "@/components/assets/Image";
import IdeasEditorForm from "@/components/ideas/IdeasEditorForm";
import {DeleteRequest} from "@/models/DeleteRequest";
import LoadingDots from "@/components/app/LoadingDots";

export default {
  name: "IdeasEditorItem",
  components: {LoadingDots, IdeasEditorForm, AssetsImage, Space},
  props: {
    data: {
      default: null,
      type: Object,
    },
    is_cover: {
      default: false,
      type: Boolean,
    },
    number: {
      default: null,
      type: [String, Number],
    }
  },
  data() {
    return {
      request: new DeleteRequest('item'),
    }
  },
  methods: {
    remove() {
      try {
        if (!this.data || !this.data.id) return;
        if (!confirm(this.__('AreYouSure'))) return;
        this.request.setApiParams(['idea', this.data.id]);
        this.request.setBody({'status': 'archived'});
        this.request.setThen((res) => this.$emit('remove', res))
        this.request.patch();
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <ideas
        api="get_project_ideas"
        :api_params="[this.id]"
        :filter_status="false"
        :parameters="parameters"
        :item_attrs="{in_modal: true}"
        sort="date_added_to_project"
        ref="ideas"
        disabled_auto
    >

      <!--  Add Button    -->
      <template v-slot:add>
        <project-add-idea :id="id" @then="get()"/>
        <space/>
      </template>

      <!--  Selectors    -->
      <template v-slot:creator="{request}">
        <inline-selector :items="items()" @input="get" :value="request.getParameter('scope')" :disabled="request.isLoading()"/>
      </template>
    </ideas>
  </div>
</template>

<script>
import Ideas from "../ideas/Ideas";
import InlineSelector from "../selectors/InlineSelector";
import {Parameters} from "../../models/Parameters";
import Space from "../app/Space";
import ProjectAddIdea from "./ProjectAddIdea";

export default {
  name: "ProjectIdeas",
  components: {ProjectAddIdea, Space, InlineSelector, Ideas},
  props: {
    id: {
      default: null,
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      parameters: new Parameters(),

    }
  },
  mounted() {
    this.get()
  },
  methods: {
    items() {
      return [
        {
          text: this.__('All'),
          value: 'published_ideas',
        },
        {
          text: this.__('AddedByMe'),
          value: 'created_by_client'
        },
        {
          text: this.__('AddedByPro'),
          value: 'created_by_pro'
        },
      ];
    },
    get(e = 'published_ideas') {
      this.parameters.set('scope', e);
      this.$refs.ideas.get();
    },

  },


}
</script>

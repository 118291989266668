import Vue from "vue";

export class CustomFilters {

    constructor() {
        this.object = {}
    }

    /**
     * Add item
     * @param key
     * @param value
     */
    add(key, value) {
        try {
            if (!value) return this.remove(key);
            if (Array.isArray(value) && value.length === 0) return this.remove(key);
            Vue.set(this.object, key, value)
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Remove item
     * @param key
     */
    remove(key) {
        try {
            Vue.delete(this.object, key)
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Delete items
     * @param key
     */
    reset() {
        this.object = null;
    }


    /**
     * Get filters object
     * @returns {{}}
     */
    getObject() {
        try {
            return {'_and': Object.values(this.object)};
        } catch (e) {
            return [];
        }
    }

}

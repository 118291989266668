<template>
  <!--  Update Setting  -->
  <div>
    <loading v-if="request.isLoading()"/>
    <form v-else @submit.prevent="request_update.patch()">
      <v-row v-if="request_update.body && social_media">
        <v-col cols="12" md="6" v-for="(item, key) in social_media" :key="'item-key-'+key">
          <div class="font-weight-bold">{{ __(item.name) }}</div>
          <v-text-field
              v-model="request_update.body.social_media[key].link"
              :placeholder="item.link"
              dense
              outlined
              required
          >
            <template v-slot:prepend>
              <assets-image :data="item.icon" width="26"/>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('GoogleDrive') }}</div>
          <v-text-field
              v-model="request_update.body.default_files_folder_id"
              dense
              outlined
              required
          >
            <template v-slot:prepend>
              <v-icon class="mr-1">mdi-google-drive</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col md="12">

          <v-btn type="submit" color="primary" elevation="0" :disabled="request_update.isLoading()">
            <span v-if="!request_update.is_loading">{{ __('Submit') }}</span>
            <span v-else>...</span>
          </v-btn>
        </v-col>

      </v-row>
    </form>
  </div>

</template>

<script>

import {GetRequest} from "@/models/GetRequest";
import {PostRequest} from "@/models/PostRequest";
import AssetsImage from "@/components/assets/Image";
import Loading from "../app/Loading";

export default {
  name: "Settings",
  components: {Loading, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object
    },
  },
  data() {
    return {
      request_update: new PostRequest('update_settings'),
      request: new GetRequest('get_settings'),
    }
  },
  computed: {
    social_media() {
      try {
        return this.request.data.social_media;
      } catch (e) {
        return null;
      }
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.request.setThen(this.setBody);
      this.request.row();
    },
    setBody() {
      let social_media_items = [];
      if (this.social_media) {
        this.social_media.forEach(s => social_media_items.push(s))
      }
      this.request_update.setBody(
          {
            default_files_folder_id: this.request.data.default_files_folder_id ?? null,
            social_media: social_media_items
          }
      );
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width background  ">
    <v-list-item dense>
      <v-list-item-avatar v-if="data.logo" class="mr-3">
        <assets-image :data="data.logo"/>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="  font-size-14 font-mt-2 grey--text">
          {{
            data.business_name
          }}
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <div v-if="!_owner(data.pro_id)" class="text-center">
          <v-btn class="rounded-lg mr-2" width="36" height="36" disabled icon outlined>
            <v-icon small>mdi-comment</v-icon>
          </v-btn>
          <v-btn
              class="rounded-lg mr-2"
              color="#00B2A8"
              width="36"
              height="36"
              v-if="_user"
              :href="'tel:'+data.phone_number"
              icon
              outlined
          >
            <v-icon small>mdi-phone</v-icon>
          </v-btn>
          <v-btn
              color="#0066FF"
              class="rounded-lg"
              width="36"
              height="36"
              v-if="_user"
              :href="'mailto:'+data.email"
              icon
              outlined
          >
            <v-icon small>mdi-email</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>

  </div>
</template>

<script>
import Rate from "@/components/app/Rate";
import AssetsImage from "@/components/assets/Image";

export default {
  name: "ProfileNameSmall",

  components: {AssetsImage, Rate},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>
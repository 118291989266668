<template>
  <v-list-item v-if="getData()" v-bind="$attrs">

    <div class="pr-3">
      <avatar :data="getData()" color="secondary" :to="to"/>
    </div>


    <v-list-item-content>
      <v-list-item-title>
        <full-name :data="getData()"/>
      </v-list-item-title>
      <v-list-item-subtitle v-if="!hide_email">
        <span>{{ getData('email') }}</span>
      </v-list-item-subtitle>

    </v-list-item-content>

    <v-list-item-action>
      <div class="d-flex">
        <v-btn v-if="call && getData('phone_number')" :href="'tel:'+getData('phone_number')"
               :title="getData('phone_number')" icon>
          <v-icon>mdi-phone</v-icon>
        </v-btn>
        <v-btn v-if="chat && getData('telegram_id')" :href="'https://t.me/'+getData('telegram_id')"
               :title="__('Telegram')+' : @'+getData('telegram_id')" target="_blank" icon>
          <v-icon>mdi-chat</v-icon>
        </v-btn>
        <v-btn v-if="email && getData('email')" :href="'mailto:'+getData('email')"
               :title="__('Email')+' :  '+getData('email')" target="_blank" icon>
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <slot></slot>
        <v-chip v-if="role && getRole()" small>{{ getRole() }}</v-chip>
      </div>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Avatar from "../account/Avatar";
import FullName from "../account/FullName";

export default {
  name: "User",
  components: {FullName, Avatar},
  props: {
    data: {
      default: null,
      type: Object,
    },
    to: {
      default: null,
      type: Object,
    },
    call: {
      default: false,
      type: Boolean,
    },
    hide_email: {
      default: false,
      type: Boolean,
    },
    email: {
      default: false,
      type: Boolean,
    },
    role: {
      default: false,
      type: Boolean,
    },
    chat: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    user() {
      return this.$store.state.user_info
    }
  },
  methods: {
    getData(key = null) {
      try {
        let data = this.data ? this.data : this.user;
        if (!key) return data;
        return data[key];
      } catch (e) {
        return null;
      }
    },
    getRole() {
      try {
        let role = this.getData('role')
        return role.name;
      } catch (e) {
        return null;
      }
    }
  }
}
</script>

<style scoped>

</style>
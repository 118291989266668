<template>
  <v-container>
    <page-title :text="__('Lead')" heading class="pr-6"/>
    <projects  lead_status="new" :add_is_visible="true" route_name="LeadDetails" hide_status_selector hide_buttons hide_subscribe show_subscription_type>

      <template v-slot:selector="{request}">
        <v-row>
          <v-col>
            <lead-projects-status :request="request"/>
          </v-col>
        </v-row>
      </template>
    </projects>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Projects from "../../../components/projects/Projects";
import LeadProjectsStatus from "../../../components/lead/LeadProjectsStatus";

export default {
  name: "LeadPage",
  components: {LeadProjectsStatus, Projects, PageTitle,}
}
</script>

<style scoped>

</style>
<template>
  <div v-if="request.body">
    <v-row align="center">
      <v-col cols="12" sm="12" md="4" class="pb-0 pb-md-3">
        <div class="py-3">{{ __('LanguagesCommunicate') }}</div>
      </v-col>
      <v-col cols="12" sm="8" class="pt-0 pt-md-3">
        <div v-if="get_request.isLoading()" class="py-3 ">&nbsp;<span>...</span></div>
        <div v-else class="d-flex align-center">
          <template v-for="(item, index) in request.body.languages">
            <div class="d-flex pa-3 align-center" style="line-height: 25px;">
              <template v-if="_is_object(language(item))">
                <assets-image :data="language(item, 'icon')" width="20" height="15"/>&nbsp;&nbsp;
                <div>{{ language(item, 'name') }}</div>
                <v-btn v-if="language(item, 'name') !== 'English'" icon small @click="unselect(index)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </template>
            </div>
          </template>


          <modal class="pa-5" max_width="390" width="100%">
            <template #button>
              <v-btn class="text-transform-unset" small outlined>
                <v-icon>mdi-plus</v-icon>&nbsp;
                {{ __('AddLanguage') }}
              </v-btn>
            </template>

            <template #main>
              <div>
                <div class="font-weight-bold mb-3">{{ __("Languages") }}</div>

                <languages-selector
                    :value="selected_items"
                    :data="request.data"
                    :disabled_items="['English']"
                    @input="setSelected"
                    multiple
                    sm="12"
                    hide_title
                    hide_first_item
                    list
                />
              </div>
            </template>
          </modal>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {PostRequest} from "@/models/PostRequest";
import AssetsImage from "@/components/assets/Image";
import LanguagesSelector from "@/components/selectors/LanguagesSelector";
import Modal from "@/components/modal/Modal";
import {GetRequest} from "@/models/GetRequest";

export default {
  name: "ProfileFormLanguages",
  components: {Modal, LanguagesSelector, AssetsImage},

  props: {
    request: {
      default: null,
      type: PostRequest,
    },
  },
  data() {
    return {
      model: null,
      get_request: new GetRequest('get_items', ['language']),
      languages: {},
    }
  },
  computed: {
    'selected_items'() {
      try {
        return this.request.body.languages.map((item) => item.language_id);
      } catch (e) {
        return [];
      }
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      this.get_request.setSort('name');
      this.get_request.setThen(() => this.setLanguages())
      this.get_request.get();
    },
    language(item, key = null) {
      try {
        if (this._is_object(item)) item = item.language_id;
        if (!key) return this.languages[item];
        return (this.languages[item])[key];
      } catch (e) {
        console.log(e);
      }
    },
    setLanguages() {
      try {
        this.get_request.data.forEach((item) => this.$set(this.languages, item.id, item));
      } catch (e) {
        console.log(e);
      }
    },
    setSelected(data) {
      try {
        data = data.map(item => ({language_id: item}))
        this.$set(this.request.body, 'languages', data);
      } catch (e) {
        console.log(e);
      }
    },
    unselect(index) {
      try {
        this.$delete(this.request.body.languages, index);
      } catch (e) {
        console.log(e);
      }
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <div v-if="_is_object(data)" class="d-flex justify-end py-2 align-center text--disabled">
    <small>
      <date-text :data="data.date_created" use_format/>
    </small>


    <template v-if="is_owner">
      <span>&nbsp;&nbsp;&nbsp;|&nbsp;</span>
      <v-btn class="text-transform-unset" color="grey" @click="()=> $emit('delete', data)" text small>
        <v-icon small>mdi-delete</v-icon>&nbsp;
        {{ __('Delete') }}
      </v-btn>
      <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <v-btn class="text-transform-unset" color="grey" @click="()=> $emit('edit', data)" text small>
        <v-icon small>mdi-pencil</v-icon>&nbsp;
        {{ __('Edit') }}
      </v-btn>
    </template>

    <template v-if="!hide_reply">
      <span>&nbsp;&nbsp;&nbsp;|&nbsp;</span>
      <v-btn class="text-transform-unset" @click="()=> $emit('reply', data)" color="grey" text small>
        <v-icon small>mdi-reply</v-icon>&nbsp;
        {{ __('Reply') }}
      </v-btn>
    </template>

  </div>
</template>

<script>
import DateText from "@/components/app/DateText";

export default {
  name: "ReviewActions",
  components: {DateText},
  props: {
    data: {
      default: null,
      type: Object,
    },
    hide_reply: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    is_owner() {
      try {
        return this._is_object(this.data.user_created) && this._owner(this.data.user_created.id)
      } catch (e) {
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>
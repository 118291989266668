<template>
  <div>
    <div class="font-weight-bold">{{ title ? title : __('Date') }}</div>
    <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            outlined
            dense
            v-model="date"
            append-icon="mdi-calendar"
            readonly
            @click="modal = true"
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" scrollable>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="$refs.dialog.save(null)">
          {{ __('Cancel') }}
        </v-btn>
        <v-btn text color="primary" @click="select">
          {{ __('OK') }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "DateSelector",
  components: {},
  props: {
    value: {
      default: null,
      type: String,
    },

    title: {
      default: null,
      type: String,
    },
  },
  watch: {
    'value'() {
      this.date = this.value;
    }
  },
  computed: {},

  data() {
    return {
      date: null,
      dialog: null,
      modal: null,
    }
  },

  mounted() {
    this.date = this.value;
  },
  methods: {
    select() {
      this.$emit('input', this.date);
      this.$refs.dialog.save(this.date);
    }
  }
}
</script>

<style>
</style>
<template>
  <div class="font-size-14 hide-field-details">
    <v-divider class="grey lighten-1 mx-3"/>

    <show-more open_default>
      <template #title>
        <div class="font-weight-bold primary--text">{{ __('EditContactInformation') }}</div>
      </template>

      <template #more>

          <v-row class="px-3  ">
            <v-col cols="12" sm="12">
              <address-selector v-model="request.body.address" @input-object="setPlace" title_classes="font-size-14"/>
            </v-col>

            <v-col cols="12" sm="4">
              <city-selector
                  v-model.number="request.body.city"
                  :state="request.body.state"
                  :place="place"
                  title_classes="font-size-14"
                  @input-object="e => place.city = e"
                  return_name
              />
            </v-col>

            <v-col cols="12" sm="4">
              <state-selector
                  v-model="request.body.state"
                  :place="place"
                  @change="e => changeState()"
                  title_classes="font-size-14"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <div class="font-size-14">{{ __('Country') }}</div>
              <v-text-field
                  v-model="request.body.country"
                  class="rounded-lg custom-selector-border font-size-14"
                  outlined
                  dense
              />
            </v-col>

            <v-col cols="12" sm="6">
              <div class="font-size-14">{{ __('PhoneNumber') }}</div>
              <div class="d-flex full-width">

                <country-code-selector
                    v-model="request.body.phone_country_code"
                    outlined
                    hide_title
                    style="max-width: 90px"
                    dense
                />
                <space/>
                <v-text-field
                    v-model="request.body.phone_number"
                    class="rounded-lg font-size-14 full-width custom-selector-border"
                    outlined
                    dense
                />
              </div>
            </v-col>

            <v-col cols="12" sm="6">
              <div>{{ __('Fax') }}</div>
              <div class="d-flex full-width">
                <country-code-selector
                    v-model="request.body.fax_country_code"
                    outlined
                    hide_title
                    style="max-width: 90px"
                    dense
                />
                <space/>
                <v-text-field
                    v-model="request.body.fax"
                    class="rounded-lg font-size-14 full-width custom-selector-border"
                    outlined
                    dense
                />
              </div>
            </v-col>

            <v-col cols="12" sm="6">
              <div>{{ __('Email') }}</div>
              <v-text-field
                  v-model="request.body.email"
                  class="rounded-lg font-size-14 custom-selector-border"
                  outlined
                  dense
              />
            </v-col>

            <v-col cols="12" sm="6">
              <div>{{ __('Website') }}</div>
              <v-text-field
                  v-model="request.body.website"
                  class="rounded-lg font-size-14 custom-selector-border"
                  outlined
                  dense
              />
            </v-col>

            <v-col cols="12">
              <profile-form-languages :request="request"/>
              <space height="30"/>
            </v-col>
          </v-row>

      </template>
    </show-more>

  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import {PostRequest} from "@/models/PostRequest";
import FromListSelector from "@/components/selectors/FromListSelector";
import ProfileFormLanguages from "@/components/profiles/ProfileFormLanguages";
import StatusSelector from "@/components/selectors/StatusSelector";
import StateSelector from "@/components/selectors/StateSelector";
import CitySelector from "@/components/selectors/CitySelector";
import AddressSelector from "@/components/selectors/AddressSelector";
import Space from "@/components/app/Space";
import PhoneCodeSelector from "@/components/selectors/CountryCodeSelector";
import CountryCodeSelector from "@/components/selectors/CountryCodeSelector";
import ShowMore from "@/components/app/ShowMore";

export default {
  name: "ProfileFromContact",
  components: {
    ShowMore,
    CountryCodeSelector,
    PhoneCodeSelector,
    Space,
    AddressSelector,
    CitySelector,
    StateSelector,
    StatusSelector,
    ProfileFormLanguages,
    FromListSelector,
    AssetsImage
  },
  props: {
    request: {
      default: null,
      type: PostRequest,
    },
  },
  data() {
    return {
      place: {city: null},
    }
  },
  methods: {
    changeState() {
      this.place = {city: null}
      this.request.body.city = null;
      this.request.body.address = null;
    },
    setPlace(place) {
      try {
        this.place = place
        this.request.body.country = this._is_object(place.country) ? place.country.name : place.country;
      } catch (e) {
        console.log(e);
      }
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <div id="profile-head" v-if="_is_object(data)">
    <profile-head-desktop class="hidden-sm-and-down" :data="data" :editable="editable" :request="request"/>
    <template v-if="editable">
      <profile-head-mobile class="hidden-md-and-up" :data="data" :editable="editable" :request="request"/>
    </template>
    <template v-else>
      <profile-head-tablet class="hidden-xs-only hidden-md-and-up" :data="data" :editable="editable" :request="request"/>
      <profile-head-mobile class="hidden-sm-and-up" :data="data" :editable="editable" :request="request"/>
    </template>
    <profile-head-fixed v-if="!editable" class="hidden-md-and-up" :data="data" :editable="editable" :request="request"/>

  </div>
</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import ProfileHeadMobile from "@/components/profiles/ProfileHeadMobile";
import ProfileHeadDesktop from "@/components/profiles/ProfileHeadDesktop";
import ProfileHeadTablet from "@/components/profiles/ProfileHeadTablet";
import ProfileHeadFixed from "@/components/profiles/ProfileHeadFixed";

export default {
  name: "ProfileHead",
  components: {ProfileHeadFixed, ProfileHeadTablet, ProfileHeadDesktop, ProfileHeadMobile},
  props: {
    data: {
      default: null,
      type: Object,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    request: {
      default: null,
      type: PostRequest,
    },
  },
  computed: {},

  methods: {},
}
</script>

<style>

</style>
<template>
  <div class="full-width">
    <v-chip
        v-for="(item, key) in items"
        :key="'status-selector-'+ key"
        @click="$emit('input', item.value);"
        :class="{'mr-2': true,'primary background--text': isSelected(item), 'text--text secondary': !isSelected(item) }"
        :disabled="disabled"
        text
    >
      {{ item.text }}
    </v-chip>
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "InlineSelector",
  props: {
    value: {
      default: null,
      type: [String, Object],
    },
    items: {
      default: null,
      type: []
    },
    inline: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {
    isSelected(item){
      try{
        if (typeof item.value === 'object') return JSON.stringify(item.value) === JSON.stringify(this.value);
        return this.value === item.value
      }catch (e){
        return this.value === item.value
      }
    }

  }
}
</script>

<style scoped>

</style>

<template>
  <v-container>
    <page-title :text="__('Clients')" heading/>

    <clients/>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Clients from "../../../components/clients/Clients";

export default {
  name: "ClientsPage",
  components: {Clients, PageTitle}
}
</script>

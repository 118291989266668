<template>
  <div class="app-textarea-editor">
    <upload-image class="my-3" @input="upload">
      <template #default="{progress_size}">
        <v-btn color="grey" outlined v-if="progress_size === 0">{{ __('UploadImage') }}</v-btn>
        <v-btn outlined v-else disabled>{{ __('Uploading') }} ({{progress_size}}%)</v-btn>
      </template>
    </upload-image>
    <el-tiptap
        autofocus
        v-model="result"
        :extensions="extensions"
        :spellcheck="false"
        :menu-bubble-options="{ 'keep-in-bounds': false }"
        v-bind="$attrs"
    ></el-tiptap>
    <span class="checkbox-custom-checkmark"></span>
  </div>
</template>

<script>

import {
  Blockquote,
  Bold,
  BulletList,
  Code,
  CodeView,
  Doc,
  ElementTiptap,
  Fullscreen,
  HardBreak,
  Heading,
  History,
  HorizontalRule,
  Image,
  Indent,
  Italic,
  Link,
  ListItem,
  OrderedList,
  Paragraph,
  Strike,
  Text,
  TextAlign,
  TodoItem,
  TodoList,
  Underline
} from 'element-tiptap';
import codemirror from 'codemirror';
import 'codemirror/lib/codemirror.css'; // import base style
import 'codemirror/mode/xml/xml.js'; // language
import 'codemirror/addon/selection/active-line.js'; // require active-line.js
import 'codemirror/addon/edit/closetag.js';
import UploadImage from "@/components/assets/UploadImage";
import urls from "@/tools/Urls"; // autoCloseTags

export default {
  name: "AppTextarea",
  components: {
    UploadImage,
    'el-tiptap': ElementTiptap
  },
  props: {
    value: {
      default: null,
      type: String,
    },
    label: {
      default: null,
      type: [String],
    },
  },
  watch: {
    result() {
      this.$emit('input', this.result)
    }
  },
  data() {
    return {
      result: null,
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({level: 5}),
        new Bold({bubble: true}),
        new Underline({bubble: true}),
        new Italic({bubble: true}),
        new Strike({bubble: true}),
        new Code(),
        new Link({bubble: true}),
        new Image(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList({bubble: true}),
        new OrderedList({bubble: true}),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new HorizontalRule({bubble: true}),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true,
          },
        }),
        new Fullscreen(),
        new History(),
      ],
    }
  },
  mounted() {
    this.result = this.value;
  },
  methods: {
    upload(image_id) {
      let image = "<img src='" + this.getUrl(image_id) + "'/>";
      this.result = (this.result ?? '') + image;
    },
    getUrl(id) {
      try {
        return urls.api('assets_url', [id]);
      } catch (e) {
        return '';
      }
    },

  }

}
</script>

<style>
.app-textarea-editor div.el-tiptap-editor__menu-bar.border-top-radius > div:nth-child(7) {
  display: none;
}
</style>
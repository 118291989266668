<template>
  <v-container>
    <page-title :text="__('EditPro')"/>
    <professional-edit :id="$route.params.id"/>
  </v-container>
</template>

<script>
import PageTitle from "@/components/app/PageTitle";
import ProfessionalEdit from "@/components/professionals/ProfessionalEdit";

export default {
  name: "EditProfessionalPage",
  components: {ProfessionalEdit, PageTitle}
}
</script>

<style scoped>

</style>

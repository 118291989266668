<template>
  <v-container>
    <v-breadcrumbs class="px-0" :items="breadcrumbs_items"/>
    <project-ideas :id="$route.params.id"/>
  </v-container>
</template>

<script>
import ProjectIdeas from "../../../components/projects/ProjectIdeas";

export default {
  name: "ProjectIdeasPage",
  components: {ProjectIdeas},
  computed: {
    id() {
      try {
        return this.$route.params.id;
      } catch (e) {
        return null;
      }
    },
    title() {
      try {
        return this.$route.query.title;
      } catch (e) {
        return this.__('Project');
      }
    },
    breadcrumbs_items() {
      return [
        {
          text: this.__('Projects'),
          disabled: false,
          href: '/panel/projects/',
        },
        {
          text: this.title,
          disabled: false,
          href: '/panel/projects/' + (this.id ? this.id + '/details' : ''),
        },
        {
          text: this.__('Ideas'),
          disabled: true,
        },
      ]
    },
  }
}
</script>

<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ __('JobType') }}</div>
    <v-select
        :items="items()"
        :value="value"
        @input="(e) => $emit('input', e)"
        class="custom-selector-border rounded-lg"
        dense
        outlined
        :disabled="disabled"
    ></v-select>
  </div>
</template>

<script>

import {GetRequest} from "@/models/GetRequest";

export default {
  name: "JobTypeSelector",
  props: {
    value: {
      default: null,
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      request: new GetRequest('get_job_type')
    }
  },
  mounted() {
    this.request.setSort('title');
    this.request.get();
  },
  methods: {
    items(){
      try {
        let items = [
          {
            text: this.__('SelectOne'),
            value: null,
          },
        ];
        if (!this.request.data) return;
        this.request.data.forEach((item) => {
          items.push({
            text: item.title,
            value: item.id,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
        return null;
      }
    }
  }
}
</script>

<style scoped>

</style>

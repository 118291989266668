<template>
  <div>
    <!--   Menu      -->
    <v-menu v-if="data && data.id" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
      </template>
      <v-list :disabled="update_request.isLoading()">


        <v-list-item v-if="$route.name !== 'Project' "
                     :to="{name:(data.status !== 'lead' ? 'Project' : 'LeadDetails'), params: { id: data.id }}">
          <v-list-item-title>{{ __("OpenProject") }}</v-list-item-title>
        </v-list-item>

        <v-list-item
            v-if="!data.is_expired"
            :to="{name:ideasRoute(), params: { id: data.id }, query: {title: data.title}}">
          <v-list-item-title>{{ __("Ideas") }}</v-list-item-title>
        </v-list-item>


        <v-list-item v-if="_access('client') && !data.is_expired" :to="{name:'CustomProfessionals'}">
          <v-list-item-title>{{ __("MyCustomPro") }}</v-list-item-title>
        </v-list-item>


        <!--  Lead Actions   -->
        <v-list-item v-if="data.status === 'lead' && _access('admin')" @click="packages_dialog = !packages_dialog">
          <v-list-item-title>{{ __("AddPackage") }}</v-list-item-title>
        </v-list-item>


        <v-list-item v-if="data.status === 'lead' && _access('admin')" @click="unlock_dialog = !unlock_dialog">
          <v-list-item-title>{{ __("UnLock") }}</v-list-item-title>
        </v-list-item>

        <!--   Archive Actions   -->
        <v-list-item v-if="data.status !== 'archived' && (_access('admin') || _owner(data.client_id))"  @click="archive_dialog = true">
          <v-list-item-title>{{ __("Archive") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="data.status === 'archived' && ( _access('admin') || _owner(data.client_id))"
                     @click="update('active')">
          <v-list-item-title>{{ __("UnArchive") }}</v-list-item-title>
        </v-list-item>


      </v-list>
    </v-menu>


    <!-- Dialogs   -->
    <add-package
        unique_key="project-menu-unlock-package"
        v-model="packages_dialog"
        :project_id="data.id"
        @then="$emit('reload')"
        :without="['subscription']"
        hide_free
        hide_subscription
        modal
    />

    <unlock
        unique_key="project-menu-unlock-package"
        v-model="unlock_dialog"
        :project_id="data.id"
        @then="$emit('reload')"
    />

    <confirm v-model='archive_dialog' @accepted="update('archived')"/>

  </div>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";
import AddPackage from "../packages/AddPackage";
import Unlock from "../packages/Unlock";
import Confirm from "../modal/Confirm";

export default {
  name: "ProjectMenu",
  components: {Confirm, Unlock, AddPackage},
  props: {
    data: {
      default: null,
      type: Object
    },
  },
  computed: {},
  data: () => {
    return {
      update_request: new PostRequest('update_project'),
      packages_dialog: null,
      unlock_dialog: null,
      archive_dialog: null,
    }
  },
  mounted() {
    if (!this.data) return;
    this.update_request.setApiParams([this.data.id])
  },
  methods: {
    update(status) {
      this.update_request.setBody({'status': status});
      this.update_request.setThen(() => this.$emit('reload'));
      this.update_request.patch();

    },
    ideasRoute() {
      try{
        return (this.$route.name !== 'LeadDetails' ? 'ProjectIdeas' : 'LeadIdeas')
      }catch (e){
        return 'ProjectIdeas';
      }
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width">
    <inline-selector :value="request.getFilter('pro_registration_status')" :items="items()" @input="select" :disabled="request.isLoading()">

    </inline-selector>
  </div>
</template>

<script>
import InlineSelector from "@/components/selectors/InlineSelector";
import {GetRequest} from "@/models/GetRequest";

export default {
  name: "ProfessionalsTabs",
  components: {InlineSelector},
  props: {
    request: {
      default: new GetRequest(),
      type: GetRequest,
      required: true,
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    items() {
      return [
        {
          text: this.__('All'),
          value: null,
        },
        {
          text: this.__('Hired'),
          value: 'hired'
        },
        {
          text: this.__('NotApproved'),
          value: 'not_approved'
        },
        {
          text: this.__('AddByClient'),
          value: 'add_by_client'
        },
      ];
    },
    select(value) {
      try {
        this.request.is_loading = false;
        this.request.addFilter('pro_registration_status', value);
        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>

</style>
<template>
  <v-card class="border-light rounded-lg overflow-hidden  mb-6" outlined>
    <v-progress-linear v-if="is_loading || user_info_request.isLoading()" indeterminate></v-progress-linear>

    <div class="pt-12 pb-3 px-3 text-center">
      <router-link class="mt-1 mx-auto" :to="{name:'Home'}">
        <logo/>
      </router-link>
      <h2 class="font-weight-medium mt-2">{{ __('SignIn') }}</h2>
      <div>{{ __('ToRenotive') }}</div>
    </div>

    <div class="text-center pb-16 my-16 pt-8 px-3">
      <v-btn @click="google_sign_in" elevation="0" outlined :disabled="is_loading || user_info_request.isLoading()">
        <img src="@/assets/img/google.png" :width="16" class="mr-2"/>
        {{ __('SignInByGoogle') }}
      </v-btn>
    </div>

  </v-card>

</template>

<script>
import urls from "../../tools/Urls";
import auth from "../../tools/Auth";
import {GetRequest} from "../../models/GetRequest";
import Loading from "../app/Loading";
import Logo from "../app/Logo";

export default {
  name: "AuthGoogle",
  components: {Logo},
  props: {
    redirect: {
      default: null,
      type: String,
    }
  },
  data() {
    return {
      user_info_request: new GetRequest('get_current_user'),
      is_loading: false,
    }
  },
  mounted() {
  },
  created() {
    this.check();
  },
  methods: {
    google_sign_in() {
      let default_redirect_url = window.location.protocol + '//' + window.location.host + this.$router.currentRoute.path;
      var url = new URL(this.redirect ? this.redirect : default_redirect_url);
      url.searchParams.set('provider', 'google');

      open(urls.api('login_google', [url.href]), '_self');
    },
    async refresh() {
      let response = await fetch(urls.api('refresh_token'), {
        method: 'POST',
        mode: 'cors',
        credentials: 'include'
      });
      this.is_loading = false;
      this.then(response)
    },
    async then(response) {
      try {
        response = await response.json();
        if (!response || !response.data || !response.data.access_token) throw 'Login failed!';

        // save token
        auth.saveToken(response.data.access_token)
        auth.saveRefreshToken(response.data.refresh_token)
        auth.saveExpiration(response.data.expires)

        this.getUser()

      } catch (e) {
        console.log(e);
      }
    },
    getUser() {
      try {
        this.user_info_request.setThen((response) => {
          if (!response || !response.data || !response.data.id) throw 'Can not get user info!';
          auth.setUserInfo(response.data)
          this.$store.commit('setUserInfo', response.data)
          this.$emit('authorised')
        })
        this.user_info_request.setFields('*, role.*');
        this.user_info_request.row();
      } catch (e) {
        console.log(e);
      }
    },
    check() {
      try {
        if (this.$route.query.provider !== 'google') return;
        this.is_loading = true;
        setTimeout(() => {
          this.refresh();
        }, 1000)
      } catch (e) {
        console.log(e);
      }
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <v-row>
    <v-col cols="12" class="text-center pt-0">
      <v-card elevation="0" class="border-light rounded-lg my-6 mt-9 py-9" style="border-width: 3px !important;">
        <v-row>
          <v-col cols="12" sm="3">
            <img src="@/assets/img/start-project.png" width="190" v-bind="$attrs"/>
          </v-col>
          <v-col cols="12" sm="9" class="text-left">
            <div class="mt-9 mb-16">
              <div class="text-h4 mb-6">{{ __('StartProjectTitle') }}</div>
              <div class="text--secondary">{{ __('StartProjectText') }}</div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" elevation="0" large @click="questions.next()">{{ __('GetStart') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>



      <div v-if="!_user" class="mt-16 pt-16 mb-6">
        <div class="text-h4 mb-6">{{ __('CallRequestTitle') }}</div>
        <div class="text--secondary">{{ __('CallRequestText') }}</div>
        <v-btn color="primary" elevation="0" :to="{name:'Panel'}" class="my-16" large>
          <span >{{ __('SignUp') }}</span>
        </v-btn>
      </div>

    </v-col>
  </v-row>
</template>

<script>

import {Questions} from "../../models/Questions";

export default {
  name: "SurveyStart",
  components: {},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    }
  },

}
</script>

<style scoped>

</style>
<template>
  <v-card flat class="grey overflow-hidden lighten-4 rounded-lg pb-6">
    <v-card-title class="title">
      {{ __('Rooms') }}
    </v-card-title>
    <div v-if="rooms">

      <room-item
          class="px-5"
          v-for="(item, key) in rooms"
          :data="item.room_id"
          :key="'room-'+key"
          tile
      >
        <project-room-answers :data="answers(item.room_id)"/>
      </room-item>
    </div>
  </v-card>

</template>

<script>

import RoomItem from "../rooms/RoomItem";
import ProjectRoomAnswers from "./ProjectRoomAnswers";

export default {
  name: "ProjectRooms",
  components: {ProjectRoomAnswers, RoomItem},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  computed: {
    rooms() {
      try {
        return this.data.rooms;
      } catch (e) {
        return null;
      }
    },
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    answers(room) {
      try {
        let result = [];
        this.data.quiz_answers.forEach((item) => {
          if (!item || !item.answer) return;
          let answer = item.answer;

          if (!answer.room_id) return;
          if (answer.room_id !== room.id) return;

          result.push(answer);
        })
        return result;
      } catch (e) {
        return null;
      }
    }
  },
}
</script>

<style scoped>

</style>

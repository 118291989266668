<template>
  <span>
    <span class="font-size-14" v-html="text"></span>
    <span v-if="has_more">
      <span v-if="!show">...</span>
      <div v-if="!show" class="text-left pt-3">
        <span class="primary--text pointer" @click="show = true">{{ __('ReadMore') }}&nbsp;
        <v-icon>mdi-chevron-down</v-icon>
        </span>
      </div>
      <div v-else class="text-left pt-3">
        <span class="primary--text pointer" @click="show = false">{{ __('ReadLess') }}&nbsp;
        </span>
      </div>
    </span>
  </span>
</template>

<script>

import app from "../../tools/App";

export default {
  name: "Description",
  props: {
    data: {
      default: null,
      type: [String]
    },

  },
  computed: {
    text() {
      try {
        let result = this.data;
        if (app.max_text_length < result.length && !this.show) result = result.substring(0, app.max_text_length);
        return result;
      } catch (e) {
        return this.data;
      }
    },
    has_more() {
      try {
        return app.max_text_length < this.data.length;
      } catch (e) {
        return false;
      }
    }
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>
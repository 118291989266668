<template>
  <div v-if="data && data.id"   :title="__('Note')"  >
    <div v-if="!hide_title">
      {{ __('Note') }}
    </div>

    <div>
      <v-textarea
          class="rounded-lg"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          v-model="request.body.text"
      ></v-textarea>
    </div>
    <div class="d-flex">
      <slot name="actions"></slot>
      <v-spacer/>
      <v-btn class="elevation-0 text--text" color="secondary" @click="request.post()">{{__('Save')}}</v-btn>
    </div>
  </div>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";

export default {
  name: "ProfessionalNote",
  components: {},
  props: {
    data: {
      default: null,
      type: Object,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      request: new PostRequest('add_personal_note'),
    }
  },
  watch:{
    'data'(){
      this.setBody();
    }
  },
  mounted() {
    this.request.setThen(() => this.$emit('reload'));
    this.setBody();
  },
  methods: {
    setBody() {
      this.request.setBody(
          {
            item_id: this.data.id,
            collection_name: "users",
            text: this.getDefault()
          }
      );

    },
    getDefault(){
      try{
        let result = {};
        if (this.data.note) result = this.data.note;
        if (this.data.personal_comment) result = this.data.personal_comment;
        return result[0].text;
      }catch(e){
        return null;
      }
    }
  }
}
</script>
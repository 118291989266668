import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-width"},[(!_vm.hide_title)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('Expertise')))]):_vm._e(),(!_vm.list)?[_c(VSelect,_vm._b({staticClass:"custom-selector-border rounded-lg",attrs:{"items":_vm.items(),"value":_vm.value,"outlined":"","dense":"","multiple":_vm.multiple},on:{"input":_vm.change},scopedSlots:_vm._u([(_vm.multiple)?{key:"item",fn:function({ item, on }){return [_c(VListItem,_vm._g({attrs:{"link":"","dense":""}},on),[_c(VCheckbox,{attrs:{"input-value":_vm.value.includes(item.value)}}),_c(VListItemTitle,[_vm._v(_vm._s(item.text))])],1)]}}:null,{key:"prepend-item",fn:function(){return [(_vm.show_custom)?_c(VBtn,{staticClass:"ma-3",attrs:{"elevation":"0","color":"primary","small":""},on:{"click":function($event){_vm.custom_dialog = !_vm.custom_dialog}}},[_vm._v(_vm._s(_vm.__('CustomExpertise'))+" ")]):_vm._e()]},proxy:true}],null,true)},'v-select',_vm.$attrs,false)),(_vm.show_custom)?_c('custom-expertise',{model:{value:(_vm.custom_dialog),callback:function ($$v) {_vm.custom_dialog=$$v},expression:"custom_dialog"}}):_vm._e()]:[(_vm.request.isLoading())?_c(VSkeletonLoader,{attrs:{"type":"card","height":"38"}}):_c('from-list-selector',_vm._b({attrs:{"items":_vm.items(),"value":_vm.value,"multiple":_vm.multiple},on:{"input":(e) => _vm.$emit('input', e)}},'from-list-selector',_vm.$attrs,false))]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <form @submit.prevent="submit" ref="upload-form">
    <input type="file" id="upload-file" @input="setFile" ref="file" required :accept="accept" v-show="false">

    <div @click="$refs.file.click()">
      <slot :progress_size="progress_size">
        <v-card
            :width="width"
            :height="height"
            class="rounded-lg ma-1 overflow-hidden pointer"
            style="border:3px dashed rgba(0, 0, 0, 0.08);"
            elevation="0"
        >
          <div :class="['px-3 align-center text-center', text_classes]">

            <!--  Done          -->
            <template v-if="!request.isLoading() && progress_size === 100">
              <div class="pt-2">
                <div>
                  <v-icon size="35" color="verify">mdi-checkbox-marked-circle</v-icon>
                </div>
                <div class="font-weight-bold text--disabled subtitle-1">{{ __('Uploaded') }}</div>
              </div>
            </template>

            <!-- Progress -->
            <template v-else-if="progress_size">
              <div>
                <div class="py-3 text--disabled">{{ progress_size }}%</div>
                <v-progress-linear
                    color="verify"
                    class="rounded-lg"
                    :height="8"
                    v-model="progress_size"
                    query
                />
              </div>
            </template>

            <!--  Plus          -->
            <template v-else>
              <div class="pt-2">
                <div>
                  <v-icon size="35" color="grey lighten-1">mdi-plus</v-icon>
                </div>
                <div class="font-weight-bold text--disabled subtitle-1">{{ title ?? __('UploadFile') }}</div>
              </div>
            </template>
          </div>
        </v-card>
      </slot>
    </div>


  </form>
</template>

<script>
import {PostRequest} from "../../models/PostRequest";

export default {
  name: "Upload",
  props: {
    accept: {
      default: null,
      type: String,
    },
    subtitle: {
      default: null,
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    width: {
      default: 190,
      type: [String, Number],
    },
    show_done: {
      default: false,
      type: Boolean,
    },
    height: {
      default: 188,
      type: [String, Number],
    },
    text_classes: {
      default: 'pt-12',
      type: [String],
    }
  },
  data() {
    return {
      request: new PostRequest('upload_file'),
      file: null,
      progress_size: 0,
    }
  },
  mounted() {

  },
  methods: {
    setFile() {
      this.file = this.$refs.file.files[0];
      this.submit();
    },
    submit() {
      let formData = new FormData();
      formData.append("file", this.file);
      this.progress_size = 0;

      this.request.setHeaders({'Content-Type': 'multipart/form-data'});
      this.request.setBody(formData)
      this.request.setApiConfig({
        onUploadProgress: this.progress,
      });
      this.request.setThen((data) => {
        this.$emit('then', data);
        this.reset();
        this.progress_size = this.show_done ? 100 : 0;
      })
      this.request.post();
    },
    progress(progressEvent) {
      try {
        this.progress_size = parseInt((progressEvent.loaded / progressEvent.total) * 100);
      } catch (e) {
        console.log(e);
      }
    },
    reset() {
      try {
        this.file = null;
        this.$refs["upload-form"].reset();
        this.request.reset();
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-footer v-if="fixed" fixed style="background: rgba(229,229,229,0.5);" class="py-3">
      <v-container>
        <div class="d-flex px-6 justify-center">
          <slot>
            <v-btn
                large
                color="primary"
                class="rounded-lg"
                elevation="0"
                @click="next"
                v-bind="$attrs"
                style="min-width: 160px"
            >
              {{ !text ? __('Next') : text }}
            </v-btn>
          </slot>
        </div>
      </v-container>
    </v-footer>

    <div v-else>
      <v-btn color="primary" elevation="0" @click="next" v-bind="$attrs">
        {{ !text ? __('Next') : text }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {Questions} from "../../models/Questions";

export default {
  name: "QuestionsNextButton",
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
    text: {
      default: null,
      type: String,
    },
    fixed: {
      default: false,
      type: Boolean,
    },

  },
  methods: {
    next() {
      if (this.questions.isStep(2) && this.questions.isNotFirst()) return this.questions.next(3)
      this.questions.next()
    }
  }
}
</script>

<style scoped>

</style>
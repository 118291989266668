<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ title ? title : __('Package') }}</div>

    <v-select
        :items="items()"
        :value="value"
        :placeholder="__('SelectOne')"
        @input="e => change(e)"
        class="custom-selector-border rounded-lg"
        item-text="text"
        item-value="value"
        outlined
        dense
        return-object
        v-bind="$attrs"
    >
      <template slot="item" slot-scope="data">
        {{ data.item.text }}
        <span v-if="price && data.item && typeof data.item.item === 'object' && data.item.item.price" >
          &nbsp;(<price  :data="data.item.item.price" :default_price="__('Free')"></price>)
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";
import Price from "../app/Price";

export default {
  name: "PackageSelector",
  components: {Price},
  props: {
    value: {
      default: null,
      type: [Object, String],
    },
    title: {
      default: null,
      type: String,
    },
    type: {
      default: null,
      type: [Array],
    },
    price: {
      default: false,
      type: Boolean,
    },
    default_item: {
      default: null,
      type: [Number,String],
    }
  },
  watch: {

  },
  data() {
    return {
      request: new GetRequest('get_packages'),
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.request.setFields('*,services.service_id.*')
      this.request.setIn('type', this.type)
      this.request.setThen(e => this.then(e))
      this.request.get();
    },

    items() {
      try {
        let items = [ ];
        if (this.request.data) {
          this.request.data.forEach((item) => {
            items.push({
              text: item.title,
              value: item.id,
              item: item,
            })
          })
        }
        return items;


      } catch (e) {
        console.log(e);
      }
    },
    set(){
      try{
        if (this.default_item) {
          let first_item = this.request.data[this.default_item];
          this.change({
            text: first_item.title,
            value: first_item.id,
            item: first_item,
          })
        }
      }catch (e){
        console.log(e);
      }
    },
    change(e){
      this.$emit('input', e.value)
      this.$emit('input-object', e.item)
    },
    then(response){
      try{
        if (!response || !response.data) throw 'Login failed!';
        this.set();
      }catch (e){
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
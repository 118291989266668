<template>
  <div class="full-width">
    <div :class="title_classes" v-if="!hide_title">{{ __('City') }}</div>

    <v-autocomplete
        :items="items()"
        :value="value"
        rel="city-selector"
        :search-input.sync="input"
        @input="(e) => change(e)"
        class="custom-selector-border font-size-14 rounded-lg"
        item-text="name"
        item-value="value"
        outlined
        :placeholder="placeholder ?? __('SelectOne')"
        v-bind="$attrs"
        dense
        return-object
    >
    </v-autocomplete>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "CitySelector",
  components: {},
  props: {
    value: {
      default: null,
      type: [String, Number],
    },
    title_classes: {
      default: 'font-weight-bold',
      type: String,
    },
    place: {
      default: null,
      type: [Object],
    },
    return_object: {
      default: false,
      type: Boolean,
    },
    text: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: null,
      type: String,
    },
    return_name: {
      default: false,
      type: Boolean,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
    state: {
      default: null,
      type: [String],
    }
  },
  watch: {
    input() {
      this.get();
    },
    place() {
      this.set();
    },
    value() {
      if (!this.value) return this.input = null;
    }
  },
  data() {
    return {
      request: new GetRequest('get_items',['city']),
      input: null,
      custom_items: [],
    }
  },
  mounted() {
    this.set()
    this.setItem();
  },
  methods: {
    set(){
      if (!this.place || typeof this.place !== "object" || !this.place.city) return this.get();
      this.setCity();
    },
    setItem(){
      try {
        if (!this.return_name && this.value) return;
        this.custom_items.push({
          name: this.value,
          id: null,
          value: this.value,
          item: null,
        })
      } catch (e) {
        console.log(e);
      }
    },
    setCity(){
      try{
        if (!this.place.city || !this.place.city.id) return this.$emit('input', null);
        this.emitInput(this.place.city.id, this.place.city);
        this.custom_items.push(this.place.city)
      }catch (e){
        console.log(e)
      }
    },
    emitInput(data, item){
      if (this.return_object) data = item;
      if (this.return_name) data = item.name;
      this.$emit('input', data);
      this.$emit('input-object', item);
    },
    change(e){
      try{
        this.emitInput(e.id, e.item)
      }catch (e){
        console.log(e)
      }
    },
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.is_loading = false;
      this.request.addFilter('name', this.input, '_contains');
      this.request.addFilter('state', this.state);
      this.request.setLimit(100);
      this.request.get();
    },
    items() {
      try {
        let items = [];

        if (this.request.data) {
          this.request.data.forEach((item) => {
            items.push({
              name: item.name,
              id: item.id,
              value: this.return_name ? item.name : item.id,
              item: item,
            })
          })
        }

        items = items.concat(this.custom_items);
        return items;

      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
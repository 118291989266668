<template>
  <v-container  class="container-max-width">
    <portfolio-project :id="$route.params.project_id"/>
  </v-container>
</template>

<script>

import PortfolioProject from "@/components/portfolio/PortfolioProject";

export default {
  name: "ProfessionalProjectPage",
  components: {PortfolioProject},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

<template>
  <v-row v-if="data">
    <v-col cols="12" v-if="!is_lead">
      <!-- work completion status -->
      <v-progress-linear
          :value="data.work_completion_status"
          :title="__('WorkCompletionStatus')"
          background-color="grey lighten-3"
          class="rounded-lg"
      />
    </v-col>

    <v-col cols="12">
      <!-- Rooms -->
      <project-rooms :data="data"></project-rooms>
    </v-col>

    <v-col cols="12" v-if="data && !is_lead" >
      <!-- Jobs -->
      <div class="d-flex justify-space-between">
        <div class="title">
          {{ __('Jobs') }}
        </div>
        <div>
          <v-btn v-if="_access('client')" :to="{name:'CustomProfessionals'}" color="secondary" elevation="0"
                 class="text--text" :disabled="expired">
            {{ __('MyCustomPro') }}
          </v-btn>
        </div>
      </div>

      <div v-if="data.jobs" class="mt-3">
        <jobs :data="data.jobs" :disabled="expired"></jobs>
      </div>

      <div class="pt-12 pb-7" v-else>
        <not-found-data :title="__('NotFoundAnyJob')"/>
      </div>
    </v-col>
  </v-row>
</template>

<script>

import ProjectRooms from "@/components/projects/ProjectRooms"
import Jobs from "@/components/jobs/Jobs"
import NotFoundData from "../app/NotFoundData";

export default {
  name: "ProjectWork",
  components: {NotFoundData, Jobs, ProjectRooms},
  props: {
    data: {
      default: null,
      type: Object,
    },
    is_lead: {
      default: false,
      type: Boolean,
    },
    expired: {
      default: false,
      type: Boolean,
    }
  },
  computed: {},
  data() {
    return {
      jobs: [],
    }
  },
  mounted() {
    this.jobs = this.data.jobs;
  },
  watch: {},
  methods: {},
}
</script>

<style scoped>

</style>

<template>
  <!-- Cost Estimate -->
  <v-card v-if="data && !_access('pro')" class="secondary rounded-lg my-3" :title="__('CostEstimate')" flat>
    <v-card-title class="justify-space-between">
      <div class="title">
        <v-icon class="mb-1">mdi-cash</v-icon> &nbsp;
        {{ __('CostEstimate') }}
      </div>
      <price :data="data.cost_estimate" class="text-h5 font-weight-bold"/>
    </v-card-title>
  </v-card>
</template>

<script>
import Price from "../app/Price";
export default {
  name: "ProjectCost",
  components: {Price},
  props: {
    data: {
      default: null,
      type: Object,
    }
  },
}
</script>

<style scoped>

</style>
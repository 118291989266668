import Vue from 'vue'
import Vuex from 'vuex'
import auth from "../tools/Auth";
import app from "../tools/App";

const package_info = require('../../package.json');

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        version: package_info.version || '0',
        user_info: {},
        message: {},
        package: null,
    },
    mutations: {
        setUserInfo(state, user) {
            state.user_info = user;
        },
        setUserData(state, {key, value}) {
            try {
                let user = state.user_info;
                user[key] = value;
                auth.setUserInfo(user)
                state.user_info = user;
            } catch (e) {
                console.log(e);
            }
        },
        setMessage(state, message) {
            state.message = message
        },
    },
    actions: {
        updateUserInfo(context) {
            setTimeout(() => {
                auth.getUserInfo((value) => {
                    context.commit('setUserInfo', value)
                })
            }, 150)
        }
    }
})

<template>
  <v-container>
    <v-row justify="center" style="margin-top: 90px">
      <v-col sm="4">
        <auth/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import auth from "../../components/auth/Auth";
export default {
  name: "Login",
  components: {auth},

}
</script>

<style scoped>

</style>
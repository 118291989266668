<template>
  <v-container>
    <page-title :text="__('IdeasManagement')" heading/>

    <!-- Creator  -->
    <v-row>
      <v-col>
        <ideas-tabs v-model="tab"/>
      </v-col>
    </v-row>

    <ideas
        fields="*,*.*,projects"
        :add_is_visible="true"
        :item_attrs="{
          show_date: is_my_ideas,
          show_status: is_my_ideas,
          show_saved_count: is_my_ideas,
          in_modal: true,
        }"
        :filters_attrs="{
         with_status:is_my_ideas,
         with_color:false,
         with_creator:true,
         with_date:true,
         with_project:true,
         with_size:false,
         with_style:false,
        }"
        :status="is_users_pictures ? 'unpublished' : null"
        :creator="is_my_ideas ? '$CURRENT_USER' : null"
        masonry
    />
  </v-container>
</template>

<script>
import Ideas from "@/components/ideas/Ideas";
import PageTitle from "../../../components/app/PageTitle";
import IdeasTabs from "../../../components/ideas/IdeasTabs";

export default {
  name: "PanelIdeasPage",
  components: {IdeasTabs, PageTitle, Ideas},
  computed: {
    is_my_ideas() {
      return this.tab === 'my_ideas';
    },
    is_users_pictures() {
      return this.tab === 'users_pictures';
    }
  },
  data() {
    return {
      tab: 'users_pictures',
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div id="profile-project-form">
    <template v-if="get_request.is_loading && blog_id">
      <loading/>
    </template>

    <template v-else>
      <form @submit.prevent="submit">
        <v-row class="profile-project-form-fields">
          <v-col cols="12" sm="12">
            <profile-breadcrumbs v-if="pro_profile" :data="pro_profile" :advanced_items="breadcrumbs_items"/>
          </v-col>
          <v-col cols="12" sm="12" class="pb-0">
            <label class="font-weight-bold">{{ __('CreateNewStory') }}</label>
          </v-col>

          <v-col cols="12" sm="4" class="pt-0">

            <div class="mb-3 mt-6">
              <assets-image v-if="image" :data="image" class="rounded-lg" width="100%" type="cover" height="284">
                <template>
                  <v-btn icon outlined @click="request.body.pictures = null" class="white ma-2 " small>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </assets-image>
              <upload-image
                  v-else
                  @input="e => request.body.pictures = [{directus_files_id:e}]"
                  width="100%"
              />
            </div>

            <div class="font-weight-bold">{{ __('Title') }}</div>
            <v-text-field
                v-model="request.body.title"
                class="custom-selector-border rounded-lg"
                outlined
                required
                dense
            />

            <div class="font-weight-bold">{{ __('Summary') }}</div>
            <v-textarea
                v-model="request.body.summary"
                class="custom-selector-border rounded-lg"
                height="252"
                outlined
                required
                dense
            />
          </v-col>


          <v-col cols="12" sm="8" class="pt-0" style="min-height: 480px">
            <div class="font-weight-bold">{{ __('Description') }}</div>
            <app-textarea
                v-model="request.body.text"
                height="565"
                required
            />

          </v-col>
        </v-row>

        <space height="35"/>

        <v-row>
          <v-col cols="12" class="text-center">

            <v-btn
                v-if="blog_id"
                :to="{name: 'ProfessionalBlog', params:{id: blog_id}}"
                type="button"
                color="primary"
                elevation="0"
                class="py-3 mx-auto rounded-lg mr-3 mb-3 preview-button"
                target="_blank"
                min-width="180"
                outlined
            >
              {{ __('Preview') }}
            </v-btn>
            <v-btn
                type="submit"
                color="primary"
                elevation="0"
                class="py-3 mx-auto rounded-lg mr-3 mb-3"
                @mousedown="setStatus('published')"
                :disabled="request.isLoading()"
                min-width="180"
                ref="button-publish"
            >
              <loading-dots :request="request" :text="__('Publish')"/>
            </v-btn>
            <v-btn
                v-if="!request.isLoading()"
                type="submit"
                color="grey"
                elevation="0"
                class="py-3 mx-auto rounded-lg background--text mr-3 mb-3"
                min-width="180"
                @mousedown="setStatus('draft')"
            >
              <loading-dots :request="request" :text="__('SaveToDraft')"/>
            </v-btn>
          </v-col>
        </v-row>
      </form>

      <space height="45"/>
    </template>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import Loading from "@/components/app/Loading";
import NotFoundData from "@/components/app/NotFoundData";
import {PostRequest} from "@/models/PostRequest";
import StatusSelector from "@/components/selectors/StatusSelector";
import ProfileBreadcrumbs from "@/components/profiles/ProfileBreadcrumbs";
import Space from "@/components/app/Space";
import LoadingDots from "@/components/app/LoadingDots";
import UploadImage from "@/components/assets/UploadImage";
import AssetsImage from "@/components/assets/Image";
import AssetsImages from "@/components/assets/Images";
import AppTextarea from "@/components/inputs/AppTextarea";

export default {
  name: "ProfileBlogForm",
  components: {
    AppTextarea,
    AssetsImages,
    AssetsImage,
    UploadImage,
    LoadingDots,
    Space,
    ProfileBreadcrumbs,
    StatusSelector,
    NotFoundData,
    Loading,
  },
  props: {
    profile_id: {
      default: null,
      type: [String, Number],
      required: true
    },
    blog_id: {
      default: null,
      type: [String, Number],
    }
  },
  watch: {
    'blog_id'() {
      this.set()
    },
    'without'() {
      this.getProject()
    }
  },
  data() {
    return {
      get_request: new GetRequest('item'),
      request: new PostRequest('items', ['blog']),
      status_items: [
        {
          text: this.__('Published'),
          value: 'published'
        },
        {
          text: this.__('Pending'),
          value: 'pending'
        },
        {
          text: this.__('Draft'),
          value: 'draft'
        }
      ],

    }
  },
  computed: {
    image() {
      try {
        return this.request.body.pictures[0];
      } catch (e) {
        return null;
      }
    },
    breadcrumbs_items() {
      try {
        return [
          {
            text: this.__('Blog'),
            disabled: true
          }
        ];
      } catch (e) {
        return [];
      }
    },
    blog() {
      return this.get_request.data;
    },
    pro_profile() {
      try {
        if (!this.blog_id) return this.get_request.data;
        return this.get_request.data.pro_profile_id;
      } catch (e) {
        return null;
      }
    },
  },
  mounted() {
    this.set();
  },
  methods: {
    setParams() {
      if (!this.blog_id) return;
      this.request.setApi('item');
      this.request.setApiParams(['blog', this.blog_id]);
    },
    setStatus(status = 'published') {
      this.request.body.status = status;
    },
    submit() {
      this.request.setThen(this.then)
      if (this.blog_id) return this.request.patch();
      this.request.post();
    },
    then(response) {
      this.$router.back();
    },
    set() {
      this.setParams();
      if (!this.blog_id && this.profile_id) this.getProfile();
      if (!this.blog_id) return this.setBody();
      this.getDetails();
    },
    setBody() {
      let data = {status: 'daft',}
      let body = {};

      if (this.blog_id) {
        data = this.blog;
      }

      body = {
        status: data.status,
        title: data.title,
        summary: data.summary,
        text: data.text,
        pictures: data.pictures,
      };

      if (!this.blog_id) {
        body.pro_profile_id = this.profile_id;
      }

      this.request.setBody(body)
    },
    getDetails() {
      if (!this.blog_id) return;
      this.get_request.setApiParams(['blog', this.blog_id]);
      this.get_request.setFields('*,pictures.directus_files_id,pro_profile_id.*')
      this.get_request.setThen(() => this.setBody());
      this.get_request.row();
    },
    getProfile() {
      if (!this.profile_id) return;
      this.get_request.setApiParams(['pro_profile', this.profile_id]);
      this.get_request.setFields('*')
      this.get_request.row();
    },
  }
}
</script>

<style>
#profile-project-form .profile-project-form-fields div.font-weight-bold {
  font-weight: normal !important;
}

.preview-button:before {
  background-color: transparent !important;
}
</style>
<template>
  <modal fullscreen v-model="dialog">
    <template v-slot:button>
      <slot name="button">
        <v-btn color="primary" elevation="0" large class="rounded-lg">
          {{ __('Add') }}
        </v-btn>
      </slot>
    </template>
    <template v-slot:title>
      <span>{{ __("AddIdeaProject") }}</span>
    </template>
    <template v-slot:main>
      <ideas-write @before="(e) => before(e)"/>
    </template>
  </modal>
</template>

<script>
import IdeasWrite from "../ideas/IdeasWrite";
import {PostRequest} from "../../models/PostRequest";
import Modal from "../modal/Modal";

export default {
  name: "ProjectAddIdea",
  components: {Modal, IdeasWrite},
  props: {
    id: {
      default: null,
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      dialog: null,
    }
  },
  mounted() {
  },
  methods: {
    before(request) {
      if (!(request instanceof PostRequest)) return;
      request.filterBody((body) => {
        return {idea_id: body, project_id: this.id}
      });
      request.setApi('add_project_idea')
      request.setThen((e) => {
        this.dialog = false;
        this.$emit('then', e);
      });
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <form @submit.prevent="request.patch()">

    <v-row>
      <v-col>
        <v-card class="border-light rounded-lg overflow-hidden" outlined>

          <v-progress-linear
              v-if="request.isLoading() "
              indeterminate
          ></v-progress-linear>

          <div class="pt-6 pb-3 px-3 text-center">
            <router-link class="mt-1 mx-auto" :to="{name:'Home'}">
              <logo/>
            </router-link>
            <h2 class="font-weight-medium mt-2">{{ __('PhoneNumber') }}</h2>
            <div>{{ __('EnterPhoneNumber') }}</div>
          </div>

          <v-card-text>
            <div>
              <div class="font-weight-bold">{{ __('PhoneNumber') }}</div>
              <v-text-field
                  v-model="request.body.phone_number"
                  dense
                  outlined
              />
            </div>
          </v-card-text>

          <v-card-actions class="justify-end px-4 pb-6">
            <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
              <span v-if="!request.is_loading">{{ __('Next') }}</span>
              <span v-else>...</span>
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-col>
    </v-row>

  </form>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";
import Logo from "../app/Logo";
import auth from "../../tools/Auth";

export default {
  name: "CompletePhone",
  components: {Logo},
  props: {

  },
  data() {
    return {
      request: new PostRequest('update_profile'),
    }
  },
  mounted() {
    this.request.setThen(this.then);
  },
  computed: {},
  methods: {
    then() {
      let user = this._user;
      user.phone_number = this.request.body.phone_number;

      auth.setUserInfo(user)
      this.$store.commit('setUserInfo', user)
      this.redirect();
    },
    redirect() {
      if (this.$route.query.redirect) return window.location.href = this.$route.query.redirect;
      this.$router.push({name: 'Panel'});
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="profile-head-line" v-if="_is_object(data)">
    <h3 class="font-size-18">{{ __('WriteReviewFor') }}</h3>

    <v-card class="overflow-hidden my-6 grey lighten-3  rounded-lg" outlined flat>
      <v-row class="align-center" style="align-items: stretch !important;">
        <!--  Left Side Info      -->
        <v-col cols="12" md="6" sm="10">
          <div class="pa-6 d-flex primary--text align-center">
            <v-avatar class="profile-logo" size="70">
              <assets-image :data="data.logo"/>
            </v-avatar>

            <div class="px-5">
              <span class="title font-head font-size-18">{{ data.business_name }}</span>
            </div>

            <v-divider class="mx-3" vertical/>

            <div class="px-3 font-size-14">
              <div>{{ data.address }}</div>
              <div>{{ data.state }}</div>
              <div>{{ data.city }}</div>
              <div>{{ data.country }}</div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6" sm="2" class="hidden-xs-only" :style="'background-image: url('+banner_url+');background-size: cover'">
<!--          <assets-image :data="data.banner" width="100%" type="cover" height="290"/>-->
          <div class="banner-main" style="margin-left: -20px;"></div>
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import UserCard from "@/components/users/UserCard";
import Rate from "@/components/app/Rate";
import Upload from "@/components/assets/Upload";
import urls from "@/tools/Urls";

export default {
  name: "ProfileHeadInline",
  components: {Upload, Rate, UserCard, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    editable: {
      default: false,
      type: Boolean,
    },
  },

  computed:{
    banner_url(){
      try {
        return  urls.api('assets_url', [this.data.banner]);
      } catch (e) {
      }
    }
  }
}
</script>

<style>
.profile-head-line .banner-main {
  position: absolute;
  height: 600px;
  width: 100%;
  top: -60px;
  left: unset;
  z-index: 999;
  -webkit-box-shadow: inset 80px -5px 50px 30px #eeeeee ;
  box-shadow: inset 80px -5px 80px 50px #eeeeee ;
}
</style>
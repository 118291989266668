<template>
  <div class="full-width">
    <div class="font-weight-bold" v-if="!hide_title">{{__('Style')}}</div>

    <v-select
        v-if="!list"
        :items="items()"
        :value="value"
        @input="(e) => $emit('input', e)"
        class="custom-selector-border rounded-lg"
        outlined
        dense
    ></v-select>


    <template v-else>
      <v-skeleton-loader v-if="request.isLoading()"  type="card" height="38" />

      <from-list-selector
          :items="items()"
          :value="value"
          @input="(e) => $emit('input', e)"
          v-bind="$attrs"
      />
    </template>

  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import FromListSelector from "@/components/selectors/FromListSelector";

export default {
  name: "StyleSelector",
  components: {FromListSelector},
  props: {
    value: {
      default: null,
      type: [String, Object],
    },
    list: {
      default: false,
      type: Boolean,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
    first_label: {
      default: null,
      type: String,
    }
  },
  data() {
    return {
      request: new GetRequest('get_list'),
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.addFilter('type', 'idea_style');
      this.request.get();
    },
    items() {
      try {
        let items = [
          {
            text: this.first_label ? this.first_label : this.__('All'),
            value: null,
          },
        ];
        if (!this.request.data) return;
        this.request.data.forEach((item) => {
          items.push({
            text: item.name,
            value: item.id,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-btn @click="share" v-bind="$attrs">
    <v-icon small>mdi-share-variant</v-icon>&nbsp;
    {{ title ?? __('Share') }}
  </v-btn>
</template>

<script>
import message from "@/tools/Message";

export default {
  name: "ButtonShare",
  props: {
    title: {
      type: String,
      default: null,
    },
    data: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    }
  },
  methods: {
    async share() {
      try {
        let text = this.data ? this.data : window.location.href;
        await navigator.clipboard.writeText(text);
        message.show(this.message ?? this.__('UrlCopiedForShare'))
      } catch (e) {
        console.log(e);
      }

    },
  },
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-card v-if="job" class="grey lighten-4 rounded-lg mb-3" flat>
      <v-row align="center">
        <v-col cols="12" sm="8">
          <v-card-title :class="{'align-start pointer': !disabled, 'align-start': disabled}"
                        @click="!disabled ? modal = true : null">
            <div>
              <div>
                {{ job.title ? job.title : job.job_type_id.title.toUpperCase() }}
              </div>
              <div class="subtitle-2 text--disabled">
                <div>
                  {{ __('JobStatus') }}:
                  {{ __(data && data.job_id ? data.job_id.status : '') }}
                </div>
                <div>
                  <span>{{ __('Client') }} : <full-name :data="client"/> </span>
                  <span><v-icon>mdi-circle-small</v-icon></span>
                  <span>{{ __('CreateDate') }} : <date-text :data="data.date_created"/></span>
                </div>
                <div>
                  <tags v-bind:data="data.job_id.tags"/>
                </div>
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="4">
          <div v-if="!data.job_id.pro_id && data.pro_status === 'pending'" class="d-flex pa-3 justify-end">
            <v-btn
                elevation="0"
                @click="reject()"
                :disabled="data.pro_status === 'rejected'">
              {{ __('Reject') }}
            </v-btn>
            <space/>
            <v-btn
                color="success"
                elevation="0"
                @click="accept()"
                :disabled="data.pro_status === 'accepted' || request.body.pro_status === 'accepted'">
              {{ __('Accept') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!--  More info modal  -->
    <modal v-model="modal" fullscreen>

      <template v-slot:title>
        <user :data="client" hide_email :call="connected" :chat="connected" :email="connected"/>
      </template>

      <template v-slot:main>
        <div>
          <project-title :data="job.project_id"/>

          <!--  Job and Project info        -->
          <v-row class="my-6">
            <v-col cols="12" sm="6">
              <v-card v-if="data" class="rounded-lg" :title="__('JobInfo')" outlined>
                <v-card-title class="title">
                  {{ job.title ? job.title : job.job_type_id.title.toUpperCase() }}
                </v-card-title>

                <v-card-text>
                  <div class="subtitle-2 text--disabled">
                    <div>
                      {{ __('Status') }}:
                      {{ __(job.status) }}
                    </div>
                    <div>
                      {{ __('DesiredStartDate') }}:
                      {{ job.desired_start_date }}
                    </div>
                    <div>
                      <span>{{ __('Client') }} : <full-name :data="client"/> </span>
                    </div>
                    <div v-if="client && connected">
                      <div>
                        <span>{{ __('Email') }} : {{ client.email }} </span>
                      </div>
                      <div>
                        <span>{{ __('PhoneNumber') }} : {{ client.phone_number }} </span>
                      </div>
                    </div>
                    <div>
                      <span>{{ __('CreateDate') }} : <date-text :data="data.date_created"/></span>
                    </div>
                    <div>
                      <span>{{ __('Description') }} : {{ job.description }}</span>
                    </div>
                    <div>
                      <tags v-bind:data="data.job_id.tags"/>
                    </div>
                  </div>
                </v-card-text>

                <v-card-actions v-if="!data.job_id.pro_id && data.pro_status === 'pending'" class="pa-4">
                  <v-btn color="success" elevation="0" @click="accept()"
                         :disabled="data.pro_status === 'accepted' || request.body.pro_status === 'accepted'">
                    {{ __('Accept') }}
                  </v-btn>
                  <v-spacer/>
                  <v-btn elevation="0" @click="reject()" :disabled="data.pro_status === 'rejected'">{{
                      __('Reject')
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <project-info :data="job.project_id"/>
            </v-col>
          </v-row>
        </div>
      </template>

      <template v-slot:actions>

      </template>
    </modal>
  </div>
</template>

<script>

import {PostRequest} from "@/models/PostRequest";
import DateText from "../app/DateText";
import FullName from "../account/FullName";
import Tags from "../tags/Tags";
import Modal from "../modal/Modal";
import User from "../users/User";
import ProjectInfo from "../projects/ProjectInfo";
import ProjectTitle from "../projects/ProjectTitle";
import Space from "../app/Space";

export default {
  name: "LeadJob",
  components: {Space, ProjectTitle, ProjectInfo, User, Modal, Tags, FullName, DateText},
  props: {
    data: {
      default: null,
      type: Object,
    },
    disabled: {
      default: false,
      type: Boolean,
    }
  },
  computed: {
    job() {
      try {
        return this.data.job_id;
      } catch (e) {
        return {};
      }
    },
    connected() {
      try {
        return this.data.client_status === 'connected';
      } catch (e) {
        return false;
      }
    },
    client() {
      try {
        return this.data.job_id.project_id.client_id;
      } catch (e) {
        return null;
      }
    }
  },
  data() {
    return {
      request: new PostRequest('job_pro_suggest'),
      accepted: false,
      hide_item: false,
      modal: null,
    }
  },

  mounted() {
    this.request.setApiParams([this.data.id]);
    this.setBody();
  },
  watch: {},
  methods: {
    setBody() {
      this.request.setBody({
        pro_status: this.data.status,
      });
    },
    change(value, key) {
      this.request.body.status = value;
      this.request.patch();
      return value + key;
    },
    accept() {
      this.request.setThen(() => this.$emit('reload'));
      this.request.body.pro_status = 'accepted';
      this.request.patch();
    },
    reject() {
      this.request.setThen(() => this.$emit('reload'));
      this.request.body.pro_status = 'rejected';
      this.request.patch();

    },

  },
}
</script>

<style scoped>

</style>

<template>
  <div v-if="archive_request ">
    <v-menu offset-y left>
      <template v-slot:activator="{ on, attrs }">

        <v-btn v-bind="attrs" v-on="on" :style="{'background':hover? 'rgba(0,0,0,0.08)': ''}" icon>
          <v-icon :color="color">mdi-dots-vertical</v-icon>
        </v-btn>

      </template>
      <v-list>
        <v-list-item v-if="is_owner" @click="edit_dialog = true">
          <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="is_owner" @click="archive_dialog = true">
          <v-list-item-title>{{ __("Delete") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="convertable" @click="copy">
          <v-list-item-title>{{ __("Copy") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="$route.name !== 'IdeaDetails' && data" :to="{name:'IdeaDetails', params: { id: data.id }}">
          <v-list-item-title>{{ __("Details") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Edit Dialog -->
    <modal fullscreen v-model="edit_dialog">
      <template v-slot:title>
        <span>{{ __("EditIdea") }}</span>
      </template>
      <template v-slot:main>
        <ideas-write :data="data" @then="()=>$emit('edited', null)"/>
      </template>
    </modal>

    <!-- Archive Dialog -->
    <confirm v-model='archive_dialog' @accepted="archive"/>

  </div>
</template>

<script>

import IdeasWrite from "./IdeasWrite";
import {PostRequest} from "../../models/PostRequest";
import Modal from "../modal/Modal";
import Confirm from "../modal/Confirm";

export default {
  name: "IdeaMenu",
  components: {Confirm, Modal, IdeasWrite},
  props: {
    data: {
      default: null,
      type: Object
    },
    hover: {
      default: null,
      type: Boolean
    },
    color: {
      default: null,
      type: String
    },
  },
  data: () => {
    return {
      edit_dialog: false,
      archive_dialog: false,
      archive_request: new PostRequest('edit_idea'),
      copy_request: new PostRequest('add_idea'),
    }
  },
  computed: {
    convertable() {
      try {
        if (!this._access('admin')) return false;
        let user_id = (typeof this.data.user_created === 'object') ? this.data.user_created.id : this.data.user_created;
        if (user_id === this._user.id) return false;
        return true;
      } catch (e) {
        return false;
      }
    },
    is_owner() {
      try {
        let user_id = (typeof this.data.user_created === 'object') ? this.data.user_created.id : this.data.user_created;
        if (user_id !== this._user.id) return false;
        return true;
      } catch (e) {
        return false;
      }
    },
  },
  mounted() {
    this.archive_request.setApiParams([this.data.id])
  },
  methods: {
    archive() {
      try {
        this.archive_dialog = false;
        this.archive_request.setBody({'status': 'archived'})
        this.archive_request.setThen(() => this.$emit('archived', this.data))
        this.archive_request.patch();
      } catch (e) {
        console.log(e);
      }
    },
    copy() {
      try {
        this.copy_request.setBody(
            {
              title: this.data.title,
              size_id: this.getId(this.data.size_id),
              room_id: this.getId(this.data.room_id),
              budget: this.data.budget,
              color_id: this.getId(this.data.color_id),
              online_link: this.data.online_link,
              design_credit: this.data.design_credit,
              photography_credit: this.data.photography_credit,
              pictures: this.picturesIds(this.data.pictures),
              add_by: this.getId(this.data.add_by),
              status: 'pending',
            }
        );
        this.copy_request.setThen(() => this.$emit('copied', this.data))
        this.copy_request.post();
      } catch (e) {
        console.log(e);
      }
    },
    getId(item) {
      try {
        if (typeof item === 'object') return item.id;
        return item;
      } catch (e) {
        return null;
      }
    },
    picturesIds(pictures) {
      try {

        return pictures.map((item) => {
          if (item.id) delete item.id;
          if (item.idea_id) delete item.idea_id;
          return item;
        });
      } catch (e) {
        return pictures;
      }
    },

  },
}
</script>

<style scoped>

</style>
<template>
  <v-dialog
      v-model="dialog"
      scrollable
      width="100%"
      max-width="890"
  >

    <template v-slot:activator="{ on }">
      <slot name="button">
        <v-btn  v-on="on" dark color="primary" elevation="0" class="rounded-lg">
          <span v-if="!button_title">{{ __('Save') }}</span>
          <span v-else>{{ button_title }}</span>
        </v-btn>
      </slot>
    </template>

    <v-card class="rounded-lg overflow-visible">
      <v-card-title>
        <div  class="full-width d-flex justify-space-between">
          <span class="font-size-18">{{ __("SaveIdeaInProject") }}</span>
          <div>
            <v-btn icon @click="close" outlined small>
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card-title>

      <div class="px-6">
        <v-divider></v-divider>
      </div>

      <v-card-text class="light-scroll px-0 overflow-x-hidden" style="max-height: 400px;">
        <project-selector v-model="selected" @then="(e)=>then(e)" status="active" checkbox/>
      </v-card-text>

      <v-card-actions class="align-center text-center justify-center">
        <div class="text-center my-3">
          <v-btn color="primary" elevation="0"   class="rounded-lg"   @click="save" :disabled="add_request.isLoading()">
            {{ __('Send') }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Modal from "../modal/Modal";
import ProjectSelector from "../selectors/ProjectSelector";
import {PostRequest} from "../../models/PostRequest";
import {DeleteRequest} from "../../models/DeleteRequest";

export default {
  name: "IdeaSave",
  components: {ProjectSelector, Modal},
  props: {
    id: {
      default: null,
      type: [String, Number],
      required: true
    },
    button_title: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      selected: [],
      old_selected: {},
      dialog: null,
      add_request: new PostRequest('add_project_idea'),
      remove_request: new DeleteRequest('add_project_idea'),
    }
  },
  mounted() {
  },
  methods: {
    save() {
      this.close()
      this.add();
      this.remove();
    },
    add() {
      this.add_request.setBody(this.body())
      this.add_request.post();
    },
    remove() {
      let items = this.removedItems();
      if (!items || !Array.isArray(items) || items.length === 0) return;
      this.remove_request.setBody(items)
      this.remove_request.delete();
    },
    body() {
      try {
        let result = [];
        this.selected.forEach((id) => {
          result.push({project_id: id, idea_id: this.id});
        })
        return result;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    close() {
      this.dialog = false;
    },
    then(response) {
      try {
        let items = response.data;
        if (!items) return;

        items.forEach((item) => {
          if (!item || typeof item !== "object" || !item.ideas) return;
          if (!Array.isArray(item.ideas) || item.ideas.length === 0) return;

          // check exist idea
          item.ideas.forEach((relation_item) => {
            if (!relation_item || !relation_item.idea_id) return;
            if (this.id !== relation_item.idea_id) return;
            if (!this.selected.includes(item.id)) this.selected.push(item.id);

            // save relation id for remove old selected items
            this.old_selected[item.id] = {project_id: item.id, relation_id: relation_item.id};
          })

        })
      } catch (e) {
        console.log(e);
      }
    },
    removedItems() {
      try {
        let result = [];
        if (!this.old_selected || Object.keys(this.old_selected).length === 0) return null;

        Object.keys(this.old_selected).forEach((key) => {
          if (!key) return;

          if (this.selected.includes(key)) return;

          let item = this.old_selected[key];

          if (!item || !item.relation_id) return;

          result.push(item.relation_id);
        })
        return result;
      } catch (e) {
        console.log(e);
        return null;
      }
    }
  },
}
</script>

<style>

</style>
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm._is_object(_vm.data))?_c('div',[(!_vm.hide_title)?_c('div',{staticClass:"font-size-10"},[_vm._v(_vm._s(_vm.__('ProfessionalInProject')))]):_vm._e(),_c('div',{staticClass:"py-4"},[_c('router-link',{attrs:{"to":{name:'ProfessionalProfile', params: {id: _vm.data.id}}}},[_c(VListItem,{staticClass:"px-0"},[_c(VListItemAvatar,{staticClass:"mr-1"},[_c(VAvatar,{staticClass:"profile-logo",attrs:{"size":"35"}},[_c('assets-image',{attrs:{"data":_vm.data.logo}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.data.business_name)+" ")]),_c(VListItemSubtitle,{staticClass:"text--disabled font-size-12"},[_c(VIcon,{attrs:{"color":"grey","small":""}},[_vm._v("mdi-map-marker")]),_vm._v("  "),_c('span',[_vm._v(_vm._s(_vm.data.city)+", "+_vm._s(_vm.data.country))])],1)],1)],1)],1)],1),(!_vm.hide_buttons)?[_c(VBtn,{staticClass:"full-width letter-normal mb-2 text-transform-unset rounded-lg font-size-15",attrs:{"color":"primary","outlined":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.__('Message'))+" ")]),(_vm.data.phone_number)?_c(VBtn,{staticClass:"full-width letter-normal mb-2 text-transform-unset rounded-lg font-size-15",attrs:{"color":"primary","elevation":"0","href":'tel:'+_vm.data.phone_number}},[_vm._v(" "+_vm._s(_vm.__('Call'))+" ")]):_vm._e()]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm._is_object(_vm.data))?_c('div',{staticClass:"profile-head-line"},[_c('h3',{staticClass:"font-size-18"},[_vm._v(_vm._s(_vm.__('WriteReviewFor')))]),_c(VCard,{staticClass:"overflow-hidden my-6 grey lighten-3 rounded-lg",attrs:{"outlined":"","flat":""}},[_c(VRow,{staticClass:"align-center",staticStyle:{"align-items":"stretch !important"}},[_c(VCol,{attrs:{"cols":"12","md":"6","sm":"10"}},[_c('div',{staticClass:"pa-6 d-flex primary--text align-center"},[_c(VAvatar,{staticClass:"profile-logo",attrs:{"size":"70"}},[_c('assets-image',{attrs:{"data":_vm.data.logo}})],1),_c('div',{staticClass:"px-5"},[_c('span',{staticClass:"title font-head font-size-18"},[_vm._v(_vm._s(_vm.data.business_name))])]),_c(VDivider,{staticClass:"mx-3",attrs:{"vertical":""}}),_c('div',{staticClass:"px-3 font-size-14"},[_c('div',[_vm._v(_vm._s(_vm.data.address))]),_c('div',[_vm._v(_vm._s(_vm.data.state))]),_c('div',[_vm._v(_vm._s(_vm.data.city))]),_c('div',[_vm._v(_vm._s(_vm.data.country))])])],1)]),_c(VCol,{staticClass:"hidden-xs-only",style:('background-image: url('+_vm.banner_url+');background-size: cover'),attrs:{"cols":"12","md":"6","sm":"2"}},[_c('div',{staticClass:"banner-main",staticStyle:{"margin-left":"-20px"}})])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="slider-selector">
    <v-slider
        :value="value"
        @input="(e) => $emit('input', e)"
        thumb-label="always"
        color="yellow darken-3"
        track-color="grey lighten-3"
        height="5"
        v-bind="$attrs"
    >
      <template #thumb-label>
        <div v-if="!hide_thumb" class="mt-3 text--disabled">{{ value }}</div>
      </template>
    </v-slider>
  </div>
</template>


<script>

export default {
  name: "SliderSelector",
  props: {
    value: {
      default: null,
      type: [String, Number],
    },
    hide_thumb: {
      default: false,
      type:  Boolean,
    },
  }, 
}
</script>

<style>
.slider-selector .v-slider__track-container {
  height: 8px;
  border-radius: 10px;
  overflow: hidden;
}

.slider-selector .v-messages {
  display: none;
}
.slider-selector .v-slider__thumb-label.yellow.darken-3 {
  height: 15px !important;
  background-color: transparent !important;
}
.slider-selector[readonly='readonly'] .v-slider__thumb.yellow.darken-3 {
 display: none;
}
</style>
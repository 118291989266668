<template>
  <v-container>
    <page-title :text="__('UserManagement')" heading/>
    <users
        :nin_role='{"_nin": ["3448c9ef-6456-4cad-8015-87a4e8fe1c31","ebe9313f-150a-4331-98bf-af294eec69cf"]}'
        table
        add_is_visible
    />
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Users from "@/components/users/Users"

export default {
  name: "UsersPage",
  components: {Users, PageTitle}
}
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.archive_request )?_c('div',[_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({style:({'background':_vm.hover? 'rgba(0,0,0,0.08)': ''}),attrs:{"icon":"","disabled":_vm.disabled}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.color}},[_vm._v("mdi-dots-horizontal")])],1)]}}],null,false,662826668)},[_c(VList,[_c(VListItem,{on:{"click":function($event){_vm.archive_dialog = true}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Delete")))])],1),_c(VListItem,{on:{"click":function($event){_vm.edit_dialog = !_vm.edit_dialog}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Edit")))])],1)],1)],1),_c('confirm',{on:{"accepted":_vm.archive},model:{value:(_vm.archive_dialog),callback:function ($$v) {_vm.archive_dialog=$$v},expression:"archive_dialog"}}),_c('job-edit',{attrs:{"data":_vm.data,"modal":""},on:{"updated":e => _vm.$emit('updated', e)},model:{value:(_vm.edit_dialog),callback:function ($$v) {_vm.edit_dialog=$$v},expression:"edit_dialog"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
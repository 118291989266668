<template>
  <div>
    <questions/>
    <space class="mb-16 pb-16"/>
  </div>
</template>

<script>
import Questions from "@/components/questions/Questions";
import Space from "../../../components/app/Space";

export default {
  name: "StartProjectPage",
  components: {Space, Questions}
}
</script>

<style scoped>

</style>
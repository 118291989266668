<template>
  <!--  Add Idea Form  -->
  <div>
    <loading v-if="request_get.isLoading()"/>

    <form v-else-if="request.body.first_name  !== undefined" @submit.prevent="submit()">
      <v-row>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('FirstName') }} *</div>
          <v-text-field
              v-model="request.body.first_name"
              dense
              outlined
              required
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('LastName') }} *</div>
          <v-text-field
              v-model="request.body.last_name"
              dense
              outlined
              required
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('Phone') }}</div>
          <v-text-field
              v-model="request.body.phone_number"
              dense
              outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('Email') }}</div>
          <v-text-field
              v-model="request.body.email"
              dense
              outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('TelegramId') }}</div>
          <v-text-field
              v-model="request.body.telegram_id"
              dense
              outlined
          />
        </v-col>

        <v-col cols="12" md="6">
          <role-selector
              v-model="request.body.role"
              :without='[
                "3448c9ef-6456-4cad-8015-87a4e8fe1c31",
                "ebe9313f-150a-4331-98bf-af294eec69cf",
                "ec5d18b5-047b-4ab5-b7f8-a089bd8be43a",
                "be3523ab-31c0-40a6-a363-300808d4dbbe"
              ]'
          />
        </v-col>

        <v-col cols="12" md="6">
          <status-selector :items="status_items" v-model="request.body.status"/>
        </v-col>

        <v-col md="12">
          <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
            <span v-if="!request.is_loading">{{ __('Submit') }}</span>
            <span v-else>...</span>
          </v-btn>
        </v-col>

      </v-row>
    </form>
  </div>
</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import RoleSelector from "@/components/selectors/RoleSelector"
import StatusSelector from "@/components/selectors/StatusSelector";
import {GetRequest} from "@/models/GetRequest";
import Loading from "../app/Loading";

export default {
  name: "UsersEdit",
  components: {Loading, StatusSelector, RoleSelector},
  props: {
    id: {
      type: [Number, String],
      default: 0,
      require: true
    },
  },
  data() {
    return {
      request_get: new GetRequest('get_user', [this.id]),
      request: new PostRequest('update_user', [this.id]),
      status_items: [
        {
          text: this.__('Active'),
          value: 'active',
        },
        {
          text: this.__('Archive'),
          value: 'archive',
        },
      ]
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      this.request_get.setThen(this.setBody);
      this.request_get.setFields('*, role.*')
      this.request_get.row();
    },
    setBody() {
      this.request.setThen(this.submitted);
      this.request.setBody(
          {
            first_name: this.request_get.data.first_name,
            last_name: this.request_get.data.last_name,
            phone_number: this.request_get.data.phone_number,
            email: this.request_get.data.email,
            status: this.request_get.data.status,
            role: this.request_get.data.role.id,
            telegram_id: this.request_get.data.telegram_id,
          }
      );
    },
    submit() {
      this.request.patch();
    },
    submitted(data) {
      this.$emit('then', data);
      this.get();
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <v-container>
    <page-title :text="__('Profile')"/>

    <v-row>

      <!-- Loading -->
      <loading v-if="request.isLoading()"/>

      <!-- Data -->
      <v-col v-else>
        <account-edit :data="request.data" @updated="get"/>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import Loading from "@/components/app/Loading";
import auth from "@/tools/Auth";
import PageTitle from "../../../components/app/PageTitle";
import AccountEdit from "../../../components/account/AccountEdit";

export default {
  name: "ProfilePage",
  components: {AccountEdit, PageTitle, Loading},
  data() {
    return {
      request: new GetRequest('get_current_user'),
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    then(response) {
      try {
        if (!response || !response.data || !response.data.id) throw 'Can not get user info!';
        auth.setUserInfo(response.data)
        this.$store.commit('setUserInfo', response.data)
      } catch (e) {
        console.log(e);
      }
    },
    get() {
      let fields = '*, role.*';
      if (this._access('pro', true)) fields += ',expertises.expertise_id';
      this.request.setFields(fields);
      this.request.setThen((response) => this.then(response));
      this.request.row();
    }
  }
}
</script>

<style scoped>

</style>
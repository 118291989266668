<template>
  <v-row>
    <v-col v-if="_access('pro')" cols="12">
      <div class="text-center mt-16 pt-16">
        <div class="text--secondary mb-6">
          {{__('ProCannotCreateProject')}}
        </div>
        <v-btn
            large
            color="primary"
            class="rounded-lg"
            elevation="0"
            @click="redirect"

            style="min-width: 160px"
        >
          {{ __('GoToMyAccount') }}
        </v-btn>
      </div>
    </v-col>

    <template v-else>
      <v-col cols="12">
        <div class="my-6 mt-16 text-center">{{ __('QuestionsAuthFirstText') }}</div>
      </v-col>
      <v-col cols="12">
        <div class="text-h4 my-6 mt-16 text-center">{{ __('QuestionsAuthTitle') }}</div>
        <div class="text--secondary text-center">{{ __('QuestionsAuthText') }}</div>

        <div class="text-center">

          <questions-next-button
              v-if="questions.getProject('id') && _user"
              :questions="questions"
              class="my-16"
              fixed
          />
        </div>
      </v-col>

      <v-col cols="12">
        <div class="mx-auto" style="max-width: 390px">
          <auth v-if="!user || !user.id" @then="next" dont_redirect/>
        </div>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import {Questions} from "../../models/Questions";
import Auth from "../auth/Auth";
import auth from "../../tools/Auth";
import QuestionsNextButton from "./QuestionsNextButton";
import app from "../../tools/App";
import {PostRequest} from "../../models/PostRequest";
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "QuestionsAuth",
  components: {QuestionsNextButton, Auth},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.user_info
    }
  },
  data() {
    return {
      update_request: new PostRequest('update_profile'),
      user_request: new GetRequest('get_current_user'),
    }
  },
  mounted() {
    if (this.user) this.next();
  },
  methods: {
    redirect() {
      this.questions.clear();
      this.$router.push({name: 'Panel'});
    },
    next() {
      try {

        // update user role if its new
        if (this._access('pending')) {
          this.updateRole();
        }

        // check role is pro
        if (this._access('pro')) return null;

        if (!auth.check()) throw 'Not found user info!';
        this.questions.save(() => this.then());

      } catch (e) {
        console.log(e);
      }
    },
    then() {
      this.getUser();
      this.questions.next();
    },
    getUser() {
      try {
        this.user_request.setThen((response) => {
          if (!response || !response.data || !response.data.id) throw 'Can not get user info!';
          auth.setUserInfo(response.data)
          this.$store.commit('setUserInfo', response.data)
        })
        this.user_request.setFields('*, role.*');
        this.user_request.row();
      } catch (e) {
        console.log(e);
      }
    },
    updateRole(){
      this.update_request.body.role = app.roles['client'];
      this.update_request.patch();
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-row>
    <v-col>
      <v-card class="border-light rounded-lg overflow-hidden" outlined>
        <form @submit.prevent="request.post()" ref="upload-form">

          <v-progress-linear v-if="request.isLoading() || user_info_request.isLoading()" indeterminate></v-progress-linear>

          <div class="pt-6 pb-3 px-3 text-center">
            <router-link class="mt-1 mx-auto" :to="{name:'Home'}">
              <logo/>
            </router-link>
            <h2 class="font-weight-medium mt-2">{{ __('SelectRole') }}</h2>
            <div>{{ __('ToRenotive') }}</div>
          </div>

          <v-card-text>
            <v-card flat class="grey lighten-4 pa-6 text-center mb-3 pointer" @click="select('client')">
              {{ __("Client") }}
            </v-card>
            <v-card flat class="grey lighten-4 pa-6 text-center mb-3 pointer" @click="select('pro')">
              {{ __("Pro") }}
            </v-card>
          </v-card-text>

          <v-card-actions class="justify-end px-4 pb-6">

          </v-card-actions>
        </form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import Logo from "../app/Logo";
import {PostRequest} from "../../models/PostRequest";
import app from "../../tools/App";
import auth from "../../tools/Auth";
import {GetRequest} from "../../models/GetRequest";
import urls from "../../tools/Urls";

export default {
  name: "CompleteRegister",
  components: {Logo},
  props: {
    dont_redirect: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      request: new PostRequest('update_profile'),
      user_info_request: new GetRequest('get_current_user')
    }
  },
  mounted() {
    this.request.setThen(this.refresh);
    this.setBody();
  },
  methods: {
    setBody() {
      this.request.setBody({
        'role': null,
      })
    },
    select(key) {
      this.request.body.role = app.roles[key];
      this.request.patch();
    },
    async refresh() {
      let response = await fetch(urls.api('refresh_token'), {
        method: 'POST',
        mode: 'cors',
        credentials: 'include'
      });
      this.is_loading = false;
      this.then(response)
    },
    async then(response) {
      try {
        response = await response.json();
        if (!response || !response.data || !response.data.access_token) throw 'Login failed!';

        // save token
        auth.saveToken(response.data.access_token)
        auth.saveRefreshToken(response.data.refresh_token)
        auth.saveExpiration(response.data.expires)

        this.getUser()

      } catch (e) {
        console.log(e);
      }
    },
    redirect() {
      if (this.$route.query.redirect) return window.location.href = this.$route.query.redirect;
      this.$router.push({name: 'Panel'});
    },
    getUser() {
      try {
        this.user_info_request.setThen((response) => {
          if (!response || !response.data || !response.data.id) throw 'Can not get user info!';
          auth.setUserInfo(response.data)
          this.$store.commit('setUserInfo', response.data)
          this.redirect();
        })
        this.user_info_request.setFields('*, role.*');
        this.user_info_request.get();
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<template>

  <v-slide-x-reverse-transition>
    <div v-if="_is_array(data) && render">
      <!--  List  -->
      <v-slide-group
          id="expertises-horizontal"
          class="px-sm-2 px-md-0 slide-group-hide-arrows slide-group-custom-arrows"
          show-arrows
      >
        <v-slide-item v-for="(item, key) in data" :key="'exp-horizontal'+key">
          <expertise-card :data="item" @select="e => $emit('select', e)"/>
        </v-slide-item>
      </v-slide-group>
    </div>
  </v-slide-x-reverse-transition>
</template>

<script>
import ExpertiseCard from "@/components/expertises/ExpertiseCard";

export default {
  name: "ExpertisesHorizontal",
  components: {ExpertiseCard},
  props: {
    data: {
      default: null,
      type: Array,
    },
    card: {
      default: null,
      type: Boolean,
    },
  },
  data() {
    return {
      render: false,
    }
  },
  mounted() {
    setTimeout(() => this.render = true, 500)
  }
}
</script>

<style>

#expertises-horizontal.slide-group-custom-arrows .v-slide-group__prev,
#expertises-horizontal.slide-group-custom-arrows .v-slide-group__next {
  top: 52px !important;
}

#expertises-horizontal > div.v-slide-group__next,
#expertises-horizontal > div.v-slide-group__prev {
  padding: 8px 7px;
  border-radius: 9px;
}
</style>
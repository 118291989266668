import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.request.patch()}}},[_c(VRow,[_c(VCol,[_c(VCard,{staticClass:"border-light rounded-lg overflow-hidden",attrs:{"outlined":""}},[(_vm.request.isLoading() )?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('div',{staticClass:"pt-6 pb-3 px-3 text-center"},[_c('router-link',{staticClass:"mt-1 mx-auto",attrs:{"to":{name:'Home'}}},[_c('logo')],1),_c('h2',{staticClass:"font-weight-medium mt-2"},[_vm._v(_vm._s(_vm.__('PhoneNumber')))]),_c('div',[_vm._v(_vm._s(_vm.__('EnterPhoneNumber')))])],1),_c(VCardText,[_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('PhoneNumber')))]),_c(VTextField,{attrs:{"dense":"","outlined":""},model:{value:(_vm.request.body.phone_number),callback:function ($$v) {_vm.$set(_vm.request.body, "phone_number", $$v)},expression:"request.body.phone_number"}})],1)]),_c(VCardActions,{staticClass:"justify-end px-4 pb-6"},[_c(VBtn,{attrs:{"type":"submit","color":"primary","elevation":"0","disabled":_vm.request.isLoading()}},[(!_vm.request.is_loading)?_c('span',[_vm._v(_vm._s(_vm.__('Next')))]):_c('span',[_vm._v("...")])])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
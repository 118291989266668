<template>
  <v-container  class="container-max-width">
    <page-title :text="__('HomeDesignIdeas')"></page-title>
    <rooms-horizontal :limit="150"/>

    <div class="py-3"></div>

    <div class="rounded-lg px-7 py-1 pt-5 grey lighten-4">
      <blogs single class="my-3"/>
    </div>

    <div class="py-3"></div>

    <ideas
        :item_attrs="{hide_options: true}"
        :filters_attrs="{with_budget: true}"
        status="published"
        masonry
    />
  </v-container>
</template>

<script>
import Ideas from "../../../components/ideas/Ideas";
import RoomsHorizontal from "../../../components/rooms/RoomsHorizontal";
import PageTitle from "../../../components/app/PageTitle";
import Blogs from "../../../components/blogs/Blogs";

export default {
  name: "IdeasLandingPage",
  components: {Blogs, PageTitle, RoomsHorizontal, Ideas}
}
</script>

<style scoped>

</style>

<template>
  <v-container>
    <page-title :text="__('LeadJobs')" heading/>
    <lead-jobs/>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import LeadJobs from "../../../components/lead/LeadJobs";

export default {
  name: "LeadJobsPage",
  components: {LeadJobs, PageTitle}
}
</script>

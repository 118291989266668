import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({hover}){return [(_vm.data && _vm.data.option)?_c('div',{class:{'text-center pa-3': true, 'pointer': _vm.select_able},style:({'min-width': _vm.min_width ? _vm.min_width + 'px' : 'auto'}),on:{"click":function($event){return _vm.select(_vm.data)}}},[_c('div',{class:{
          ' text-center justify-center mx-auto rounded-circle overflow-hidden': true,
          'elevation-5': hover,
          'grey lighten-3': !_vm.selected,
        },style:({
          'width': _vm.width ? _vm.width+'px' : 'auto',
          'height': _vm.height ? _vm.height+'px' : 'auto',
          'background': (_vm.selected ? '#e594004d' : ''),
          'padding-top': (_vm.width/3.5)+'px'
        }),attrs:{"title":_vm.data.text}},[(_vm.selected)?_c('div',{staticClass:"text-right",staticStyle:{"position":"absolute","width":"130px"}},[_c(VIcon,[_vm._v("mdi-checkbox-marked-circle")])],1):_vm._e(),_c('assets-image',{staticClass:"mx-auto",attrs:{"data":_vm.data.icon,"width":"64"}})],1),_c('div',{staticClass:"text-center text-subtitle-1 text-uppercase pt-2",staticStyle:{"max-width":"160px"}},[_vm._v(" "+_vm._s(_vm.data.text)+" ")])]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
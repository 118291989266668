<template>
  <v-row>

    <v-col cols="12" sm="12">
      <div class="text-h4 my-6 mt-16 ">{{ __('QuestionsFilesTitle').replace('%s', user.first_name) }}</div>
      <div class="text--secondary" style="max-width: 690px">{{ __('QuestionsFilesText') }}</div>
    </v-col>

    <v-col cols="12" sm="12">
      <user/>
    </v-col>

    <v-col cols="12" sm="12">


      <v-col sm="12">
        <v-row class="border-light rounded-lg my-6 px-16 py-9 d-flex flex-wrap justify-space-between align-center">
          <v-col cols="12" sm="8" md="9">
            <div class="text-h4 py-6" v-html="__('NextSteps')"></div>
            <div class="text--secondary" v-html="__('UploadFilesText')"></div>
          </v-col>

          <v-col cols="12" sm="4" md="3">
            <drive :project_id="questions.getProject('id')" @uploaded="uploaded"/>
          </v-col>

          <v-col cols="12" sm="12">
            <assets-images :data="files" max-width="64" max-height="64" style="min-width: 64px;min-height: 64px;"/>
          </v-col>
        </v-row>
      </v-col>

      <v-col sm="12">
        <calendly/>
      </v-col>


      <!--   :to="{name:'Project', params: {id: questions.getProject('id')}}"   -->
      <v-col sm="12" align="center">
        <questions-next-button :questions="questions" fixed>
          <v-btn
              large
              color="primary"
              class="rounded-lg"
              elevation="0"
              @click="redirect"
              style="min-width: 160px"
          >
            {{ __('GoToMyAccount') }}
          </v-btn>

        </questions-next-button>
      </v-col>

    </v-col>
  </v-row>
</template>

<script>
import {Questions} from "../../models/Questions";
import Drive from "../assets/Drive";
import User from "../users/User";
import AssetsImages from "../assets/Images";
import Calendly from "../calendly/Calendly";
import QuestionsNextButton from "./QuestionsNextButton";

export default {
  name: "QuestionsFiles",
  components: {QuestionsNextButton, Calendly, AssetsImages, User, Drive,},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.user_info;
    }
  },
  data() {
    return {
      files: [],
    }
  },
  mounted() {

  },
  methods: {
    redirect() {
      this.questions.clear();
      this.$router.push({name: 'Panel'});
    },
    uploaded(file_id) {
      this.files.push({directus_files_id: file_id})
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>

    <!-- Desktop  -->
    <div class="hidden-sm-and-down">

      <cities-selector
          v-model="filters.cities"
          :placeholder="__('SearchCity')"
          class="mb-3"
          @input="reload"
          hide_title
          hide_chips
      />

      <profile-filters-chips v-model="filters" @input="reload"/>

      <div class="px-2">
        <profile-filters-options :filters="filters" @reload="reload"/>
      </div>
    </div>

    <!--  Mobile & Tablet  -->
    <div class="hidden-md-and-up">
      <modal v-model="modal" fullscreen>
        <template #button>
          <v-btn class="full-width mt-6 rounded-6 text-transform-unset font-weight-bold letter-normal" large color="primary" outlined>
            <v-icon style="margin-top:-2px">mdi-filter-variant</v-icon>&nbsp;
            <span class="font-size-18">{{ __('Filters') }}</span>
            <div v-if="filters_count" class="primary white--text rounded-circle font-weight-medium font-size-12 ml-2" style="padding:2px 7px 0 5px;">{{filters_count}}</div>
          </v-btn>
        </template>

        <template #default>
          <v-container>
            <div class="px-3">
              <div class="d-flex justify-space-between py-3 mt-6 px-6">
                <div></div>
                <div class="d-flex align-center">
                  <v-icon color="primary" style="margin-top:-2px">mdi-filter-variant</v-icon>&nbsp;
                  <span class="font-size-18 primary--text font-weight-bold">{{ __('Filters') }}</span>
                  <div v-if="filters_count" class="primary white--text rounded-circle font-weight-medium font-size-12 ml-2" style="padding:3px 7px 1px 6px; height: 20px">{{filters_count}}</div>
                </div>
                <div>
                  <v-btn icon @click="modal = false" outlined small>
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>

              <v-divider class="primary mt-1" style="border-width: 1.5px"/>

              <div id="mobile-profile-filters-main" class="px-6 pt-6">
                <cities-selector
                    v-model="filters.cities"
                    :placeholder="__('SearchCity')"
                    class="mb-3"
                    @input="reload"
                    hide_title
                    hide_chips
                />

                <profile-filters-options :filters="filters" @reload="reload"/>
              </div>

              <v-divider class="primary" style="border-width: 1.5px"/>
            </div>
          </v-container>
        </template>
      </modal>

      <profile-filters-chips class="mt-5" v-model="filters" @input="reload"/>

    </div>
  </div>
</template>

<script>
import CitySelector from "@/components/selectors/CitySelector";
import FromListSelector from "@/components/selectors/FromListSelector";
import {GetRequest} from "@/models/GetRequest";
import ProfilesFilter from "@/components/profiles/ProfilesFilter";
import CitiesSelector from "@/components/selectors/CitiesSelector";
import StyleSelector from "@/components/selectors/StyleSelector";
import LanguagesSelector from "@/components/selectors/LanguagesSelector";
import ExpertiseSelector from "@/components/selectors/ExpertiseSelector";
import JobExpertisesSelector from "@/components/selectors/JobExpertisesSelector";
import Rate from "@/components/app/Rate";
import ProfileFiltersChips from "@/components/profiles/ProfileFiltersChips";
import ProfileFiltersOptions from "@/components/profiles/ProfileFiltersOptions";
import Modal from "@/components/modal/Modal";

export default {
  name: "ProfilesFilters",
  components: {
    Modal,
    ProfileFiltersOptions,
    ProfileFiltersChips,
    Rate,
    JobExpertisesSelector,
    ExpertiseSelector,
    LanguagesSelector, StyleSelector, CitiesSelector, ProfilesFilter, FromListSelector, CitySelector
  },
  props: {
    request: {
      default: new GetRequest(),
      type: GetRequest,
      required: true,
    }
  },
  data() {
    return {
      filters: {},
      modal: false,
      default_filters: {
        cities: null,
        categories: null,
        budgets: null,
        languages: {"text": this.__("All"), "value": null},
        rating: {'text': this.__('AnyRating'), 'value': null, 'star_count': null},
        styles: {"text": this.__("All"), "value": null},
      }
    }
  },
  computed:{
    filters_count(){
      try {
        let count = 0;
        if (this._is_array(this.filters.cities)) count += this.filters.cities.length;
        if (this._is_array(this.filters.categories)) count += this.filters.categories.length;
        if (this._is_array(this.filters.budgets)) count += this.filters.budgets.length;
        if (this._is_object(this.filters.languages) && this.filters.languages.value) count ++;
        if (this._is_object(this.filters.rating) && this.filters.rating.value) count ++;
        if (this._is_object(this.filters.styles) && this.filters.styles.value) count ++;
        return  count;
      } catch (e) {
        return 0;
      }
    }
  },
  mounted() {
    this.setDefaults();
    this.setFromUrl()
  },
  methods: {
    setFromUrl() {
      try {
        if (!this.$route.query) return;

        // Expertise
        if (this.$route.query.expertise_id) {
          this.filters.categories = [{text: this.$route.query.expertise_title, value: this.$route.query.expertise_id}]
        }

        this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    reload() {
      this.setFilters()
      this.request.reload(true, true);
    },
    setFilters() {
      this.setDefaults();
      this.setCitiesFilter();
      this.setCategoriesFilter();
      this.setBudgetsFilter();
      this.setLanguagesFilter();
      this.setRatingFilter();
      this.setStylesFilter();
    },
    setCitiesFilter() {
      try {
        let filter = null
        let data = this.filters.cities;
        if (data && Array.isArray(data) && data.length) filter = {"_and": [{"city": {"_in": data}}]}
        this.request.custom_filters.add('cities', filter);
      } catch (e) {
        console.log(e);
      }
    },
    setCategoriesFilter() {
      try {
        let data = this.filters.categories;
        let filter = this.getValues(data);
        if (filter && filter.length !== 0) filter = {"_and": [{"pro_id": {"expertises": {"expertise_id": {"_in": filter}}}}]}
        this.request.custom_filters.add('categories', filter);
      } catch (e) {
        console.log(e);
      }
    },
    setBudgetsFilter() {
      try {
        let filter = null
        let data = this.filters.budgets;
        if (data && Array.isArray(data) && data.length) {
          data = data.map(item => {
            if (typeof item !== 'object' && !item.value) return;
            return {[item.value]: {"_eq": true}}
          });
          filter = {"_or": data}
        }
        this.request.custom_filters.add('budgets', filter);
      } catch (e) {
        console.log(e);
      }
    },
    setLanguagesFilter() {
      try {
        let filter = null
        let data = this.filters.languages;
        data = this.getValue(data);
        if (data) filter = {"_and": [{"languages": {"language_id": {"_eq": data}}}]}
        this.request.custom_filters.add('languages', filter);
      } catch (e) {
        console.log(e);
      }

    },
    setRatingFilter() {
      try {
        let filter = null
        let data = this.filters.rating;
        data = this.getValue(data);
        if (data) filter = {"_and": [data]}
        this.request.custom_filters.add('rating', filter);
      } catch (e) {
        console.log(e);
      }
    },
    setStylesFilter() {
      try {
        let filter = null
        let data = this.filters.styles;
        data = this.getValue(data);
        if (data) filter = {"_and": [{"projects": {"style_id": {"_eq": data}}}]}
        this.request.custom_filters.add('styles', filter);
      } catch (e) {
        console.log(e);
      }
    },
    getValue(item) {
      try {
        return item.value;
      } catch (e) {
        return null;
      }
    },

    getValues(data) {
      try {
        if (!data || !Array.isArray(data) || !data.length) return null;

        let filter = []

        data.forEach(item => {
          if (!this._is_object(item) || !item.value) return filter.push(item);
          filter.push(item.value)
        })

        return filter;
      } catch (e) {
        return null;
      }
    },
    setDefaults() {
      try {
        Object.keys(this.default_filters).forEach(key => {
          if (this.filters[key]) return;
          this.$set(this.filters, key, this.default_filters[key]);
        })
      } catch (e) {
      }
    }

  }
}
</script>

<style >
#mobile-profile-filters-main > div:nth-child(2) > div:nth-child(5) > hr {
  display:  none !important;
}

#mobile-profile-filters-main  div.d-flex.justify-space-between.pointer > div {
  color: var(--v-primary-base) !important;;
}
</style>
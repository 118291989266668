<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" class="text-center">
        <h2 class="font-head grey--text" style="margin-top: 190px">{{ __('WelcomeToRenotive') }}</h2>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import app from "@/tools/App";
import auth from "@/tools/Auth";

export default {
  name: "Dashboard",
  mounted() {
    this.check()
  },
  methods:{
    check(){
      try{
        let role = auth.getUserInfo().role;
        if (typeof role === 'object') role = role.id;
        if (role === app.roles['client']) return this.$router.push({name: 'Projects'})
      }catch (e){
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
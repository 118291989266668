<template>
  <!--  Expertise List  -->
  <v-list v-if="request.data">
    <v-list-item v-for="item in request.data" :key="item.id" two-line>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.title }}
          <v-list-item-subtitle v-if="item.job_type_id && item.job_type_id.hasOwnProperty('title')">
            {{ item.job_type_id.title }}
          </v-list-item-subtitle>
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action-text>
        
        <v-btn :disabled="item.status === 'archive'" color="primary" icon @click="selectItem(item)">
          <v-icon>
            mdi-trash-can
          </v-icon>
        </v-btn>
      </v-list-item-action-text>
    </v-list-item>
    <expertise-archive-modal :data="selected" v-model="archive_modal" @then="thenArchive"/>
  </v-list>
</template>

<script>
import ExpertiseArchiveModal from "@/components/expertises/ExpertiseArchiveModal";
import {GetRequest} from "@/models/GetRequest";

export default {
  name: "ExpertiseList",
  components: {ExpertiseArchiveModal},
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest(),
      require: true,
    }
  },
  data() {
    return {
      archive_modal: false,
      selected: null,
    }
  },
  mounted() {
  },
  methods: {
    thenArchive() {
      this.archive_modal = false;
      this.request.reload();
    },
    selectItem(item){
      this.selected = item
      this.archive_modal = true;
    }
  },
}
</script>

<style scoped>

</style>
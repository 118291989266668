<template>
  <div>
    <div class="d-flex">
      <template v-for="(item, key) in data">
        <a
            v-if="_is_object(item) && item.link"
            class="ma-0 rounded-sm ml-1"
            :href="item.link"
            target="_blank"
        >
          <v-icon color="primary" :title="item.name.toUpperCase()" small>{{ icon(item) }}</v-icon>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Socials",
  props: {
    data: {
      default: null,
      type: Array,
    },
  },
  data() {
    return {
      icons: {
        'facebook': 'mdi-facebook',
        'linkedin': 'mdi-linkedin',
        'instagram': 'mdi-instagram',
        'twitter': 'mdi-twitter',
        'pinterest': 'mdi-pinterest',
      }
    }
  },
  methods: {
    icon(item) {
      try {
        return this.icons[item.name] ? this.icons[item.name] : 'mdi-link';
      } catch (e) {
        return null
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-btn
        :tilte="__('CallRequest')"
        :to="{name:'CallRequest'}"
        color="primary"
        class="hidden-sm-and-down"
        small
        elevation="0"
        outlined
    >
      <v-icon small>mdi-phone</v-icon>&nbsp;
      <span>{{ __('RequestCall') }}</span>
    </v-btn>

    <v-btn
        icon
        class="hidden-md-and-up background--text  "
        style="background: #BCB08B;"
        :tilte="__('CallRequest')"
        :to="{name:'CallRequest'}"
        elevation="0"
        width="36"
        height="36"
    >
      <v-icon size="18">mdi-phone</v-icon>
    </v-btn>

  </div>
</template>

<script>
export default {
  name: "ButtonCallRequest"
}
</script>

<style scoped>

</style>
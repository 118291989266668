<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col cols="12" class="text-center">
        <calendly/>

        <div class="text-h4  my-6">{{ __('CallRequestTitle') }}</div>
        <div class="text--secondary">{{ __('CallRequestText') }}</div>

        <v-btn color="primary" elevation="0" :to="{name:'Panel'}" class="my-16" large>
          <span v-if="!_user">{{ __('SignUp') }}</span>
          <span v-else>{{ __('MyAccount') }}</span>
        </v-btn>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Calendly from "../../../components/calendly/Calendly";

export default {
  name: "CallRequest",
  components: {Calendly}
}
</script>

<style scoped>

</style>
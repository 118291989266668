<template>
  <div>
    <v-chip-group v-if="horizontal" id="tags-horizontal" :title="__('Tags')">
      <tag  v-for="(item, key) in [...data, ...new_items]" v-bind:key="key" :data="item" v-bind="$attrs">

      </tag>
    </v-chip-group>

    <div v-else :title="__('Tags')">
      <tag class="ma-1" v-for="(item, key) in [...data, ...new_items]" v-bind:key="key" :data="item" v-bind="$attrs"></tag>
    </div>


    <div class="d-flex justify-end">
      <v-btn class="rounded mt-2 text--text" v-if="show_add" elevation="0" color="secondary" @click="add_modal = true">
        {{__('Add')}}
      </v-btn>
    </div>


    <modal v-model="add_modal" max_width="390">
      <template #main>
        <div class="px-3">
          <tags-add :item_id="item_id" @cancel="add_modal = false" @added="e => add(e)"/>
        </div>
      </template>
    </modal>
  </div>

</template>

<script>
import Tag from "@/components/tags/Tag"
import Modal from "../modal/Modal";
import TagsAdd from "./TagsAdd";

export default {
  name: "Tags",
  components: {TagsAdd, Modal, Tag},
  props: {
    data: {
      default: null,
      type: Array,
    },
    horizontal: {
      default: false,
      type: Boolean,
    },
    show_add: {
      default: false,
      type: Boolean,
    },
    item_id: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      add_modal: null,
      new_items: [],
    }
  },
  methods:{
    add(item){
      this.new_items.push(item);
    }
  }
}
</script>


<style>

#tags-horizontal .v-slide-group__next--disabled,
#tags-horizontal .v-slide-group__prev--disabled {
  display: none;
}
</style>
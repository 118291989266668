<template>
  <div class="pa-3">
    <div class="pb-2">
      {{ __('NeedForNext') }}
    </div>
    <div>
      <v-textarea
          class="rounded-lg"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          v-model="request.body.pro_next_needs"
          :placeholder="__('AddYourText')"
      ></v-textarea>
    </div>
    <div class="d-flex pt-1">
      <v-spacer/>
      <v-btn class="elevation-0 text--text" color="secondary" @click="update()">{{__('Save')}}</v-btn>
    </div>
  </div>
</template>

<script>
import {PostRequest} from "../../models/PostRequest";

export default {
  name: "JobNeed",
  components: {},
  props: {
    data: {
      default: null,
      type: Object,
    },

  },
  data() {
    return {

      request: new PostRequest('update_job'),

    }
  },
  watch: {},
  mounted() {

    this.setBody();
  },
  methods: {
    setBody() {
      if (!this.data) return;
      this.request.setBody(
          {
            pro_next_needs: this.data.pro_next_needs,
          }
      );
    },
    update() {
      try {
        this.request.setApiParams([this.data.id]);
        this.request.setThen(e => this.$emit('then', e));
        this.request.patch();
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div v-if="item">
    <professional-list-item :data="item.pro_id" v-if="show" :show_menu="!hide_actions" :show_info="!hide_actions">
      <template v-if="!hide_actions" v-slot:actions>
        <v-btn
            v-if="selectable(item)" @click="select(item)" small :title="__('Select')" outlined
            :disabled="item.pro_status !== 'accepted'"
        >
          {{ __('Select') }}
        </v-btn>

        <v-btn
            v-else-if="selectable(item) && item.pro_status !== 'accepted'" :title="item.pro_status"
            outlined disabled small
        >
          {{ __('Select') }}
        </v-btn>

        <confirm v-else @accepted="e => connect(item)">
          <v-btn small :title="__('Connect')" outlined>
            {{ __('Connect') }}
          </v-btn>
        </confirm>

        <confirm @accepted="e => remove(item)">
          <v-btn icon :title="__('Remove')" class="ml-3">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </confirm>

      </template>
    </professional-list-item>

  </div>
</template>

<script>
import ProfessionalListItem from "../professionals/ProfessionalListItem";
import {PostRequest} from "../../models/PostRequest";
import {DeleteRequest} from "../../models/DeleteRequest";
import Confirm from "../modal/Confirm";

export default {
  name: "JobPro",
  components: {Confirm, ProfessionalListItem},
  props: {
    data: {
      default: null,
      type: Object,
    },
    hide_actions: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {

      update_job: new PostRequest('update_job'),
      job_pro_suggest: new PostRequest('job_pro_suggest'),
      delete_suggest: new DeleteRequest('job_pro_suggest'),
      selected_pro: null,

      item: null,
      connect_dialog: false,
      archive_dialog: false,
      show: true,
    }
  },
  watch: {
    'data'() {
      this.item = this.data;
    }
  },
  mounted() {
    this.item = this.data;
  },
  methods: {
    selectable(item) {
      try {
        return item.client_status === 'connected';
      } catch (e) {
        return false;
      }
    },
    connect(item) {
      this.connect_dialog = false;
      this.job_pro_suggest.body = {};
      this.job_pro_suggest.body.client_status = 'connected';
      this.job_pro_suggest.setApiParams([item.id]);
      this.job_pro_suggest.setThen(e => item.client_status = 'connected');
      this.job_pro_suggest.patch();
    },
    remove(item) {
      this.delete_suggest.setApiParams([item.id]);
      this.delete_suggest.setThen(e => this.show = false);
      this.delete_suggest.delete();
    },
    select(item) {
      try {
        this.update_job.body.pro_id = item.pro_id.id;
        this.update_job.setApiParams([item.job_id]);
        this.update_job.setThen(e => {
          item.client_status = true;
          this.$emit('select', item.pro_id)
        });
        this.update_job.patch();
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
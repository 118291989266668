import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('div',[(_vm.horizontal)?_c(VSlideGroup,{staticClass:"slide-group-hide-arrows slide-group-custom-arrows",attrs:{"multiple":"","show-arrows":""}},_vm._l((_vm.data),function(item,index){return _c(VSlideItem,[_c('div',{style:('width:'+ _vm.width + 'px;')},[_c(VImg,_vm._b({key:index+ Math.random(),staticClass:"rounded-lg ma-1 ml-0 secondary pointer",attrs:{"src":_vm.getUrl(item)},on:{"click":function($event){return _vm.$emit('select', item, index)}}},'v-img',_vm.$attrs,false),[_vm._t("image",null,{"item":item,"index":index})],2)],1)])}),1):(_vm.carousel)?_c(VCarousel,_vm._b({attrs:{"cycle":"","hide-delimiter-background":"","height":_vm.height}},'v-carousel',_vm.$attrs,false),_vm._l((_vm.data),function(item,i){return _c(VCarouselItem,{key:'carousel-item'+i+Math.random(),attrs:{"src":_vm.getUrl(item)}})}),1):_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.data),function(item,index){return _c(VImg,_vm._b({key:index+ Math.random(),staticClass:"rounded-lg ma-1 secondary",attrs:{"src":_vm.getUrl(item)}},'v-img',_vm.$attrs,false),[_vm._t("image",null,{"item":item,"index":index})],2)}),_vm._t("default")],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div   class="mobile" v-if="_is_object(data)">
    <v-divider/>
    <show-more>
      <template #title>
        <div class="font-weight-bold primary--text">{{ __('UsersRatingReviews') }}</div>
      </template>

      <template #more>
        <div class="py-5 mb-6 ">
          <v-row class="pr-4">
            <v-col cols="6">
              <v-row>
                <v-col cols="10">
                  <div class="background grey--text text--darken-1 px-2 pb-1 font-size-14">{{ __('Quality') }} :</div>
                  <slider-selector :value="data.quality" max="5" hide_thumb readonly/>
                </v-col>
                <v-col cols="2">
                  <div class="mt-2 text-center">
                    {{data.quality}}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="10">
                  <div class="background grey--text text--darken-1 px-2 pb-1 font-size-14">{{ __('TimeManagement') }} :</div>
                  <slider-selector :value="data.time_management" max="5" hide_thumb readonly/>
                </v-col>
                <v-col cols="2">
                  <div class="mt-2 text-center">
                    {{data.time_management}}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="10">
                  <div class="background grey--text text--darken-1 px-2 pb-1 font-size-14">{{ __('Communication') }} :</div>
                  <slider-selector :value="data.communication" max="5"  hide_thumb readonly/>
                </v-col>
                <v-col cols="2">
                  <div class="mt-2 text-center">
                    {{data.communication}}
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-row>
                <v-col cols="10">
                  <div class="background grey--text text--darken-1 px-2 pb-1 font-size-14">{{ __('Value') }} :</div>
                  <slider-selector :value="data.value" max="5" hide_thumb readonly/>
                </v-col>
                <v-col cols="2">
                  <div class="mt-2 text-center">
                    {{data.value}}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>


          <profile-reviews class="mt-4 px-2" :data="data"/>
        </div>
      </template>
    </show-more>


  </div>
</template>

<script>

import SliderSelector from "@/components/selectors/SliderSelector";
import ShowMore from "@/components/app/ShowMore";
import ProfileReviews from "@/components/profiles/ProfileReviews";
export default {
  name: "ProfileRatingMobile",
  components: {ProfileReviews, ShowMore, SliderSelector},
  props: {
    data: {
      default: null,
      type: Object,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style>
#profile-rating.mobile .v-slider__thumb-label{
  display: none !important;
}
</style>
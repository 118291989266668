<template>
  <div>
    <v-list-item v-if="data" class="px-0" three-line>
      <v-list-item-icon class="mr-6 mt-5">
        <v-hover v-slot="{hover}">
          <upload @then="e => uploaded(e)" accept="image/*" class="pointer">
            <v-avatar size="70">
              <assets-image :data="typeof data.picture === 'string' ? data.picture : data.picture.id"/>
              <v-overlay v-if="hover" absolute>
                {{ __('Edit') }}
              </v-overlay>
            </v-avatar>
          </upload>
        </v-hover>

      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="pb-3">
          <router-link :to="{name:(data.status !== 'lead' ? 'Project' : 'LeadDetails'), params: { id: data.id }}">
            <span class="text-h5 text--text" v-text="data.title"/>
          </router-link>
        </v-list-item-title>
        <v-list-item-subtitle class="text--disabled">
          <span>{{ __('CreatedAt') }} :<date-text :data="data.date_created"/></span> &nbsp;&nbsp;&nbsp;&nbsp;
          <span>
            {{ __('StartDate') }} :
            <date-text v-if="data.desire_start_time" :data="data.desire_start_time"/>
            <span v-else>-</span>
          </span>
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text--disabled">
          <span>{{ __('Status') }} : <project-status :data="data"/></span> &nbsp;&nbsp;&nbsp;&nbsp;
          <span v-if="is_lead">{{ __('LeadStatus') }} : {{__(data.lead_status)}}</span> &nbsp;&nbsp;&nbsp;&nbsp;
          <span v-if="data.is_expired"><v-icon color="yellow darken-3" small>mdi-alert-circle</v-icon>&nbsp;{{ __('Expired') }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>

        <div v-if="_access('admin')" style="max-width: 150px" class="hidden-xs-only">
          <project-update-status :data="data" @reload="() => $emit('reload')" :is_lead="is_lead"/>
        </div>

      </v-list-item-action>
    </v-list-item>


    <div v-if="_access('admin')" class="hidden-sm-and-up">
      <project-update-status :data="data" @reload="() => $emit('reload')" :is_lead="is_lead"/>
    </div>

  </div>
</template>

<script>
import DateText from "../app/DateText";
import AssetsImage from "../assets/Image";
import ProjectStatus from "./ProjectStatus";
import ProjectUpdateStatus from "./ProjectUpdateStatus";
import {PostRequest} from "../../models/PostRequest";
import Upload from "../assets/Upload";

export default {
  name: "ProjectTitle",
  components: {Upload, ProjectUpdateStatus, ProjectStatus, AssetsImage, DateText},
  props: {
    data: {
      default: null,
      type: Object,
    },
    is_lead: {
      default: false,
      type: Boolean,
    }
  },

  data() {
    return {
      request: new PostRequest('update_project'),
    }
  },
  mounted() {
    this.request.setApiParams([this.data.id])
  },
  methods: {
    uploaded(response) {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        this.request.body.picture = response.data.id;
        this.request.setThen(() => this.$emit('reload'));
        this.request.patch();

      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style scoped>

</style>
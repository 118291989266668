<template>
  <v-chip v-if="item.title" v-bind="$attrs" class="expertise" outlined>
    {{ item.title }}
  </v-chip>
</template>

<script>

export default {
  name: "Expertise",
  components: {},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {
    item() {
      try {
        if (this.data.expertise_id) return this.data.expertise_id;
        return this.data;
      } catch (e) {
        return this.data;
      }
    },
  },
  mounted() {

  },
  methods: {},
}
</script>

<style  >
.expertise.v-chip:before{
  display: none !important;
}
</style>

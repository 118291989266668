import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data)?_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"grey ","icon":"","light":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,613547004)},[_c(VList,{attrs:{"dense":""}},[_c(VListItem,{attrs:{"disabled":!_vm.data.telegram_id,"href":'https://t.me/' + _vm.data.telegram_id,"link":"","target":"_blank"}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Telegram")))])],1),_c(VListItem,{attrs:{"disabled":_vm.email_link.length < 1,"href":_vm.email_link,"link":""}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Email")))])],1),(_vm.data.phone_number)?_c(VListItem,{attrs:{"href":_vm.tel,"link":""}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Call")))])],1):_vm._e(),(!_vm.hide_edit)?_c(VListItem,{attrs:{"to":{name:'EditUser', params:{id: _vm.data.id}},"link":""}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Edit")))])],1):_vm._e(),(!_vm.hide_archive && _vm.data.status !== 'archive')?_c(VListItem,{on:{"click":function($event){_vm.archive_modal=true}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Archive")))])],1):_vm._e()],1)],1):_vm._e(),_c('user-archive-modal',{attrs:{"data":_vm.data,"api_key":"archive_professional"},on:{"then":_vm.thenArchive},model:{value:(_vm.archive_modal),callback:function ($$v) {_vm.archive_modal=$$v},expression:"archive_modal"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
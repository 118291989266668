<template>
  <div class="full-width">
    <inline-selector :value="request.allFilters()" :items="items()" @input="select" :disabled="request.isLoading()">

    </inline-selector>
  </div>
</template>

<script>
import InlineSelector from "../selectors/InlineSelector";
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "LeadJobsStatus",
  components: {InlineSelector},
  props: {
    request: {
      default: new GetRequest(),
      type: GetRequest,
      required: true,
    }
  },
  data() {
    return {
      selected: null,
    }
  },
  mounted() {

  },
  methods: {
    default() {
      return {
        "_and": [
          {
            "job_id": {
              "pro_id": {
                "_null": "true"
              }
            }
          },
          {
            "_or": [
              {
                "pro_status": {
                  "_eq": "pending"
                }
              },
              {
                "pro_status": {
                  "_eq": "accepted"
                }
              }
            ]
          }
        ]
      };
    },
    items() {
      return [
        {
          text: this.__('Pending'),
          value: this.default(),
        },
        {
          text: this.__('Won'),
          value: {
            "_and": [
              {
                "job_id": {
                  "pro_id": {
                    "_eq": "$CURRENT_USER"
                  }
                }
              },
              {
                "pro_status": {
                  "_eq": "accepted"
                }
              }
            ]
          }
        },
        {
          text: this.__('Lost'),
          value: {
            "_or": [
              {
                "_and": [
                  {
                    "job_id": {
                      "pro_id": {
                        "_neq": "$CURRENT_USER"
                      }
                    }
                  },
                  {
                    "job_id": {
                      "pro_id": {
                        "_nnull": "true"
                      }
                    }
                  }
                ]
              },
              {
                "pro_status": {
                  "_eq": "rejected"
                }
              }
            ]
          }
        }
      ];
    },
    select(value) {
      try {
        this.request.is_loading = false;
        this.request.clearFilters();
        this.request.pushFilter(value);
        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>

</style>
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({hover}){return [_c(VCard,{staticClass:"mx-3 mb-3 ml-0 text-center rounded-lg py-7 px-3",attrs:{"outlined":"","flat":"","width":"190"}},[_c('div',[_c(VAvatar,{attrs:{"size":"90","color":"secondary","max-width":"100%"}},[(_vm.data.avatar && (_vm.data.avatar.length > 0))?_c('assets-image',{attrs:{"data":_vm.data.avatar,"width":"64"}}):_c(VIcon,{attrs:{"color":"grey lighten-1","size":"64"}},[_vm._v("mdi-account")])],1),_c(VOverlay,{staticClass:"justify-end",staticStyle:{"height":"38px"},attrs:{"light":"","opacity":"0","absolute":"","z-index":"1"}},[_c('user-menu',{attrs:{"data":_vm.data}})],1)],1),(_vm.data && _vm.data.id)?_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"title text-truncate"},[_vm._v(" "+_vm._s(_vm.data.first_name)+" "+_vm._s(_vm.data.last_name)+" ")]),_c('div',{staticClass:"subtitle-2 text--disabled"},[(_vm.data.role && typeof _vm.data.role === 'object')?_c('div',[_vm._v(_vm._s(_vm.data.role.name))]):_vm._e()]),_c('small',{staticClass:"grey--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm.data.status)+" ")])]):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
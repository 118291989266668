<template>
  <div>

    <template v-if="get_profile_request.is_loading">
      <loading/>
    </template>

    <template v-else-if="_is_object(item)">
      <v-row>
        <v-col cols="12" sm="12" class="hidden-sm-and-down">
          <profile-breadcrumbs :data="item" :advanced_items="[{text:__('Review'), disabled: true}]"/>
        </v-col>
        <v-col v-if="!_owner(item.pro_id) " cols="12" sm="3"  class="hidden-sm-and-down">
          <div class="position-sticky" style="top: 150px !important;">
            <profile-contact :data="item"/>
          </div>
        </v-col>
        <v-col cols="12" sm="12" :md="!_owner(item.pro_id) ? 9 : 12"  class="pt-0">
          <profile-head-inline :data="item" class="hidden-sm-and-down"/>
          <profile-name-small :data="item" class="hidden-md-and-up"/>
          <div class="hidden-md-and-up px-3 py-2"><v-divider class="primary"  /></div>
          <div class="px-3 px-md-0">
            <review-form :request="request" @submit="submit" @cancel="cancel"/>
          </div>
        </v-col>
      </v-row>

      <space height="45"/>

    </template>

  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import Loading from "@/components/app/Loading";
import ProfileHeadInline from "@/components/profiles/ProfileHeadInline";
import {PostRequest} from "@/models/PostRequest";
import ReviewForm from "@/components/reviews/ReviewForm";
import ProfileBreadcrumbs from "@/components/profiles/ProfileBreadcrumbs";
import Space from "@/components/app/Space";
import ProfileContact from "@/components/profiles/ProfileContact";
import ProfileNameSmall from "@/components/profiles/ProfileNameSmall";

export default {
  name: "ProfileReviewForm",
  components: {
    ProfileNameSmall,
    ProfileContact,
    Space,
    ProfileBreadcrumbs,
    ReviewForm,
    ProfileHeadInline,
    Loading,
  },
  props: {
    id: {
      default: null,
      type: [String, Number],
      required: true
    }
  },
  watch: {
    'id'() {
      this.get();
    }
  },
  data() {
    return {
      get_profile_request: new GetRequest('get_item'),
      get_review_request: new GetRequest('get_item'),
      request: new PostRequest('items', ['pro_profile_review']),
    }
  },
  computed: {
    item() {
      return this.get_profile_request.data;
    },
    reply_to() {
      try {
        return this.$route.query.reply_to
      } catch (e) {
        return null;
      }
    },
    review_id() {
      try {
        return this.$route.query.review_id
      } catch (e) {
        return null;
      }
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.getReview();
      this.getProfile();
    },
    submit() {
      this.request.setThen(this.thenSubmit)
      if (this.review_id) return this.update()
      this.request.post();
    },
    thenSubmit() {
      this.$router.back();
    },
    update() {
      this.request.setApi('item')
      this.request.setApiParams(['pro_profile_review', this.review_id])
      this.request.patch()
    },
    cancel() {
      try {
        this.$router.push({name: 'ProfessionalProfile', params: {id: this.item.id}})
      } catch (e) {
        console.log(e);
      }
    },
    then() {
      const data = this.get_review_request.data ? this.get_review_request.data : {};

      let body = {
        'pro_profile_id': this.id,
        'text': data.text,
        'quality': data.quality,
        'communication': data.communication,
        'time_management': data.time_management,
        'value': data.value,
        'reply_to': this.reply_to,
        'images': this._is_array(data.images) ? data.images : [],
      };

      this.request.setBody(body);
    },
    getProfile() {
      if (!this.id) return;
      this.get_profile_request.setApiParams(['pro_profile', this.id]);
      this.get_profile_request.setFields('*')
      if (!this.review_id) this.get_profile_request.setThen(() => this.then());
      this.get_profile_request.row();
    },
    getReview() {
      if (!this.review_id) return;
      this.get_review_request.setApiParams(['pro_profile_review', this.review_id]);
      this.get_review_request.setFields('*, images.directus_files_id')
      this.get_review_request.setThen(() => this.then());
      this.get_review_request.row();
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <span v-if="data" :class="getClass()">{{ data.status }}</span>
</template>

<script>
export default {
  name: "ProjectStatus",
  props: {
    data: {
      type: Object,
      default: null,
    }
  },
  methods: {
    getClass() {
      try {
        let status = this.data.status;
        if (status === 'archived') return "red--text"
        if (status === 'active') return "green--text"
        return '';
      } catch (e) {
        console.log(e);
        return '';
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!-- Loading -->
    <loading v-if="request.isLoading()"/>


    <v-row v-else-if="request.data">
      <v-col cols="12" md="12">
        <h2 class="mt-2">{{ item.title }}</h2>
      </v-col>

      <!--  Slider    -->
      <v-col md="8">
        <assets-images class="rounded-lg" carousel :data="request.data.pictures"/>
      </v-col>

      <!--  Right Side    -->
      <v-col md="4">
        <idea-info :data="request.data"/>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import {GetRequest} from "../../models/GetRequest";
import AssetsImages from "../assets/Images";
import Loading from "../app/Loading";
import FullName from "../account/FullName";
import Avatar from "../account/Avatar";
import Space from "../app/Space";
import IdeaSave from "./IdeaSave";
import IdeaInfo from "@/components/ideas/IdeaInfo";

export default {
  name: "IdeaDetails",
  components: {IdeaInfo, IdeaSave, Space, Avatar, FullName, Loading, AssetsImages,},
  props: {
    id: {
      default: null,
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      request: new GetRequest('get_idea', [this.id]),
    }
  },
  computed: {
    item() {
      return this.request.data;
    },
  },
  watch: {
    'id'() {
      if (!this.id) return;
      this.request.setApiParams([this.id]);
      this.request.row();
    }
  },
  mounted() {
    if (!this.id) return;
    this.request.setFields('*.*')
    this.request.setThen((data) => this.$emit('onload', data));
    this.request.row();
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width">
    <div class="font-weight-bold" v-if="!hide_title">{{ __('Code') }}</div>

    <v-text-field
        :value="value"
        class="rounded-lg font-size-14 custom-selector-border"
        @input="e => $emit('input', e)"
        outlined
        dense
    >
      <template #prepend-inner>

        <assets-image
            v-if="flag_image"
            :data="flag_image"
            class="mr-1"
            style="margin-top: 2px;"
            width="20"
        />
        <div v-else>&nbsp;&nbsp;</div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";
import AssetsImage from "@/components/assets/Image";

export default {
  name: "CountryCodeSelector",
  components: {AssetsImage},
  props: {
    value: {
      default: null,
      type: [String, Number],
    },
    place: {
      default: null,
      type: [Object],
    },
    return_object: {
      default: false,
      type: Boolean,
    },
    return_name: {
      default: false,
      type: Boolean,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    input() {
      this.get();
    },
    value() {
      if (!this.value) this.input = null;
    }
  },
  data() {
    return {
      request: new GetRequest('get_items', ['country']),
      items: {},
    }
  },
  computed:{
    flag_image() {
      try {
        return this.items[this.value.toString()].icon;
      } catch (e) {
        return null;
      }
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      this.request.is_loading = false;
      this.request.setFields('*');
      this.request.setThen(() => this.then());
      this.request.setLimit('-1');
      this.request.get();
    },
    then() {
      try {
        if (!this.request.data)  return;
        this.request.data.forEach((item) => {
          this.$set(this.items, item.code.toString(), item);
        })
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>

</style>
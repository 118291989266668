<template>
  <v-chip v-if="data && data.option" class="mr-2 my-1">
    <assets-image :data="data.icon" width="16" height="16" class="mr-3"/>
    {{ data.text }}
  </v-chip>
</template>

<script>

import AssetsImage from "../assets/Image";

export default {
  name: "ProjectRoomAnswer",
  components: {AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  computed: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

<template>
  <div class="full-width">
    <div class="font-weight-bold" v-if="!hide_title">{{ __('Expertise') }}</div>
    <template v-if="!list">
      <v-select
          :items="items()"
          :value="value"
          @input="change"
          class="custom-selector-border rounded-lg"
          outlined
          dense
          :multiple="multiple"
          v-bind="$attrs"
      >
        <template v-slot:item="{ item, on }" v-if="multiple">
          <v-list-item link v-on="on" dense>
            <v-checkbox :input-value="value.includes(item.value)"/>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:prepend-item>
          <v-btn
              v-if="show_custom"
              elevation="0"
              color="primary"
              class="ma-3"
              small
              @click="custom_dialog = !custom_dialog"
          >{{ __('CustomExpertise') }}
          </v-btn>
        </template>
      </v-select>
      <custom-expertise v-if="show_custom" v-model="custom_dialog"/>
    </template>

    <!-- List   -->
    <template v-else>
      <v-skeleton-loader v-if="request.isLoading()" type="card" height="38"/>

      <from-list-selector
          v-else
          :items="items()"
          :value="value"
          :multiple="multiple"
          v-bind="$attrs"
          @input="(e) => $emit('input', e)"
      />
    </template>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import CustomExpertise from "@/components/expertises/CustomExpertise";
import FromListSelector from "@/components/selectors/FromListSelector";

export default {
  name: "ExpertiseSelector",
  components: {FromListSelector, CustomExpertise},
  props: {
    value: {
      default: null,
      type: [Array, String],
    },
    list: {
      default: false,
      type: Boolean,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
    show_custom: {
      default: false,
      type: Boolean
    },
    multiple: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      request: new GetRequest('get_expertise'),
      custom_dialog: false
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    change(e) {
      this.$emit('input', e)
    },
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.setFields('id,title')
      this.request.setLimit(500);
      this.request.get();
    },
    items() {
      try {
        let items = [];
        if (!this.request.data) return;
        this.request.data.forEach((item) => {
          items.push({
            text: item.title,
            value: item.id,
          })
        })
        return items;

      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style scoped>

</style>
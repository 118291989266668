<template>
  <v-footer id="main-footer" color="polar background--text px-6 px-sm-3" v-bind="$attrs">

    <!-- Desktop   -->
    <v-container v-if="!hide_columns" class="hidden-sm-and-down container-max-width py-6">
      <v-row>
        <!--  Column 1       -->
        <v-col cols="12" md="3" class="py-6">
          <logo width="110" white/>

          <space height="20"/>
          <div class="pt-6 font-weight-light body-2 pl-1 mt-16">{{ __('ConnectWithUs') }} :</div>
          <social-links/>
        </v-col>

        <!--  Column 2       -->
        <v-col cols="12" md="3">
          <div class="pt-3">
            <b class="py-3 main-footer-title" v-html="__('Company')"></b>
          </div>

          <div class="mt-6" v-if="!hide_links">
            <router-link v-for="(item, index) in links" :key="'footer-links-'+ index" :to="item.route">
              <div class="py-1 background--text font-weight-light font-size-14">
                {{ __(item.text) }}
              </div>
            </router-link>
          </div>
        </v-col>

        <!--  Column 3       -->
        <v-col cols="12" md="3">
          <div class="pt-3">
            <b class="py-3 main-footer-title" v-html="__('SignUp')"></b>
          </div>

          <div class="mt-12" style="max-width: 320px">

            <div v-if="_user">
              <div class="mb-3 font-weight-light body-2 " v-html="__('GetAccessToYourAccount')"></div>

              <v-btn
                  color="primary lighten-3"
                  height="30"
                  class="rounded-lg px-2"
                  elevation="0"
                  :to="{name:'Panel'}"
                  outlined
              >
                <span class="font-size-12 font-mt-2">{{ __('MyAccount') }}</span>
              </v-btn>
            </div>

            <div v-else>
              <div class="mb-3 font-weight-light body-2 " v-html="__('JoinRenotive')"></div>

              <v-btn
                  color="primary lighten-3"
                  height="30"
                  class="rounded-lg"
                  elevation="0"
                  :to="{name:'Panel'}"
                  outlined
              >
                <span class="font-size-12 font-mt-2">{{ __('SignUp') }}</span>
              </v-btn>
            </div>


            <div class="mt-9">
              <router-link :to="{name: 'PublicBlogs'} " class="py-3 primary--text  main-footer-title-top-border">
                <b v-html="__('Blogs')"></b>
              </router-link>
            </div>

          </div>
        </v-col>

        <!--  Column 4       -->
        <v-col cols="12" md="3">
          <div class="pt-3">
            <b class="py-3 main-footer-title" v-html="__('StartProject')"></b>
          </div>

          <div class="mt-7">

            <div class="body-2 mb-3 font-weight-light">{{ __('LikeToDesign') }}</div>
            <room-selector
                :first_label="__('Room(s)')"
                css_class="font-size-14"
                v-model="query.room_id"
                hide_title
                multiple
            />

            <div class="body-2 mb-3 font-weight-light">{{ __('EnterYourZIPCode') }}</div>
            <v-text-field
                v-model="query.zip_code"
                :placeholder="__('ZipCode')"
                class="custom-selector-border font-size-14 rounded-lg"
                type="text"
                dense
                outlined
            />

            <div class="d-flex align-center justify-center">
              <v-btn
                  class="font-size-12 font-mt-2 rounded-lg"
                  @click="start"
                  dark
                  color="primary lighten-3"
                  elevation="0 "
                  outlined
                  height="30"
                  min-width="90"
              >
                {{ __('Go') }}
              </v-btn>
            </div>
          </div>

        </v-col>

      </v-row>
    </v-container>

    <!--  Mobile and Tablet  -->
    <v-container v-if="!hide_columns" class="hidden-md-and-up container-max-width py-6">
      <v-row>
        <!--  Column 1       -->
        <v-col cols="6" sm="4" md="4" class="full-width-small-screen d-flex flex-column justify-space-between pt-6">
          <div>
            <logo width="140" white/>
            <div class="pt-5 font-weight-light font-size-14 body-2 pl-1">{{ __('ConnectWithUs') }} :</div>
            <social-links/>

            <div class="pt-5">
              <router-link
                  :to="{name: 'PublicBlogs'} "
                  class="py-3 primary--text text--lighten-2 font-size-14 main-footer-title-top-border"
              >
                <b v-html="__('Blogs')"></b>
              </router-link>
            </div>
          </div>
          <div class="mt-5 font-size-12 primary--text">
            © {{ new Date().getFullYear() }}
            <span>
              {{ __('RenotiveInc') }}
            </span>
          </div>
        </v-col>

        <!--  Column 2       -->
        <v-col cols="12" sm="4" md="4" class="hidden-xs-only">
          <div class="pt-3">
            <b class="py-3 main-footer-title" v-html="__('SignUp')"></b>
          </div>

          <div class="mt-12" style="max-width: 320px">

            <div v-if="_user">
              <div class="mb-3 font-weight-light body-2 " v-html="__('GetAccessToYourAccount')"></div>

              <v-btn
                  color="primary lighten-3"
                  height="30"
                  class="rounded-lg px-2"
                  elevation="0"
                  :to="{name:'Panel'}"
                  outlined
              >
                <span class="font-size-12 font-mt-2">{{ __('MyAccount') }}</span>
              </v-btn>
            </div>

            <div v-else>
              <div class="mb-3 font-weight-light body-2 " v-html="__('JoinRenotive')"></div>

              <v-btn
                  color="primary lighten-3"
                  height="30"
                  class="rounded-lg"
                  elevation="0"
                  :to="{name:'Panel'}"
                  outlined
              >
                <span class="font-size-12 font-mt-2">{{ __('SignUp') }}</span>
              </v-btn>
            </div>


          </div>
        </v-col>

        <!--  Column 3      -->
        <v-col class="full-width-small-screen" cols="6" sm="4" md="4">
          <div class="pt-3">
            <b class="py-3 main-footer-title" v-html="__('Company')"></b>
          </div>

          <div class="mt-6" v-if="!hide_links">
            <router-link v-for="(item, index) in links" :key="'footer-links-'+ index" :to="item.route">
              <div class="py-1 background--text font-weight-light font-size-14">
                {{ __(item.text) }}
              </div>
            </router-link>
          </div>
        </v-col>

      </v-row>
    </v-container>

    <div
        :class="{'width-full py-4 text-center polar darken-3':true, 'hidden-md-and-down':!hide_columns}"
        :title="$store.state.version"
    >
      © {{ new Date().getFullYear() }}
      <strong>
        <app-name/>
      </strong> -
      <span>{{ __('AllRightsReserved') }}</span>
    </div>

  </v-footer>
</template>

<script>
import AppName from "../app/AppName";
import Logo from "../app/Logo";
import SocialLinks from "../social/SocialLinks";
import RoomSelector from "../../components/selectors/RoomSelector";
import Space from "@/components/app/Space";

export default {
  name: "MainFooter",
  components: {Space, SocialLinks, Logo, AppName, RoomSelector},
  props: {
    hide_links: {
      default: false,
      type: Boolean,
    },
    hide_columns: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    links: [
      {text: 'Home', route: {name: 'Home'}},
      {text: 'AboutRenotive', route: {name: 'Template', params: {id: 'about_us'}}},
      {text: 'Services', route: {name: 'Template', params: {id: 'services'}}},
      {text: 'Careers', route: {name: 'Template', params: {id: 'careers'}}},
      {text: 'Terms', route: {name: 'Template', params: {id: 'terms'}}},
      {text: 'CopyRightTrademark', route: {name: 'Template', params: {id: 'copyright_and_trademark'}}},
      {text: 'Testimonials', route: {name: 'Template', params: {id: 'testimonials'}}},
      {text: 'ContactUs', route: {name: 'Template', params: {id: 'contact_us'}}},
    ],
    query: {
      room_id: null,
      zip_code: null,
    }
  }),
  methods: {
    start() {
      try {
        this.$router.push({name: 'StartProject', query: this.query})
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style>
.main-footer-title {
  border-bottom: var(--v-primary-base) 2px solid !important;
}

.main-footer-title-top-border {
  border-top: var(--v-primary-base) 2px solid !important;
}

#main-footer .v-select__selection,
#main-footer .theme--light.v-input input,
#main-footer .theme--light.v-input textarea {
  color: var(--v-background-base) !important;
}

#main-footer ::placeholder {
  color: var(--v-background-lighten3) !important;
}
</style>
<template>
  <div v-if="_is_object(data)" class="py-4">
    <description class="font-size-14" :data="data.description"/>
  </div>
</template>

<script>

import Description from "@/components/app/Description";

export default {
  name: "PortfolioProjectText",
  components: {Description},
  props: {
    data: {
      default: null,
      type: Object,
    },

  },

}
</script>

<style scoped>

</style>
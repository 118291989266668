<template>
  <canvas :id="id"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';
import {_t} from "../../tools/Utils";


export default {
  name: 'Chart',
  components: {},
  props: {
    id: {
      type: String,
      default: null,
      required: true,
    },
    type: {
      default: 'pie',
      type: String,
    },
    labels: {
      default: null,
      type: Array,
    },
    data: {
      default: null,
      type: Array,
    },
    object: {
      default: null,
      type: Object,
    },
    colors: {
      default: null,
      type: Array,
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.set();
  },
  methods: {
    set() {
      new Chart(
          document.getElementById(this.id),
          {
            type: this.type,
            data: {
              labels: this.getLabels(),
              datasets: [{
                label: 'My First Dataset',
                data: this.getData(),
                backgroundColor: this.getColors(),
                hoverOffset: 4
              }]
            },
            options: this.getOptions()
          }
      );
    },
    getOptions() {
      return {
        responsive: true,
        lineTension: 1,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                padding: 25
              }
            }
          ]
        }
      };
    },
    getColors() {
      if (!this.colors) {
        return [
          '#B99470',
          '#FFC8AA',
          '#B3A797',
          '#ECD6C1',
          '#F44336',
          '#E91E63',
          '#9C27B0',
          '#673AB7',
          '#3949AB',
          '#2196F3',
          '#00BCD4',
          '#009688',
          '#8BC34A',
        ]
      }
      return this.colors;
    },
    getData() {
      if (this.object) {
        return Object.values(this.object);
      }
      return this.data;
    },
    getLabels() {
      if (this.object) {
        let result = Object.keys(this.object);
        result = result.map((item) => _t(item))
        return result;
      }
      return this.labels;
    }
  }
}
</script>
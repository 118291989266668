<template>
  <v-container>
    <page-title :text="__('Professionals')" heading/>

    <professionals
        v-if="_access('admin')"
        api='get_professionals_admin'
        add_is_visible
    />

    <div v-if="_access('client')">
      <div class="pt-3">
        <v-chip :to="{name:'Professionals'}" class="mr-2 primary background--text" text>
          {{ __('Professionals') }}
        </v-chip>
        <v-chip :to="{name:'CustomProfessionals'}" class="mr-2 secondary text--text" text>
          {{ __('MyCustomPro') }}
        </v-chip>
      </div>
      <professionals
          :filters_attrs="{hide_status: true}"
      />
    </div>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Professionals from "../../../components/professionals/Professionals";

export default {
  name: "PanelProfessionalsPage",
  components: {Professionals, PageTitle,}
}
</script>

<style scoped>

</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-more"},[_c(VListItem,{staticClass:"py-0",on:{"click":function($event){_vm.show_more  = !_vm.show_more}}},[_c(VListItemTitle,[_vm._t("title")],2),_c(VListItemAction,[_c(VBtn,{attrs:{"small":"","icon":""}},[(_vm.show_more)?_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-up")]):_c(VIcon,{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-down")])],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_more),expression:"show_more"}]},[_vm._t("more")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="_is_object(data) && _is_object(data.ideas)">
    <v-row>
      <v-col cols="12" sm="6" md="4" v-for="item in data.ideas" class="pb-0">
        <portfolio-project-picture :data="item" @select="select"/>
      </v-col>
    </v-row>


    <modal v-model="modal" max_width="1200" width="100%">
      <template #main>
        <div v-if="_is_object(selected)" class="px-2">
          <v-row class="flex-row-reverse">
            <v-col cols="12" md="3">
              <div class="d-flex justify-space-between pt-2">
                <h2>{{ selected.title }}</h2>
                <v-btn @click="modal = !modal" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <div class="hidden-sm-and-down">
                <idea-info
                    :data="selected"
                    :button_title="__('AddThisIdea')"
                    hide_user
                    bottom
                    hide_budget
                    hide_size
                    hide_style
                />
                <v-divider class=" mt-6"/>
                <profile-contact :data="data.pro_profile_id" hide_title/>
              </div>
            </v-col>
            <v-col cols="12" md="9">
              <div class="py-0 py-md-4">
                <assets-image class="hidden-sm-and-down" css_class="rounded-lg" :data="image" max-height="490"/>
                <assets-image class="hidden-md-and-up" css_class="rounded-lg" :data="image" max-height="390"/>
                <assets-images
                    :data="images"
                    @select="(item, index) => selectByIndex(index)"
                    class="mt-2"
                    width="150"
                    max-width="150"
                    min-height="100"
                    max-height="100"
                    horizontal
                />
              </div>
            </v-col>

            <v-col cols="12" class="hidden-md-and-up" >
              <idea-info
                  :data="selected"
                  :button_title="__('AddThisIdea')"
                  hide_user
                  bottom
                  hide_budget
                  hide_size
                  hide_style
              />
              <v-divider class="mt-5"  />
              <profile-contact :data="data.pro_profile_id" hide_title hide_buttons/>
            </v-col>

          </v-row>
        </div>
      </template>
    </modal>

  </div>
</template>

<script>

import PortfolioProjectPicture from "@/components/portfolio/PortfolioProjectPicture";
import AssetsImages from "@/components/assets/Images";
import Modal from "@/components/modal/Modal";
import AssetsImage from "@/components/assets/Image";
import IdeaInfo from "@/components/ideas/IdeaInfo";
import ProfileContact from "@/components/profiles/ProfileContact";

export default {
  name: "PortfolioProjectPictures",
  components: {ProfileContact, IdeaInfo, AssetsImage, Modal, AssetsImages, PortfolioProjectPicture},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  computed: {

    image() {
      try {
        return this.selected.pictures[0].directus_files_id;
      } catch (e) {
        return null;
      }
    }
  },
  data() {
    return {
      modal: false,
      selected: null,
      images: [],
    }
  },
  mounted() {
    this.set();
  },
  methods: {
    set(){
      try {
        this.data.ideas.forEach(item => {
          let image = this.getImage(item)
          if (!image) return;
          this.images.push(image);
        })
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    getImage(item) {
      try {
        return item.pictures[0];
      } catch (e) {
        return null;
      }
    },
    select(item) {
      this.selected = item;
      this.modal = true;
    },
    selectByIndex(index) {
      try {
        if (!this.data.ideas[index]) return;
        this.selected = this.data.ideas[index];
      } catch (e) {
      }
    }
  }
}
</script>
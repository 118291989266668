import { render, staticRenderFns } from "./ProfessionalProfile.vue?vue&type=template&id=793f21f6&scoped=true&"
import script from "./ProfessionalProfile.vue?vue&type=script&lang=js&"
export * from "./ProfessionalProfile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793f21f6",
  null
  
)

export default component.exports
<template>
  <div id="profile-stories">
    <!--  Desktop  -->
    <profile-stories-desktop v-if="!show_more"  class="hidden-sm-and-down" v-bind="$attrs"/>


    <!-- Mobile   -->
    <div :class="{'hidden-md-and-up':!show_more}">
      <slot name="divider">
        <v-divider/>
      </slot>
      <show-more :open_default="open">
        <template #title>
          <div class="font-weight-bold primary--text">{{   title ?? __('Stories') }}</div>
        </template>

        <template #more>
          <profile-stories-desktop v-bind="$attrs" class="py-3 px-2 pb-0" hide_title/>
        </template>
      </show-more>
    </div>
  </div>
</template>

<script>

import ProfileProjectsDesktop from "@/components/profiles/ProfileProjectsDesktop";
import ShowMore from "@/components/app/ShowMore";
import ProfileStoriesDesktop from "@/components/profiles/ProfileStoriesDesktop";

export default {
  name: "ProfileStories",
  components: {ProfileStoriesDesktop, ShowMore, ProfileProjectsDesktop},
  props: {
    title: {
      type: String,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    show_more: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
<template>
  <div>
    <div class="pointer" @click="toggle(true)">
      <slot>
      </slot>
    </div>

    <!-- In Modal   -->
    <modal v-model="dialog" fullscreen @input="(e) => toggle(e)">
      <template v-slot:button>
        <slot name="button"></slot>
      </template>
      <template v-slot:title>
        <span>{{ title ? title : __("AddJob") }}</span>
      </template>
      <template v-slot:main>
        <job-add :project_id="data.id" @then="e => added(e)"/>
      </template>

    </modal>
  </div>

</template>

<script>
import Modal from "../modal/Modal";
import JobAdd from "../jobs/JobAdd";


export default {
  name: "ProjectAddJob",
  components: {JobAdd, Modal},
  props: {
    value: {
      default: null,
      type: Boolean,
    },
    data: {
      type: [Object],
      require: true
    },
    title: {
      default: null,
      type: String
    },
    button_title: {
      default: null,
      type: String
    },
    modal: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {

      dialog: null,
    }
  },
  mounted() {

  },
  watch: {
    'value'() {
      this.dialog = this.value;
    }
  },
  methods: {
    toggle(value) {
      this.dialog = value;
      this.$emit('input', value)
    },

    added(value) {
      this.$emit('then', value)
      this.toggle(false)
    },

  },
}
</script>

<style scoped>

</style>
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VListItem,{class:{'package-item': true, 'secondary':_vm.selected },attrs:{"two-line":""},on:{"click":function($event){return _vm.$emit('click', _vm.data)}}},[_c(VListItemAvatar,{attrs:{"size":"70"}},[_c(VAvatar,{attrs:{"color":"secondary"}},[_vm._v(" "+_vm._s(_vm.data.service_type)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.data.title)+" "),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.__(_vm.data.type))+" ")])],1),_vm._v(" "+_vm._s(_vm.data.text)+" "),_c(VListItemSubtitle,[_c(VChipGroup,_vm._l((_vm.data.services),function(service){return _c(VChip,{key:service.service_id.id,staticClass:"grey lighten-4"},[_vm._v(_vm._s(service.service_id.title))])}),1)],1)],1),_c(VListItemActionText,[_c(VAvatar,{staticClass:"font-weight-bold",attrs:{"color":"secondary","size":"50"}},[_c('price',{attrs:{"data":_vm.data.price,"default_price":_vm.__('Free')}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
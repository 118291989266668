<template>
  <div class="full-width">
    <div class="font-weight-bold" v-if="!hide_title">{{ title ?? __('Room') }} {{ $attrs['required'] !== undefined ? '*' : '' }}</div>
    <v-select
        :items="items()"
        :value="value"
        :placeholder="first_label"
        @input="(e) => $emit('input', e)"
        :class="['custom-selector-border rounded-lg', ...this.css_class]"
        outlined
        dense
        v-bind="$attrs"
    ></v-select>
  </div>

</template>

<script>
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "RoomSelector",
  props: {
    value: {
      default: null,
      type: [String,Object, Array],
    },
    data: {
      default: null,
      type: Array,
    },
    first_label: {
      default: null,
      type: String,
    },
    css_class: {
      default: '',
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    hide_title: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      request: new GetRequest('get_rooms'),
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.setLimit(1000);
      this.request.get();
    },
    items() {
      try {
        let items = [];

        if (!this.request.data) return;

        this.request.data.forEach((item) => {
          items.push({
            text: item.title,
            value: item.id,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="modal">
    <div @click="toggle(true)">
      <slot name="button">
      </slot>
    </div>

    <v-dialog
        v-model="modal"
        :fullscreen="fullscreen"
        :max-width="max_width"
        @input="$emit('input', modal)"
        v-bind="$attrs"
        transition="dialog-bottom-transition"

        style="z-index: 9999 !important;"
    >
      <slot name="content">
        <v-card :class="{'rounded-lg': !fullscreen}">
          <slot>
            <v-container v-if="fullscreen">
              <v-toolbar color="background" flat>
                <v-btn icon @click="toggle(false)" class="grey lighten-4">
                  <v-icon>mdi-close</v-icon>
                </v-btn>

                <v-toolbar-title>
                  <slot name="title"></slot>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <slot name="actions">
                </slot>
              </v-toolbar>
            </v-container>

            <v-divider></v-divider>

            <v-container :fluid="!fullscreen">
              <space v-if="fullscreen" height="15"/>
              <slot name="main"></slot>
              <space v-if="fullscreen" height="90"/>
            </v-container>
          </slot>
        </v-card>
      </slot>
    </v-dialog>
  </div>
</template>

<script>
import Space from "../app/Space";

export default {
  name: "Modal",
  components: {Space},
  props: {
    value: {
      default: null,
      type: Boolean,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    max_width: {
      default: null,
      type: [String, Number],
    }
  },
  watch: {
    'value'() {
      this.modal = this.value;
    }
  },
  data() {
    return {
      modal: null,
    }
  },
  mounted() {
  },
  methods: {
    toggle(value) {
      this.modal = value;
      this.$emit('input', value)
    }
  },
}
</script>

<template>
  <v-container class="container-max-width">
    <portfolio-blog :id="$route.params.id"/>
  </v-container>
</template>

<script>

import PortfolioBlog from "@/components/portfolio/PortfolioBlog";
export default {
  name: "ProfessionalBlogPage",
  components: {PortfolioBlog},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

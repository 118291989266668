<template>
  <div>
    <div class="pointer" @click="toggle(true)">
      <slot>
      </slot>
    </div>

    <!-- In Modal   -->
    <modal v-model="dialog" fullscreen @input="(e) => toggle(e)">
      <template v-slot:button>
        <slot name="button"></slot>
      </template>

      <template v-slot:title>
        <span>{{ title ? title : __("JobEdit") }}</span>
      </template>

      <template v-slot:main>

        <!--  Add Job Form  -->
        <form @submit.prevent="submit" v-if="request.body">
          <v-row>

            <v-col cols="12" md="12">
              <div class="font-weight-bold">{{ __('Description') }}</div>
              <v-textarea
                  v-model="request.body.description"
                  dense
                  auto-grow
                  outlined
              />
            </v-col>

            <v-col cols="12" md="6">
              <div class="font-weight-bold">{{ __('ContractAmount') }} (<currency/>)</div>
              <v-text-field
                  v-model.number="request.body.contract_amount"
                  type="number"
                  dense
                  outlined
              />
            </v-col>

            <v-col cols="12" md="6">
              <date-selector v-model="request.body.desired_start_date" :title="__('DesiredStartDate')"/>
            </v-col>

            <v-col cols="12" md="6">
              <div class="font-weight-bold">{{ __('Status') }}</div>
              <job-status-selector :value="request.body.status" :items="status_items" @input="(e) => request.body.status = e"/>
            </v-col>

            <v-col md="12">

              <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
                <span v-if="!request.is_loading">{{ __('Submit') }}</span>
                <span v-else>...</span>
              </v-btn>
            </v-col>

          </v-row>
        </form>

      </template>

      <template v-slot:actions>

      </template>
    </modal>

  </div>

</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import Modal from "../modal/Modal";
import DateSelector from "../selectors/DateSelector";
import JobStatusSelector from "../selectors/JobStatusSelector";
import auth from "../../tools/Auth";
import Currency from "../app/Currency";


export default {
  name: "JobEdit",
  components: {Currency, JobStatusSelector, DateSelector, Modal},
  props: {
    data: {
      default: null,
      type: Object,
    },
    value: {
      default: null,
      type: Boolean,
    },

    title: {
      default: null,
      type: String,
    },

  },

  data() {
    return {
      request: new PostRequest('update_job'),
      dialog: null,
      status_items: [
        {
          text: this.__('Pending'),
          value: 'pending',
        },
        {
          text: this.__('LogSiteVisit'),
          value: 'log_site_visit',
        },
        {
          text: this.__('EstimateSent'),
          value: 'estimate_sent',
        },
        {
          text: this.__('AddContract'),
          value: 'add_contract',
        },
        {
          text: this.__('OnHold'),
          value: 'on_hold',
        },
        {
          text: this.__('Closed'),
          value: 'closed',
        },
      ]
    }
  },
  mounted() {
    if (!this.data) return;
    this.request.setApiParams([this.data.id])
    this.request.setThen(this.then)
    this.request.setFields('*,*.*, suggested_pros.pro_id.*, suggested_pros.pro_id.expertises.expertise_id.*, pro_id.expertises.expertise_id.*, suggested_pros.pro_id.personal_comment.*, pro_id.*.*')
    this.setBody();
  },
  watch: {
    'value'() {
      this.dialog = this.value;
    }
  },
  methods: {
    setBody() {
      if (!this.data) return;
      this.request.setBody(
          {
            title: this.data.title,
            description: this.data.description,
            desired_start_date: this.data.desired_start_date,
            status: this.data.status,
            contract_amount: this.data.contract_amount,
          }
      );
    },
    then(response) {
      try{
        if (!response || !response.data || !response.data.id) throw 'Can not get response!';
        this.toggle(false);
        this.$emit('updated', response.data)
      }catch (e){
        console.log(e)
      }
    },
    submit() {
      this.request.patch();
    },
    toggle(value) {
      this.dialog = value;
      this.$emit('input', value)
    },
  },
}
</script>

<style scoped>

</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.request.body.first_name !== undefined)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.request.post()}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('FirstName'))+" *")]),_c(VTextField,{attrs:{"dense":"","outlined":"","required":""},model:{value:(_vm.request.body.first_name),callback:function ($$v) {_vm.$set(_vm.request.body, "first_name", $$v)},expression:"request.body.first_name"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('LastName'))+" *")]),_c(VTextField,{attrs:{"dense":"","outlined":"","required":""},model:{value:(_vm.request.body.last_name),callback:function ($$v) {_vm.$set(_vm.request.body, "last_name", $$v)},expression:"request.body.last_name"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('Phone')))]),_c(VTextField,{attrs:{"dense":"","outlined":""},model:{value:(_vm.request.body.phone_number),callback:function ($$v) {_vm.$set(_vm.request.body, "phone_number", $$v)},expression:"request.body.phone_number"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('Email')))]),_c(VTextField,{attrs:{"dense":"","outlined":""},model:{value:(_vm.request.body.email),callback:function ($$v) {_vm.$set(_vm.request.body, "email", $$v)},expression:"request.body.email"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('TelegramId')))]),_c(VTextField,{attrs:{"dense":"","outlined":""},model:{value:(_vm.request.body.telegram_id),callback:function ($$v) {_vm.$set(_vm.request.body, "telegram_id", $$v)},expression:"request.body.telegram_id"}})],1),(!_vm.role)?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('role-selector',{attrs:{"first_label":_vm.__('SelectOne'),"without":[
              "3448c9ef-6456-4cad-8015-87a4e8fe1c31",
              "ebe9313f-150a-4331-98bf-af294eec69cf",
              "ec5d18b5-047b-4ab5-b7f8-a089bd8be43a",
              "be3523ab-31c0-40a6-a363-300808d4dbbe"
            ]},model:{value:(_vm.request.body.role),callback:function ($$v) {_vm.$set(_vm.request.body, "role", $$v)},expression:"request.body.role"}})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('status-selector',{attrs:{"items":_vm.status_items},model:{value:(_vm.request.body.status),callback:function ($$v) {_vm.$set(_vm.request.body, "status", $$v)},expression:"request.body.status"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VBtn,{attrs:{"type":"submit","color":"primary","elevation":"0","disabled":_vm.request.isLoading()}},[(!_vm.request.is_loading)?_c('span',[_vm._v(_vm._s(_vm.__('Submit')))]):_c('span',[_vm._v("...")])])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[(_vm.questions.hasTests())?_c(VCol,{attrs:{"cols":"12","sm":"12"}},[_vm._l((_vm.questions.getTests()),function(item,key){return [(item && item.quiz)?_c(VRow,{key:'test-'+key,staticClass:"py-6",attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('div',{staticClass:"title",staticStyle:{"font-size":"22pt !important"},domProps:{"innerHTML":_vm._s(item.question_title)}}),(item.question_subtitle)?_c('div',{staticClass:"text--secondary mt-6",domProps:{"innerHTML":_vm._s(item.question_subtitle)}}):_vm._e()]),_c(VCol,{attrs:{"cols":"12","sm":"7","md":"7"}},[_c('answer-selector',{attrs:{"value":_vm.questions.selected_answers[item.quiz],"data":item.answer_options},on:{"input":(e) => _vm.questions.selectAnswer(item, e)}})],1)],1):_vm._e()]})],2):_vm._e(),_c(VCol,{attrs:{"sm":"12"}},[_c('questions-next-button',{attrs:{"questions":_vm.questions,"fixed":""}},[_c(VBtn,{staticClass:"rounded-lg",staticStyle:{"min-width":"160px"},attrs:{"large":"","color":"primary","elevation":"0"},on:{"click":function($event){return _vm.click()}}},[_vm._v(" "+_vm._s(_vm.__('Next'))+" ")])],1),_c(VDialog,{attrs:{"persistent":"","max-width":"690"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_c('div',{domProps:{"innerHTML":_vm._s( _vm.__('Confirm'))}})]),_c(VCardText,{staticClass:"text--secondary"},[_c('div',{domProps:{"innerHTML":_vm._s( _vm.__('TestDialog'))}})]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.__('Cancel'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.__('Yes'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
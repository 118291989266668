<template>
  <div v-if="_is_object(filters)">
    <profiles-filter
        :title="__('ProfessionalCategory')"
        :count="_is_array(filters.categories) ? filters.categories.length : null"
    >

      <job-expertises-selector
          class="hidden-sm-and-down"
          v-model="filters.categories"
          @input="reload"
          show_search
          hide_under_line
          hide_title
          return_object
          show_minimal
          rows
      />

      <job-expertises-selector
          class="hidden-md-and-up"
          v-model="filters.categories"
          @input="reload"
          show_search
          hide_under_line
          hide_title
          return_object
      />
    </profiles-filter>

    <profiles-filter
        v-model="filters.budgets"
        :items="budgets_items"
        :count="_is_array(filters.budgets) ? filters.budgets.length : null"
        :title="__('Budget')"
        @input="reload"
        multiple
        return_object
        without_light_scroll
    />

    <profiles-filter
        :title="__('Language')" without_light_scroll
        :count="(_is_object(filters.languages)  && filters.languages.value) ? 1 : null"
    >
      <languages-selector
          v-model="filters.languages"
          list
          hide_title
          @input="reload"
          hide_flag
          return_object
      />
    </profiles-filter>

    <profiles-filter
        v-model="filters.rating"
        :items="rating_items"
        :count="(_is_object(filters.rating)  && filters.rating.value) ? 1 : null"
        :title="__('Rating')"
        @input="reload"
        return_object
        without_light_scroll
    >
      <template #label="{item}">
        <div class="d-flex align-center">
          <rate v-if="item.star_count" :data="item.star_count" hide_number size="15"/>
          &nbsp;{{ item.text }}
        </div>
      </template>
    </profiles-filter>

    <profiles-filter
        :title="__('Style')"
        :count="(_is_object(filters.styles)  && filters.styles.value) ? 1 : null"
        without_light_scroll
    >
      <style-selector v-model="filters.styles" list hide_title @input="reload" return_object/>
    </profiles-filter>
  </div>
</template>

<script>
import ProfilesFilter from "@/components/profiles/ProfilesFilter";
import StyleSelector from "@/components/selectors/StyleSelector";
import Rate from "@/components/app/Rate";
import LanguagesSelector from "@/components/selectors/LanguagesSelector";
import JobExpertisesSelector from "@/components/selectors/JobExpertisesSelector";

export default {
  name: "ProfileFiltersOptions",
  components: {JobExpertisesSelector, LanguagesSelector, Rate, StyleSelector, ProfilesFilter},
  props: {
    filters: {
      default: null,
      type: [Object],
    },
  },
  computed: {
    budgets_items() {
      return [
        {
          text: this.__('LowBudget'),
          value: 'low_budget',
        },
        {
          text: this.__('LowToMediumBudget'),
          value: 'low_to_medium_budget',
        },
        {
          text: this.__('MediumToHighBudget'),
          value: 'medium_to_high_budget',
        },
        {
          text: this.__('UnlimitedBudget'),
          value: 'unlimited_budget',
        },
      ];
    },
    rating_items() {
      return [
        {
          text: this.__('AnyRating'),
          value: null,
          star_count: null,
        },
        {
          text: this.__('5Star'),
          value: {"total_score": {"_eq": 5}},
          star_count: 5,
        },
        {
          text: this.__('4StarAndUp'),
          value: {"total_score": {"_gte": 4}},
          star_count: 4,
        },
        {
          text: this.__('3StarAndUp'),
          value: {"total_score": {"_gte": 3}},
          star_count: 3,
        },
      ];
    },
  },
  methods: {
    reload() {
      this.$emit('reload')
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-container  class="container-max-width">
    <page-title :text="__('Blogs')"   />
    <blogs summary/>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Blogs from "@/components/blogs/Blogs";

export default {
  name: "PublicBlogsPage",
  components: {Blogs, PageTitle}
}
</script>

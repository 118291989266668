<template>
  <v-card v-if="data && data.id" class="rounded-lg  mb-3" :title="__('Note')" outlined>
    <v-card-title class="title">
      {{ __('Note') }}
    </v-card-title>

    <v-card-text>
      <v-textarea
          class="rounded-lg"
          auto-grow
          outlined
          rows="1"
          row-height="15"
          v-model="request.body.text"
          :disabled="expired"
      ></v-textarea>
    </v-card-text>
    <v-card-actions class="px-4 pb-4">
      <v-spacer/>
      <v-btn class="elevation-0 text--text" color="secondary" @click="request.post()" :disabled="expired">{{__('Save')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";

export default {
  name: "ProjectComment",
  components: {},
  props: {
    data: {
      default: null,
      type: Object,
    },
    expired: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      request: new PostRequest('add_personal_note'),
    }
  },
  mounted() {
    this.request.setThen((e) => this.$emit('reload'));
    this.setBody();
  },
  methods: {
    setBody() {
        this.request.setBody(
            {
              item_id: this.data.id,
              collection_name: "project",
              text: this.getDefault()
            }
        );

    },
    getDefault(){
      try{
        return this.data.personal_comment[0].text;
      }catch(e){
        return null;
      }
    }
  }
}
</script>
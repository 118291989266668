<template>
  <div>

    <slot name="selector" :request="request"/>

    <!-- Lead List  -->
    <v-row v-if="!hide_status_selector">
      <v-col>
        <projects-status-selector :request="request"/>
      </v-col>
    </v-row>

    <v-row v-if="!hide_subscribe">
      <v-col class="d-flex align-center">
        <slot name="title"></slot>
        <projects-subscription :request="request"/>
      </v-col>
    </v-row>

    <v-row v-if="!hide_actions">
      <v-col class="d-flex justify-space-between">
        <div class="d-flex width-full">

          <!-- Search -->
          <items-search :request="request" :columns="['title']"></items-search>


        </div>

        <space/>

        <div class="d-flex">

          <!-- Limit -->
          <items-limit :request="request"/>
          <space/>

          <!--   Filters button     -->
          <v-btn v-if="!hide_filters" class="rounded-lg border-light" outlined @click="show_filters = !show_filters"
                 large>
            <v-icon left>mdi-filter-variant</v-icon>
            {{ __("Filters") }}
          </v-btn>

          <!-- Add Button   -->
          <template v-if="add_is_visible && !_access('pro')">
            <space/>
            <v-btn color="primary" elevation="0" large class="hidden-sm-and-down rounded-lg"
                   :to="{name:'StartProject', query:{clear:true}}">
              {{ __('StartProject') }}
            </v-btn>
            <v-btn color="primary" fab elevation="0" small class="hidden-md-and-up" :to="{name:'StartProject'}">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

        </div>

      </v-col>
    </v-row>

    <!-- Filters -->
    <v-row v-show="show_filters" v-if="!hide_filters">
      <v-col>
        <projects-filters
            @reset="get"
            :request="request"
        >
        </projects-filters>
      </v-col>
    </v-row>

    <!-- Loading -->
    <loader cols="12" col_sm="6" col_md="4" col_lg="4" col_xl="2" v-if="request.isLoading()" :count="loader_count"/>

    <!-- List -->
    <v-row v-else>
      <v-col cols="12" sm="6" md="4" v-for="(item, key) in request.data" v-bind:key="key">
        <project
            :data="item"
            :hide_buttons="hide_buttons"
            :show_subscription_type="show_subscription_type"
            :route_name="route_name"
            @reload="request.reload()"
        />
      </v-col>

      <v-col sm="12">
        <!--   Not found data   -->
        <not-found-data v-if="!request.data" style="padding-top: 90px !important;"/>

        <!-- Load More -->
        <load-more pagination v-if="!hide_load_more" :request="request"/>
      </v-col>

    </v-row>

  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";

import LoadMore from "../app/LoadMore";
import Space from "../app/Space";
import Loader from "../app/Loader";
import NotFoundData from "../app/NotFoundData";
import ItemsSearch from "../items/ItemsSearch";
import ProjectsFilters from "./ProjectsFilters";
import Project from "./Project";
import ProjectsStatusSelector from "./ProjectsStatus";
import ProjectsSubscription from "./ProjectsSubscription";
import ItemsLimit from "../items/ItemsLimit";

export default {
  name: "Projects",
  components: {
    ItemsLimit,
    ProjectsSubscription,
    ProjectsStatusSelector,
    Project,
    ProjectsFilters, ItemsSearch, NotFoundData, Loader, Space, LoadMore,
  },
  props: {
    api: {
      default: 'get_items',
      type: String,
    },
    api_params: {
      default: null,
      type: Array,
    },
    pro_id: {
      default: null,
      type: String,
    },

    loader_count: {
      default: 12,
      type: [String, Number],
    },
    status: {
      default: null,
      type: String,
    },
    lead_status: {
      default: null,
      type: String,
    },
    client_id: {
      default: null,
      type: String,
    },
    add_is_visible: {
      default: false,
      type: Boolean,
    },
    hide_actions: {
      default: false,
      type: Boolean,
    },
    hide_buttons: {
      default: false,
      type: Boolean,
    },
    show_subscription_type: {
      default: false,
      type: Boolean,
    },
    route_name: {
      default: 'Project',
      type: String,
    },
    hide_status_selector: {
      default: false,
      type: Boolean,
    },
    hide_filters: {
      default: false,
      type: Boolean,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    hide_subscribe: {
      default: false,
      type: Boolean,
    },
    filter_status: {
      default: true,
      type: Boolean,
    },
    without: {
      default: null,
      type: Array,
    },
    limit: {
      default: null,
      type: [String, Number],
    },
    sort: {
      default: 'date_created',
      type: String,
    },
    fields: {
      default: '*, client_id.*, personal_tags.*',
      type: String,
    }
  },
  data() {
    return {
      request: new GetRequest(),
      show_filters: false,
      selected_status: null,
    }
  },
  mounted() {
    this.get();
  },
  watch: {
    "$route.query.page"() {
      this.get();
    }
  },
  methods: {

    get() {
      this.request.reset();
      this.request.setApi(this.api)
      this.request.setApiParams(this.api_params ? this.api_params : ['project'])

      this.setFilters();
      this.setQueries();
      this.setPage();


      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get();
    },
    setFilters() {
      if (this.pro_id) this.request.addFilter('pro_id', this.pro_id);
      if (this.status) this.request.addFilter('status', this.status);
      if (this.lead_status) this.request.addFilter('lead_status', this.lead_status);
      if (this.client_id) this.request.addFilter('client_id', this.client_id);
    },
    setPage() {
      try {
        this.request.setPage(this.$route.query.page)
      } catch (e) {
        this.request.setPage(1)
      }
    },
    setQueries() {
      try {
        let queries = this.$route.query;
        if (!queries) return;

        if (queries.status) this.request.addFilter('status', queries.status)
        if (queries.subscription_type) this.request.addFilter('subscription_type', queries.subscription_type)
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
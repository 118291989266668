import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-width"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.title ? _vm.title : _vm.__('Range')))]),_c('div',{staticClass:"border-light px-2 rounded-lg pointer py-2 px-2 text-truncate",on:{"click":function($event){_vm.dialog = true}}},[(_vm.min === '-')?_c('span',[_vm._v(_vm._s(_vm.__('All')))]):_c('span',[_vm._v(_vm._s(_vm.min)+" - "+_vm._s(_vm.max))])]),_c(VDialog,{attrs:{"persistent":"","max-width":"590"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title ? _vm.title : _vm.__('SelectRange'))+" ")]),_c(VCardText,[_c(VRow,{staticClass:"pt-5"},[_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('Min')))]),_c(VTextField,{attrs:{"oninput":"validity.valid||(value='');","min":"0","type":"number","dense":"","outlined":""},model:{value:(_vm.result.min),callback:function ($$v) {_vm.$set(_vm.result, "min", $$v)},expression:"result.min"}})],1),_c(VCol,[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('Max')))]),_c(VTextField,{attrs:{"min":"0","type":"number","oninput":"validity.valid||(value='');","dense":"","outlined":""},model:{value:(_vm.result.max),callback:function ($$v) {_vm.$set(_vm.result, "max", $$v)},expression:"result.max"}})],1)],1)],1),_c(VCardActions,{staticClass:"pb-4"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.__('Cancel'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":parseInt(_vm.result.max) < parseInt(_vm.result.min)},on:{"click":_vm.pick}},[_vm._v(" "+_vm._s(_vm.__('Select'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ __('Role') }}</div>
    <v-select
        :items="items()"
        :value="value"
        @input="(e) => $emit('input', e)"
        class="custom-selector-border rounded-lg"
        outlined
        dense
    ></v-select>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "RoleSelector",
  components: {},
  props: {
    value: {
      default: null,
      type: String,
    },
    first_label: {
      default: null,
      type: String,
    },
    without: {
      default: null,
      type: [Array],
    }
  },
  data() {
    return {
      request: new GetRequest('get_roles'),
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.addFilter('*');
      this.request.setNotIn('id', this.without);
      this.request.setLimit(1000);
      this.request.get();
    },
    items() {
      try {
        let items = [
          {
            text: this.first_label ? this.first_label : this.__('All'),
            value: null,
          },
        ];
        if (!this.request.data) return;
        this.request.data.forEach((item) => {
          items.push({
            text: item.name,
            value: item.id,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>

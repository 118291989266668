<template>
  <v-card v-if="!hide" outlined flat class="rounded-lg   pa-6 ">
    <v-row v-if="data && data.id">
      <v-col cols="10">
        <v-row>

          <v-col cols="12" md="2">
            <assets-image :data="data.avatar" width="128" height="128" max-width="100%" class="rounded-lg"/>
          </v-col>

          <v-col cols="12" md="6">
            <div>
              <div class="title pa-0 pointer" @click="dialog = true">
                {{ data.first_name }}
                {{ data.last_name }}
              </div>

              <div v-if="_user">
                <div>
                  {{ __('Email') }} : {{ data.email }}
                </div>
                <div>
                  {{ __('PhoneNumber') }} : {{ data.phone_number }}
                </div>
                <div>
                  {{ __('Status') }} :
                  <span :class="data.status === 'active' ? 'green--text' : 'red--text'">
                  {{ data.status }}
                </span>
                  <div>
                    {{ __('StartDate') }} : {{ data.date_created }}
                  </div>
                  <div>
                    {{ __('TotalPay') }} :
                    <price :data="data.total_pay"/>
                  </div>
                </div>
              </div>
            </div>
          </v-col>

        </v-row>
      </v-col>

      <v-col cols="2" class="d-flex justify-end">


        <v-btn :disabled="!data.telegram_id" icon :href="'https://t.me/' + data.telegram_id" target="_blank">
          <v-icon>mdi-chat</v-icon>
        </v-btn>

        <user-menu :data="data"/>

      </v-col>
    </v-row>


    <!-- More Info   -->
    <modal max_width="390" v-model="dialog" fullscreen>

      <template v-slot:button>

      </template>

      <template v-slot:title>
        <div class="px-3">

          <div class="d-flex align-center justify-space-between pt-3 pb-2">
            <v-avatar color="secondary" size="50">
              <assets-image v-if="data.avatar" :data="data.avatar" width="30"/>
              <v-icon v-else :data="data.avatar">mdi-account</v-icon>
            </v-avatar>
            <space/>
            <div class="font-weight-bold">
              {{ data.first_name }}
              {{ data.last_name }}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:main>
        <div >
          <v-row>
            <div class="heading px-3">{{__('Projects')}}</div>
            <v-col cols="12" v-for="(item, key) in data.projects" v-bind:key="key">
              <v-card outlined flat class="rounded-lg   pa-3 ">
                <v-card-title class="pb-6">
                  <router-link :to="{name: 'Project' , params: { id: item.id }}">
                    <div class="text--text">
                      {{ item.title }}
                    </div>
                  </router-link>
                </v-card-title>

                <v-card-subtitle>
                  <!--               Jobs   -->
                  <div>
                    <span class="  text--disabled">{{ __('Jobs') }} : </span>
                    <span v-if="item.jobs">
                      <template v-for="(job, job_key) in item.jobs">
                        <span v-if="job && job.job_type_id && job.job_type_id.title"
                              v-bind:key="item.id + '-job-'+job_key">
                          <span>
                            {{ job.job_type_id.title }} <span v-if="job_key + 1 < item.jobs.length">,</span>
                          </span>
                        </span>
                      </template>
                    </span>
                  </div>

                  <!-- Packages -->
                  <div>
                    <span class="  text--disabled">{{ __('Packages') }} : </span>
                    <span v-if="item.packages">
                      <template v-for="(package_item, package_key) in item.packages">
                        <span v-if="package_item && package_item.package_id && package_item.package_id.title"
                              v-bind:key="item.id + '-package-'+package_key">
                          <span>
                            {{
                              package_item.package_id.title
                            }} <small>({{ __(package_item.package_id.type) }})</small> <span
                              v-if="package_key + 1 < item.packages.length">,</span>
                          </span>
                        </span>
                      </template>
                    </span>
                  </div>
                </v-card-subtitle>


              </v-card>
            </v-col>

            <v-col sm="12">
              <!--   Not found data   -->
              <not-found-data :title="__('NotFoundAnyProject')"
                              v-if="!data.projects || !Array.isArray(data.projects) || data.projects.length === 0"
                              style="padding-top: 90px !important;"/>
            </v-col>
          </v-row>


        </div>
      </template>

      <template v-slot:actions>
        <user-menu :data="data" hide_archive hide_edit/>
      </template>
    </modal>
  </v-card>
</template>

<script>

import AssetsImage from "@/components/assets/Image";
import {PostRequest} from "../../models/PostRequest";
import Price from "../app/Price";
import UserMenu from "../users/UserMenu";
import Modal from "../modal/Modal";
import Space from "../app/Space";
import NotFoundData from "../app/NotFoundData";

export default {
  name: "Client",
  components: { NotFoundData, Space, Modal, UserMenu, Price, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object
    },

  },
  data: () => {
    return {
      request: new PostRequest('edit_professional'),
      archive_modal: false,
      dialog: null,
      hide: false,
    }
  },
  mounted() {
    this.request.setApiParams([this.data.id])
  },
  computed: {},
  methods: {
    approved() {
      this.request.setBody({pro_registration_status: 'hired'});
      this.request.setThen(e => this.hide = true);
      this.request.patch();
    }
  }
}
</script>

<style scoped>

</style>

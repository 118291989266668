<template>
  <div class="full-width">
    <inline-selector :value="getDefault()" :items="items()" @input="select" :disabled="request.isLoading()">

    </inline-selector>
  </div>
</template>

<script>
import InlineSelector from "../selectors/InlineSelector";
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "ProjectsStatus",
  components: {InlineSelector},
  props: {
    request: {
      default: new GetRequest(),
      type: GetRequest,
      required: true,
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    items() {
      return [
        {
          text: this.__('All'),
          value: null,
        },
        {
          text: this.__('Active'),
          value: 'active'
        },
        {
          text: this.__('Archived'),
          value: 'archived'
        },
        {
          text: this.__('Completed'),
          value: 'completed'
        }
      ];
    },
    select(value) {
      try {
        this.request.is_loading = false;

        let filter_key = this._access('pro') ? 'project_id' : 'status';

        // remove for null value
        if (!value) {
          this.request.removeFilter(filter_key);
          this.request.reload();
          return
        }

        // is not pro
        if (this._access('pro')) value = {status: {'_eq': value}};

        // for pro
        this.request.addFilter(filter_key, value);
        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },
    getDefault() {
      try {
        if (!this._access('pro')) return this.request.getFilter('status');

        // for pro
        let result = this.request.getFilter('project_id', false);
        return result.status._eq;
      } catch (e) {
        return null;
      }
    },

  }
}
</script>

<style scoped>

</style>
<template>
  <v-container v-if="$store.state.user_info && $store.state.user_info.role">
    <page-title :text="__('Projects')" heading/>

<!--    /items/job?filter={"_and": [{"project_id":{"status": {"_eq": "active"}}},{"pro_id":{"_eq":"$CURRENT_USER"}}]}-->


    <projects
        v-if="_access('pro')"
        fields="*.*.*"
        api="items_collection"
        :api_params="['job']"
        pro_id='$CURRENT_USER'
        in_project_id
        hide_filters
        hide_subscribe
        add_is_visible
    />


    <projects
        v-else
        :hide_filters="!_access('admin')"
        hide_subscribe
        add_is_visible
    />
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Projects from "../../../components/projects/Projects";

export default {
  name: "ProjectsPage",
  components: {Projects, PageTitle,},
  computed: {},
  mounted() {
  }
}
</script>

<template>
  <div class="d-flex align-center">
    <div v-if="!hide_number" class="primary--text" style="margin-top: 5px">{{data}} &nbsp;</div>
    <v-rating :value="data" class="rating ma-0 pa-0" background-color="grey"  color="yellow darken-3" readonly :length="5"  v-bind="$attrs"/>
  </div>
</template>

<script>
export default {
  name: "Rate",
  props: {
    data: {
      default: null,
      type: [String, Number]
    },
    hide_number: {
      default: false,
      type: Boolean
    },
  },
}
</script>

<style>
.rating.v-rating button {
  padding: 0 2px;
}
</style>
<template>
  <div>
    <!-- Pagination -->
    <v-row v-if="pagination">
      <v-col cols="12" sm="6" class="mx-auto py-0">
        <div class="full-width d-flex align-center justify-center">
          <v-btn
              v-if="1 < request.page"
              :disabled="request.is_loading"
              :title="__('Back')"
              @click="back"
              color="primary"
              class="rounded-lg px-1"
              min-width="10"
              small
              outlined
          >
            <v-icon v-if="!request.is_loading">mdi-chevron-left</v-icon>
            <span v-else>...</span>
          </v-btn>

          <space/>

          <div v-if="1 < request.maxPage()" class="d-flex">
            <v-btn v-for="item in items" small text min-width="auto" @click="setPage(item)" :disabled="item === '...'">
              <span :class="item == request.page ? 'font-weight-bold' : 'text--disabled'">{{ item }}</span>
            </v-btn>
          </div>

          <space/>

          <v-btn
              v-if="request.hasMore()"
              :title="__('Next')"
              :disabled="request.isLoading() || !request.hasPage()"
              @click="next"
              min-width="10"
              color="primary"
              class="rounded-lg px-1"
              small
              outlined
          >
            <v-icon v-if="!request.is_loading">mdi-chevron-right</v-icon>
            <span v-else>...</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!--   Load more button   -->
    <v-btn
        v-else-if=" request.hasMore()"
        color="primary"
        class="border-light rounded-lg font-size-14"
        @click="loadMore"
        :disabled="request.is_loading"
        elevation="0"
        v-bind="$attrs"
    >
      <span v-if="!request.is_loading">{{ title ?? __('LoadMore') }}</span>
      <span v-else>...</span>
    </v-btn>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import Space from "@/components/app/Space";

export default {
  name: "LoadMore",
  components: {Space},
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    add_to_limit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    load_method: {
      type: Function,
      default: null,
    }
  },
  computed: {
    items() {
      try {
        let after = [];
        let before = [];
        let current = this.request.page;

        for (let i = 1; i <= 3; i++) {
          if (current - i < 1) break;
          after.unshift(current - i)
          if (i === 3 && (current - i) !== 1) after.unshift('...')
        }

        for (let i = 1; i <= 3; i++) {
          if (this.request.maxPage() < current + i) break;
          before.push(current + i)
          if (i === 3 && (current + i) !== this.request.maxPage()) {
            before.push('...');
            before.push(this.request.maxPage());
          }
        }

        return [...after, ...[current], ...before];
      } catch (e) {
      }
    }
  },
  methods: {
    setPage(page) {
      this.request.setPage(page)
      this.reload()
    },
    next() {
      this.request.nextPage()
      this.reload()
    },
    back() {
      this.request.backPage()
      this.reload()
    },
    reload() {
      this.request.reload(false)
    },
    loadMore() {
      if (this.load_method) return this.load_method();
      this.request.loadMore()
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <v-row v-if="data && data.id">

    <!--  Title  -->
    <v-col cols="12">
      <div class="d-flex justify-space-between ">
        <div class="title d-flex" :title="__('LastProjectIdeas')">
          {{ __('Ideas') }}
          <space/>

        </div>

        <div>
          <v-btn v-if="hasItem" text :to="{name:(data.status !== 'lead' ? 'ProjectIdeas' : 'LeadIdeas'), params:{id:data.id}, query: {title:data.title}}">
            {{ __('More') }} &nbsp;
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>

      </div>
    </v-col>

    <loader count="3" v-if="request.isLoading()"/>

    <!--  Items  -->
    <v-col v-else-if="!hasItem" align="center">
      <div class="mb-16 mt-3">
        <not-found-data/>

        <!--  Add Button    -->
        <project-add-idea v-if="!expired" :id="data.id" @then="get">
          <template v-slot:button >
            <v-btn fab elevation="0" class="grey--text" color="secondary" :title="__('AddIdeaProject')">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </project-add-idea>
      </div>

    </v-col>

    <v-col v-else v-for="(item, index) in items" v-bind:key="index" cols="6" sm="6" md="4">
      <idea v-if="index < 3" :data="item" image_height="210" in_modal />
    </v-col>
  </v-row>
</template>

<script>

import Idea from "@/components/ideas/Idea"
import NotFoundData from "../app/NotFoundData";
import {GetRequest} from "../../models/GetRequest";
import ProjectAddIdea from "./ProjectAddIdea";
import Space from "../app/Space";
import Loading from "../app/Loading";
import Loader from "../app/Loader";

export default {
  name: "ProjectLastIdeas",
  components: {Loader,  Space, ProjectAddIdea, NotFoundData, Idea},
  props: {
    data: {
      default: null,
      type: Object,
    },
    expired: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    hasItem() {
      return this.items && Array.isArray(this.items) && this.items.length !== 0
    },
    items() {
      return this.request.data;
    },
  },
  data() {
    return {
      request: new GetRequest('get_project_ideas', [this.data.id]),
    }
  },
  watch: {},
  mounted() {
    this.get()
  },
  methods: {
    get(){
      this.request.setFields('*.*');
      this.request.setSort('date_added_to_project');
      this.request.get();
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <v-list-item class="pointer" @click="click">
      <v-list-item-avatar size="64" tile class="rounded-lg">
        <assets-image
            :data="picture"
            :height="height"
            class="rounded-lg"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="pb-3 title">
          {{ data.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ __('By') }}
          <full-name v-if="data.user_created" :data="data.user_created"/>
          <span v-else>{{ __('Renotive') }}</span>
        </v-list-item-subtitle>

      </v-list-item-content>

    </v-list-item>


    <modal v-if="in_modal" v-model="modal" fullscreen>
      <template #title>
        {{ __('Blog') }}
      </template>
      <template #main>
        <blog-view :id="data.id"/>
      </template>
    </modal>
  </div>
</template>

<script>

import AssetsImage from "../assets/Image";
import FullName from "../account/FullName";
import Modal from "../modal/Modal";
import BlogView from "./BlogView";

export default {
  name: "BlogItem",
  components: {BlogView, Modal, FullName, AssetsImage,},
  props: {
    data: {
      default: null,
      type: Object
    },
    height: {
      default: '90',
      type: [String, Number]
    },
    vertical: {
      default: false,
      type: Boolean
    },
    in_modal: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    picture() {
      try {
        return this.data.pictures[0].directus_files_id;
      } catch (e) {
        return null;
      }
    },
    link() {
      return {name: 'BlogView', params: {id: this.data.id}}
    }
  },
  data: () => {
    return {
      modal: null,
    }
  },
  mounted() {

  },
  methods: {
    click() {
      try {
        if (this.in_modal) return this.modal = true;
        return this.$router.push(this.link)
      } catch (e) {
        return '';
      }
    },
  },
}
</script>

<style scoped>

</style>
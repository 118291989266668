<template>
  <div>
    <p class="px-1 px-md-0 font-size-14">{{ __('ProfileEditBudgetText') }}</p>
    <div style="max-width: 510px" class="px-2 px-md-0 mx-auto pt-4 pb-7">
      <v-row>
        <v-col cols="12" sm="6" class="pa-0">
          <v-checkbox
              :label="__('LowBudget')"
              class="font-size-14 text--black"
              v-model="request.body.low_budget"
              dense
          />
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-checkbox
              :label="__('LowToMediumBudget')"
              class="font-size-14 text--black"
              v-model="request.body.low_to_medium_budget"
              dense
          />
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-checkbox
              :label="__('MediumToHighBudget')"
              class="font-size-14 text--black"
              v-model="request.body.medium_to_high_budget"
              dense
          />
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-checkbox
              :label="__('UnlimitedBudget')"
              class="font-size-14 text--black"
              v-model="request.body.unlimited_budget"
              dense
          />
        </v-col>
      </v-row>
    </div>

    <space class="hidden-sm-and-down" height="45"/>
    <space class="hidden-md-and-up" height="15"/>

    <!--  Min and Max Cost         -->
    <p class="pb-0 mb-0 pb-md-3 grey--text  font-size-14" >{{ __('ProfileEditBudgetTip') }}</p>
    <div style="max-width: 800px" class="mx-auto pt-5">
      <v-row>
        <v-col cols="12" sm="5">
          <div class="d-flex">
            <div class="mr-3 d-inline-block font-size-14 mt-2" style="width: 60%">
              {{ __('TypicalJobCostRange') }} :
            </div>
            <currency-selector v-model="request.body.currency" style="width: 40%" hide_title/>
          </div>
        </v-col>

        <v-col cols="12" sm="7"   >
          <div class="d-flex font-size-14 justify-space-between">
            <v-text-field
                v-if="_is_object(request.body.typical_job_cost)"
                v-model="request.body.typical_job_cost.min"
                :placeholder="__('MinCost')"
                class="custom-selector-border rounded-lg"
                outlined
                dense
            />
            <div class="font-weight-bold px-3 pt-2">-</div>
            <v-text-field
                v-if="_is_object(request.body.typical_job_cost)"
                v-model="request.body.typical_job_cost.max"
                :placeholder="__('MaxCost')"
                class="custom-selector-border rounded-lg"
                outlined
                dense
            />
          </div>
        </v-col>
      </v-row>
    </div>

    <v-card
        class="rounded-lg transparent mt-3 mt-md-0 pa-1 pa-md-3"
        style="border: 1px solid rgba(33,33,33,0.32) !important;"
        outlined
    >
      <div class="text-center">
        <v-btn class="text-transform-unset" @click="show_services = !show_services" small text>
                      <span class="font-weight-bold font-size-14 letter-normal">{{
                          __('ServicesProvided')
                        }}&nbsp;</span>
          <v-icon v-if="show_services" small>mdi-chevron-up</v-icon>
          <v-icon v-else small>mdi-chevron-down</v-icon>
        </v-btn>
      </div>
      <div v-show="show_services" class="mx-auto px-3 pb-3 pt-6" style="max-width: 800px">
        <job-expertises-selector
            v-if="_is_object(request.body.pro_id)"
            v-model="request.body.pro_id.expertises"
            multiple
            hide_under_line
            add_expertise_id
            hide_title
        />
      </div>
    </v-card>
  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import {PostRequest} from "@/models/PostRequest";
import FromListSelector from "@/components/selectors/FromListSelector";
import CurrencySelector from "@/components/selectors/CurrencySelector";
import ExpertisesSectionLists from "@/components/expertises/ExpertisesSectionLists";
import ExpertiseSelector from "@/components/selectors/ExpertiseSelector";
import JobExpertisesSelector from "@/components/selectors/JobExpertisesSelector";
import Space from "@/components/app/Space";
import ShowMore from "@/components/app/ShowMore";

export default {
  name: "ProfileFromInfoServices",
  components: {
    ShowMore,
    Space,
    JobExpertisesSelector,
    ExpertiseSelector, ExpertisesSectionLists, CurrencySelector, FromListSelector, AssetsImage
  },
  props: {
    request: {
      default: null,
      type: PostRequest,
    },
  },
  data() {
    return {
      show_services: false,
    }
  },
  methods: {}

}
</script>

<template>
  <v-container>
    <v-row justify="center" style="margin-top: 90px">
      <v-col sm="4">
        <complete-phone/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompletePhone from "../../components/auth/CompletePhone";

export default {
  name: "CompletePhonePage",
  components: {CompletePhone},

}
</script>

<style scoped>

</style>
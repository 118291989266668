<template>
  <v-row v-if="data">
    <v-col  v-for="(item, key) in data" cols="12" sm="12" md="4" class="pa-0">
      <expertises-section-tab
          :data="item"
          :selected="checkSelected(item, key)"
          @select="select(item, key)"
      >
        <slot/>
      </expertises-section-tab>
    </v-col>
    <v-col class="hidden-sm-and-down py-0" cols="12">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
import ExpertisesSectionTab from "@/components/expertises/ExpertisesSectionTab";

export default {
  name: "ExpertisesSectionTabs",
  components: {ExpertisesSectionTab},
  props: {
    data: {
      default: null,
      type: Array,
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    select(item, key) {
      this.selected = item;
      this.$emit('select', item)
      this.$emit('select-key', key)
    },
    checkSelected(item, index) {
      try {
        if (index === 0 && this.selected === null) return true;
        return this.selected.id === item.id;
      } catch (e) {
        return false;
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div v-if="data && data.id">

    <!--  Card Item  -->
    <v-card flat v-if="card" class="text-center rounded-lg py-9 px-3" outlined @click="open()">
      <div>
        <v-avatar color="secondary" size="64">
          <assets-image v-if="data.avatar" :data="data.avatar" width="30"/>
          <v-icon v-else :data="data.avatar">mdi-account</v-icon>
        </v-avatar>
      </div>
      <div class="pointer mt-6 mb-2 text-capitalize text-truncate" :title="data.first_name+ ' '+data.last_name">
        {{ data.first_name }}
        {{ data.last_name }}
      </div>
    </v-card>

    <!-- List Item   -->
    <v-list-item v-else two-line :value="data.id">
      <v-list-item-avatar size="60">
        <v-avatar color="secondary" size="50">
          <assets-image v-if="data.avatar" :data="data.avatar" width="30"/>
          <v-icon v-else :data="data.avatar">mdi-account</v-icon>
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title class="pointer mb-2" @click="open()">
          {{ data.first_name }}
          {{ data.last_name }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="Array.isArray(data.expertises) && data.expertises.length !== 0">
          <span v-for="(item, key) in data.expertises" :key="item.id">
            <span v-if="item.expertise_id && item.expertise_id.title">
              {{ item.expertise_id.title }}
              <span v-if="key !== data.expertises.length -1">,</span>
            </span>
          </span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <div class="d-flex align-center">
          <slot name="actions"></slot>
          <div v-if="show_buttons">

            <v-btn
                :disabled="!data.telegram_id"
                icon
                :href="'https://t.me/' + data.telegram_id"
                target="_blank"
                :title="__('Telegram')"
            >
              <v-icon>mdi-chat</v-icon>
            </v-btn>

            <v-btn :disabled="!data.email" icon :href="'mailto:' + data.email" target="_blank" :title="__('Email')">
              <v-icon>mdi-email</v-icon>
            </v-btn>

            <v-btn
                :disabled="!data.phone_number"
                icon
                :href="'tel:' + data.phone_number"
                target="_blank"
                :title="__('Call')"
            >
              <v-icon>mdi-phone</v-icon>
            </v-btn>

          </div>

          <user-menu :data="data" hide_archive hide_edit/>
        </div>
      </v-list-item-action>
    </v-list-item>


    <!-- More Info   -->
    <modal max_width="390" v-model="dialog">

      <template v-slot:title>
        <span>{{ __("Professional") }}</span>
      </template>

      <template v-slot:main>
        <div class="px-3 pb-3">

          <div class="d-flex align-center justify-space-between pt-3 pb-2">
            <v-avatar color="secondary" size="50">
              <assets-image v-if="data.avatar" :data="data.avatar" width="30"/>
              <v-icon v-else :data="data.avatar">mdi-account</v-icon>
            </v-avatar>
            <space/>
            <div class="font-weight-bold">
              {{ data.first_name }}
              {{ data.last_name }}
            </div>
            <v-spacer/>
            <user-menu :data="data" hide_archive hide_edit/>
            <v-btn icon @click="dialog = false" class="grey lighten-4">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>

          <v-list-item v-if="data.phone_number" :href="'tel:'+data.phone_number" class="pa-0" :title="__('PhoneNumber')" >
            <v-list-item-avatar>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              {{ data.phone_number ? data.phone_number : __('None') }}
            </v-list-item-title>
          </v-list-item>

          <v-divider v-if="data.email && data.phone_number" class="grey lighten-3"/>

          <v-list-item v-if="data.email" :href="'mailto:'+data.email" class="pa-0" :title="__('Email')" target="_blank">
            <v-list-item-avatar>
              <v-icon>mdi-email</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              {{ data.email }}
            </v-list-item-title>
          </v-list-item>

          <v-divider v-if="data.telegram_id" class="grey lighten-3"/>

          <v-list-item v-if="data.telegram_id" :href="'https://t.me/' + data.telegram_id" class="pa-0" :title="__('TelegramId')" target="_blank">
            <v-list-item-avatar>
              <v-icon>mdi-send</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              @{{ data.telegram_id }}
            </v-list-item-title>
          </v-list-item>

          <v-divider v-if="has_expertises" class="grey lighten-3"/>

          <v-list-item v-if="has_expertises" class="pa-0" :title="__('Expertises')">
            <v-list-item-avatar>
              <v-icon>mdi-hammer-screwdriver</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                <span v-for="(item, key) in data.expertises" :key="item.id">
                  <span v-if="item.expertise_id && item.expertise_id.title">
                    {{ item.expertise_id.title }}
                    <span v-if="key !== data.expertises.length -1">,</span>
                  </span>
                </span>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="data.number_of_projects" class="grey lighten-3"/>

          <v-list-item v-if="data.number_of_projects" class="pa-0" :title="__('NumberOfProjects')">
            <v-list-item-avatar>
              <v-icon>mdi-archive-cog</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              {{data.number_of_projects}} {{__('Projects')}}
            </v-list-item-content>
          </v-list-item>


          <v-divider v-if="portfolio_link()" class="grey lighten-3"/>

          <v-list-item v-if="portfolio_link()" :href="portfolio_link()" class="pa-0" :title="__('DownloadPortfolio')" target="_blank">
            <v-list-item-avatar>
              <v-icon>mdi-download</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              {{__('DownloadPortfolio')}}
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="!minimal" class="grey lighten-3"/>

          <v-list-item v-if="!minimal && !_access('client')" class="pa-0" :title="__('Status')">
            <v-list-item-avatar>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>
              {{ data.status }}
            </v-list-item-title>
          </v-list-item>

          <v-divider v-if="!minimal" class="grey lighten-3"/>

          <div v-if="!minimal">
            <div class="py-3" v-if="!request.isLoading()">
              <professional-note :data="getNoteData()"  >
                <template v-slot:actions>
                  <div v-if="_access('admin') && data.pro_registration_status === 'not_approved'">
                    <v-btn @click="approved" elevation="0" color="primary" :disabled="edit_request.isLoading()">
                      <span v-if="edit_request.isLoading()">...</span>
                      <span v-else>{{__('Approved')}}</span>
                    </v-btn>
                  </div>
                </template>
              </professional-note>
            </div>
            <div class="py-3" v-else>
              <loading margin="35px auto"/>
            </div>

          </div>

        </div>
      </template>

    </modal>
  </div>
</template>

<script>

import AssetsImage from "@/components/assets/Image";
import UserMenu from "../users/UserMenu";
import Modal from "../modal/Modal";
import Space from "../app/Space";
import urls from "../../tools/Urls";
import ProfessionalNote from "./ProfessionalNote";
import {GetRequest} from "../../models/GetRequest";
import Loading from "../app/Loading";
import {PostRequest} from "../../models/PostRequest";

export default {
  name: "ProfessionalListItem",
  components: {Loading, ProfessionalNote, Space, Modal, UserMenu, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    show_buttons: {
      default: false,
      type: Boolean
    },
    list: {
      default: true,
      type: Boolean
    },
    card: {
      default: false,
      type: Boolean
    },
    minimal: {
      default: false,
      type: Boolean
    },
  },
  computed:{
    has_expertises(){
      try{
        return Array.isArray(this.data.expertises) && 0 < this.data.expertises.length ;
      }catch (e){
        return false;
      }
    }
  },
  data() {
    return {
      dialog: null,
      request: new GetRequest('get_professional_info'),
      edit_request: new PostRequest('edit_professional'),
    }
  },
  mounted() {
    if (!this.data) return;
    this.request.setApiParams([this.data.id])
  },
  methods: {
    portfolio_link() {
      try {
        if ((this.data.portfolio !== null) && (this.data.portfolio.length > 0))
          return urls.api('assets_url', [this.data.portfolio]);
        else
          return ''
      } catch (e) {
        return '';
      }
    },
    open() {
      this.dialog = null
      this.dialog = true
      this.request.row();
    },
    getNoteData() {
      try {
        if (this.request.data) return this.request.data;
        return this.data;
      } catch (e) {
        return this.data;
      }
    },
    approved(){
      this.edit_request.setApiParams([this.data.id]);
      this.edit_request.setBody({pro_registration_status:'hired'});
      this.edit_request.setThen(() => this.$emit('then'));
      this.edit_request.patch();
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div v-if="item_id">
    <form @submit.prevent="request.post()" v-if="request.body">

      <div class="title mb-6">{{ __('AddTag') }}</div>

      <div>
        <div class="font-weight-bold">{{ __('Title') }}</div>
        <v-text-field
            v-model="request.body.title"
            dense
            outlined
            required
        />
      </div>

      <div>
        <div class="font-weight-bold">{{ __('Color') }}</div>
        <v-color-picker
            mode="hexa"
            @input="e => setColor(e)"
        />
      </div>

      <div class="my-3 d-flex">
        <v-btn class="elevation-0" color="primary" type="submit">{{ __('Save') }}</v-btn>
        <v-spacer/>
        <v-btn class="elevation-0 text--text" @click="$emit('cancel')">{{ __('Cancel') }}</v-btn>
      </div>
    </form>
  </div>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";

export default {
  name: "TagsAdd",
  components: {},
  props: {
    item_id: {
      default: null,
      type: String,
    },
    collection_name: {
      default: 'project',
      type: String,
    }
  },
  data() {
    return {
      request: new PostRequest('items_collection', ['personal_tag']),
    }
  },
  mounted() {
    this.request.setThen((e) => this.then(e));
    this.setBody();
  },
  methods: {
    setBody() {
      this.request.setBody(
          {
            item_id: this.item_id,
            collection_name: "project",
            title: null,
            color: '#000000FF',
          }
      );

    },
    then(response) {
      try{
        if (!response || !response.data || !response.data.id) throw 'not found response!';
        this.$emit('added', response.data);
        this.setBody();
      }catch (e){
        console.log(e)
      }

    },
    setColor(e) {
      try {
        this.request.body.color = e.hexa;
      } catch (e) {
        console.log(e)
      }
    },
  }
}
</script>
<template>
  <div>
    <v-card flat outlined class=" mb-3 rounded-lg">
      <div :class=" ['full-width', color ]" style="height: 10px;"></div>
      <div class="pa-4 px-6" style="min-height: 580px">
        <!--   Header   -->
        <div class="text-center">
          <v-img :src='image' class="mx-auto my-6" width="90" height="90"/>
          <div class="title font-weight-bold">{{ title }}</div>
          <div class="py-6">{{ text }}</div>
        </div>

        <!--  Packages Selectors    -->
        <div class="mt-3" style="min-height: 90px">
          <div v-if="types && Array.isArray(types)">
            <template v-for="(item, i) in types">
              <package-selector
                  :key="'type-'+item+i"
                  :type="[item]"
                  :title="__(item)"
                  :value="getValue(item)"
                  :default_item="1"
                  @input-object="select"
                  price
              />
            </template>
          </div>
        </div>


        <!--  Sum Price   -->
        <slot name='price' :price="price" :selected="selected">
          <div class="text-center">
            <div class="text-h4">
              <price :data="price" :default_price="__('Free')"/>
            </div>
            <div class="text--disabled mt-1" v-if="!types || !Array.isArray(types)">{{ __('30Days') }}</div>
            <div v-if="per_month" class="text--disabled pt-3"> {{ __('PerMonth') }}</div>
          </div>
        </slot>

      </div>

      <!--  Actions    -->
      <div class="pa-3 pb-9 text-center">
        <v-btn
            :color="color"
            class="rounded-lg"
            elevation="0"
            @click=" payment()"
            :disabled="(types && Object.values(selected).length === 0) || is_paying"
            large
        >
          <slot name="button-title" :types="types" :selected="selected" :is_paying="is_paying">
            <span v-if="!types || !Array.isArray(types)">{{ __('FreeTrial') }}</span>
            <span v-else>{{ __('Payment') }}</span>
            <span v-if="is_paying">...</span>
          </slot>

        </v-btn>

      </div>
    </v-card>
  </div>
</template>

<script>
import PackageSelector from "../selectors/PackageSelector";
import Price from "../app/Price";

export default {
  name: "PackagesBox",
  components: {Price, PackageSelector,},
  props: {
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: 'grey lighten-3'
    },
    image: {
      type: String,
      default: null
    },

    types: {
      type: Array,
      default: null
    },
    per_month: {
      type: Boolean,
      default: false
    },
    is_paying: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    price() {
      try {
        let result = 0;
        Object.values(this.selected).forEach((item) => {
          result += item.price;
        })
        return result;
      } catch (e) {
        console.log(e);
        return 0;
      }
    }
  },
  data() {
    return {
      selected: {},
    }
  },
  methods: {
    select(item) {
      this.$set(this.selected, item.type, item);
    },
    payment() {
      this.$emit('payment', Object.values(this.selected));
    },
    getValue(type) {
      try {
        return this.selected[type].id;
      } catch (e) {
        return null;
      }
    }
  }
}
</script>


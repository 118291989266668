<template>
  <span v-if="data">{{ name }}</span>
</template>

<script>

export default {
  name: "CityName",
  props: {
    data: {
      default: null,
      type: Object
    }
  },
  computed: {
    name() {
      try {
        return this.data.city_id.name
      } catch (e) {
        return null;
      }
    }
  },
}
</script>

import { render, staticRenderFns } from "./JobPro.vue?vue&type=template&id=2fc148e3&scoped=true&"
import script from "./JobPro.vue?vue&type=script&lang=js&"
export * from "./JobPro.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fc148e3",
  null
  
)

export default component.exports
<template>
  <div class="full-width">
    <inline-selector :value="getDefault()" :items="items()" @input="select" :disabled="request.isLoading()">

    </inline-selector>
  </div>
</template>

<script>
import InlineSelector from "../selectors/InlineSelector";
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "LeadProjectsStatus",
  components: {InlineSelector},
  props: {
    request: {
      default: new GetRequest(),
      type: GetRequest,
      required: true,
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    items() {
      return [
        {
          text: this.__('New'),
          value: 'new'
        },
        {
          text: this.__('Lead'),
          value: 'lead'
        },
        {
          text: this.__('Contacted'),
          value: 'contacted'
        },
        {
          text: this.__('MeetingScheduled'),
          value: 'meeting_scheduled'
        },
        {
          text: this.__('Won'),
          value: 'won'
        },
        {
          text: this.__('Lost'),
          value: 'lost'
        },
        {
          text: this.__('Expired'),
          value: 'expired'
        },
        {
          text: this.__('CustomPackage'),
          value: 'custom_package'
        }
      ];
    },
    select(value) {
      try {
        this.request.is_loading = false;
        this.request.addFilter('lead_status', value);
        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },
    getDefault() {
      try {
        return this.request.getFilter('lead_status');
      } catch (e) {
        return null;
      }
    },

  }
}
</script>

<style scoped>

</style>
import {GetRequest} from "./GetRequest";
import Vue from "vue";
import {PostRequest} from "./PostRequest";
import {_t} from "../tools/Utils";
import storage from "../tools/Storage";
import auth from "../tools/Auth";

/**
 * Start project questions model
 */
export class Questions {

    step = 1;
    max_steps = 10;
    selected_rooms = {};
    selected_styles = {};
    selected_answers = {};
    place = {city:null,};
    address = null;
    state = null;
    city = null;
    zip_code = null;
    desire_start_time = null;
    phone_number = null;
    budget = null;
    project = {};
    client = {
        id: null,
        first_name: null,
        last_name: null,
        email: null,
    };

    request = new GetRequest('get_quiz');
    calculate_request = new PostRequest('calculate_cost_range');
    create_request = new PostRequest('create_project');
    analyze_request = new PostRequest('analyze_styles');

    add_client_request = new PostRequest('add_project_client');

    sent_save_request = false;

    constructor() {

    }

    /**
     * Check step
     *
     * @param number
     * @returns {boolean}
     */
    isStep(number) {
        return Number(this.step) === Number(number);
    }

    /**
     * Is first project
     *
     * @param number
     * @returns {boolean}
     */
    isNotFirst() {
        try {
            return this.request.data.built_project_already;
        } catch (e) {
            return false;
        }
    }

    /**
     * Check is calculating cost range
     *
     * @returns {boolean}
     */
    isCalculating() {
        return this.calculate_request.isLoading();
    }

    /**
     * Check is calculating cost range
     *
     * @returns {boolean}
     */
    isAnalyzing() {
        return this.analyze_request.isLoading();
    }

    /**
     * Go next step
     *
     * @returns {boolean}
     */
    next(count = 1) {
        this.step = this.step + count;
        this.storage();
        window.scrollTo(0, 0)
    }

    /**
     * save to local
     */
    storage() {
        try {
            storage.set('questions_data', {
                step: this.step,
                selected_rooms: this.selected_rooms,
                selected_styles: this.selected_styles,
                selected_answers: this.selected_answers,
                address: this.address,
                state: this.state,
                city: this.city,
                zip_code: this.zip_code,
                desire_start_time: this.desire_start_time,
                budget: this.budget,
                project: this.project,
                client: this.client,
                request_data: this.request.data,
                calculate_request_data: this.calculate_request.data,
                create_request_data: this.create_request.data,
                analyze_request_data: this.analyze_request.data,
                add_client_request_data: this.add_client_request.data,
                phone_number: this.phone_number,
            })
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * clear local data
     */
    clear() {
        try {
            storage.remove('questions_data')
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * load form local storage
     */
    load() {
        try {
            let data = storage.get('questions_data');
            if (!data || typeof data !== 'object' || !data['step']) return;

            this.step = data['step'];
            if (!auth.check() &&  8 < this.step) {
                this.step = 1;
                this.clear();
                return;
            }
            this.selected_rooms = data['selected_rooms'];
            this.selected_styles = data['selected_styles'];
            this.selected_answers = data['selected_answers'];
            this.address = data['address'];
            this.state = data['state'];
            this.city = data['city'];
            this.zip_code = data['zip_code'];
            this.desire_start_time = data['desire_start_time'];
            this.budget = data['budget'];
            this.project = data['project'];
            this.client = data['client'];
            this.phone_number = data['phone_number'];

            this.request.data = data['request_data'];
            this.calculate_request.data = data['calculate_request_data'];
            this.create_request.data = data['create_request_data'];
            this.analyze_request.data = data['analyze_request_data'];
            this.add_client_request.data = data['add_client_request_data'];
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * Go next step
     *
     * @returns {boolean}
     */
    back() {
        if (this.step === 1) return;
        this.step--;
        window.scrollTo(0, 0)
    }

    /**
     * Send create project request
     *
     * @returns {boolean}
     */
    save(then = null) {
        if (this.sent_save_request) return;
        if (this.getProject('id')) return;

        this.create_request.setThen((data) => {
            this.setProject(data)
            if (then) then(data);
        });
        this.create_request.setFinally(() => {
            this.sent_save_request = true;
        });
        this.create_request.setMessage(_t('YourProjectCreated'));
        this.create_request.setBody(this._createBody())
        this.create_request.post();
    }

    /**
     * Get data by request from server
     *
     * @returns {boolean}
     */
    fetch(then = null) {
        this.request.setFields('id,title,icon,styles.id,styles.picture,styles.type,styles.room_id')
        this.request.setThen(then);
        this.request.row();
    }

    /**
     * Calculate request
     *
     * @returns {boolean}
     */
    calculate() {
        this.calculate_request.setThen((res) => this._calculateBudget(res))
        this.calculate_request.hideMessages()
        this.calculate_request.setBody(this._calculateBody());
        this.calculate_request.post();
    }

    /**
     * Analyze selected styles request
     *
     * @returns {boolean}
     */
    analyze() {
        this.analyze_request.hideMessages()
        this.analyze_request.setBody(this._analyzeBody());
        this.analyze_request.post();
    }

    /**
     * Add client
     *
     * @returns {null}
     */
    addClient(then = null) {
        this.add_client_request.setThen((res) => this._setClient(res, then))
        this.add_client_request.setBody(this._clientBody());
        this.add_client_request.post();
    }

    /**
     * Get request data
     *
     * @returns {*}
     */
    getData() {
        return this.request.data;
    }

    /**
     * Get request data
     *
     * @returns {*}
     */
    getAnalyzedData(key = null) {
        try {
            if (key) return this.analyze_request.data[key];
            return this.request.data;
        } catch (e) {
            return null;
        }
    }

    /**
     * Get request rooms form data
     *
     * @returns {*}
     */
    getRooms() {
        try {
            return this.request.data.rooms;
        } catch (e) {
            return null;
        }
    }

    /**
     * Get selected room styles
     *
     * @returns {*}
     */
    getStyles() {
        try {
            let result = [];
            for (const [room_id, room] of Object.entries(this.selected_rooms)) {
                if (!room || typeof room !== 'object' || !room.styles) continue;
                let styles = this._getRoomStyles(room.styles);
                Array.prototype.push.apply(result, styles);
            }
            return result;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
     * Get  Calendly Url
     *
     * @returns {*}
     */
    getCalendlyUrl() {
        try {
            return this.request.data.scheduling_link;
        } catch (e) {
            return null;
        }
    }

    /**
     * Set answer for a test
     *
     * @param test
     * @param answer
     * @returns {null}
     */
    selectAnswer(test, answer) {
        try {
            if (!test || !test.quiz) return null;
            answer['room_id'] = test.room_id;
            test['selected'] = answer;
            Vue.set(this.selected_answers, test.quiz, test)
        } catch (e) {
            return null;
        }
    }

    /**
     * Set address
     *
     * @returns {null}
     * @param value
     */
    setAddress(value) {
        this.address = value;
    }


    /**
     * Set state
     *
     * @returns {null}
     * @param value
     */
    setState(value) {
        this.state = value;
    }

    /**
     * Set city
     *
     * @returns {null}
     * @param value
     */
    setCity(value) {
        this.city = value;
    }

    /**
     * Set created project data
     *
     * @param response
     */
    setProject(response) {
        try {
            if (!response || !response.data || !response.data.id) throw 'Project not found';
            this.project = response.data;
        } catch (e) {
            this.project = {};
        }
    }

    /**
     * Set Zip Code
     *
     * @returns {null}
     * @param value
     */
    setZipCode(value) {
        this.zip_code = value;
    }

    /**
     * Check complete all address fields
     *
     * @returns {null}
     */
    checkAddress() {
        try {
            if (!this.address) throw 'address validate error!';
            if (!this.zip_code) throw 'zip_code validate error!';
            if (!this.desire_start_time) throw 'desire_start_time validate error!';
            if (!this.city) throw 'city validate error!';
            if (!this.state) throw 'state validate error!';
            if (!this.phone_number) throw 'phone number validate error!';
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Check complete user info
     *
     * @returns {null}
     */
    checkClient() {
        try {
            if (this.client.id) return true;
            if (!this.client.email) throw 'zip_code validate error!';
            if (!this.client.first_name) throw 'desire_start_time validate error!';
            if (!this.client.last_name) throw 'city validate error!';
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Get styles pictures
     *
     * @returns {*}
     */
    getStylesPictures() {
        try {
            let result = [];

            let styles = this.getStyles();
            styles.forEach((item) => {
                if (!item || typeof item !== 'object' || !item.picture) return;
                result.push(item.picture);
            });

            return result;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
     * Get project data
     *
     * @param key
     */
    getProject(key = null) {
        try {
            if (key) return this.project[key];
            return this.project;
        } catch (e) {
            return null;
        }
    }

    /**
     * Get tests items
     *
     * @returns {*}
     */
    getTests() {
        try {
            let tests = this.getData().quiz_questions;
            let result = [];
            tests.forEach((item) => {
                if (!item || typeof item !== 'object') return;

                let room_id = item.room_id;
                if (!room_id) return result.push(item);

                if (!this.isSelectedRoom(room_id)) return;
                result.push(item);
            })
            return result;
        } catch (e) {
            return null;
        }
    }

    /**
     * Select room
     *
     * @returns {*}
     */

    selectRoom(item) {
        try {
            if (!item || !item.id) return;
            if (this.isSelectedRoom(item)) return this.removeRoom(item)
            Vue.set(this.selected_rooms, item.id, item)
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
     * unselect Room
     *
     * @returns {*}
     */
    removeRoom(item) {
        try {
            if (!item || !item.id) return;
            Vue.delete(this.selected_rooms, item.id);
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
     * Check selected room
     *
     * @returns {*}
     */
    isSelectedRoom(item) {
        try {
            if (typeof item === 'object') return this.selected_rooms[item.id] !== undefined;
            return this.selected_rooms[item] !== undefined;
        } catch (e) {
            return false;
        }
    }

    /**
     * Check has tests
     *
     * @returns {*}
     */
    hasTests() {
        try {
            return this.getTests().length !== 0;
        } catch (e) {
            return false;
        }
    }

    /**
     * Check has tests
     *
     * @returns {*}
     */
    hasSelectedRooms() {
        try {
            return Object.keys(this.selected_rooms).length !== 0;
        } catch (e) {
            return false;
        }
    }

    /**
     * Check has styles
     *
     * @returns {*}
     */
    hasSelectedStyles() {
        try {
            return 3 <= Object.keys(this.selected_styles).length;
        } catch (e) {
            return false;
        }
    }

    /**
     * Get min budget
     *
     * @returns {*}
     */
    getMinBudget() {
        try {
            return Number.parseInt(this.calculate_request.data.min);
        } catch (e) {
            return null;
        }
    }

    /**
     * Get max budget
     *
     * @returns {*}
     */
    getMaxBudget() {
        try {
            return Number.parseInt(this.calculate_request.data.max);
        } catch (e) {
            return null;
        }
    }

    /**
     * Get Calculate request body data
     *
     * @returns {boolean}
     */
    _calculateBody() {
        return {
            "city_id": this.city,
            "state_id": this.state,
            "zip_code": this.zip_code,
            "address": this.address,
            "desire_start_time": this.desire_start_time,
            "quiz_answers": this._quizAnswers(),
        }
    }

    /**
     * Get add client body
     *
     * @returns {boolean}
     */
    _clientBody() {
        let body = this.client;
        delete body.id;
        return body;
    }

    /**
     * Get analyze request body data
     *
     * @returns {boolean}
     */
    _analyzeBody() {
        return {
            "selected_styles": Object.keys(this.selected_styles),
        }
    }

    /**
     * Get Create project request body data
     *
     * @returns {{desire_budget: null, rooms: {}, quiz_answers: *[], address: null, state_id: null, zip_code: null, city_id: null}}
     */
    _createBody() {
        let result = {
            "phone_number": this.phone_number,
            "city_id": this.city,
            "state_id": this.state,
            "zip_code": this.zip_code,
            "address": this.address,
            "desire_start_time": this.desire_start_time,
            "desire_budget": this.budget,
            "rooms": this._selectedStylesBody(),
            "quiz_answers": this._quizAnswers(true),
        }

        if (this.client.id) result['client_id'] = this.client.id;

        return result;
    }

    /**
     * Get rooms styles selected for create project request
     *
     * @returns {{}}
     * @private
     */
    _selectedStylesBody() {
        try {
            let rooms_object = {};
            let result = [];
            if (!this.selected_rooms) return null;

            // create standard structr for rooms
            Object.keys(this.selected_styles).forEach((style_id) => {
                let item = this.selected_styles[style_id];
                if (!item || typeof item !== "object" || !item.room_id) return;

                if (!rooms_object[item.room_id]) rooms_object[item.room_id] = [];
                rooms_object[item.room_id].push({style_id: style_id})
            });

            // convert to body structure
            Object.keys(this.selected_rooms).forEach((room_id) => {
                let item = rooms_object[room_id];
                result.push({room_id: room_id, styles_selected: item})
            });

            return result;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
     * Get tests answers in quiz answers structure
     *
     * @returns {*[]}
     */
    _quizAnswers(full_item = false) {
        try {
            let result = [];
            if (!this.selected_answers) return null;

            Object.keys(this.selected_answers).forEach((key) => {
                let item = this.selected_answers[key];
                if (!item || typeof item !== "object" || !item.selected) return;
                if (!item.selected.option) return;

                let answer = item.selected;
                if (!full_item) answer = item.selected.option;

                result.push({quiz: key, answer: answer, item: item})
            });

            return result;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
     * Get styles
     *
     * @param styles
     * @returns {*[]|*}
     * @private
     */
    _getRoomStyles(styles) {
        try {
            if (!Array.isArray(styles) || styles.length === 0) return [];
            return styles;
        } catch (e) {
            return [];
        }
    }

    /**
     * Calculate default budget
     *
     * @private
     */
    _calculateBudget() {
        try {
            let sum = this.getMaxBudget() + this.getMinBudget();
            this.budget = Number.parseInt((sum / 2));
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Calculate default budget
     *
     * @private
     */
    _setClient(res, then = null) {
        try {
            this.client.id = this.add_client_request.data.id;
            if (then) then();
        } catch (e) {
            console.log(e);
        }
    }

}
<template>

  <v-row>
    <v-col cols="12">
      <!--   Steps   -->
      <div class="secondary">
        <v-container>
          <div class="text--text text--lighten-3 d-flex justify-space-between py-3">
            <div v-for="(item, key) in items()" :key="'questions-steps-'+key" :class="{'hidden-xs-only text--disabled': disabled(item)}">
              <div>{{ item.title }}</div>
            </div>
          </div>
        </v-container>
      </div>

      <!--   Buttons   -->

      <v-container>
        <v-row>
          <div class="d-flex flex-wrap mt-3">
            <v-btn icon @click="questions.back()" :disabled="questions.step === 1" :title="__('Back')">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn text color="grey">{{ questions.step }} / {{ questions.max_steps }}</v-btn>
          </div>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import {Questions} from "../../models/Questions";

export default {
  name: "QuestionsSteps",
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    items() {
      return [
        {
          title: 'STYLE TEST',
          step: 1,
          max_step: 3,
        },
        {
          title: 'TEST RESULT',
          step: 4,
          max_step: 4,
        },
        {
          title: 'CURRENT STATUS',
          step: 5,
          max_step: 6,
        },
        {
          title: 'YOUR NEEDS',
          step: 7,
          max_step: 8,
        },
        {
          title: 'HOME SWEET HOME',
          step: 9,
          max_step: 10,
        },
      ]
    },
    disabled(item){
      try{
        if (item.step <= this.questions.step && this.questions.step <= item.max_step) return false;
        return true;
      }catch (e){
        return true;
      }
    }
  },

}
</script>

<style scoped>

</style>
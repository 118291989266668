<template>
  <div v-if="_is_object(data)">

    <!--   Creator     -->
    <v-list-item v-if="!hide_user" class="pl-0 grow" >
      <v-list-item-icon>
        <avatar :data="data.add_by" color="grey lighten-3"/>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>
          <full-name :data="data.add_by"/>
        </v-list-item-title>
      </v-list-item-content>

      <!--    Actions     -->
      <v-list-item-action>
        <div class="d-flex">
          <idea-save v-if="_user && !bottom" :id="data.id" :button_title="button_title"/>
        </div>
      </v-list-item-action>

    </v-list-item>

    <!--   Chips   -->
    <div class="d-flex flex-wrap px-0 py-6">
      <v-chip class="my-2 mr-2" outlined>
        {{ __('Room') }} : {{ room }}
      </v-chip>

      <v-chip v-if="!hide_style" class="my-2 mr-2" outlined>
        {{ __('Style') }} : {{ style }}
      </v-chip>

      <v-chip v-if="!hide_size" class="my-2 mr-2" outlined>
        {{ __('Size') }} : {{ size }}
      </v-chip>

      <v-chip class="my-2 mr-2" outlined>
        {{ __('Color') }} :&nbsp;&nbsp;
        <v-avatar class="border-light" :color="color"></v-avatar>
      </v-chip>

      <v-chip v-if="data.photography_credit" class="my-2 mr-2" outlined>
        {{ __('PhotographyCredit') }} :&nbsp;&nbsp;
        <div>{{data.photography_credit}}</div>
      </v-chip>

      <v-chip v-if="!hide_budget" class="my-2 mr-2" outlined>
        {{ __('Budget') }} : &nbsp; {{ budget }}
      </v-chip>
    </div>
    <div v-if="data.online_link">
      {{ __('CopyRight') }}
      <div>
        <a :href="data.online_link" target="_blank">{{ data.online_link }}</a>
      </div>
    </div>

    <div class="text-center">
      <idea-save v-if="_user && bottom" :id="data.id" :button_title="button_title"/>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/account/Avatar";
import FullName from "@/components/account/FullName";
import IdeaSave from "@/components/ideas/IdeaSave";
import Space from "@/components/app/Space";
export default {
  name: "IdeaInfo",
  components: {Space, IdeaSave, FullName, Avatar},
  props: {
    data: {
      default: null,
      type: Object,
    },
    button_title: {
      default: null,
      type: String,
    },
    hide_user: {
      default: false,
      type: Boolean,
    },
    bottom: {
      default: false,
      type: Boolean,
    },
    hide_budget: {
      default: false,
      type: Boolean,
    },
    hide_size: {
      default: false,
      type: Boolean,
    },
    hide_style: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    color() {
      try {
        return this.data.color_id.value;
      } catch (e) {
        return null;
      }
    },
    room() {
      try {
        let result = this.data.room_id.title;
        return result ? result : '-';
      } catch (e) {
        return '-';
      }
    },
    budget() {
      try {
        let result = this.data.budget_id.name;
        return result ? result : '-';
      } catch (e) {
        return '-';
      }
    },
    size() {
      try {
        let result = this.data.size_id.name;
        return result ? result : '-';
      } catch (e) {
        return '-';
      }
    },
    style() {
      try {
        let result = this.data.style_id.name;
        return result ? result : '-';
      } catch (e) {
        return '-';
      }
    }
  },

}
</script>

<style scoped>

</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.archive_request )?_c('div',[_c(VMenu,{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({style:({'background':_vm.hover? 'rgba(0,0,0,0.08)': ''}),attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"color":_vm.color}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3490183894)},[_c(VList,[(_vm.is_owner)?_c(VListItem,{on:{"click":function($event){_vm.edit_dialog = true}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Edit")))])],1):_vm._e(),(_vm.is_owner)?_c(VListItem,{on:{"click":function($event){_vm.archive_dialog = true}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Delete")))])],1):_vm._e(),(_vm.convertable)?_c(VListItem,{on:{"click":_vm.copy}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Copy")))])],1):_vm._e(),(_vm.$route.name !== 'IdeaDetails' && _vm.data)?_c(VListItem,{attrs:{"to":{name:'IdeaDetails', params: { id: _vm.data.id }}}},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__("Details")))])],1):_vm._e()],1)],1),_c('modal',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.__("EditIdea")))])]},proxy:true},{key:"main",fn:function(){return [_c('ideas-write',{attrs:{"data":_vm.data},on:{"then":()=>_vm.$emit('edited', null)}})]},proxy:true}],null,false,1803988439),model:{value:(_vm.edit_dialog),callback:function ($$v) {_vm.edit_dialog=$$v},expression:"edit_dialog"}}),_c('confirm',{on:{"accepted":_vm.archive},model:{value:(_vm.archive_dialog),callback:function ($$v) {_vm.archive_dialog=$$v},expression:"archive_dialog"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <!--  Manage Expertise List  -->
  <div class="mt-2">
    <v-scroll-y-transition>
      <!-- List -->
      <div v-if="request.data" class="mt-4">

        <!--  Items and Tabs    -->
        <template v-if="request && request.data">
          <expertises-section-tabs
              :data="request.data"
              @select="e => this.selected_tab = e"
              @select-key="e => this.selected_tab_index = e"
          >
            <div :class="main_classes">
              <expertises-horizontal :data="selected_tab_items" @select="e => select({item: e, group: selected_tab})"/>
              <expertises-section-lists class="hidden-sm-and-down" :data="request.data" @select="select"/>
            </div>
          </expertises-section-tabs>

          <expertises-section-lists class="hidden-md-and-up" :data="request.data" @select="select"/>
        </template>

        <!--   Not found data   -->
        <not-found-data v-if="!request.data" style="padding-top: 90px !important;"/>

        <!-- Load More -->
        <load-more :request="request" pagination/>
      </div>
    </v-scroll-y-transition>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import ExpertiseList from "@/components/expertises/ExpertiseList";
import Space from "@/components/app/Space";
import NotFoundData from "@/components/app/NotFoundData";
import Loading from "../app/Loading";
import LoadMore from "../app/LoadMore";
import ExpertisesSectionTabs from "@/components/expertises/ExpertisesSectionTabs";
import ExpertisesHorizontal from "@/components/expertises/ExpertisesHorizontal";
import ExpertisesSectionLists from "@/components/expertises/ExpertisesSectionLists";
import Loader from "@/components/app/Loader";

export default {
  name: "ExpertisesSection",
  components: {
    Loader,
    ExpertisesSectionTabs,
    ExpertisesSectionLists,
    ExpertisesHorizontal, LoadMore, Loading, NotFoundData, Space, ExpertiseList,
  },
  props: {},
  data() {
    return {
      request: new GetRequest('get_job_type'),
      custom_dialog: false,
      selected_tab: null,
      selected_tab_index: 0,
    }
  },
  computed: {
    main_classes() {
      try {
        let result = ["expertises-section-main pa-4 py-5 rounded-b-lg"];
        const data_length = (this.request.data.length - 1);

        if (this.selected_tab_index === 0) result.push('rounded-tr-lg');
        else if (this._is_array(this.request.data) && this.selected_tab_index === data_length) result.push('rounded-tl-lg');
        else result.push('rounded-tl-lg');

        return result;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    selected_tab_items() {
      try {
        return this.selected_tab.expertises;
      } catch (e) {
        return null;
      }
    }
  },
  mounted() {
    this.request.setThen(() => this.setDefaults())
    this.request.setFields('*,expertises.*');
    this.request.setSort('title')
    this.request.get();
  },
  methods: {
    setDefaults() {
      try {
        this.selected_tab = this.request.data[0];
      } catch (e) {
      }
    },
    select({item, group}) {
      this.$emit('select', item);
      this.$emit('select-group', group);
    }
  },
}
</script>

<style>

.expertises-section-main {
  background: #eeeeee ;
}
</style>
import {Parameters} from "./Parameters";
import api from "../tools/Api";
import urls from "../tools/Urls";
import {Filters} from "./Filters";
import app from "../tools/App";
import {CustomFilters} from "@/models/CustomFilters";

export class GetRequest {

    api_key = null;
    api_params = null;
    search_keyword = null;
    is_array = false;
    data;
    total;
    filter = new Filters();
    custom_filters = new CustomFilters();
    page;
    is_loading;
    params = new Parameters();
    then = null;
    map = null;

    constructor(api_key, api_params) {
        this.api_key = api_key;
        this.api_params = api_params;
        this.reset();
    }

    /**
     * Send get request and get a list form server
     */
    get() {
        this.is_array = true;
        this._send();
    }

    /**
     * Send get request and get a row from server
     */
    row() {
        this.is_array = false;
        this._send();
    }

    /**
     * Load more request
     */
    loadMore() {
        this.page++;
        this.get();
    }

    /**
     * Get Max Page
     */
    maxPage() {
        try {
            return Math.ceil(this.total / this.params.getLimit());
        } catch (e) {
            return 1;
        }
    }

    /**
     * Get Next Page
     */
    nextPage() {
        this.page++;
    }

    /**
     * Get Back Page
     */
    backPage() {
        this.page--;
        if (this.page < 1) this.page = 1;
    }

    /**
     * Set filter param
     *
     * @param filter
     */
    setFilter(filter) {
        this.filter = filter;
    }

    /**
     * Set  Parameters param
     *
     * @param value
     */
    setParameters(value) {
        if (!(value instanceof Parameters)) return false;
        this.params = value;
    }

    /**
     * Set api
     *
     * @param key
     */
    setApi(key) {
        this.api_key = key;
    }

    /**
     * Set filter item param
     *
     * @param key
     * @param value
     * @param operator
     */
    addFilter(key, value, operator = '_eq') {
        if (!value) return this.removeFilter(key)
        this.filter.set(key, value, operator);
    }

    /**
     * Push filter item param
     *
     * @param object
     */
    pushFilter(object) {
        this.filter.push(object);
    }

    /**
     * Get filter item
     *
     * @param key
     * @param values
     */
    getFilter(key, values = true) {
        return this.filter.get(key, values);
    }

    /**
     * Get parameter item
     *
     * @param key
     */
    getParameter(key) {
        return this.params.get(key);
    }

    /**
     * delete a filter
     *
     * @param key
     */
    removeFilter(key) {
        return this.filter.delete(key);
    }

    /**
     * Get all filters
     *
     */
    allFilters() {
        return this.filter.getAll();
    }

    /**
     * clear filters
     */
    clearFilters() {
        return this.filter.clear();
    }

    /**
     * Check loading is true
     *
     * @returns {boolean}
     * @param check_data
     */
    isLoading(check_data = true) {
        try {
            let result = this.is_loading;
            if (check_data && Array.isArray(this.data)) result = result && this.data.length === 0;
            return result;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    /**
     * Check can load more for list of items
     *
     * @returns {boolean}
     */
    hasMore() {
        try {
            if (this.data === null || this.data.length === 0) return false;
            return this.data.length < this.total;
        } catch (e) {
            return false;
        }
    }


    /**
     * Check has more page for load
     *
     * @returns {boolean}
     */
    hasPage() {
        try {
            return Number.parseInt(this.page) < Number.parseInt(this.maxPage());
        } catch (e) {
            return false;
        }
    }

    /**
     * Search with get request
     *
     * @param keyword
     * @param fields
     * @param timer
     * @returns {boolean}
     */
    search(keyword, fields, timer = true) {
        try {
            keyword = keyword.trim();

            // clear search timer
            this._clearSearchTimer();

            // check keyword length
            if (keyword.length === 0) return this._startSearch('', fields);
            if (keyword.length < app.search_keyword_length) return false;

            // start without timer
            if (!timer) return this._startSearch(keyword, fields);

            // set timer for type char times
            this.search_timer = setTimeout(() => {
                this._startSearch(keyword, fields)
            }, 450)
        } catch (e) {
            return false;
        }
    }

    /**
     * Reset request params and data to default values
     */
    reset() {
        this.data = null;
        this.search_keyword = null;
        this.total = null;
        this.filter = new Filters();
        this.page = 1;
        this.is_loading = false;
        this.params = new Parameters();

    }

    /**
     * Clear data and get first page from server
     */
    reload(reset_page = true, rest_is_loading = false) {
        if (reset_page) this.page = 1;
        if (rest_is_loading) this.is_loading = false;
        this.data = null;
        this.total = null;
        if (!this.is_array) return this.row()
        this.get();
    }

    /**
     * Set fields param for request
     *
     * @param fields
     */
    setFields(fields) {
        this.params.setFields(fields)
    }

    /**
     * Set then method for send request
     *
     * @param then
     */
    setThen(then) {
        this.then = then;
    }

    /**
     * Set map method for data response
     *
     * @param map
     */
    setMap(map) {
        this.map = map;
    }

    /**
     * Set custom limit for request
     *
     * @param limit
     */
    setLimit(limit) {
        if (!limit) return;
        this.params.setLimit(limit);
    }

    /**
     * Set page
     *
     * @param page
     */
    setPage(page) {
        if (!page) return this.page = 1;
        this.page = page;
    }

    /**
     * Set api params
     *
     * @param array
     */
    setApiParams(array) {
        this.api_params = array;
    }

    /**
     * Set not in filter
     *
     * @param column
     * @param array
     */
    setNotIn(column, array) {
        if (!array || !Array.isArray(array) || array.length === 0) return;
        this.addFilter(column, array, '_nin');
    }

    /**
     * Set in filter
     *
     * @param column
     * @param array
     */
    setIn(column, array) {
        if (!array || !Array.isArray(array) || array.length === 0) return;
        this.addFilter(column, array, '_in');
    }


    /**
     * Set param for request
     *
     * @param key
     * @param value
     */
    setParam(key, value) {
        this.params.set(key, value)
    }

    /**
     * Hide error response
     */
    hideError() {
        this.setParam('hide_response_error', true);
    }

    /**
     * Set sort for request
     *
     * @param column
     * @param order
     */
    setSort(column, order = '-') {
        this.params.setSort(column, order)
    }

    /**
     * Group by a column
     *
     * @param column
     */
    setGroupBy(column) {
        this.params.setGroupBy(column);
    }

    /**
     * Set data from server response
     *
     * @param response
     */
    _setData(response) {
        try {

            // check data type is a list or is a row
            if (this.is_array) {
                if (!response || (!Array.isArray(response.data))) return;
                if (response.data.length === 0) return;
            }

            if (!this.is_array) {
                if (!response.data) return;
            }

            let data = response.data;

            // map data
            if (this.map) data = data.map(this.map)

            if (this.page === 1 || !this.data) {
                this.data = data;
                return;
            }

            Array.prototype.push.apply(this.data, data)
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Set total of items
     *
     * @param response
     */
    _setTotal(response) {
        try {
            if (!response || response.meta === undefined) return;
            if (response.meta.filter_count === undefined) return;
            this.total = response.meta.filter_count;
        } catch (e) {
            this.total = null;
        }
    }

    /**
     * Clear search timer
     *
     * @private
     */
    _clearSearchTimer() {
        try {
            clearTimeout(this.search_timer);
        } catch (e) {
            this.search_timer = null;
        }
    }

    /**
     * Start send search request
     *
     * @param keyword
     * @param fields
     * @returns {boolean}
     * @private
     */
    _startSearch(keyword, fields) {
        try {
            if (!fields) return false;

            if (typeof fields == 'string') {
                this.filter.setContain(fields, keyword);
            }
            /* if (Array.isArray(fields)) {
                 let  result = [];
                 fields.forEach((item) => {
                     let search_item = {};
                     search_item[item] = {"_contains":keyword};
                     result.push(search_item);
                 });
                 this.filter.push({'_and': [{"_or": result}]})
             }*/


            else if (typeof fields == 'object') {
                fields.forEach((item) => {
                    this.filter.setContain(item, keyword)
                })
            }

            this.is_loading = false;
            this.reload();
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Send get request
     */
    _send() {
        if (!this.api_key) return;
        if (this.is_loading) return;
        this.is_loading = true;

        this._setParamFilters();
        this.params.setPage(this.page);

        api.get(urls.api(this.api_key, this.api_params), this.params.getItems())
            .then((response) => {
                this._setData(response);
                this._setTotal(response);
                this._runThen(response);
            })
            .finally(() => {
                this.is_loading = false;
            })
    }

    _runThen(response) {
        try {
            if (!this.then) return;
            this.then(response);
        } catch (e) {
            console.log(e);
        }
    }

    _setParamFilters() {
        this.filter.push(this.custom_filters.getObject())
        this.params.setFilter(this.filter);
    }
}
<template>
  <div v-if="archive_request ">
    <v-menu offset-y left>

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" :style="{'background':hover? 'rgba(0,0,0,0.08)': ''}" icon :disabled="disabled">
          <v-icon :color="color">mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="archive_dialog = true">
          <v-list-item-title>{{ __("Delete") }}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="edit_dialog = !edit_dialog ">
          <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <confirm v-model='archive_dialog' @accepted="archive"/>

    <job-edit v-model='edit_dialog' :data="data" modal @updated="e => $emit('updated', e)"/>

  </div>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";
import Confirm from "../modal/Confirm";
import JobEdit from "./JobEdit";

export default {
  name: "JobMenu",
  components: {JobEdit, Confirm},
  props: {
    data: {
      default: null,
      type: Object
    },
    hover: {
      default: null,
      type: Boolean
    },
    color: {
      default: null,
      type: String
    },
    disabled: {
      default: false,
      type: Boolean,
    }
  },
  data: () => {
    return {
      archive_dialog: false,
      edit_dialog: false,
      archive_request: new PostRequest('update_job'),
    }
  },
  computed: {},
  mounted() {
    this.archive_request.setApiParams([this.data.id]);
  },
  methods: {
    archive() {
      try {
        this.archive_dialog = false;
        this.archive_request.setBody({'status': 'archived'})
        this.archive_request.setThen(() => this.$emit('archived', this.data))
        this.archive_request.patch();
      } catch (e) {
        console.log(e);
      }
    },


  },
}
</script>

<style scoped>

</style>
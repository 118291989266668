<template>
  <div class="hide-field-details" v-if="_is_object(data)" :key="'ideas-editor-item-' + number">
    <form @submit.prevent="submit">
      <v-row class="align-center pb-6 pt-7">

        <!--  Drag Icon    -->
        <v-col cols="12" sm="1" md="2">
          <div class="d-flex align-center text-center">
            <div>
              <v-icon>mdi-drag</v-icon>
            </div>
            <space class="hidden-sm-and-down" width="20"/>
            <div>
                <v-avatar color="primary" class="background--text" size="25" >
                  <span style="margin-top:3px;">{{ number }}</span>
                </v-avatar>
                <v-btn class="hidden-lg-and-up" icon size="25" @click="modal = !modal">
                  <v-icon>mdi-help-circle-outline</v-icon>
                </v-btn>

            </div>
          </div>
        </v-col>

        <!--  Image    -->
        <v-col cols="12" sm="3" md="2">
          <div >
            <upload-image
                :value="image"
                @input="setImage"
                width="120"
                height="120"
                add_directus_file_id
                upload_text_classes="pt-5"
                class="ml-md-2"
                show_done
            />
          </div>
        </v-col>

        <!--  Info    -->
        <v-col cols="12" sm="6" class="primary--text">
          <v-row align="center">
            <v-col cols="12" sm="7">
              <v-text-field
                  v-model="request.body.title"
                  :label="__('ImageTitle')"
                  dense
                  outlined
                  required
              />
            </v-col>
            <v-col cols="12" sm="5">
              <room-selector v-model="request.body.room_id" :first_label="__('Select') + ' '+ __('Room')" hide_title/>
            </v-col>
            <v-col cols="12" sm="7">
              <v-text-field
                  v-model="request.body.photography_credit"
                  :label="__('PhotographyCredit')"
                  dense
                  outlined
                  required
              />
            </v-col>
            <v-col cols="12" sm="5">
              <color-selector
                  :first_label="__('Select') + ' '+ __('Color')"
                  v-model="request.body.color_id"
                  hide_title
              />
            </v-col>
          </v-row>
        </v-col>

        <!--   Actions   -->
        <v-col cols="12" sm="2" class="align-center justify-end">
          <div class="d-flex">
            <v-btn
                class="rounded-lg"
                type="submit"
                color="primary"
                max-width="250"
                elevation="0"
                :disabled="request.isLoading()"
            >
              <v-icon small>mdi-pencil-outline</v-icon>&nbsp;
              <loading-dots :request="request" :text="__('Save')"/>
            </v-btn>
          </div>
        </v-col>
      </v-row>


      <modal v-model="modal">
        <template #main>
          <div class="pb-3">
            <v-list-item  >
              <v-list-item-title>
                {{__('UploadGuide')}}
              </v-list-item-title>
              <v-list-item-action>
                <v-btn icon @click="modal = !modal">
                  <v-icon >mdi-close</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <div class="px-3">
              <profile-project-form-guide/>
            </div>
          </div>
        </template>
      </modal>
    </form>
  </div>
</template>

<script>
import Space from "@/components/app/Space";
import AssetsImage from "@/components/assets/Image";
import UploadImage from "@/components/assets/UploadImage";
import ColorSelector from "@/components/selectors/ColorSelector";
import RoomSelector from "@/components/selectors/RoomSelector";
import {PostRequest} from "@/models/PostRequest";
import {getId} from "@/tools/Utils";
import LoadingDots from "@/components/app/LoadingDots";
import Modal from "@/components/modal/Modal";
import ProfileProjectFormGuide from "@/components/profiles/ProfileProjectFormGuide";

export default {
  name: "IdeasEditorForm",
  components: {ProfileProjectFormGuide, Modal, LoadingDots, RoomSelector, ColorSelector, UploadImage, AssetsImage, Space},
  props: {
    data: {
      default: null,
      type: Object,
    },
    project_id: {
      default: null,
      type: String,
    },
    number: {
      default: null,
      type: [String, Number],
    }
  },
  watch: {
    'data'() {
      this.set();
    },
  },
  data() {
    return {
      request: new PostRequest(),
      modal: false,
    }
  },
  computed:{
    id(){
      try {
        return this.data.id;
      } catch (e) {
        return null;
      }
    },
    image(){
      try {
        return this.request.body.pictures[0];
      } catch (e) {
        return null;
      }
    }
  },
  mounted() {
    this.set();
  },
  methods: {
    set() {
      if (this.id) this.setUpdateRequest();
      else this.setCreateRequest();
      this.setBody();
      this.setFields();
    },
    setCreateRequest() {
      this.request.body = {pro_profile_project_id: this.project_id};
      this.request.setApi('items')
      this.request.setApiParams(['idea'])
    },
    setUpdateRequest() {
      this.request.setApi('item')
      this.request.setApiParams(['idea', this.id])
    },
    submit(){
      if (!this.id) return this.create();
      this.update();
    },
    create(){
      this.request.post();
    },
    update(){
      this.request.patch();
    },
    setBody() {
      this.request.body = {
        ...this.request.body,
        title: this.data.title,
        color_id: getId(this.data.color_id),
        room_id: getId(this.data.room_id),
        photography_credit: this.data.photography_credit,
        is_pro_project_cover: this.is_cover,
        pictures: this.data.pictures,
      }
    },
    setImage(data){
      try {
        console.error(data);
        if (!this._is_array(this.request.body.pictures)) this.request.body.pictures = [];
        this.request.body.pictures[0] = data;
      } catch (e) {
        console.log(e);
      }
    },
    setFields(){
      this.request.setThen(this.then);
      this.request.setFields('*, color_id.*, room_id.*, pictures.directus_files_id')
    },
    then() {
      this.$emit('update', this.request.data);
    },
  }
}
</script>

<style scoped>

</style>
import Vue from "vue";

export class Filters {


    constructor(operation = 'and') {
        this.default_operation_items = {};
        this.default_operation = '_' + operation;
        this.object = {};
    }

    /**
     * This method set filter items with operations
     * example: {"room_id":{"_eq": "5c574889-e223-42f7-9b2e-e5ec181c4081"}}
     *
     * @param key
     * @param value
     * @param operator
     */
    set(key, value, operator = '_eq') {
        let result = {};
        if ((!Array.isArray(value) && typeof value === 'object') || operator == null) result = value;
        else result[operator] = value;
        Vue.set(this.default_operation_items, key, result)
    }

    /**
     * This method set filter items with contain operations
     * example: {"title":{"_contain": "NAME"}}
     *
     * @param key
     * @param value
     */
    setContain(key, value) {
        if (!value) return this.delete(key)
        let result = {};
        result['_contains'] = value;
        Vue.set(this.default_operation_items, key, result)
    }

    /**
     * This method push object filter items
     * example: {"_and": [{"title":{"_contain": "NAME"}}]}
     *
     * @param object object
     */
    push(object) {
        this.object = Object.assign(this.object, object);
    }

    /**
     * Clear all filters
     *
     */
    clear() {
        this.default_operation_items = {};
        this.object = {};
    }

    /**
     * Get items in json string
     *
     * @returns {string|null}
     */
    getJson() {
        try {
            let result = this.getAll();
            if (!result) return null;
            return JSON.stringify(result)
        } catch (e) {
            console.log(e)
            return null;
        }
    }

    /**
     * Get a key value
     *
     * @param key
     * @param values
     * @returns {null|any}
     */
    get(key, values = true) {
        try {
            let result = this.default_operation_items[key];
            if (!result) return null;
            if (!values) return result;
            result = Object.values(result);
            return result[0];
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
     * Delete a filter
     *
     * @param key
     */
    delete(key) {
        try {
            Vue.delete(this.default_operation_items, key)
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Get all filters
     *
     */
    getAll() {
        try {
            this._generate();
            if (Object.keys(this.object).length === 0) return null;
            return this.object;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    /**
     * Generate default operation
     */
    _generate() {
        try {
            let result = {};
            if (this.default_operation_items && typeof this.default_operation_items === 'object' && Object.keys(this.default_operation_items).length !== 0) {
                result[this.default_operation] = [this.default_operation_items];
            }

            this.push(result);
        } catch (e) {
            console.log(e);
        }
    }
}

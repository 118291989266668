<template>
  <div class="font-size-14 hide-field-details">
    <v-divider class="grey lighten-1 mx-3"/>

    <show-more open_default>
      <template #title>
        <div class="font-weight-bold primary--text">{{ __('EditSocialMedia') }}</div>
      </template>

      <template #more>


          <v-row class="py-5 px-3  ">
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="4">
                  <div class="pt-2">{{__('FacebookProfileLink')}} :</div>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="body.facebook.link"
                      class="custom-selector-border font-size-14"
                      @input="change"
                      type="url"
                      outlined
                      dense
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="4">
                  <div class="pt-2">{{__('LinkdinProfileLink')}} :</div>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="body.linkedin.link"
                      class="custom-selector-border font-size-14"
                      @input="change"
                      type="url"
                      outlined
                      dense
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="4">
                  <div class="pt-2">{{__('InstagramProfileLink')}} :</div>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="body.instagram.link"
                      class="custom-selector-border font-size-14"
                      @input="change"
                      type="url"
                      outlined
                      dense
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="4">
                  <div class="pt-2">{{__('TiktakProfileLink')}} :</div>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="body.tiktak.link"
                      class="custom-selector-border font-size-14"
                      @input="change"
                      type="url"
                      outlined
                      dense
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="4">
                  <div class="pt-2">{{__('TwitterProfileLink')}} :</div>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="body.twitter.link"
                      class="custom-selector-border font-size-14"
                      @input="change"
                      type="url"
                      outlined
                      dense
                  />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6">
              <v-row>
                <v-col cols="4">
                  <div class="pt-2">{{__('PinterestProfileLink')}} :</div>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                      v-model="body.pinterest.link"
                      class="custom-selector-border font-size-14"
                      @input="change"
                      type="url"
                      outlined
                      dense
                  />
                </v-col>
              </v-row>
            </v-col>

          </v-row>

      </template>

    </show-more>
  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import {PostRequest} from "@/models/PostRequest";
import FromListSelector from "@/components/selectors/FromListSelector";
import ShowMore from "@/components/app/ShowMore";

export default {
  name: "ProfileFromSocialMedia",
  components: {ShowMore, FromListSelector, AssetsImage},
  props: {
    request: {
      default: null,
      type: PostRequest,
    },
  },
  data() {
    return {
      body: {
        'facebook': {name: "facebook", link: ''},
        'linkedin': {name: "linkedin", link: ''},
        'instagram': {name: "instagram", link: ''},
        'twitter': {name: "twitter", link: ''},
        'tiktak': {name: "tiktak", link: ''},
        'pinterest': {name: "pinterest", link: ''},
      }
    }
  },
  mounted() {
    this.set()
  },
  methods: {
    set() {
      try {
        if (!this.request.body.social_media) return;
        this.request.body.social_media.forEach((item) => {
          this.body[item.name] = item;
        })
      } catch (e) {
        console.log(e);
      }
    },
    change() {
      try {
        this.request.body.social_media = Object.values(this.body);
      } catch (e) {
        console.log(e);
      }
    }
  }

}
</script>

<style >
</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm._access('client') && typeof _vm.data === 'object' && _vm.data.is_expired)?_c(VCard,{staticClass:"yellow overflow-hidden lighten-4 rounded-lg pb-3",attrs:{"flat":""}},[_c(VRow,{attrs:{"align":"center"}},[_c(VCol,{attrs:{"cols":"12","md":"8"}},[_c(VCardTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.__('YouNeedSubscription'))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.__('YouNeedSubscriptionText'))+" ")])],1),_c(VCol,{attrs:{"cols":"12","md":"4","align":"end"}},[_c('div',{staticClass:"pa-3"},[_c('unlock',{attrs:{"unique_key":"project-expired-unlock-package","project_id":_vm.data.id},on:{"then":function($event){return _vm.$emit('reload')}}},[_c(VBtn,{attrs:{"text":"","title":_vm.__('Unlock')}},[_c(VIcon,[_vm._v("mdi-lock-open-variant-outline")]),_c('span',[_vm._v(_vm._s(_vm.__('Unlock')))])],1)],1)],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
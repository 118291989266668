<template>
  <div>
    <v-menu v-if="data" offset-y left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="grey " icon light v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item :disabled="!data.telegram_id" :href="'https://t.me/' + data.telegram_id" link target="_blank">
          <v-list-item-title>{{ __("Telegram") }}</v-list-item-title>
        </v-list-item>
        <v-list-item :disabled="email_link.length < 1" :href="email_link" link>
          <v-list-item-title>{{ __("Email") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="data.phone_number" :href="tel" link>
          <v-list-item-title>{{ __("Call") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!hide_edit" :to="{name:'EditUser', params:{id: data.id}}" link>
          <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!hide_archive && data.status !== 'archive'" @click="archive_modal=true">
          <v-list-item-title>{{ __("Archive") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>


    <user-archive-modal :data="data" v-model="archive_modal" @then="thenArchive" api_key="archive_professional"/>

  </div>
</template>

<script>
import UserArchiveModal from "./UserArchiveModal";
export default {
  name: "UserMenu",
  components: {UserArchiveModal},
  props: {
    data: {
      default: null,
      type: Object
    },
    hide_archive: {
      default: false,
      type: Boolean
    },
    hide_edit: {
      default: false,
      type: Boolean
    },
  },

  computed: {
    email_link() {
      try {
        if ((this.data.email !== null) && (this.data.email.length > 0))
          return 'mailto:' + this.data.email;
        else
          return ''
      } catch (e) {
        return '';
      }
    },
    tel() {
      try {
        if ((this.data.phone_number !== null) && (this.data.phone_number.length > 0))
          return 'tel:' + this.data.phone_number;
        else
          return ''
      } catch (e) {
        return '';
      }
    }
  },
  data: () => {
    return {
      archive_modal: false
    }
  },
  methods: {
    thenArchive(e) {
      this.archive_modal = false;
      this.data = e;
      this.$emit('then', this.data)
    }
  }
}
</script>

<style scoped>

</style>
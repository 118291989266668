<template>
  <v-container  class="container-max-width">

    <h1 class="font-head mb-6 hidden-md-and-up font-size-18 font-weight-regular text-center" >
      {{ __('FindProfessionals') }}
    </h1>

    <profiles :filters_attrs="{'hide_status': true}" hide_expertises>
      <template #list-header>
        <breadcrumbs class="hidden-sm-and-down" :data="breadcrumbs_items"/>
      </template>
    </profiles>

  </v-container>
</template>

<script>
import Professionals from "../../../components/professionals/Professionals";
import Blogs from "../../../components/blogs/Blogs";
import Profiles from "@/components/profiles/Profiles";
import Breadcrumbs from "@/components/app/Breadcrumbs";

export default {
  name: "ProfessionalsPage",
  components: {Breadcrumbs, Profiles, Blogs, Professionals},
  computed: {
    breadcrumbs_items() {
      return [
        {
          text: this.__('Professionals'),
          disabled: true
        },
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>

</style>

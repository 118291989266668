<template>
  <span>
    <span v-if="!this._is_object(request) || !request.is_loading">{{ text }}</span>
    <span v-else>...</span>
  </span>
</template>

<script>

export default {
  name: "LoadingDots",
  props: {
    request: {
      default: null,
    },
    text: {
      type: String,
      default: null,
    }
  }
}
</script>

<template>
  <div v-if="_is_object(data)" class="d-flex align-center justify-center">
    <div @click="open">
      <assets-images class="hidden-sm-and-down"  :data="slice_images" max-width="108" min-height="108" max-height="108"/>
      <assets-images class="hidden-md-and-up"  :data="slice_images" max-width="48" min-height="48" max-height="48"/>
    </div>

    <v-btn @click="open" v-if="has_more" color="primary" class="ml-1 px-3 rounded-lg" height="45" min-width="10" outlined>
      <v-icon style="margin-top:1px;">mdi-dots-horizontal</v-icon>
    </v-btn>

    <modal v-model="modal" max_width="1200" width="100%">
      <template #main>
        <div class="pa-3">
          <v-row class="flex-column-reverse flex-md-row">
            <v-col cols="12" sm="12" md="7">
              <assets-images-box :data="images"/>
            </v-col>

            <v-col cols="12" sm="12" md="5">
              <div class="d-flex justify-space-between align-center">
                <review-user :data="data"/>

                <div class="pl-1">
                  <v-btn @click="modal = !modal" icon small outlined>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>

              <div class="my-5 hidden-sm-and-down light-scroll" style="max-height: 80%; height: 100%; overflow-y:auto; overflow-x:hidden;">
                <description :data="data.text" />
              </div>

              <div class="my-5 hidden-md-and-up">
                <description :data="data.text"/>
              </div>

            </v-col>

          </v-row>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>

import AssetsImages from "@/components/assets/Images";
import Modal from "@/components/modal/Modal";
import AssetsImage from "@/components/assets/Image";
import ReviewUser from "@/components/reviews/ReviewUser";
import Description from "@/components/app/Description";
import AssetsImagesBox from "@/components/assets/ImagesBox";
import ProfileName from "@/components/profiles/ProfileName";

export default {
  name: "ReviewImages",
  components: {ProfileName, AssetsImagesBox, Description, ReviewUser, AssetsImage, Modal, AssetsImages},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  computed: {
    slice_images() {
      try {
        const images = [...this.data.images]
        return images.slice(0, 3);
      } catch (e) {
        return null;
      }
    },
    images() {
      try {
        return this.data.images
      } catch (e) {
        return null;
      }
    },
    has_more() {
      try {
        return 3 < this.data.images.length;
      } catch (e) {
        return false;
      }
    }
  },
  data() {
    return {
      modal: null,
    }
  },
  methods: {
    open() {
      this.modal = true;
    },

  }

}
</script>

<style scoped>

</style>
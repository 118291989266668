<template>
  <div class="full-width background text-center">
    <v-row class="px-5" align="center">
      <v-col cols="12" sm="6" class="pt-0 ">
        <div class="font-head  profile-title title mt-3 d-inline py-1 px-3 ">
          <span class="font-weight-bold font-size-18">
            {{
              editable && _is_object(request.body) ? request.body.business_name : data.business_name
            }}
          </span>&nbsp;
          <v-icon color="blue" small>mdi-check-circle</v-icon>
        </div>

        <div class="hidden-xs-only text-center">
          <div class="d-flex justify-center align-center py-2 font-size-14">
            <rate :data="data.total_score" size="18"/>&nbsp;&nbsp;|&nbsp;&nbsp;
            <span class="text--disabled font-mt-2">{{ data.reviews_count }} {{ __('Reviews') }}</span>
          </div>
        </div>
      </v-col>

      <v-col cols="12" sm="6">
        <div v-if="_owner(data.pro_id)" class="text-center">
          <v-btn
              class="full-width rounded-lg font-size-14"
              :to="{name:'ProfessionalProfileForm',params: {id: data.id}}"
              color="primary"
              max-width="250"
              outlined
          >
            <v-icon small>mdi-pencil-outline</v-icon>&nbsp;
            <span class="font-mt-2">{{ __('EditProfile') }}</span>
          </v-btn>
        </div>


        <v-btn v-else class="rounded-lg" disabled large color="primary" width="100%">
          <v-icon>mdi-comment</v-icon>&nbsp;&nbsp;
          <span class="font-size-14 font-mt-2">{{ __('SendMessage') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Rate from "@/components/app/Rate";
import {PostRequest} from "@/models/PostRequest";

export default {
  name: "ProfileName",

  components: {Rate},
  props: {
    request: {
      default: null,
      type: PostRequest,
    },
    data: {
      default: null,
      type: Object,
    },
    editable: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <v-row>


    <v-col cols="12">

      <questions-steps :questions="questions"/>

      <v-container>
        <!-- Loading  -->
        <loading v-if="questions.request.isLoading() || questions.create_request.isLoading()"/>

        <!-- Steps -->
        <div v-else>
          <survey-start v-if="questions.isStep(1)" :questions="questions"></survey-start>
          <survey-rooms v-if="questions.isStep(2)" :questions="questions"></survey-rooms>
          <survey-styles v-if="questions.isStep(3)" :questions="questions"></survey-styles>
          <survey-analyze v-if="questions.isStep(4)" :questions="questions"></survey-analyze>

          <questions-address v-if="questions.isStep(5)" :questions="questions"></questions-address>
          <questions-tests v-if="questions.isStep(6)" :questions="questions"></questions-tests>
          <questions-budget v-if="questions.isStep(7)" :questions="questions"></questions-budget>
          <questions-auth v-if="questions.isStep(8)" :questions="questions"></questions-auth>
          <questions-packages v-if="questions.isStep(9)" :questions="questions"></questions-packages>
          <questions-files v-if="questions.isStep(10)" :questions="questions"></questions-files>
        </div>
      </v-container>


    </v-col>
  </v-row>
</template>

<script>
import {Questions} from "../../models/Questions";
import SurveyRooms from "./SurveyRooms";
import SurveyStart from "./SurveyStart";
import Loading from "../app/Loading";
import SurveyStyles from "./SurveyStyles";
import QuestionsTests from "./QuestionsTests";
import QuestionsAddress from "./QuestionsAddress";
import QuestionsBudget from "./QuestionsBudget";
import QuestionsAuth from "./QuestionsAuth";
import QuestionsSteps from "./QuestionsSteps";
import SurveyAnalyze from "./SurveyAnalyze";
import QuestionsPackages from "./QuestionsPackages";
import QuestionsFiles from "./QuestionsFiles";

export default {
  name: "Questions",
  components: {
    QuestionsFiles,
    QuestionsPackages,
    SurveyAnalyze,
    QuestionsSteps, QuestionsAuth,
    QuestionsBudget, QuestionsAddress, QuestionsTests, SurveyStyles, Loading, SurveyStart, SurveyRooms
  },
  data() {
    return {
      questions: new Questions(),
    }
  },
  watch: {
    'questions.step'() {
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.questions.fetch()
    this.load()

  },
  methods: {
    load() {
      if (this.$route.query.clear) {
        this.questions.clear();
        this.$router.replace({path: this.$route.path});
        return;
      }

      if (this.$route.query.room_id) {
        return this.set();
      }

      this.questions.load();
    },
    set() {
      try {
        let room_id = this.$route.query.room_id;
        if (room_id) {
          this.questions.clear();
          if (Array.isArray(room_id)) {
            room_id.forEach((item) => {
              this.questions.selectRoom({id: item});
            })
            this.questions.next()
          } else {
            this.questions.selected_rooms[room_id] = {id: room_id};
          }
        }

        let zip_code = this.$route.query.zip_code;
        if (zip_code) this.questions.zip_code = zip_code;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style>

</style>
<template>
  <v-container>
    <v-row justify="center" style="margin-top: 90px">
      <v-col sm="4">
        <complete-register/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompleteRegister from "../../components/auth/CompleteRegister";

export default {
  name: "CompleteRegisterPage",
  components: {CompleteRegister},

}
</script>

<style scoped>

</style>
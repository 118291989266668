<template>
  <div>

    <!-- Actions -->
    <profiles-actions
        v-if="search_in_up"
        :request="request"
        @search="e => filter.keyword = e"
        :hide_limit="hide_limit"
    />

    <!--  Expertises  -->
    <expertises-tabs
        v-if="!hide_expertises"
        v-show="!request.isLoading()"
        @select="setExpertise"
        @select-group="e => filter.expertise_group = e"
    />

    <v-row>

      <!--  Filters  -->
      <v-col v-if="!hide_filters" class="hidden-sm-and-down" cols="12" sm="3" >
        <div class="position-sticky">
          <space height="123"/>
          <profiles-filters :request="request"/>
        </div>
      </v-col>

      <!-- Profile List -->
      <v-col cols="12" :md="hide_filters ? 12 : 9">

        <slot name="list-header"></slot>

        <!-- Actions -->
        <profiles-actions
            v-if="!search_in_up"
            :request="request"
            @search="e => filter.keyword = e"
            :hide_limit="hide_limit"
        />

        <profiles-filters class="hidden-md-and-up px-2 px-sm-3" v-if="!hide_filters" :request="request"/>

        <!-- Loading -->
        <loading v-if="this.request.isLoading()"/>

        <!--  List  -->
        <v-row v-else class="mt-3 mb-1">
          <v-col cols="12" v-for="(item, key) in request.data" :key="'profile-'+key" class="pb-2">
            <profile-item :data="item" card/>
            <v-divider class="hidden-xs-only hidden-md-and-up mt-8 mb-3" v-if="_is_array(request.data) && key < request.data.length - 1"/>
          </v-col>

          <v-col sm="12">
            <!--   Not found data   -->
            <not-found-data :text="not_found_message" v-if="!request.data" style="padding: 90px 0 150px !important;"/>

            <!-- Load More -->
            <load-more v-if="!hide_load_more" :request="request" pagination/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";

import LoadMore from "../app/LoadMore";
import NotFoundData from "../app/NotFoundData";
import Professional from "@/components/professionals/Professional"
import Space from "@/components/app/Space";
import ProfessionalsTabs from "@/components/professionals/ProfessionalsTabs";
import ItemsSearch from "@/components/items/ItemsSearch";
import ProfessionalsFilters from "@/components/professionals/ProfessionalsFilters";
import Loading from "../app/Loading";
import ItemsLimit from "../items/ItemsLimit";
import Expertises from "@/components/expertises/Expertises";
import ExpertiseList from "@/components/expertises/ExpertiseList";
import ExpertisesTabs from "@/components/expertises/ExpertisesSection";
import ProfilesActions from "@/components/profiles/ProfilesActions";
import ProfilesFilters from "@/components/profiles/ProfilesFilters";
import ProfileItem from "@/components/profiles/ProfileItem";
import Breadcrumbs from "@/components/app/Breadcrumbs";

export default {
  name: "Profiles",
  components: {
    Breadcrumbs,
    ProfileItem,
    ProfilesFilters,
    ProfilesActions,
    ExpertisesTabs,
    ExpertiseList,
    Expertises,
    ItemsLimit,
    Loading,
    ProfessionalsFilters,
    ProfessionalsTabs,
    Professional,
    NotFoundData,
    LoadMore,
    Space,
    ItemsSearch
  },
  props: {
    loader_count: {
      default: 5,
      type: [String, Number],
    },
    filters_attrs: {
      default: null,
      type: [Object],
    },
    limit: {
      default: 12,
      type: [String, Number],
    },
    user_created: {
      default: null,
      type: [String],
    },
    search_in_up: {
      default: false,
      type: Boolean,
    },
    api: {
      default: 'get_pro_profiles',
      type: String,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    filters: {
      default: null,
      type: Object,
    },
    hide_expertises: {
      default: false,
      type: Boolean,
    },
    hide_filters: {
      default: false,
      type: Boolean,
    },
    hide_limit: {
      default: false,
      type: Boolean,
    },
    sort: {
      default: 'sort,-date_created',
      type: String,
    },
    fields: {
      default: '*,count(reviews)',
      type: String,
    },
    not_found_message: {
      default: null,
      type: String,
    },
    add_is_visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      request: new GetRequest(),
      filter: {
        expertise: null,
        expertise_group: null,
        keyword: null,
      },
    }
  },
  mounted() {
    this.request.setApi(this.api)
    this.getProfessionals();

  },

  methods: {
    getProfessionals() {
      this.request.reset();
      this.setFilters();

      this.request.setLimit(this.limit)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get();
    },
    setFilters() {
      if (this.user_created) this.request.addFilter('user_created', this.user_created);
      if (this.status) this.request.addFilter('status', this.status);
    },
    setExpertise(data) {
      if (!this._is_object(data)) return;
      this.$router.push({name: 'FindProfessionals', query: {expertise_id: data.id, expertise_title: data.title}})
    },

  }
}
</script>

<style>

</style>

<template>
  <div class="d-flex">

    <tag-selector
        @input="(e) => change( e ? {'id': {'_eq': e}} : null, 'personal_tags')"
        :value="defaultTag()"
    />

    <space/>

    <user-selector
        role="3448c9ef-6456-4cad-8015-87a4e8fe1c31"
        status="active"
        :title="__('Client')"
        :value="getDefault('client_id')"
        @input="(e) => change( e, 'client_id')"
    />

    <space/>
    <date-range-selector
        @input="(e) => change( e  , 'date_created')"
        :value="getDefault('date_created')"
    />

    <space/>
    <div class="pt-6">
      <v-btn class="border-light rounded-lg" @click="reset" large outlined>
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";
import TagSelector from "../selectors/TagSelector";
import UserSelector from "../selectors/UserSelector";
import Space from "../app/Space";
import DateRangeSelector from "../selectors/DateRangeSelector";

export default {
  name: "LeadFilters",
  components: {Space, UserSelector, TagSelector, DateRangeSelector},
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_rooms: {
      default: true,
      type: Boolean,
    },
    with_status: {
      default: true,
      type: Boolean,
    }
  },
  data() {
    return {
      filter: {}
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
    change(value, key) {
      try {
        let operator = '_eq';
        if (key === 'date_created') operator = '_between';

        this.request.addFilter(key, value, operator);
        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },
    getDefault(key) {
      try {
        return this.request.getFilter(key);
      } catch (e) {
        console.log(e);
      }
    },
    defaultTag() {
      try {
        let result = this.request.getFilter('personal_tags');
        if (!result || !result._eq) return null;
        return result._eq;
      } catch (e) {
        return null;
      }
    }
  },
}
</script>

<style scoped>

</style>
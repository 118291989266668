import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && !_vm._access('pro'))?_c(VCard,{staticClass:"rounded-lg pb-1",attrs:{"flat":"","outlined":""}},[_c(VListItem,[_c(VListItemTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.__('Answers'))+" "),(_vm.count)?_c('span',[_vm._v("( "+_vm._s(_vm.count)+" )")]):_vm._e()]),_c(VListItemAction,[_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.show_more = !_vm.show_more}}},[(!_vm.show_more)?_c(VIcon,[_vm._v("mdi-chevron-down")]):_c(VIcon,[_vm._v("mdi-chevron-up")])],1)],1)],1),(_vm.show_more)?_c('div',{staticClass:"pb-4"},[(_vm.data && Array.isArray(_vm.data.quiz_answers) && _vm.data.quiz_answers.length !==0)?_c('div',_vm._l((_vm.data.quiz_answers),function(item,key){return _c(VListItem,{key:'project-detail-answer-'+key},[(item.answer && typeof item.answer === 'object')?_c(VListItemIcon,[_c('assets-image',{attrs:{"data":item.answer.icon,"width":"48"}})],1):_vm._e(),_c(VListItemContent,[(item.item && typeof item.item === 'object')?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(item.item.question_title)}})]):_vm._e(),(item.answer && typeof item.answer === 'object')?_c('div',{staticClass:"text--disabled"},[_c('div',{domProps:{"innerHTML":_vm._s(item.answer.text)}})]):_vm._e()])],1)}),1):_c('div',[_c('not-found-data',{staticClass:"pb-16"})],1)]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
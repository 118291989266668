<template>
  <v-simple-table v-if="data">
    <template v-slot:default>
      <thead>
      <tr>
        <th class="text-left" width="10">
          {{ __('Avatar') }}
        </th>
        <th class="text-left">
          {{ __('Name') }}
        </th>
        <th class="text-left">
          {{ __('Role') }}
        </th>
        <th class="text-left hidden-md-and-down" >
          {{ __('Email') }}
        </th>
        <th class="text-left hidden-md-and-down">
          {{ __('PhoneNumber') }}
        </th>
        <th class="text-left">
          {{ __('Status') }}
        </th>
        <th class="text-left">
          {{ __('Actions') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, key) in data" :key="'user-row-'+key">
        <td>
          <v-avatar size="32" color="secondary" max-width="100%">
            <assets-image v-if="item.avatar && (item.avatar.length > 0)" :data="item.avatar" width="32"/>
            <v-icon v-else color="grey lighten-1" size="16">mdi-account</v-icon>
          </v-avatar>
        </td>
        <td>
          <full-name :data="item"/>
        </td>
        <td>
          <div v-if="item.role && typeof item.role === 'object'">{{ item.role.name }}</div>
        </td>
        <td class="hidden-md-and-down">
          <a :href="mailto(item)" target="_blank">
            <div>{{ item.email }}</div>
          </a>
        </td>
        <td class="hidden-md-and-down">
          <a :href="tel(item)">
            <div>{{ item.phone_number }}</div>
          </a>
        </td>
        <td>
          <div>{{ item.status }}</div>
        </td>
        <td>
          <user-menu :data="item"/>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import FullName from "../account/FullName";
import AssetsImage from "../assets/Image";
import UserMenu from "./UserMenu";

export default {
  name: "UsersTable",
  components: {UserMenu, AssetsImage, FullName},
  props: {
    data: {
      type: Array,
      default: null,
    }
  },
  methods: {
    mailto(item) {
      try {
        if ((item.email !== null) && (item.email.length > 0))
          return 'mailto:' + item.email;
        else
          return ''
      } catch (e) {
        return '#';
      }
    },
    tel(item) {
      try {
        if ((item.phone_number !== null) && (item.phone_number.length > 0))
          return 'tel:' + item.phone_number;
        else
          return ''
      } catch (e) {
        return '#';
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>

    <v-row v-if="!hide_actions">
      <v-col class="d-flex justify-space-between">
        <div class="d-flex width-full">

          <!-- Add Button   -->
          <template v-if="add_is_visible">
            <v-btn color="primary" elevation="0" large class="hidden-sm-and-down rounded-lg"
                   :to="{name:'AddUser'}">
              {{ __('AddNewUser') }}
            </v-btn>
            <v-btn color="primary" fab elevation="0" small class="hidden-md-and-up" :to="{name:'AddUser'}"
                   :title="__('AddNewUser')">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <space/>

          <!-- Search -->
          <items-search :request="request" :columns="['full_name']"></items-search>
        </div>

        <space/>

        <div class="d-flex">
          <!-- Limit -->
          <items-limit :request="request"/>
        </div>
      </v-col>
    </v-row>

    <!-- Loading -->
    <loading v-if="request.isLoading()" :count="loader_count"/>

    <!-- List -->
    <div v-else class="mt-4 px-0">
      <!--  Table    -->
      <v-row v-if="table">
        <v-col cols="12">
          <users-table :data="request.data"/>
        </v-col>
      </v-row>

      <!--   Card   -->
      <v-row v-else>
        <v-col cols="6" md="3" sm="4" lg="2" v-for="(item, key) in request.data" :key="'user-card-'+key">
          <user-card :data="item"/>
        </v-col>
      </v-row>

      <!--   Not found data   -->
      <not-found-data v-if="!request.data" style="padding-top: 90px !important;"/>

      <!-- Load More -->
      <load-more v-if="!hide_load_more" :request="request" pagination/>
    </div>

  </div>

</template>

<script>
import {GetRequest} from "@/models/GetRequest";

import LoadMore from "@/components/app/LoadMore";
import Space from "@/components/app/Space";
import NotFoundData from "@/components/app/NotFoundData";
import ItemsSearch from "@/components/items/ItemsSearch";
import UserCard from "./UserCard";
import Loading from "../app/Loading";
import ItemsLimit from "../items/ItemsLimit";
import UsersTable from "./UsersTable";

export default {
  name: "Users",
  components: {
    UsersTable,
    ItemsLimit,
    Loading,
    UserCard, ItemsSearch, NotFoundData, Space, LoadMore,
  },
  props: {
    loader_count: {
      default: 12,
      type: [String, Number],
    },
    status: {
      default: null,
      type: String,
    },
    add_is_visible: {
      default: false,
      type: Boolean,
    },
    hide_actions: {
      default: false,
      type: Boolean,
    },
    hide_filters: {
      default: false,
      type: Boolean,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    table: {
      default: false,
      type: Boolean,
    },
    filter_status: {
      default: true,
      type: Boolean,
    },
    without: {
      default: null,
      type: Array,
    },
    nin_role: {
      default: null,
      type: [String, Object],
    },
    limit: {
      default: null,
      type: [String, Number],
    },
    sort: {
      default: 'date_created',
      type: String,
    },
    fields: {
      default: '*, role.*',
      type: String,
    }
  },
  data() {
    return {
      request: new GetRequest('get_users'),
      show_filters: false,
      selected_status: null,
      loaderAttrs: {
        class: '',
        boilerplate: false,
        elevation: 1,
      },
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.request.reset();

      this.setFilters();
      this.setQueries();

      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get();
    },
    setFilters() {
      if (this.status) this.request.addFilter('status', this.status);
      if (this.nin_role) this.request.addFilter('role', this.nin_role, '_nin');
    },
    setQueries() {
      try {
        let queries = this.$route.query;
        if (!queries) return;

        if (queries.status) this.request.addFilter('status', queries.status)
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>

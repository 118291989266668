<template>
  <v-container  class="container-max-width" >
    <profile :id="$route.params.id"/>
  </v-container>
</template>

<script>

import Profile from "@/components/profiles/Profile";

export default {
  name: "ProfessionalProfilePage",
  components: {Profile},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

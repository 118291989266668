import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm._is_object(_vm.data))?_c(VRow,[(_vm._is_object(_vm.data.user_created))?_c(VCol,{attrs:{"cols":"6"}},[_c(VListItem,{staticClass:"px-0",style:({'margin-left': _vm.reply ? '-40px' : ''})},[_c(VListItemAvatar,[_c('avatar',{attrs:{"data":_vm.data.user_created,"color":"secondary","size":"35"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',{staticClass:"font-size-14"},[_vm._v(_vm._s(_vm.data.user_created.full_name))])]),_c(VListItemSubtitle,{class:{'hidden-xs-only hidden-md-and-up':_vm.reply}},[_c('date-text',{attrs:{"data":_vm.data.date_created,"use_format":""}})],1)],1)],1)],1):_c(VCol,{attrs:{"cols":"6"}},[_c(VListItem,{staticClass:"px-0",style:({'margin-left': _vm.reply ? '-40px' : ''})},[_c(VListItemAvatar,[_c(VAvatar,{attrs:{"color":"secondary"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.__('None')))])])],1)],1)],1),_c(VCol,{staticClass:"d-flex justify-end align-center",staticStyle:{"line-height":"15px"},attrs:{"cols":"6"}},[(!_vm.reply)?_c('div',{staticStyle:{"font-size":"15px"}},[_c('rate',{attrs:{"data":_vm.data.total_score,"size":"18"}})],1):_vm._e(),(_vm.reply)?_c('date-text',{staticClass:"font-size-12 hidden-sm-only grey--text",attrs:{"data":_vm.data.date_created,"use_format":""}}):_vm._e(),_vm._t("default")],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-img :src="image_url" style="background: #CCC" :width="width" :class="css_class" :height="height" v-bind="$attrs">
    <slot></slot>
  </v-img>
</template>

<script>
import app from "../../tools/App";
import urls from "../../tools/Urls";

export default {
  name: "AssetsImage",
  props: {
    data: {
      default: null,
      type: [Object, String, Array]
    },
    css_class: {
      default: null,
      type: String
    },
    width: {
      default: null,
      type: [Number, String]
    },
    height: {
      default: null,
      type: [Number, String]
    },
    crop_width: {
      default: null,
      type: [Number, String]
    },
    crop_height: {
      default: null,
      type: [Number, String]
    },
    quality: {
      default: 90,
      type: [Number, String]
    },
    type: {
      default: 'cover',
      type: String
    },
    default_color: {
      default: false,
      type: Boolean
    },
  },
  watch:{
    'data'(){
      this.setImage()
    }
  },
  data() {
    return {
      image_url: null,
    }
  },
  mounted() {
    this.setImage();
  },

  methods: {
    setImage() {
      try {

        // check is string
        if (typeof  this.data  === 'string') {
          if (this.data == null) throw 'Not found image url!';
          this.image_url = this.getUrl(this.data);
          return;
        }

        let data = this.data;

        // check is array
        if (Array.isArray(this.data)) {
          data = this.data[0].directus_files_id;
        }

        // check is object
        if (typeof data === 'object') {
          if (data.directus_files_id) data = data.directus_files_id;
          else data = this.data.id;
        }

        if (data == null) throw 'Not found image url!';


        this.image_url = this.getUrl(data);
      } catch (e) {
        this.image_url =  !this.default_color ? app.default_image : null;
      }
    },
    getUrl(id) {
      try {
        let url = urls.api('assets_url', [id]);

        let params = `?fit=${this.type}`;
        if (this.crop_width) params += `&width=${this.crop_width}`;
        if (this.crop_height) params += `&height=${this.crop_height}`;
        if (this.quality) params += `&quality=${this.quality}`;

        return url + params;
      } catch (e) {
        return '';
      }
    },
  }
}
</script>

<style scoped>

</style>
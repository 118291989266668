<template>
  <!--  Add Blog Form  -->
  <form @submit.prevent="submit" v-if="request.body">
    <v-row>
      <v-col cols="12" md="12">
        <assets-images :data="request.body.pictures" class="rounded-lg" max-height="188" max-width="190">
          <upload @then="uploaded" accept="image/*"></upload>

          <template v-slot:image="{item, index}">
            <v-btn icon @click="removeImage(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </assets-images>
      </v-col>

      <v-col cols="12" md="12">
        <div class="font-weight-bold">{{ __('Title') }} *</div>
        <v-text-field
            v-model="request.body.title"
            dense
            outlined
            required
        />
      </v-col>

      <v-col cols="12" md="12">
        <div class="font-weight-bold">{{ __('Description') }} *</div>
        <div class="py-2">
          <v-chip :color="editor_type === 'html' ? 'primary' : ''" @click="editor_type = 'html'" class="mr-2">{{__('Editor')}}</v-chip>
          <v-chip :color="editor_type === 'text' ? 'primary' : ''" @click="editor_type = 'text'">{{__('Text')}}</v-chip>
        </div>
        <app-textarea
            v-model="request.body.text"
            height="565"
            required
        />
      </v-col>

      <v-col cols="12" md="12">
        <div class="font-weight-bold">{{ __('Summary') }}</div>
        <v-textarea
            v-model="request.body.summary"
            auto-grow
            outlined
            rows="1"
            row-height="15"
        />
      </v-col>

      <v-col cols="12" md="12">
        <status-selector v-model="request.body.status" :value="request.body.status" :items="status_items"/>
      </v-col>

      <v-col md="12">

        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Submit') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>

    </v-row>
  </form>
</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import Upload from "@/components/assets/Upload";
import AssetsImages from "@/components/assets/Images";
import StatusSelector from "@/components/selectors/StatusSelector";
import AppTextarea from "@/components/inputs/AppTextarea";
export default {
  name: "BlogWrite",
  components: {AppTextarea, StatusSelector, AssetsImages, Upload,},
  props: {
    data: {
      default: null,
      type: Object
    },
  },
  data() {
    return {

      request: new PostRequest(),
      editor_type: 'html',
      pictures: null,
      status_items: [
        {
          text: this.__('Published'),
          value: 'published'
        },
        {
          text: this.__('Unpublished'),
          value: 'unpublished'
        },
        {
          text: this.__('Pending'),
          value: 'pending'
        },
        {
          text: this.__('Draft'),
          value: 'draft'
        }
      ]
    }
  },
  mounted() {
    this.request.setApi(this.data ? 'update_blog' : 'add_blog');
    this.request.setApiParams(this.data ? [this.data.id] : null);
    this.request.setThen(this.submitted);
    this.setBody();
  },
  methods: {
    setBody() {
      if (this.data) {
        this.request.setBody(
            {
              text: this.data.text,
              summary: this.data.summary,
              title: this.data.title,
              status: this.data.status,
              pictures: this.data.pictures,
            }
        );
        return;
      }

      this.request.setBody(
          {
            title: null,
            text: null,
            summary: null,
            status: 'published',
            pictures: [],
          }
      );
    },
    getId(item) {
      try {
        if (typeof item === 'object') return item.id;
        return item;
      } catch (e) {
        return null;
      }
    },
    uploaded(response) {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        this.request.body.pictures.push({directus_files_id: response.data.id});
      } catch (e) {
        console.log(e);
      }
    },
    submitted(data) {
      this.$emit('then', data);
      this.setBody();
    },
    submit() {
      this.before()
      if (this.data) return this.request.patch();
      this.request.post();
    },
    before() {
      try {
        this.$emit('before', this.request)
      } catch (e) {
        console.log(e);
      }
    },
    removeImage(index) {
      try {
        this.$delete(this.request.body.pictures, index)
      } catch (e) {
        console.log(e);
      }
    }
  },
}
</script>

<style scoped>

</style>
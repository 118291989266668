<template>
  <div>
    <!-- Loading -->
    <loader count="10"  class="py-7" type="avatar" horizontal  v-if="request.isLoading()"/>

    <!--  List  -->
    <v-row v-else>
      <v-slide-group id="rooms-horizontal" multiple show-arrows>
        <v-slide-item v-for="(item, key) in request.data" :key="'rooms-horizontal'+key">
          <room tile :data="item" image/>
        </v-slide-item>
      </v-slide-group>
    </v-row>

  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";
import Room from "./Room";
import Loader from "../app/Loader";


export default {
  name: "RoomsHorizontal",
  components: {Loader, Room},
  props: {
    limit: {
      type: [String, Number],
      default: 150
    }
  },
  data() {
    return {
      request: new GetRequest('get_rooms'),
    }
  },
  mounted() {
    this.request.setLimit(this.limit)
    this.request.setFields('*')
    this.request.get();
  },
  watch: {},
  methods: {}
}
</script>

<style>

</style>
<template>
  <v-hover v-slot="{hover}">

    <v-card v-if="data && data.id" flat>

      <div>
        <div style="line-height: 0;">
          <assets-image
              v-if="!masonry" :data="data.pictures" :radius="25" css_class="rounded-lg"
              :height="image_height"
          />
          <assets-image-html v-else :data="data.pictures" :radius="25" class="rounded-lg " style="max-width: 100%"/>

        </div>

        <!-- Overlay       -->

        <div v-show="hover" style="height: 36px;width: 36px; position: absolute; top: 0;right: 0; z-index: 2">
          <idea-menu
              v-if="!hide_options"
              :data="data"
              color="background"
              @archived="(e)=>$emit('archived',e)"
              @edited="(e)=>$emit('edited',e)"
          />
        </div>


        <v-fade-transition>
          <v-overlay light opacity="0.6" absolute z-index="1" v-show="hover">

            <div @click="click" class="pointer text-decoration-none text--text text-truncate text-center" :title="data.title">
              <div
                  class="body-1 white--text text-truncate"
                  style="max-width: 190px"
                  :title="data.title ? data.title : __('None')"
              >
                {{ data.title ? data.title : __('None') }}
              </div>

              <div class="white--text opacity-6 text-truncate">
                <div>{{ creator() }}</div>
                <small v-if="show_date">
                  <date-text :data="data.date_created"/>
                </small>
              </div>
            </div>


            <div v-if="show_status || show_saved_count" class="text-center pt-2 pb-1">
              <v-chip v-if="show_status" small class="grey text--text lighten-3 mr-1">{{ data.status }}</v-chip>
              <v-chip v-if="show_saved_count" small color="grey text--text lighten-3 " class="mr-1">{{ count }}
                {{ __('Pins') }}
              </v-chip>
            </div>
          </v-overlay>
        </v-fade-transition>
      </div>


      <modal v-if="in_modal" v-model="modal" fullscreen>
        <template #title>
          {{__('Idea')}}
        </template>
        <template #main>
          <idea-details :id="data.id"/>
        </template>
      </modal>

    </v-card>
  </v-hover>
</template>

<script>
import IdeaMenu from "./IdeaMenu";
import DateText from "../app/DateText";
import AssetsImageHtml from "../assets/ImageHtml";
import AssetsImage from "../assets/Image";
import Modal from "../modal/Modal";
import IdeaDetails from "./IdeaDetails";

export default {
  name: "Idea",
  components: {IdeaDetails, Modal, AssetsImage, AssetsImageHtml, DateText, IdeaMenu},
  props: {
    data: {
      default: null,
      type: Object
    },
    hide_options: {
      default: false,
      type: Boolean
    },
    masonry: {
      default: false,
      type: Boolean
    },
    show_date: {
      default: false,
      type: Boolean
    },
    show_status: {
      default: false,
      type: Boolean
    },
    show_saved_count: {
      default: false,
      type: Boolean
    },
    in_modal: {
      default: false,
      type: Boolean
    },
    image_height: {
      default: 240,
      type: [String, Number]
    }
  },
  data: () => {
    return {
      modal: null,
    }
  },
  computed: {
    convertable() {
      try {
        if (!this._access('admin')) return false;
        let user_id = (typeof this.data.user_created === 'object') ? this.data.user_created.id : this.data.user_created;
        if (user_id === this._user.id) return false;
        return true;
      } catch (e) {
        return false;
      }
    },
    count() {
      try {
        return this.data.projects.length
      } catch (e) {
        return 0;
      }
    },
  },
  mounted() {

  },
  methods: {
    click() {
      try {
        if (this.in_modal) return this.modal = true;
        return this.$router.push({name: 'IdeaDetails', params: {id: this.data.id}})
      } catch (e) {
        return '';
      }
    },
    room() {
      try {
        return this.data.room_id.title;
      } catch (e) {
        return '';
      }
    },
    size() {
      try {
        return this.data.size_id.name;
      } catch (e) {
        return '';
      }
    },
    style() {
      try {
        return this.data.style_id.name;
      } catch (e) {
        return '';
      }
    },
    creator() {
      try {
        return this.__('By') + this.data.add_by.first_name + ' ' + this.data.add_by.last_name;
      } catch (e) {
        return 'By None';
      }
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <v-chip v-if="!hide && data.title" v-bind="$attrs" :color="data.color" outlined>
    {{ data.title }}
    <span>&nbsp;&nbsp;</span>
    <v-btn v-if="deletable" @click="show_confirm = true" icon small class="ma-0">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <confirm v-model="show_confirm" @accepted="remove"/>
  </v-chip>
</template>

<script>

import {DeleteRequest} from "../../models/DeleteRequest";
import Confirm from "../modal/Confirm";

export default {
  name: "Tag",
  components: {Confirm},
  props: {
    data: {
      default: null,
      type: Object,
    },
    deletable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      remove_request: new DeleteRequest('item_collection'),
      hide: false,
      show_confirm: false,
    }
  },
  mounted() {
    this.remove_request.setApiParams(['personal_tag', this.data.id])
    this.remove_request.setThen(e => this.then(e))
  },
  methods: {
    remove() {
      this.remove_request.delete()
    },
    then() {
      this.hide = true;
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <v-hover v-slot="{hover}">
      <div>
        <v-row v-if="vertical">
          <v-col cols="12" md="8">
            <assets-image
                v-if="Array.isArray(data.pictures) && data.pictures.length === 1"
                :data="picture"
                :height="height"
                class="rounded-lg"
            />
            <assets-images v-else :data="data.pictures" :height="height" class="rounded-lg" carousel/>
          </v-col>
          <v-col cols="12" md="4">
            <router-link :to="link" class="text--text">
              <div class="text-h4 overflow-hidden" style="max-height: 85px; text-overflow: ellipsis;">
                {{ data.title }}
              </div>
            </router-link>
            <div class="mt-6 grey--text">
              <template v-if="!hide_name">
                {{ __('By') }}
                <full-name v-if="data.user_created" :data="data.user_created"/>
                <span v-else>{{ __('Renotive') }}</span>
              </template>
            </div>
            <div class="pt-3">
              {{ data.summary }}
            </div>
          </v-col>
        </v-row>


        <v-card v-else flat class="pb-3 overflow-hidden">

          <div>
            <div class="pointer" @click="click">
              <assets-image :data="picture" :height="height" class="rounded-lg"/>
            </div>
            <v-overlay class="justify-end" light style="height: 38px;" opacity="0" absolute>
              <blog-menu
                  :data="data"
                  :hover="hover"
                  @archived="(e)=>$emit('archived',e)"
                  @edited="(e)=>$emit('edited',e)"
              />
            </v-overlay>
          </div>

          <v-card-title class="px-0 pt-1 text-truncate">
            <router-link :to="link" class="text-decoration-none text-truncate text--text">
              {{ data.title ? data.title : __('None') }}
            </router-link>
          </v-card-title>

          <v-card-subtitle class="px-0 text--disabled">
            {{ __('By') }}
            <full-name v-if="data.user_created" :data="data.user_created"/>
            <span v-else>{{ __('Renotive') }}</span>

            <!--  Status        -->
            <span v-if="_access('admin')">
            <v-icon>mdi-circle-small</v-icon>
            <span>{{ __(data.status) }}</span>
          </span>
          </v-card-subtitle>

          <v-card-subtitle class="px-0 py-0" style="height: 80px">
            {{ data.summary }}
          </v-card-subtitle>

          <v-card-actions class="pa-0">

          </v-card-actions>
        </v-card>

      </div>

    </v-hover>


    <modal v-if="in_modal" v-model="modal" fullscreen>
      <template #title>
        {{ __('Blog') }}
      </template>
      <template #main>
        <blog-view :id="data.id"/>
      </template>
    </modal>
  </div>
</template>

<script>

import AssetsImage from "../assets/Image";
import FullName from "../account/FullName";
import AssetsImages from "../assets/Images";
import BlogMenu from "./BlogMenu";
import Modal from "../modal/Modal";
import BlogView from "./BlogView";

export default {
  name: "BlogSingle",
  components: {BlogView, Modal, BlogMenu, AssetsImages, FullName, AssetsImage,},
  props: {
    data: {
      default: null,
      type: Object
    },
    height: {
      default: '350',
      type: [String, Number]
    },
    vertical: {
      default: false,
      type: Boolean
    },
    hide_name: {
      default: false,
      type: Boolean
    },
    in_modal: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    picture() {
      try {
        return this.data.pictures[0].directus_files_id;
      } catch (e) {
        return null;
      }
    },
    link() {

      return {name: 'BlogView', params: {id: this.data.id}}
    }
  },
  data: () => {
    return {
      modal: null,
    }
  },
  mounted() {

  },
  methods: {
    click() {
      try {
        if (this.in_modal) return this.modal = true;
        return this.$router.push(this.link)
      } catch (e) {
        return '';
      }
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <div v-if="_is_object(data)">
    <template v-if="!hide_title">
      <h3>{{ __('Projects') }} <span v-if="request.total" class="primary--text">({{ request.total }})</span></h3>
      <v-divider class="my-3"/>
    </template>

    <!--  Actions  -->
    <items-search v-if="!hide_search" class="mt-1" :request="request" :columns="['title']" :max_width="null"/>

    <div class="mt-6">
      <loader v-if="request.isLoading()" cols="12" :col_sm="sm" type="card" :count="loader_count"/>

      <v-row v-else>

        <!--   Add   -->
        <v-col v-if="addable" cols="12" sm="6" md="4">
          <v-btn
              :to="{name:'ProfessionalProjectCreate', params:{id: data.id}}"
              color="primary"
              class="hidden-sm-and-up rounded-lg"
              elevation="0"
              block
              large
          >
            <v-icon>mdi-plus</v-icon>
            <div class="font-mt-2">{{ __('AddNewProject') }}</div>
          </v-btn>

          <v-card
              :to="{name:'ProfessionalProjectCreate', params:{id: data.id}}"
              color="grey lighten-3"
              class="hidden-xs-only rounded-lg"
              height="250"
              flat
          >
            <v-card-text class="text-center">
              <div class="pt-16">
                <v-icon size="45">mdi-plus</v-icon>
              </div>
              <div class="font-weight-regular pt-3 text-uppercase font-size-18">{{ __('AddNewProject') }}</div>
            </v-card-text>
          </v-card>
        </v-col>

        <!--   Horizontal List     -->
        <v-slide-group
            v-if="horizontal && request.data"
            class="slide-group-custom-arrows slide-group-hide-arrows"
            multiple
            show-arrows
        >
          <v-slide-item v-for="(item, key) in request.data" :key="'profile-project-slid-'+key">
            <profile-project-item
                v-if="_is_object(item)"
                :key="'profile-project-'+item.id"
                :data="item"
                :pro_id="data.id"
                class="mr-3"
                image_height="199"
                style="max-width: 346px"
                v-bind="$attrs"
            />
          </v-slide-item>
        </v-slide-group>


        <!--  List  -->
        <template v-else v-for="(item, key) in request.data">

          <v-col cols="12" :sm="sm" md="4">
            <profile-project-item
                v-if="_is_object(item)"
                :key="'profile-project-'+item.id"
                :data="item"
                :pro_id="data.id"
                v-bind="$attrs"
            />
          </v-col>
        </template>

        <v-col cols="12" class="text-center">
          <!--  Load More  -->
          <load-more
              v-if="!hide_load_more"
              :request="request"
              :title="__('LoadMoreProjects')"
              :load_method="loadMore"
              class="mb-3"
              outlined
              max-width="342"
              width="100%"
          />

          <!--  Not Found    -->
          <not-found-data v-if="!request.data"/>
        </v-col>

      </v-row>
    </div>
  </div>
</template>

<script>
import ProfileProjectItem from "@/components/profiles/ProfileProjectItem";
import ItemsSearch from "@/components/items/ItemsSearch";
import LoadMore from "@/components/app/LoadMore";
import {GetRequest} from "@/models/GetRequest";
import NotFoundData from "@/components/app/NotFoundData";
import Loading from "@/components/app/Loading";
import Loader from "@/components/app/Loader";

export default {
  name: "ProfileProjectsDesktop",
  components: {ProfileProjectItem, Loader, Loading, NotFoundData, LoadMore, ItemsSearch},
  props: {
    data: {
      default: null,
      type: Object,
    },
    addable: {
      default: false,
      type: Boolean,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    horizontal: {
      default: false,
      type: Boolean,
    },
    hide_search: {
      default: false,
      type: Boolean,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
    without: {
      default: null,
      type: Array,
    },
    loader_count: {
      default: 6,
      type: [String, Number],
    },
    limit: {
      default: 6,
      type: [String, Number],
    },
    sm: {
      default: 6,
      type: [String, Number],
    },
  },
  computed: {},
  data() {
    return {
      request: new GetRequest('get_items', ['pro_profile_project']),
    }
  },
  mounted() {
    if (this.data) this.request.data = this.data.projects;
    this.get();
  },
  methods: {
    get() {
      if (!this._is_object(this.data)) return;
      this.request.setLimit(this.limit); 
      this.request.setSort('date_created')
      this.request.addFilter('pro_profile_id', this.data.id)
      this.request.setFields('*,ideas.pictures.directus_files_id')
      this.request.setParam('deep', '{"ideas": {"_limit": 1, "_sort": ["pro_project_sort"]}}');
      this.request.setNotIn('id', this.without)
      this.request.get()
    },
    loadMore() {
      let limit = parseInt(this.request.params.getLimit()) + parseInt(this.limit);
      if (this.addable) limit++;
      this.request.setLimit(limit);
      this.request.get();
    }
  }
}
</script>

<style>
#profile-projects .slide-group-custom-arrows .v-slide-group__prev,
#profile-projects .slide-group-custom-arrows .v-slide-group__next {
  top: 90px !important;
}
</style>
`<template>
  <div   v-if="_is_object(data)">
    <h3 v-if="!hide_title">{{ __('Stories') }} <span v-if="request.total" class="primary--text">({{ request.total }})</span></h3>
    <v-divider v-if="!hide_title" class="my-3 mb-6"/>

    <loader v-if="request.isLoading()" cols="12" col_sm="12" count="3" type="card"/>

    <v-row v-else>
      <!--   Horizontal List     -->
      <v-slide-group
          v-if="horizontal && request.data"
          class="slide-group-hide-arrows slide-group-custom-arrows"
          multiple
          show-arrows
      >
        <v-slide-item v-for="(item, key) in request.data" :key="'profile-project-slid-'+key">
          <router-link :to="link(item)" class="text--text mr-3" style="max-width: 290px">
            <v-card  class="rounded-lg grey overflow-hidden mb-3 lighten-3 " flat>
              <assets-image :data="picture(item)" height="170" width="290"/>
            </v-card>
            <div class="font-weight-bold overflow-hidden text-truncate" :title="item.title">
              {{ item.title }}
            </div>
            <div class="text--disabled">
              <small><date-text :data="item.date_created" use_format/></small>
            </div>
            <div class="pt-3 text-truncate " style="max-height: 85px; white-space:pre-wrap  !important;">{{ item.summary }}</div>
          </router-link>
        </v-slide-item>

      </v-slide-group>

      <v-col cols="12" v-else>
        <template  >
          <!--   Add   -->

          <template  v-if="addable">
            <v-btn
                :to="{name:'ProfessionalBlogCreate', params:{id: data.id}}"
                color="primary"
                class="hidden-sm-and-up mb-3 rounded-lg"
                elevation="0"
                block
                large
            >
              <v-icon>mdi-plus</v-icon>
              <div class="font-mt-2" >{{ __('AddNew') }}</div>
            </v-btn>

            <v-card

                :to="{name:'ProfessionalBlogCreate', params:{id: data.id}}"
                color="grey py-3 mb-3 lighten-3"
                class="hidden-xs-only rounded-lg"
                flat
            >
              <v-card-text class="text-center">
                <div class="d-flex text-center align-center justify-center">
                  <v-icon size="45">mdi-plus</v-icon>&nbsp;
                  <div class="  text-uppercase font-mt-3 font-size-18" style="font-size: 15px;">{{ __('AddNew') }}</div>
                </div>
              </v-card-text>
            </v-card>
          </template>


          <!--  List  -->
          <template v-for="(item, key) in request.data">
            <blog-card
                v-if="_is_object(item)"
                :key="'profile-story-'+item.id"
                :data="item"
                route_name="ProfessionalBlog"
                v-bind="$attrs"
                vertical
                hide_name
            />
          </template>

        </template>
      </v-col>


      <v-col cols="12" class="text-center my-3">
        <!--  Load More  -->
        <load-more v-if="!hide_load_more" :request="request" class="mb-3" outlined block/>

        <!--  Not Found    -->
        <not-found-data v-if="!request.data"/>
      </v-col>

    </v-row>

  </div>
</template>

<script>
import Loader from "@/components/app/Loader";
import NotFoundData from "@/components/app/NotFoundData";
import LoadMore from "@/components/app/LoadMore";
import {GetRequest} from "@/models/GetRequest";
import BlogCard from "@/components/blogs/BlogCard";
import AssetsImage from "@/components/assets/Image";
import DateText from "@/components/app/DateText";

export default {
  name: "ProfileStoriesDesktop",
  components: {DateText, AssetsImage, BlogCard, LoadMore, NotFoundData, Loader,},
  props: {
    data: {
      default: null,
      type: Object,
    },
    addable: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 3,
      type: [String, Number],
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    horizontal: {
      default: false,
      type: Boolean,
    },
    hide_search: {
      default: false,
      type: Boolean,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
    without: {
      default: null,
      type: Array,
    },
  },
  data() {
    return {
      request: new GetRequest('get_items', ['blog']),
    }
  },
  mounted() {
    if (this.data) this.request.data = this.data.blogs;
    this.get();
  },
  methods: {
    get() {
      if (!this._is_object(this.data)) return;
      this.request.setLimit(this.limit);
      this.request.setSort('date_created')
      this.request.addFilter('pro_profile_id', this.data.id)
      this.request.setFields('*, pictures.directus_files_id')
      this.request.get()
    },
    picture(item) {
      try {
        return item.pictures[0].directus_files_id;
      } catch (e) {
        return null;
      }
    },
    link(item) {
      return {name: this.route_name, params: {id: item.id}}
    }
  }
}
</script>

<style>
#profile-stories .slide-group-custom-arrows .v-slide-group__prev,
#profile-stories .slide-group-custom-arrows .v-slide-group__next {
  top: 75px !important;
}
</style>
<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col cols="12">
        <profile-project-form :profile_id="$route.params.id" :id="$route.params.project_id"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import ProfileProjectForm from "@/components/profiles/ProfileProjectForm";

export default {
  name: "ProfessionalProjectForm",
  components: {ProfileProjectForm},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

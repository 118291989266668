<template>
  <div v-if="_is_object(data)" class="profile-project-head">

    <!--  Desktop  -->
    <v-row class="hidden-sm-and-down">
      <v-col cols="12" sm="4">
        <div class="primary--text font-size-10">
          <div>
            <span class="text--disabled">{{ __('ProjectBudget') }} : </span>
            <span v-if="_is_object(data.budget_id)">{{ data.budget_id.name }}</span>
          </div>
          <div>
            <span class="text--disabled">{{ __('Country') }} : </span>
            <span>{{ data.country }}</span>
          </div>
          <div>
            <span class="text--disabled">{{ __('ZipCode') }} : </span>
            <span>{{ data.zip_code }}</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="project-title text-center">
          <div class="px-3 pb-2 font-weight-bold font-head d-inline-block font-size-18">{{ data.title }}</div>
          <div class="font-weight-bold py-2 font-size-14">{{ data.year }}</div>
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="text-sm-right">
        <button-share
            color="primary"
            class="text-transform-unset font-size-12 font-weight-unset rounded-lg"
            height="30"
            elevation="0"
            :title="__('ShareThisProject')"
        />
      </v-col>
    </v-row>

    <!--  Mobile  -->
    <div class="hidden-md-and-up full-width">
      <div class="d-sm-flex full-width align-sm-center justify-sm-center text-center">
        <div class="px-3 project-title text-truncate font-weight-bold font-head d-inline-block font-size-18" style="max-width: 350px ">
          {{ data.title }}
        </div>

        <space class="hidden-xs-only hidden-md-and-up"/>

        <router-link v-if="owner" :to="{name:'ProfessionalProfile', params: {id: owner.id}}">
          <div class="  d-flex grey--text align-center justify-center mx-auto py-3 font-size-14" style="max-width: 250px ">
            <v-avatar class="profile-logo" size="30">
              <assets-image :data="owner.logo"/>
            </v-avatar>
            <space width="10"/>
            <div class="font-mt-2 text-truncate" >{{ owner.business_name }}</div>
          </div>
        </router-link>
      </div>

      <v-row class="font-size-11 pt-4" dense>
        <v-col cols="6" sm="4">
          <span class="text--disabled text-truncate">{{ __('ProjectBudget') }} : </span>
          <span v-if="_is_object(data.budget_id)">{{ data.budget_id.name }}</span>
        </v-col>

        <v-col cols="6" sm="4" class="text-right text-sm-center">
          <span class="text--disabled text-truncate">{{ __('Country') }} : </span>
          <span>{{ data.country }}</span>
        </v-col>

        <v-col cols="6" sm="4" class="text-sm-right">
          <span class="text--disabled text-truncate">{{ __('ZipCode') }} : </span>
          <span>{{ data.zip_code }}</span>
        </v-col>
      </v-row>


      <profile-item-actions :data="owner" :sm="3"/>

      <v-divider class="primary mt-6"/>
    </div>
  </div>
</template>

<script>

import ProfileTypicalCost from "@/components/profiles/ProfileTypicalCost";
import AssetsImage from "@/components/assets/Image";
import Space from "@/components/app/Space";
import ProfileItemActions from "@/components/profiles/ProfileItemActions";
import ButtonShare from "@/components/buttons/ButtonShare";

export default {
  name: "PortfolioProjectHeader",
  components: {ButtonShare, ProfileItemActions, Space, AssetsImage, ProfileTypicalCost},
  props: {
    data: {
      default: null,
      type: Object,
    },

  },

  computed: {
    owner() {
      try {
        return this.data.pro_profile_id;
      } catch (e) {
        return {};
      }
    }
  }
}
</script>

<style>
.profile-project-head .project-title {
  border-bottom: var(--v-primary-lighten2) 1px solid !important;
}
</style>
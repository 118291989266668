<template>
  <div class="d-flex">

    <job-type-selector
        @input="(e) => change( e ? {'job_type_id': {'_eq': e }} : null, 'job_id')"
        :value="defaultJob()"
    />
    <space/>

    <tag-selector
        :api_params="['job_tag']"
        :collection_name="null"
        @input="(e) => change( e ?  {'tags': {'id': {'_eq': e }} }  : null, 'job_id')"
        :value="defaultTag()"
    />
    <space/>

    <date-range-selector
        @input="(e) => change( e  , 'date_created')"
        :value="getDefault('date_created')"
    />

    <space/>

    <user-selector
        :role="_role('client')"
        :title="__('Client')"
        :value="defaultClient('client_id')"
        status="active"
        @input="(e) => change( e ?  {'project_id': {'client_id': {'_eq': e }} }  : null, 'job_id')"

    />

    <space/>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" @click="reset" large outlined>
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";
import TagSelector from "../selectors/TagSelector";
import UserSelector from "../selectors/UserSelector";
import Space from "../app/Space";
import JobTypeSelector from "../selectors/JobTypeSelector";
import DateRangeSelector from "../selectors/DateRangeSelector";

export default {
  name: "LeadJobsFilters",
  components: {DateRangeSelector, JobTypeSelector, Space, UserSelector, TagSelector},
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_rooms: {
      default: true,
      type: Boolean,
    },
    with_status: {
      default: true,
      type: Boolean,
    }
  },
  data() {
    return {
      filter: {}
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
    change(value, key) {
      try {
        let operator = '_eq';
        if (key === 'date_created') operator = '_between';

        this.request.addFilter(key, value, operator);
        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },
    getDefault(key) {
      try {
        if (key === 'projects') return (this.request.getFilter(key))._eq;
        return this.request.getFilter(key);
      } catch (e) {
        return null;
      }
    },
    defaultTag() {
      try {
        let result = this.request.getFilter('job_id', false);
        if (!result || !result.tags.id || !result.tags.id._eq) return null;
        return result.tags.id._eq;
      } catch (e) {
        return null;
      }
    },
    defaultClient() {
      try {
        let result = this.request.getFilter('job_id', false);
        if (!result || !result.project_id.client_id || !result.project_id.client_id._eq) return null;
        return result.project_id.client_id._eq;
      } catch (e) {
        return null;
      }
    },
    defaultJob() {
      try {
        let result = this.request.getFilter('job_id', false);
        if (!result || !result.job_type_id || !result.job_type_id._eq) return null;
        return result.job_type_id._eq;
      } catch (e) {
        return null;
      }
    }
  },
}
</script>

<style scoped>

</style>
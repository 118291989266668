<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col cols="12" md="8">
        <blog-view :id="$route.params.id"/>
      </v-col>
      <v-col cols="12" md="4" >
        <div class="title my-3 ml-3">{{__('NewBlogs')}}</div>
        <blogs list_item limit="8" loader_count="5" :without="[$route.params.id]"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BlogView from "@/components/blogs/BlogView";
import Blogs from "../../../components/blogs/Blogs";

export default {
  name: "BlogViewPage",
  components: {Blogs, BlogView}
}
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"container-max-width"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('page-title',{attrs:{"text":_vm.__('Packages')}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('packages',{on:{"payment":_vm.payment}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('space',{attrs:{"height":"60"}})],1)],1),_c(VDialog,{attrs:{"persistent":"","max-width":"690"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c('div',{staticClass:"pa-6 py-12 text-center"},[_c('div',{domProps:{"innerHTML":_vm._s( _vm.__('PackagesDialog'))}}),_c('div',{staticClass:"d-flex justify-center"},[_c(VBtn,{staticClass:"mt-6 rounded-lg",attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.__('Cancel'))+" ")]),_c('space'),_c(VBtn,{staticClass:"mt-6 rounded-lg",attrs:{"color":"primary ","elevation":"0","to":{name:'StartProject'}}},[_vm._v(" "+_vm._s(_vm.__('Continue'))+" ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
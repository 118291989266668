<template>
  <div class="full-width">
    <div class="font-weight-bold" v-if="!hide_title">{{ __('Expertise') }}</div>

    <!-- Search -->
    <template v-if="show_search">
      <div class="pr-3 ">
        <items-search v-model="keyword" class="mb-3"/>
      </div>
    </template>

    <!--  Loader  -->
    <v-skeleton-loader v-if="request.isLoading()" type="card" height="38"/>

    <!--  List  -->
    <template v-else-if="!rows">
      <v-row class="pt-5 pt-sm-3">
        <template v-for="group_item in  request.data">
          <v-col cols="12" sm="4"  class="py-0 pb-3 font-size-12">

            <div class="my-md-3 my-sm-3 mt-0 mb-3  primary--text pl-sm-1 pl-8 font-weight-bold overflow-hidden" >
              <div class="d-inline py-3 font-size-14">{{ group_item.title }}</div>
            </div>

            <div class="py-2" v-for="(item, key) in items(group_item)">
              <label class="input-custom" >
                {{ item.text }}
                <input
                    type="checkbox"
                    v-model="result"
                    :value="return_object ? item : item.value"
                    @change="change"
                    :key="'job-type-list-selector-checkbox-'+key+ item.text"
                    class="ma-0 pa-0"
                    multiple
                />
                <span class="input-custom-checkmark"></span>
              </label>
            </div>

          </v-col>
        </template>

      </v-row>
    </template>

    <!--  Rows without columns  -->
    <template v-else>
      <template v-for="group_item in  request.data">
        <div
            @click="$set(is_minimal, group_item.id,  !is_minimal[group_item.id])"
            class="d-flex justify-space-between primary--text py-3 pr-1 d-inline font-weight-bold"
        >
          <div :title="group_item.title">
            <div :class="!show_minimal? 'under-line-primary' : ''">{{ group_item.title }}</div>
          </div>

          <v-btn v-if="show_minimal" color="primary" icon small>
            <v-icon v-if="is_minimal[group_item.id]">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </div>

        <div v-show="!show_minimal || is_minimal[group_item.id]">
          <div class="py-2" v-for="(item, key) in items(group_item)">
          <label class="input-custom" >
            {{ item.text }}
            <input
                type="checkbox"
                v-model="result"
                :value="return_object ? item : item.value"
                @change="change"
                :key="'job-type-list-selector-checkbox-'+key+ item.text"
                class="ma-0 pa-0"
                multiple
            />
            <span class="input-custom-checkmark" style="top: 5px;"></span>
          </label>
          </div>
        </div>
      </template>

    </template>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import CustomExpertise from "@/components/expertises/CustomExpertise";
import FromListSelector from "@/components/selectors/FromListSelector";
import ItemsSearch from "@/components/items/ItemsSearch";

export default {
  name: "JobExpertisesSelector",
  components: {ItemsSearch, FromListSelector, CustomExpertise},
  props: {
    value: {
      default: null,
      type: [Array, String],
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
    show_minimal: {
      default: false,
      type: Boolean,
    },
    hide_under_line: {
      default: false,
      type: Boolean,
    },
    show_custom: {
      default: false,
      type: Boolean
    },
    multiple: {
      default: false,
      type: Boolean
    },
    add_expertise_id: {
      default: false,
      type: Boolean
    },
    rows: {
      default: false,
      type: Boolean
    },
    show_search: {
      default: false,
      type: Boolean
    },
    return_object: {
      default: false,
      type: Boolean
    },
  },
  watch: {
    'value': {
      handler() {
        this.set();
      },
      deep: true,
    }
  },
  data() {
    return {
      request: new GetRequest(),
      custom_dialog: false,
      result: [],
      is_minimal: {},
      keyword: null,
    }
  },
  mounted() {
    this.set();
    this.get();
  },
  methods: {
    change() {
      let body = [];
      this.result.forEach(item => body.push(
          this.add_expertise_id
              ? {expertise_id: item}
              : item
      ))
      this.$emit('input', body)
    },
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.setApi('get_items')
      this.request.setApiParams(['job_type'])
      this.request.setFields('*,expertises.id,expertises.title')
      this.request.setParam('deep', {"expertises": {"_sort": ["sort", "title"]}})
      this.request.setLimit(-1);
      this.request.get();
    },
    set() {
      try {
        this.result = [];
        if (!this.value) return;
        this.value.forEach(item => this.result.push(
            this.add_expertise_id
                ? item.expertise_id
                : item
        ))
      } catch (e) {
        console.log(e);
      }
    },
    items(item) {
      try {
        let items = [];
        const data = item.expertises;
        if (!data) return;

        data.forEach((item) => {
          if (item && this.checkKeyword(item))
            items.push({
              text: item.title,
              value: item.id,
            })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    },
    checkKeyword(item) {
      try {
        if (!this.keyword) return true;
        return item.title.toLowerCase().indexOf(this.keyword.toLowerCase()) !== -1
      } catch (e) {
        return true;
      }
    }

  }
}
</script>

<style>
.input-custom input:checked ~ [class^='input-custom-checkmark'] {
  background-color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
}

.under-line-primary {
  border-bottom: 2px solid !important;
  border-color: var(--v-primary-base) !important;
}
</style>
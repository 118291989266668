<template>
  <v-row v-if="_is_object(data)">
    <v-col v-if="_is_object(data.user_created)" cols="6"  >
      <v-list-item :style="{'margin-left': reply ? '-40px' : ''}" class="px-0">
        <v-list-item-avatar>
          <avatar :data="data.user_created" color="secondary" size="35"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <span class="font-size-14">{{ data.user_created.full_name }}</span>
          </v-list-item-title>
          <v-list-item-subtitle :class="{'hidden-xs-only hidden-md-and-up':reply}">
            <date-text :data="data.date_created" use_format/>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="6"  v-else>
      <v-list-item :style="{'margin-left': reply ? '-40px' : ''}" class="px-0">
        <v-list-item-avatar>
          <v-avatar color="secondary"/>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ __('None')}}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-col>
    <v-col cols="6"   class="d-flex justify-end  align-center" style="line-height: 15px">
      <div v-if="!reply" style="font-size: 15px;"><rate :data="data.total_score" size="18"/></div>
      <date-text v-if="reply" class="font-size-12 hidden-sm-only grey--text" :data="data.date_created" use_format/>
      <slot/>
    </v-col>
  </v-row>
</template>

<script>

import Avatar from "@/components/account/Avatar";
import Rate from "@/components/app/Rate";
import DateText from "@/components/app/DateText";

export default {
  name: "ReviewUser",
  components: {Avatar, DateText, Rate},
  props: {
    data: {
      default: null,
      type: Object,
    },
    reply: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

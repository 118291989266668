<template>
  <div class="d-sm-flex">

    <template v-if="with_rooms">
      <room-selector @input="(e) => change(e, 'room_id')" :value="getDefault('room_id')"/>
      <space/>
    </template>


    <template v-if="with_creator">
      <user-selector :title="__('Creator')" @input="(e) => change(e, 'user_created')"
                     :value="getDefault('user_created')"/>
      <space/>
    </template>

    <template v-if="with_date">
      <date-range-selector :title="__('Date')" @input="(e) => change(e, 'date_created')"
                           :value="getDefault('date_created')"/>
      <space/>
    </template>

    <template v-if="with_project">
      <project-selector :title="__('Project')" @input="(e) => change({project_id:{_eq:e}}, 'projects')"
                        :value="getDefault('projects')"/>
      <space/>
    </template>


    <template v-if="with_status">
      <status-selector @input="(e) => change(e, 'status')" :value="getDefault('status')" :items="status_items"/>
      <space/>
    </template>


    <template v-if="with_size">
      <size-selector @input="(e) => change(e, 'size_id')" :value="getDefault('size_id')"/>
      <space/>
    </template>

    <template v-if="with_style">
      <style-selector @input="(e) => change(e, 'style_id')" :value="getDefault('style_id')"/>
      <space/>
    </template>


    <template v-if="with_color">
      <color-selector @input="(e) => change(e, 'color_id')" :value="getDefault('color_id')"/>
      <space/>
    </template>

    <template v-if="with_budget">
      <budget-selector :title="__('Budget')" @input="(e) => change(e, 'budget_id')" :value="getDefault('budget_id')"/>
      <space/>
    </template>


    <div class="pt-6">
      <v-btn class="border-light rounded-lg" @click="reset" large outlined>
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";
import RoomSelector from "../selectors/RoomSelector";
import StatusSelector from "../selectors/StatusSelector";
import Space from "../app/Space";
import UserSelector from "../selectors/UserSelector";
import DateRangeSelector from "../selectors/DateRangeSelector";
import ProjectSelector from "../selectors/ProjectSelector";
import SizeSelector from "../selectors/SizeSelector";
import StyleSelector from "../selectors/StyleSelector";
import RangeSelector from "../selectors/RangeSelector";
import ColorSelector from "../selectors/ColorSelector";
import BudgetSelector from "../selectors/BudgetSelector";

export default {
  name: "IdeasFilters",
  components: {
    BudgetSelector,
    ColorSelector,
    RangeSelector,
    StyleSelector,
    SizeSelector,
    ProjectSelector,
    DateRangeSelector,
    UserSelector, Space, StatusSelector, RoomSelector
  },
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    with_rooms: {
      default: true,
      type: Boolean,
    },
    with_budget: {
      default: false,
      type: Boolean,
    },
    with_project: {
      default: false,
      type: Boolean,
    },
    with_status: {
      default: false,
      type: Boolean,
    },
    with_date: {
      default: false,
      type: Boolean,
    },
    with_creator: {
      default: false,
      type: Boolean,
    },
    with_size: {
      default: true,
      type: Boolean,
    },
    with_style: {
      default: true,
      type: Boolean,
    },
    with_color: {
      default: true,
      type: Boolean,
    }
  },
  data() {
    return {
      filter: {},
      status_items: [
        {
          text: this.__('All'),
          value: null,
        },
        {
          text: this.__('Published'),
          value: 'published'
        },
        {
          text: this.__('Draft'),
          value: 'draft'
        },
        {
          text: this.__('Pending'),
          value: 'pending'
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
    change(value, key) {
      try {
        let operator = '_eq';
        if (key === 'date_created') operator = '_between';
        if (key === 'budget') operator = '_between';

        if (!value) this.request.removeFilter(key);
        else this.request.addFilter(key, value, operator);

        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },
    getDefault(key) {
      try {
        if (key === 'projects') return (this.request.getFilter(key))._eq;
        return this.request.getFilter(key);
      } catch (e) {
        return null;
      }
    }
  },
}
</script>

<style scoped>

</style>

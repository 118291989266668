import { render, staticRenderFns } from "./LeadProjectsStatus.vue?vue&type=template&id=a2ec4a06&scoped=true&"
import script from "./LeadProjectsStatus.vue?vue&type=script&lang=js&"
export * from "./LeadProjectsStatus.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a2ec4a06",
  null
  
)

export default component.exports
<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ __('Tag') }}</div>
    <v-select
        :items="items()"
        :value="value"
        @input="(e) => $emit('input', e)"
        class="custom-selector-border rounded-lg"
        outlined
        dense
    ></v-select>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "TagSelector",
  props: {
    api: {
      default: 'get_items',
      type: String,
    },
    api_params: {
      default: null,
      type: Array,
    },
    collection_name: {
      default: 'project',
      type: String,
    },
    value: {
      default: null,
      type: [String, Number],
    },
  },
  data() {
    return {
      request: new GetRequest(),
    }
  },
  mounted() {
    this.request.setApi(this.api)
    this.request.setApiParams(this.api_params ? this.api_params : ['personal_tag'])
    this.get()
  },
  methods: {
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      if (this.collection_name) this.request.addFilter('collection_name', this.collection_name);
      this.request.setLimit(500);
      this.request.get();
    },
    items() {
      try {
        let items = [
          {
            text: this.__('All'),
            value: null,
          },
        ];
        if (!this.request.data) return;
        this.request.data.forEach((item) => {
          items.push({
            text: item.title,
            value: item.id,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
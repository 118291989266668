<template>
  <div id="profile-fixed-head" class="full-width background pt-3">
    <profile-name v-bind="$attrs"/>
    <v-divider class="mt-5"/>
  </div>
</template>

<script>

import ProfileName from "@/components/profiles/ProfileName";

export default {
  name: "ProfileHeadFixed",
  components: {ProfileName},
  mounted() {
    window.addEventListener('scroll', () => {
      try {
        let element = document.getElementById('profile-fixed-head');
        if (400 < window.scrollY) return element.style.display = 'block';
        element.style.display = 'none'
      } catch (e) {
        console.log(e);
      }
    })
  },
}
</script>

<style>
#profile-fixed-head {
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 1000;
  display: none;
}
</style>
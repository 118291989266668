<template>
  <v-row>

    <!--  Select Pro    -->
    <v-col cols="12">
      <!--   Title   -->
      <div class="px-3 d-flex justify-space-between">
        <div>
         <span v-if=" (data.pro_id === null) && selected == null" class="font-weight-bold">
          <div v-if="data.suggested_pros && data.suggested_pros.length !== 0">
            {{ __('ProAssigned') }}
          </div>
         </span>
          <span v-else class="ma-1 font-weight-bold">
<!--          {{ __('ChoosePro') }}-->
         </span>
        </div>

        <div>
          <template v-if="(data.status === 'pending') && (data.pro_id === null) && selected == null">
            <v-btn
                @click="add_dialog = true"
                class="secondary text--text mb-3 mx-3"
                type="submit"
                elevation="0"
                small
                :disabled="disabled"
            >
              <span>{{ __('AddCustomPro') }}</span>
            </v-btn>
          </template>
        </div>
      </div>


      <!--   Selected Pro   -->
      <professional-list-item
          v-if="data.pro_id || selected"
          :data="data.pro_id ? data.pro_id : selected"
          :hide_actions="true"
          :show_buttons="!disabled"
          :show_menu="!disabled"
      />

      <!--   List   -->
      <job-pro
          v-else
          v-for="(item, key) in data.suggested_pros"
          v-bind:key="key"
          :data="item"
          @select="e => selected = e"
          :hide_actions="disabled"
      />

    </v-col>

    <!-- Add pro Dialog -->
    <modal fullscreen v-model="add_dialog">
      <template v-slot:title>
        <span>{{ __("AddPro") }}</span>
      </template>
      <template v-slot:main>
        <professional-add
            api_key="update_job"
            :api_params="[data.id]"
            :body_map="bodyMap"
            fields="*,*.*.*.*"
            @then="e => selectPro(e)"
            is_patch
        />
      </template>
    </modal>
  </v-row>
</template>

<script>
import JobPro from "./JobPro";
import Modal from "../modal/Modal";
import ProfessionalAdd from "../professionals/ProfessionalAdd";
import ProfessionalListItem from "../professionals/ProfessionalListItem";

export default {
  name: "JobPros",
  components: {ProfessionalListItem, ProfessionalAdd, Modal, JobPro},
  props: {
    data: {
      default: null,
      type: Object,
    },
    disabled: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      add_dialog: false,
      selected: null,
    }
  },
  methods: {
    selectPro(response) {
      try {
        this.selected = response.data.pro_id;
        this.add_dialog = false;
      } catch (e) {
        console.log(e)
      }
    },
    bodyMap(body) {
      return {
        'pro_id': body
      }
    },
  }
}
</script>

<style scoped>

</style>
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm._is_object(_vm.data))?_c('div',[(!_vm.hide_user)?_c(VListItem,{staticClass:"pl-0 grow"},[_c(VListItemIcon,[_c('avatar',{attrs:{"data":_vm.data.add_by,"color":"grey lighten-3"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c('full-name',{attrs:{"data":_vm.data.add_by}})],1)],1),_c(VListItemAction,[_c('div',{staticClass:"d-flex"},[(_vm._user && !_vm.bottom)?_c('idea-save',{attrs:{"id":_vm.data.id,"button_title":_vm.button_title}}):_vm._e()],1)])],1):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap px-0 py-6"},[_c(VChip,{staticClass:"my-2 mr-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.__('Room'))+" : "+_vm._s(_vm.room)+" ")]),(!_vm.hide_style)?_c(VChip,{staticClass:"my-2 mr-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.__('Style'))+" : "+_vm._s(_vm.style)+" ")]):_vm._e(),(!_vm.hide_size)?_c(VChip,{staticClass:"my-2 mr-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.__('Size'))+" : "+_vm._s(_vm.size)+" ")]):_vm._e(),_c(VChip,{staticClass:"my-2 mr-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.__('Color'))+" :   "),_c(VAvatar,{staticClass:"border-light",attrs:{"color":_vm.color}})],1),(_vm.data.photography_credit)?_c(VChip,{staticClass:"my-2 mr-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.__('PhotographyCredit'))+" :   "),_c('div',[_vm._v(_vm._s(_vm.data.photography_credit))])]):_vm._e(),(!_vm.hide_budget)?_c(VChip,{staticClass:"my-2 mr-2",attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.__('Budget'))+" :   "+_vm._s(_vm.budget)+" ")]):_vm._e()],1),(_vm.data.online_link)?_c('div',[_vm._v(" "+_vm._s(_vm.__('CopyRight'))+" "),_c('div',[_c('a',{attrs:{"href":_vm.data.online_link,"target":"_blank"}},[_vm._v(_vm._s(_vm.data.online_link))])])]):_vm._e(),_c('div',{staticClass:"text-center"},[(_vm._user && _vm.bottom)?_c('idea-save',{attrs:{"id":_vm.data.id,"button_title":_vm.button_title}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <assets-images :data="value" class="rounded-lg" :max-height="height" :max-width="width">
    <upload
        @then="uploaded"
        accept="image/*"
        :width="width"
        :height="height"
        :text_classes="upload_text_classes"
        :show_done="show_done"
    ></upload>

    <template v-slot:image="{item, index}">
      <v-btn icon @click="removeImage(index)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </assets-images>
</template>

<script>
import AssetsImages from "@/components/assets/Images";
import Upload from "@/components/assets/Upload";

export default {
  name: "UploadImages",
  components: {Upload, AssetsImages},
  props: {
    value: {
      default: null,
      type: [Array, Object],
    },
    width: {
      default: 190,
      type: [String, Number],
    },
    height: {
      default: 188,
      type: [String, Number],
    },
    upload_text_classes: {
      default: 'pt-12',
      type: [String],
    },
    show_done: {
      default: false,
      type: Boolean,
    }
  },
  watch: {
    'value'() {
      this.set()
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.set();
  },
  methods: {
    set() {
      this.items = this.value;
      if (!this._is_array(this.items)) this.items = [];
    },
    input() {
      this.$emit('input', this.items)
    },
    uploaded(response) {
      try {
        if (!this._is_object(response.data)) throw 'Not found any uploaded file id!';
        this.items.push({directus_files_id: response.data.id})
        this.input()
      } catch (e) {
        console.log(e);
      }
    },
    removeImage(index) {
      try {
        this.$delete(this.items, index)
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
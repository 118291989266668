<template>
  <div id="profile-about" v-if="_is_object(data)" >
    <div class="hidden-sm-and-down pb-6" >
      <h3>{{ __('About') }} {{ data.business_name }}</h3>
      <v-divider class="my-3"/>
      <div>
        <description :data="data.description"/>
      </div>
    </div>


    <!--  Mobile  -->
    <div class="hidden-md-and-up">
      <v-divider/>
      <show-more open_default>
        <template #title>
          <div class="font-weight-bold primary--text">{{ __('About') }}</div>
        </template>

        <template #more>
          <div class="py-3 px-3">
            <description :data="data.description"/>
          </div>
        </template>
      </show-more>
    </div>
  </div>
</template>

<script>

import Description from "@/components/app/Description";
import ShowMore from "@/components/app/ShowMore";

export default {
  name: "ProfileAbout",
  components: {ShowMore, Description},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {}
  },


}
</script>

<style scoped>

</style>
<template>
  <v-row>
    <v-col align="center">
      <logo style="margin-top: 190px"/>
      <div class="text-h4 py-16">{{__('NotFoundPage')}}</div>
      <v-btn color="primary" elevation="0" :to="{name:'Home'}">{{__('Home')}}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import Logo from "../../../components/app/Logo";
export default {
  name: "NotFoundPage",
  components: {Logo}
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ title ? title : __('Range') }}</div>
    <div class="border-light px-2 rounded-lg pointer py-2 px-2 text-truncate" @click="dialog = true">
      <span v-if="min === '-'">{{ __('All') }}</span>
      <span v-else>{{ min }} - {{ max }}</span>
    </div>
    <v-dialog v-model="dialog" persistent max-width="590">
      <v-card>
        <v-card-title class="text-h5">
          {{ title ? title : __('SelectRange') }}
        </v-card-title>
        <v-card-text>
          <v-row class="pt-5">
            <v-col>
              <div class="font-weight-bold">{{ __('Min') }}</div>

              <v-text-field
                  v-model="result.min"
                  oninput="validity.valid||(value='');"
                  min="0"
                  type="number"
                  dense
                  outlined
              />
            </v-col>
            <v-col>
              <div class="font-weight-bold">{{ __('Max') }}</div>

              <v-text-field
                  v-model="result.max"
                  min="0"
                  type="number"
                  oninput="validity.valid||(value='');"
                  dense
                  outlined
              />

            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-btn text @click="dialog = false">
            {{ __('Cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="pick" :disabled="parseInt(result.max) < parseInt(result.min)">
            {{ __('Select') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "RangeSelector",
  components: {},
  props: {
    value: {
      default: null,
      type: [Array, Object],
    },
    title: {
      default: null,
      type: String,
    },
  },
  computed: {
    min() {
      try {
        return this.value[0];
      } catch (e) {
        return '-';
      }
    },
    max() {
      try {
        return this.value[1];
      } catch (e) {
        return '-';
      }
    },
  },
  data() {
    return {
      result: {
        min: 0,
        max: 0,
      },
      dialog: null,
    }
  },
  mounted() {

  },
  methods: {
    pick(e) {
      try {
        this.dialog = false;
        this.$emit('input', [this.result.min, this.result.max]);
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style>
</style>
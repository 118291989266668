<template>
  <v-row>
    <v-col cols="12" md="5">
      <div class="text-h4 my-6">{{ __('SelectRoomTitle') }}</div>
      <div class="text--secondary">{{ __('SelectRoomText') }}</div>

    </v-col>
    <v-col cols="12" md="7">
      <div v-if="questions.getRooms()" class="d-flex flex-wrap">
        <room
            :data="item" v-for="(item, key) in questions.getRooms()"
            :key="'room-'+key"
            @click="(value) => questions.selectRoom(value)"
            :selected="questions.isSelectedRoom(item)"
            icon
            tile
            circle
        />
      </div>

      <not-found-data v-else/>
    </v-col>

    <v-col sm="12">
      <questions-next-button
          :questions="questions"
          :disabled="!questions.hasSelectedRooms()"
          fixed
      />
    </v-col>
  </v-row>
</template>

<script>

import {Questions} from "../../models/Questions";
import NotFoundData from "../app/NotFoundData";
import Room from "../rooms/Room";
import QuestionsNextButton from "./QuestionsNextButton";

export default {
  name: "SurveyRooms",
  components: {QuestionsNextButton, Room, NotFoundData},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  mounted() {
    this.set();
  },
  methods: {
    set() {
      try {
        // this method set again selected rooms object for fix select rooms
        // object from url
        let items = {};
        let selected = this.questions.selected_rooms;
        this.questions.getRooms().forEach((item) => {
          items[item.id] = item;
        })

        this.questions.selected_rooms = {};
        Object.keys(selected).forEach((item_id) => {
          this.questions.selectRoom(items[item_id]);
        })

      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <!--  Add Job Form  -->
  <form @submit.prevent="submit" v-if="request.body">
    <v-row>
      <v-col cols="12" md="6">
        <job-type-selector v-model="request.body.job_type_id"/>
      </v-col>
      <v-col cols="12" md="6">
        <date-selector v-model="request.body.desired_start_date" :title="__('DesiredStartDate')"/>
      </v-col>
      <v-col cols="12" md="12">
        <div class="font-weight-bold">{{ __('Description') }}</div>
        <v-textarea
            class="rounded-lg"
            v-model="request.body.description"
            dense
            auto-grow
            outlined
            required
        />
      </v-col>


      <v-col cols="12" md="12">
        <v-btn :color="pro_tab === 'suggest' ? 'primary': ''" @click="tab('suggest')" elevation="0" class="mr-3">
          {{ __('SuggestPro') }}
        </v-btn>
        <v-btn :color="pro_tab === 'select' ? 'primary': ''" @click="tab('select')" elevation="0" class="mr-3">
          {{ __('SelectPro') }}
        </v-btn>
        <v-btn :color="pro_tab === 'add' ? 'primary': ''" @click="tab('add')" elevation="0" target="_blank">
          {{ __('AddNewPro') }}
        </v-btn>
      </v-col>

      <!--  Select pro    -->
      <v-col v-if="pro_tab === 'select'" cols="12" md="6">
        <user-selector
            key="select-pro"
            ref="select-pro"
            v-model="request.body.pro_id"
            :first_label="__('SelectOne')"
            :title="__('AssignProForSelectedJob') "
            :role="_role('pro')"
            :expertises="{'expertise_id':{'job_type_id': {'_eq': request.body.job_type_id}}}"
            :disabled="!request.body.job_type_id"
        />
        <div v-if="!request.body.job_type_id" class="text--disabled">
          <v-icon>mdi-alert</v-icon>
          {{__('SelectJobTypForActivation')}}
        </div>
      </v-col>

      <!--  Suggest Pro    -->
      <v-col v-else cols="12" md="6">
        <user-selector
            key="suggest-pro"
            ref="suggest-pro"
            v-model="suggested_pros"
            :first_label="__('SelectMultiPro')"
            :title="__('SuggestedPros')"
            :multiple="true"
            :role="_role('pro')"
        />
      </v-col>

      <v-col md="12">

        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Submit') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>

      <!-- Add Pro Dialog -->
      <modal fullscreen v-model="add_pro_modal" @input="e => pro_tab = 'select'">
        <template v-slot:title>
          <span>{{ __("AddNewPro") }}</span>
        </template>
        <template v-slot:main>
          <professional-add @then="e => added(e)"/>
        </template>
      </modal>
    </v-row>
  </form>
</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import JobTypeSelector from "@/components/selectors/JobTypeSelector";
import DateSelector from "../selectors/DateSelector";
import UserSelector from "../selectors/UserSelector";
import Modal from "../modal/Modal";
import ProfessionalAdd from "../professionals/ProfessionalAdd";


export default {
  name: "JobAdd",
  components: {
    ProfessionalAdd,
    Modal,
    UserSelector,
    DateSelector,
    JobTypeSelector,
  },
  props: {
    data: {
      default: null,
      type: Object
    },
    project_id: {
      default: null,
      type: [Number, String],
      require: true
    },
  },
  data() {
    return {
      request: new PostRequest(),

      suggested_pros: [],
      pro_tab: 'suggest',
      add_pro_modal: null,
    }
  },
  mounted() {
    this.request.setApi(this.data ? 'update_job' : 'items_collection');
    this.request.setApiParams(this.data ? [this.data.id] : ['job']);
    this.request.setThen((e) => this.submitted(e));
    this.setBody();
  },
  watch: {
    suggested_pros(newVal) {
      try {
        this.request.body.suggested_pros = [];
        newVal.forEach(item => this.request.body.suggested_pros.push({pro_id: item}))
      } catch (e) {
        console.log(e)
      }
    },
  },
  methods: {
    setBody() {
      if (this.data) {

        this.request.setBody(
            {
              project_id: this.data.project_id,
              title: this.data.title,
              description: this.data.description,
              job_type_id: this.data.job_type_id,
              desired_start_date: this.data.desired_start_date,
              pro_id: this.data.pro_id,
              suggested_pros: this.data.suggested_pros,
            }
        );
        return;
      }

      this.request.setBody(
          {
            project_id: this.project_id,
            title: null,
            description: null,
            job_type_id: null,
            desired_start_date: null,
            pro_id: null,
            suggested_pros: []
          }
      );

      this.suggested_pros = [];
    },
    getId(item) {
      try {
        if (typeof item === 'object') return item.id;
        return item;
      } catch (e) {
        return null;
      }
    },
    uploaded(response) {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        this.request.body.pictures.push({directus_files_id: response.data.id});
      } catch (e) {
        console.log(e);
      }
    },
    submitted(data) {
      this.$emit('then', data);
      this.setBody();
    },
    submit() {
      this.before()
      if (this.data) return this.request.patch();
      this.request.post();
    },
    before() {
      try {
        this.$emit('before', this.request)
      } catch (e) {
        console.log(e);
      }
    },
    tab(value) {
      this.pro_tab = value;
      if (value === 'add') {
        this.add_pro_modal = true;
      }
    },
    added(response) {
      try {
        this.request.body.pro_id = response.data.id;
        this.add_pro_modal = false;
        this.$refs["select-pro"].get();
        this.tab('select');
      } catch (e) {
        console.log(e)
      }
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-menu
        v-if="user"
        v-model="menu"
        max-width="360"
        offset-y
        left
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center">
          <div v-if="show_info" class="hidden-sm-and-down text-right mt-1 pr-3" style="; max-width: 230px">
            <div class="subtitle-2 text-truncate " style="line-height:15px !important">{{ user.first_name }} {{ user.last_name }}</div>
            <div class="subtitle-2 text-truncate" style="font-weight: normal !important;line-height:15px !important"><small :title="user.email">{{ user.email }}</small></div>
          </div>

          <div  v-bind="attrs" v-on="on" >
            <avatar :data="user"  color="secondary" size="36"/>
          </div>

          <v-btn v-if="show_info" icon v-on="on" small class="ma-0 hidden-sm-and-down">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>

          <div v-if="show_info" v-on="on" class="d-flex px-3 ml-2 pointer flex-column ">
            <div class="grey lighten-2 " style="margin-bottom:6px; width: 4px; height: 4px;"> </div>
            <div class="grey lighten-2 " style="margin-bottom:6px; width: 4px; height: 4px;"> </div>
            <div class="grey lighten-2 " style="width: 4px; height: 4px;"> </div>
          </div>
        </div>
      </template>

      <v-card class="rounded-lg">
        <user role/>

        <v-divider></v-divider>

        <v-list>


          <v-list-item v-if="_permission('projects')" :to="{name:'Projects'}">
            <v-list-item-icon>
              <v-icon>mdi-archive-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ __('Project') }}</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="_permission('portfolio')" @click="openPortfolio()">
            <v-list-item-icon>
              <v-icon>mdi-card-account-details-star-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ __('Portfolio') }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name:'Profile'}">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ __('Profile') }}</v-list-item-title>
          </v-list-item>

        </v-list>

        <v-divider></v-divider>

        <v-card-actions class="grey lighten-4 pa-3">
          <v-spacer></v-spacer>
          <logout/>
        </v-card-actions>
      </v-card>
    </v-menu>
    <slot v-else name="gust"></slot>
  </div>
</template>

<script>
import Avatar from "./Avatar";
import Logout from "../auth/Logout";
import User from "../users/User";

export default {
  name: "Account",
  components: {User, Logout, Avatar},
  props: {
    show_info: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      menu: null,
    }
  },
  computed: {
    user() {
      return this.$store.state.user_info
    }
  },
  mounted() {

  },
  methods:{
    openPortfolio(){
      try {
        if (this._is_array(this._user.profile_id)) {
          let profile_id = this._user.profile_id[0];
          this.$router.push({name:'ProfessionalProfile', params: {id: profile_id}});
          return;
        }

        // create portfolio
        this.$router.push({name:'ProfessionalProfileForm',params: {id: 'CREATE'}});
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
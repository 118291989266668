<template>
  <div class=" font-size-14">
    <form @submit.prevent="$emit('submit')">

      <h4 class="text--disabled py-3 font-size-14 hidden-sm-and-down">{{ __('RateBetween') }}</h4>

      <v-row v-if="request" align="center" class="py-3">

        <v-col cols="12" sm="6" class="py-2 py-md-3">
          <div class="hidden-md-and-up px-2 font-size-14 pb-1 grey--text">
            {{ __('Quality') }} :
          </div>

          <v-card class="border-light rounded-lg" flat outlined>
            <v-card-text>
              <v-row align="center">
                <v-col cols="3" md="5" class="py-2 py-md-3">
                  <div class="hidden-sm-and-down ">{{ __('Quality') }} :</div>
                  <v-btn class="hidden-md-and-up rounded-lg border-light px-1 ml-2" min-width="42" outlined>{{ request.body.quality }}
                  </v-btn>
                </v-col>
                <v-col cols="9" md="7" class="py-2 py-md-3">
                  <slider-selector class="mt-2" v-model="request.body.quality" max="5" outlined/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="py-2 py-md-3">
          <div class="hidden-md-and-up px-2 font-size-14 pb-1 grey--text">
            {{ __('TimeManagement') }} :
          </div>
          <v-card class="border-light rounded-lg" flat outlined>
            <v-card-text>
              <v-row align="center">
                <v-col cols="3" md="5" class="py-2 py-md-3">
                  <div class="hidden-sm-and-down ">{{ __('TimeManagement') }} :</div>
                  <v-btn class="hidden-md-and-up rounded-lg border-light px-1 ml-2" min-width="42" outlined>
                    {{ request.body.time_management }}
                  </v-btn>
                </v-col>
                <v-col cols="9" md="7" class="py-2 py-md-3">
                  <slider-selector class="mt-2" v-model="request.body.time_management" max="5" outlined/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="py-2 py-md-3">
          <div class="hidden-md-and-up px-2 font-size-14 pb-1 grey--text">
            {{ __('Communication') }} :
          </div>
          <v-card class="border-light rounded-lg" flat outlined>
            <v-card-text>
              <v-row align="center">
                <v-col cols="3" md="5" class="py-2 py-md-3">
                  <div class="hidden-sm-and-down ">{{ __('Communication') }} :</div>
                  <v-btn class="hidden-md-and-up rounded-lg border-light px-1 ml-2" min-width="42" outlined>
                    {{ request.body.communication }}
                  </v-btn>
                </v-col>
                <v-col cols="9" md="7" class="py-2 py-md-3">
                  <slider-selector class="mt-2" v-model="request.body.communication" max="5" outlined/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" class="py-2 py-md-3">
          <div class="hidden-md-and-up px-2 font-size-14 pb-1 grey--text">
            {{ __('Value') }} :
          </div>
          <v-card class="border-light rounded-lg" flat outlined>
            <v-card-text>
              <v-row align="center">
                <v-col cols="3" md="5" class="py-2 py-md-3">
                  <div class="hidden-sm-and-down ">{{ __('Value') }} :</div>
                  <v-btn class="hidden-md-and-up rounded-lg border-light px-1 ml-2" min-width="42" outlined>{{ request.body.value }}
                  </v-btn>
                </v-col>
                <v-col cols="9" md="7" class="py-2 py-md-3">
                  <slider-selector class="mt-2" v-model="request.body.value" max="5" outlined/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-textarea
              :placeholder="__('Review')+' ...'"
              v-model="request.body.text"
              class="custom-selector-border rounded-lg  font-size-14"
              outlined
          />
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center justify-center">
            <upload-images v-model="request.body.images" width="140" height="140" upload_text_classes="pt-7"/>
          </div>
          <div class="text-uppercase pt-4 text-center">
            {{ __('AddImagesToReview') }}
          </div>
        </v-col>
        <v-col cols="12">
          <v-checkbox
              v-model="confirm"
              class="input-align-center font-size-12"
              style="align-items: start !important;"
              :label="__('ReviewPolicy')"
              required
          />
        </v-col>
        <v-col cols="12" class="d-flex align-center justify-center">
          <v-btn class="rounded-lg" color="primary" type="submit" elevation="0" :disabled="request.isLoading()" min-width="120" large>
            <span v-if="!request.isLoading()">{{ __('Submit') }}</span>
            <span v-else>...</span>
          </v-btn>
          <space/>
          <v-btn class="rounded-lg" type="button" outlined @click="$emit('cancel')" min-width="120" large>
            {{ __('Cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import Upload from "@/components/assets/Upload";
import SliderSelector from "@/components/selectors/SliderSelector";
import AssetsImages from "@/components/assets/Images";
import UploadImages from "@/components/assets/UploadImages";
import Space from "@/components/app/Space";

export default {
  name: "ReviewForm",
  components: {Space, UploadImages, AssetsImages, SliderSelector, Upload},
  props: {
    request: {
      default: new PostRequest(),
      type: PostRequest,
    }
  },

  data() {
    return {
      confirm: false,
    }
  }

}
</script>

<style scoped>

</style>
<template>
  <img :src="image_url"  v-bind="$attrs"/>
</template>

<script>
import app from "../../tools/App";
import urls from "../../tools/Urls";

export default {
  name: "AssetsImageSimple",
  props: {
    data: {
      default: null,
      type: [Object, String, Array]
    },
    crop_width: {
      default: null,
      type: [Number, String]
    },
    crop_height: {
      default: null,
      type: [Number, String]
    },
    quality: {
      default: 90,
      type: [Number, String]
    },
    type: {
      default: 'cover',
      type: String
    },
  },
  data() {
    return {
      image_url: null,
    }
  },
  mounted() {
    this.setImage();
  },

  methods: {
    setImage() {
      try {

        // check is string
        if (!Array.isArray(this.data)) {
          if (this.data == null) throw 'Not found image url!';
          this.image_url = this.getUrl(this.data);
          return;
        }

        // get first image from array
        this.image_url = this.getUrl(this.data[0].directus_files_id);
      } catch (e) {
        this.image_url = app.default_image;
      }
    },
    getUrl(id) {
      try {
        let url = urls.api('assets_url', [id]);

        let params = `?fit=${this.type}`;
        if (this.crop_width) params += `&width=${this.crop_width}`;
        if (this.crop_height) params += `&height=${this.crop_height}`;
        if (this.quality) params += `&quality=${this.quality}`;

        return url + params;
      } catch (e) {
        return '';
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <loading v-if="questions.request.isLoading()"/>

    <div id="embedCalendly" style="height: 890px"></div>
  </div>
</template>

<script>
import urls from "../../tools/Urls";
import {Questions} from "../../models/Questions";
import Loading from "../app/Loading";

export default {
  name: "Calendly",
  components: {Loading},
  props: {
    url: {
      default: null,
      type: String,
    }
  },
  data() {
    return {
      questions: new Questions(),
    }
  },
  mounted() {
    this.set();
  },
  methods: {
    set() {
      if (this.url) return this.calendly();
      this.questions.fetch(() => this.calendly())
    },
    calendly() {
      try {
        let url = urls.url('default_calendly');
        if (this.url) url = this.url;
        else url = this.questions.getCalendlyUrl()

        let color = this.$vuetify.theme.themes.light.primary;
        if (this.$vuetify.theme.dark) color = this.$vuetify.theme.themes.dark.primary;
        color = color.replace('#', '')

        document.getElementById("embedCalendly").innerHTML = '';

        Calendly.initInlineWidget({
          url: url + "?hide_gdpr_banner=1&background_color=ffffff&text_color=000000&primary_color=" + color,
          parentElement: document.getElementById("embedCalendly"),
          prefill: {name: "", email: ""},
          height: '450px',
          utm: {},
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
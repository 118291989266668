<template>
  <v-avatar v-if="data" v-bind="$attrs">
    <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
    ></v-progress-circular>
    <template v-else>
      <template v-if="icon"><v-icon>mdi-account</v-icon></template>
      <v-img v-else-if="data.avatar && data.avatar.length > 0" :src="getUrl(data.avatar)"/>
      <template v-else><div class="font-mt-2">{{ avatarChar() }}</div></template>
    </template>
  </v-avatar>
</template>

<script>
import urls from "@/tools/Urls";

export default {
  name: "Avatar",
  props: {
    data: {
      default: null,
      type: Object,
      required: true,
    },
    icon: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    avatarChar() {
      try {
        return this.data.first_name[0]
      } catch (e) {
        return 'R'
      }
    },
    getUrl(id) {
      try {
        let url = urls.api('assets_url', [id]);

        let params = `?fit=${this.type}`;
        if (this.crop_width) params += `&width=${this.crop_width}`;
        if (this.crop_height) params += `&height=${this.crop_height}`;
        if (this.quality) params += `&quality=${this.quality}`;

        return url + params;
      } catch (e) {
        return '';
      }
    }
  }
}
</script>

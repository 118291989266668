<template>
  <div>

    <loader count="3" type="avatar" horizontal v-if="request.isLoading()"/>

    <div v-else>
      <div v-if="social_media">
        <template v-for="(item, key) in social_media">
          <v-btn :key="'item-key-'+key" v-if="item && item.link" icon :href="item.link" class="mr-1 my-3"
                 target="_blank">
            <assets-image-simple :data="item.icon" width="25"  style="filter:brightness(0) invert(1)"/>
          </v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";
import Loader from "../app/Loader";
import AssetsImage from "../assets/Image";
import AssetsImageSimple from "@/components/assets/ImageHtml";

export default {
  name: "SocialLinks",
  components: {AssetsImageSimple, AssetsImage, Loader},
  data() {
    return {
      request: new GetRequest('get_settings'),
    }
  },
  computed: {
    social_media() {
      try {
        return this.request.data.social_media;
      } catch (e) {
        return null;
      }
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.request.row();
    }
  }
}
</script>

<style scoped>

</style>
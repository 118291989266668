<template>
  <v-app id="site-app">


    <v-app-bar
        app
        class="hidden-md-and-up pl-3 mobil-appbar"
        color="background"
        height="70"
        elevation="0"
    >
      <v-btn icon   @click="drawer = !drawer">
        <div class="d-flex flex-column">
          <div class="grey lighten-2 " style="margin-bottom:6px; width: 24px; height: 4px;"> </div>
          <div class="grey lighten-2 " style="margin-bottom:6px; width: 24px; height: 4px;"> </div>
          <div class="grey lighten-2 " style="width: 24px; height: 4px;"> </div>
        </div>
      </v-btn>

      <!--  Logo and Title    -->
      <v-toolbar-title class="px-3">
        <router-link to="/">
          <logo class="  mt-3" small width="21"/>
        </router-link>
      </v-toolbar-title>


      <v-spacer></v-spacer>

      <!--   Call Request   -->
      <button-call-request/>

      <space width="19"/>

      <v-badge color="red"  offset-y="12" offset-x="8">
        <template #badge>
          <div class="pa-0" style="padding-top:1px !important;">{{9}}</div>
        </template>
        <v-btn
            class="  secondary text--text text--lighten-4"
            icon
            :to="{name:'Login'}"
            elevation="0"
            width="36"
            height="36"
            disabled
        >
          <v-icon size="18">mdi-email</v-icon>
        </v-btn>
      </v-badge>

      <space width="19"/>

      <!--   Account   -->
      <account show_info>
        <template v-slot:gust>
          <v-btn
              class="  secondary text--text text--lighten-4"
              icon
              :to="{name:'Login'}"
              elevation="0"
              width="36"
              height="36"
          >
            <v-icon size="18">mdi-account</v-icon>
          </v-btn>
        </template>
      </account>
    </v-app-bar>


    <v-app-bar
        app
        class="hidden-sm-and-down"
        color="background"
        elevation="0"
        height="70"
        hide-on-scroll
        extension-height="50"
        extended
    >

      <v-container class="container-max-width">
        <v-row align="center">
          <v-col class="d-flex align-center py-0 pr-0">

            <!--  Logo and Title    -->
            <v-toolbar-title>
              <router-link to="/">
                <logo class=" mt-4"  width="130"/>
              </router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <!--   Call Request   -->
            <button-call-request/>

            <space width="20"/>

            <!--   Account   -->
            <account show_info>
              <template v-slot:gust>
                <v-btn
                    class="hidden-xs-only"
                       color="primary"
                       small
                       :to="{name:'Login'}"
                       elevation="0">
                  {{ __('Login') }}
                </v-btn>
              </template>
            </account>
          </v-col>
        </v-row>
      </v-container>


      <!--  Menu Items    -->
      <template v-slot:extension class="pa-0 ">
        <div class="full-width">
          <v-divider/>
          <v-container class="container-max-width">
            <v-row align="center">
              <v-col class="d-flex justify-space-between pt-0 pb-1">
                <template v-for="(item, key) in menu">
                  <v-btn
                      large
                      text
                      :to="item.to"
                      active-class="menu-active-class"
                      :key="'header-menu-'+key"
                      class="menu-active-class   font-head px-0 font-weight-bold"
                      style="text-transform: unset !important;"
                      elevation="0"
                      :disabled="!item.to"
                      :ripple="false"
                  >
                    {{ item.title }}
                  </v-btn>
                </template>
              </v-col>
            </v-row>
          </v-container>
          <v-divider/>
        </div>



      </template>
    </v-app-bar>


    <!-- Drawer   -->
    <v-dialog
        v-model="drawer"
        fullscreen
        style="z-index: 999999"
    >
      <v-card class="background">
        <div class="d-flex px-3 py-6 align-center align-content-center">
          <v-btn icon  color="grey lighten-2" class="ml-1 mr-3" @click="drawer = !drawer">
            <v-icon size="30">mdi-close</v-icon>
          </v-btn>

          <router-link to="/">
            <logo class="mt-1" small width="21"/>
          </router-link>
        </div>

        <v-list  class="mt-10" >
          <template v-for="(item, index) in menu">
            <v-list-item  :key="'header-drawer-'+index"  @click="drawer = !drawer" :to="item.to" :disabled="!item.to">
              <v-list-item-content class="py-6">
                <v-list-item-title class="hidden-xs-only font-head text-center font-size-36">{{ item.title }}</v-list-item-title>
                <v-list-item-title class="hidden-sm-and-up font-head text-center font-size-24">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="_is_array(menu) && index < menu.length -1 "/>
          </template>
        </v-list>
      </v-card>
    </v-dialog>

    <v-main app id="main">
      <router-view></router-view>
    </v-main>

    <v-divider/>

    <main-footer/>
  </v-app>

</template>

<script>
import Logo from "../app/Logo";
import Space from "../app/Space";
import Account from "../account/Account";
import MainFooter from "../footers/MainFooter";
import ButtonCallRequest from "@/components/buttons/ButtonCallRequest";

export default {
  name: 'MainView',
  components: {ButtonCallRequest, MainFooter, Account, Space, Logo},
  data() {
    return {
      drawer: null,
      menu: [
        // {title: this.__('Home'), to: null, icon: 'mdi-home'},
        {title: this.__('HowItWork'), to: {name: 'Home'}, icon: 'mdi-home'},
        {title: this.__('StartProject'), to: {name: 'StartProject'}, icon: 'mdi-projector-screen'},
        {title: this.__('FindProfessionals'), to: {name: 'ProfessionalsLanding'}, icon: 'mdi-account-hard-hat'},
        {title: this.__('GetIdeas'), to: {name: 'IdeasLanding'}, icon: 'mdi-lightbulb'},
        {title: this.__('Membership'), to: {name: 'Packages'}, icon: 'mdi-projector-screen'},
      ]
    }
  }
}
</script>

<style>
.menu-active-class:hover {
  color: rgb(151, 110, 80);
}

.menu-active-class::before {
  background-color: transparent !important;
  text-align: center;
  margin-top: 35px;

}

.v-toolbar__extension {
  padding: 0;
}

.v-app-bar.v-app-bar--fixed,
.v-toolbar__content, .v-toolbar__extension {
  z-index: 9999;
}

.mobil-appbar .v-badge__badge.red {
  height: auto !important;
  width: auto !important;
  padding: 3px;
  border: solid 1px white !important;

}
</style>
<template>
  <v-row class="full-width">

    <!--  Multiple  -->
    <v-col v-for="(item, key) in items" cols="12"  md="12" :sm="sm" class="py-2 px-2">
      <label class="input-custom font-size-12"  :for="'input-'+input_id+key+item.text">
        <slot name="label" :item="item">
          {{ item.text }}
        </slot>
        <input
            :id="'input-'+input_id+key+item.text"
            :type="multiple ? 'checkbox' : 'radio'"
            v-model="selected"
            @change="select"
            :value="return_object ? item : item.value"
            :key="'from-list-selector-checkbox-'+key+ item.text"
            :disabled="disabled || (_is_array(disabled_items) && disabled_items.includes(item.text))"
            class="ma-0 pa-0"
        />
        <span :class="{'input-custom-checkmark-radio': !multiple, 'input-custom-checkmark' : multiple}"></span>
      </label>
    </v-col>
    <slot></slot>
  </v-row>
</template>

<script>

export default {
  name: "FromListSelector",
  props: {
    value: {
      default: null,
      type: [String, Object, Array],
    },
    items: {
      default: null,
      type: Array
    },
    disabled_items: {
      default: null,
      type: Array
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    return_object: {
      default: false,
      type: Boolean,
    },
    id: {
      default: null,
      type: [String, Number],
    },

    sm: {
      default: 4,
      type: [String, Number],
    },
  },
  watch: {
    'value'() {
      this.set()
    },
  },
  data() {
    return {
      selected: [],
      input_id: '',
    }
  },
  mounted() {
    this.set();
  },
  methods: {
    set() {
      this.input_id = !this.id ? Math.random() : this.id;

      if (this.multiple) {
        this.selected = this._is_array(this.value) ? this.value : [];
        return;
      }
      this.selected = this.value;
    },
    select() {
      this.$emit('input', this.selected)
    }
  }
}
</script>

<style scoped>

</style>

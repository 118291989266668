import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c(VListItem,{staticClass:"px-0"},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"#0095DA"}},[_vm._v("mdi-note-check")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__('PhotoGuidelines')))])],1)],1),_c('div',{staticClass:"subtitle-2 font-size-12 text--disabled"},[_vm._v(_vm._s(_vm.__('PhotoGuidelinesText')))])],1),_c(VDivider,{staticClass:"mt-3"}),_c('div',[_c(VListItem,{staticClass:"px-0"},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-camera")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__('PhotoDo')))])],1)],1),_c('div',{staticClass:"subtitle-2 font-size-12 text--disabled",domProps:{"innerHTML":_vm._s(_vm.__('PhotoDoText'))}})],1),_c(VDivider,{staticClass:"mt-3"}),_c('div',[_c(VListItem,{staticClass:"px-0"},[_c(VListItemAvatar,[_c(VIcon,{attrs:{"color":"red"}},[_vm._v("mdi-camera")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(_vm.__('PhotoDont')))])],1)],1),_c('div',{staticClass:"subtitle-2 font-size-12 text--disabled",domProps:{"innerHTML":_vm._s(_vm.__('PhotoDontText'))}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <package-card v-if="card" v-bind="$attrs" @click="e => $emit('click', e)"/>
    <package-inline v-else v-bind="$attrs" @click="e => $emit('click', e)"/>
  </div>
</template>

<script>
import PackageCard from "./PackageCard";
import PackageInline from "./PackageInline";
export default {
  name: "Package",
  components: {PackageInline, PackageCard},
  props: {
    card: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
}
</script>


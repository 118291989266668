import Vue from "vue";
import {_t} from "../tools/Utils";
import auth from "../tools/Auth";

export class Permission {

    static items = {
        'projects': {
            title: _t('Projects'),
            to: {name: 'Projects'},
            icon: 'mdi-archive-cog'
        },
        'professionals': {
            title: _t('Professionals'),
            to: {name: 'Professionals'},
            icon: 'mdi-account-hard-hat',
            not_role: 'pro',
        },
        'clients': {
            title: _t('Clients'),
            to: {name: 'Clients'},
            icon: 'mdi-account-box',
            role: 'admin',
        },
        'ideas': {
            title: _t('Ideas'),
            to: {name: 'Ideas'},
            icon: 'mdi-lightbulb',
            role: 'admin'
        },
        'lead': {
            title: _t('Lead'),
            to: {name: 'Lead'},
            icon: 'mdi-archive',
            role: 'admin'
        },
        'leadjobs': {
            title: _t('LeadJobs'),
            to: {name: 'LeadJobs'},
            icon: 'mdi-hammer-screwdriver',
            role: 'pro',
        },
        'users': {
            title: _t('Users'),
            to: {name: 'Users'},
            icon: 'mdi-account',
            role: 'admin',
        },
        'blogs': {
            title: _t('Blogs'),
            to: {name: 'Blogs'},
            icon: 'mdi-bookshelf',
            role: 'admin',
        },
        'settings': {
            title: _t('Settings'),
            to: {name: 'Settings'},
            icon: 'mdi-cog',
            role: 'admin'
            ,
        }
    };


    constructor( ) {

    }

    /**
     * Check has access
     *
     * @param key
     */
    static has(key) {
        try{
            let user = auth.getUserInfo();
            let menu_list = user.role.menu_access.admin_panel;
            return menu_list.includes(key);
        }catch (e){
            return false;
        }
    }

    /**
     * Check has access
     *
     * @param object
     */
    static route(object) {
        try{
            let name = object.name.toLowerCase();
             if (!this.items[name]) return true;
            return this.has(name);
        }catch (e){
            return false;
        }
    }
}

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"click":function($event){return _vm.toggle(true)}}},[_vm._t("button"),_vm._t("default")],2),_c(VDialog,{attrs:{"max-width":"400px","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.title ? _vm.title : _vm.__('AruYouSure'))+" ")]),_c(VCardActions,[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.result(false)}}},[_vm._v(" "+_vm._s(_vm.__('Cancel'))+" ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.result(true)}}},[_vm._v(" "+_vm._s(_vm.__('Yes'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
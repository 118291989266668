<template>
  <div class="full-width">
    <div v-if="!hide_label" class="font-weight-bold">{{ title || __('Status') }}</div>
    <v-select
        :items="items"
        :value="value"
        @input="(e) => $emit('input', e)"
        class="custom-selector-border rounded-lg"
        dense
        outlined
    ></v-select>
  </div>
</template>

<script>

export default {
  name: "StatusSelector",
  props: {
    value: {
      default: null,
      type: String,
    },
    items: {
      default: null,
      type: Array
    },
    hide_label: {
      default: false,
      type: Boolean
    },
    title: {
      default: null,
      type: String,
    },
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped>

</style>

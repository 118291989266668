<template>
  <v-btn text color="grey" elevation="0" :to="{name:'Panel'}" v-bind="$attrs">
    {{ __('NoThanksSkip') }}
  </v-btn>
</template>

<script>
import {Questions} from "../../models/Questions";

export default {
  name: "QuestionsSkipButton",
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
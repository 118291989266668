import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm._is_object(_vm.data))?_c('div',{staticClass:"profile-project mb-1"},[_c('router-link',{attrs:{"to":{name: 'ProfessionalProject', params:{project_id: _vm.data.id}}}},[_c(VCard,{class:{'rounded-lg pa-0':true, 'opacity-6':_vm.data.status === 'draft'},attrs:{"flat":""}},[_c('assets-image',{attrs:{"data":_vm.image,"height":_vm.image_height,"type":"cover"}})],1),_c(VListItem,{staticClass:"px-0"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold font-size-14"},[_vm._v(_vm._s(_vm.data.title))]),_c(VListItemSubtitle,{staticClass:"text--disabled pt-1 font-size-12"},[_c('span',[_vm._v(_vm._s(_vm.data.city)+", "+_vm._s(_vm.data.country))]),_vm._v("  |   "),_c('span',[_vm._v(_vm._s(_vm.data.year))]),_vm._v("  |   "),_c('span',[_c('span',[_vm._v(_vm._s(_vm.data.ideas_count))]),_vm._v("  "),_c(VIcon,{staticClass:"text--disabled",staticStyle:{"margin-top":"-3px"},attrs:{"small":""}},[_vm._v("mdi-image")])],1)])],1),(_vm.editable)?_c(VListItemAction,[_c(VBtn,{staticClass:"btn-background-transparent align-center text-transform-unset px-3 px-md-1 rounded-lg",attrs:{"to":{name:'ProfessionalProjectEdit', params:{id: _vm.pro_id, project_id: _vm.data.id}},"min-width":"25","color":"primary","outlined":"","small":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-pencil-outline")]),(_vm.data.status === 'draft')?_c('span',[_vm._v(_vm._s(_vm.__('Draft'))+" ")]):_c('div',{staticClass:"hidden-md-and-up"},[_vm._v(" "+_vm._s(_vm.__("Edit")))])],1)],1):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
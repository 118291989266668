<template>
  <v-hover v-slot="{hover}">
    <div
        v-if="data && data.option"
        :class="{'text-center pa-3': true, 'pointer': select_able}"
        :style="{'min-width': min_width ? min_width + 'px' : 'auto'}"
        @click="select(data)"
    >

      <div
          :title="data.text"
          :class="{
            ' text-center justify-center mx-auto rounded-circle overflow-hidden': true,
            'elevation-5': hover,
            'grey lighten-3': !selected,
          }"

          :style="{
            'width': width ? width+'px' : 'auto',
            'height': height ? height+'px' : 'auto',
            'background': (selected ? '#e594004d' : ''),
            'padding-top': (width/3.5)+'px'
          }"
      >
        <div v-if="selected" style="position: absolute; width: 130px" class=" text-right">
          <v-icon>mdi-checkbox-marked-circle</v-icon>
        </div>

        <assets-image :data="data.icon" width="64" class="mx-auto"/>
      </div>
      <div class="text-center text-subtitle-1 text-uppercase pt-2" style="max-width: 160px">
        {{ data.text }}
      </div>

    </div>
  </v-hover>
</template>

<script>

import AssetsImage from "../assets/Image";

export default {
  name: "AnswerSelectorItem",
  components: {AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    selected: {
      default: false,
      type: Boolean,
    },
    select_able: {
      default: true,
      type: Boolean,
    },

    width: {
      default: 160,
      type: [Number, String],
    },
    height: {
      default: 160,
      type: [Number, String],
    },
    min_width: {
      default: 160,
      type: [Number, String],
    },

  },
  methods: {

    select(item) {
      this.$emit('select', item)
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div   v-if="_is_object(data)">
    <template v-if="!hide_title">
      <div class="d-flex pt-3" >
        <h3 >{{ __('UsersRating') }}</h3>
        <v-spacer/>
        <v-btn
            :to="{name:'ProfessionalProfileReview', props:{ id: data.id}}"
            class="text-transform-unset rounded-lg font-size-12  "
            color="primary"
            elevation="0"
            v-if="!_owner(data.pro_id)"
            height="30"
        >
          <v-icon small>mdi-comment-edit-outline</v-icon>&nbsp;
          {{ __('WriteReview') }}
        </v-btn>
      </div>

      <v-divider  class="my-3 mb-6"/>
    </template>


   <div class="py-5 mb-6">
     <v-row>
       <v-col cols="12" sm="6">
         <v-row>
           <v-col cols="4">
             <div class="grey--text text--darken-1 font-size-14">{{ __('Quality') }} :</div>
           </v-col>
           <v-col cols="8">
             <div class="mt-2">
               <slider-selector :value="data.quality" max="5" readonly/>
             </div>
           </v-col>
         </v-row>
       </v-col>
       <v-col cols="12" sm="6">
         <v-row>
           <v-col cols="4">
             <div class="grey--text text--darken-1 font-size-14">{{ __('TimeManagement') }} :</div>
           </v-col>
           <v-col cols="8">
             <div class="mt-2">
               <slider-selector :value="data.time_management" max="5" readonly/>
             </div>
           </v-col>
         </v-row>
       </v-col>
       <v-col cols="12" sm="6">
         <v-row>
           <v-col cols="4">
             <div class="grey--text text--darken-1 font-size-14">{{ __('Communication') }} :</div>
           </v-col>
           <v-col cols="8">
             <div class="mt-2">
               <slider-selector :value="data.communication" max="5" readonly/>
             </div>
           </v-col>
         </v-row>
       </v-col>
       <v-col cols="12" sm="6">
         <v-row>
           <v-col cols="4">
             <div class="grey--text text--darken-1 font-size-14">{{ __('Value') }} :</div>
           </v-col>
           <v-col cols="8">
             <div class="mt-2">
               <slider-selector :value="data.value" max="5" readonly/>
             </div>
           </v-col>
         </v-row>
       </v-col>
     </v-row>
   </div>
  </div>
</template>

<script>

import SliderSelector from "@/components/selectors/SliderSelector";
export default {
  name: "ProfileRatingDesktop",
  components: {SliderSelector},
  props: {
    data: {
      default: null,
      type: Object,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
  },
}
</script>

<style>

</style>
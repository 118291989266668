<template>
  <!--  Add Idea Form  -->
  <form @submit.prevent="request.post()" v-if="request.body.first_name !== undefined">
    <v-row>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('FirstName') }} *</div>
        <v-text-field
            v-model="request.body.first_name"
            dense
            outlined
            required
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('LastName') }} *</div>
        <v-text-field
            v-model="request.body.last_name"
            dense
            outlined
            required
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('Phone') }}</div>
        <v-text-field
            v-model="request.body.phone_number"
            dense
            outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('Email') }}</div>
        <v-text-field
            v-model="request.body.email"
            dense
            outlined
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('TelegramId') }}</div>
        <v-text-field
            v-model="request.body.telegram_id"
            dense
            outlined
        />
      </v-col>

      <v-col cols="12" md="6" v-if="!role">
        <role-selector
            v-model="request.body.role"
            :first_label="__('SelectOne')"
            :without='[
                "3448c9ef-6456-4cad-8015-87a4e8fe1c31",
                "ebe9313f-150a-4331-98bf-af294eec69cf",
                "ec5d18b5-047b-4ab5-b7f8-a089bd8be43a",
                "be3523ab-31c0-40a6-a363-300808d4dbbe"
              ]'
        />
      </v-col>

      <v-col cols="12" md="6">
        <status-selector :items="status_items" v-model="request.body.status"/>
      </v-col>

      <v-col md="12">

        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Submit') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>

    </v-row>
  </form>
</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import RoleSelector from "@/components/selectors/RoleSelector"
import StatusSelector from "@/components/selectors/StatusSelector";

export default {
  name: "UsersAdd",
  components: {StatusSelector, RoleSelector},
  props: {
    role: {
      default: null,
      type: String,
    }
  },
  data() {
    return {
      request: new PostRequest('add_user'),
      status_items: [
        {
          text: this.__('Active'),
          value: 'active',
        },
        {
          text: this.__('Archive'),
          value: 'archive',
        },
      ]
    }
  },
  mounted() {
    this.request.setThen(this.submitted);
    this.setBody();
  },
  methods: {
    setBody() {
      this.request.setBody(
          {
            first_name: null,
            last_name: null,
            phone_number: null,
            email: null,
            status: null,
            telegram_id: null,
            role: this.role ? this.role : null,
          }
      );
    },
    submitted(data) {
      this.$emit('then', data);
      this.setBody();
    }
  },
}
</script>

<style scoped>

</style>

// En
// English messages



module.exports = {
    'UploadGuide': 'Upload Guide',
    'RenotiveInc': 'Renotive Inc.',
    'Terms': 'Terms',
    'Careers': 'Careers',
    'Testimonials': 'Testimonials',
    'CopyRightTrademark': 'Copyright & Trademark',
    'ContactUs': 'Contact Us',
    'Services': 'Services',
    'AboutRenotive': 'About Renotive',
    'Team': 'Team',
    'Uploading': 'Uploading',
    'UploadImage': 'Upload Image',
    'PhotoDoText': '- Photos of residential spaces<br/>' +
        '- Large Photos (1000 pixels wide or more)<br/>' +
        '- JPEG, GIF, PNG, or 1-Page TIFF file formats<br/>' +
        '- Good Quality Photos ',
    'PhotoDo': 'Do\'s',
    'PhotoDontText': '- Photos of commercial or office spaces<br/>' +
        '- Small Photos<br/>' +
        '- PDF, Multi<br/>' +
        '-Page TIFF, or EPS file formats<br/>' +
        '- Low Quality Photos',
    'PhotoDont': 'Dont\'s',
    'PhotoGuidelinesText': 'Photos that do not meet these guidelines will be removed.',
    'PhotoGuidelines': 'Photo Guidelines',
    'SelectCover': 'Select For Cover',
    'Alert': 'Alert!',
    'ContinuingWill': 'Continuing will result in unpublishing your profile. Are you sure you want to continue?',
    'EditSocialMedia': 'Edit Social Media',
    'EditContactInformation': 'Edit Contact Information',
    'EditStories': 'Edit Stories',
    'EditProjects': 'Edit Projects',
    'WhatRoomRenovate': 'What room or space are you thinking to renovate?',
    'StartProjectNow': 'Start a Project Now!',
    'EditBusinessInformation': 'Edit Business Information',
    'EditAccountInformation': 'Edit Account Information',
    'ReadLess': 'Read Less',
    'UsersRatingReviews': 'Users Rating & Reviews',
    'RenotiveInc': 'Renotive Inc.',
    'Terms&Privacy': 'Terms & Privacy',
    'Contact': 'Contact',
    'SuggestedProfessionalByYour': 'Suggested Professional by your area',
    'OtherStoriesFromThisUser': 'Other stories from this user',
    'Story': 'Story',
    'CreateNewStory': 'Add New Blog',
    'deleted': 'Deleted!',
    'AddThisIdea': 'Add This Idea',
    'AboutUs': 'About Us',
    'OurStories': 'Our Stories',
    'Business': 'Business',
    'ForAddImagesSaveProject': 'Save the project to activate the add image section',
    'cities': 'Cities',
    'categories': 'Categories',
    'budgets': 'Budgets',
    'languages': 'Languages',
    'rating': 'Rating',
    'styles': 'Styles',
    'SortUpdated': 'Sort Updated',
    'Uploaded': 'Uploaded',
    'Code': 'Code',
    'Publish': 'Publish',
    'Image': 'Image',
    'MakeCoverImage': 'The first photo is chosen for the cover of the project',
    'AddMoreImage': 'Add More Images',
    'ImageTitle': 'Image Title',
    'ProjectDescription': 'Project Description',
    'Year': 'Year',
    'CreateNewProject': 'Create a new project',
    'Preview': 'Preview',
    'EditProfile': 'Edit Profile',
    'OtherProjectFromPro': 'Other Project from This Professional',
    'Message': 'Message',
    'ProfessionalInProject': 'Professional in this project:',
    'ProjectBudget': 'Project Budget',
    'ShareThisProject': 'Share This Project',
    'SaveToDraft': 'Save to Draft',
    'SaveAndPublish': 'Save & Publish',
    'AddImagesToReview': 'Add Images to Your Review',
    'ReviewPolicy': 'I confirm that this review meets the requirements of the Renotive Review Policy,  which includes verifying that I do not work for this professional or being a relative, or rival.',
    'RateBetween': 'Rate Between 0.0 to 5.0',
    'Review': 'Review',
    'WriteReviewFor': 'Write a Review For :',
    'UrlCopiedForShare': 'Url copied to clipboard!',
    'EditCoverPhoto': 'Edit Cover Photo',
    'AddNew': 'Add New',
    'AddNewProject': 'Add New Project',
    'PinterestProfileLink': 'Pinterest',
    'TwitterProfileLink': 'Twitter',
    'TiktakProfileLink': 'Tiktak',
    'InstagramProfileLink': 'Instagram',
    'LinkdinProfileLink': 'Linkdin',
    'FacebookProfileLink': 'Facebook',
    'SocialMedia': 'Social Media',
    'AddLanguage': 'Add Language',
    'LanguagesCommunicate': 'Languages you can communicate with:',
    'PhoneCountryCode': 'Phone Country Code',
    'Country': 'Country',
    'ContactInformation': 'Contact Information',
    'ServicesProvided': 'Services Provided',
    'MinCost': 'Min Cost',
    'MaxCost': 'Max Cost',
    'TypicalJobCostRange': 'Typical Job Cost Range',
    'ProfileEditBudgetTip': 'Tip: This budget update for your non-promoted Directory card may take up to 24 hours so please check back then to see your changes on the Directory. If you would like to update the budget filter for your promoted advertising card, please visit your Subscription Settings.',
    'ProfileEditBudgetText': 'The budget preferences you select here will be displayed in the Find Pros local directory. Which types of\n' +
        ' projects are you willing to speak to homeowners about? (Select all that apply)',
    'BusinessName': 'Business Name / Name publicly displayed',
    'AccountInformation': 'Account Information',
    'LoadMoreComments': 'Load More Comments',
    'Reply': 'Reply',
    'ReviewsFor': 'Reviews for ',
    'Value': 'Value',
    'Communication': 'Communication',
    'TimeManagement': 'Time Management',
    'WriteReview': 'Write Review',
    'Quality': 'Quality',
    'UsersRating': 'Users Rating',
    'Stories': 'Stories',
    'LoadMoreProjects': 'Load More Projects',
    'LicenseNumber': 'License Number',
    'TypicalJobCost': 'Typical Job Cost',
    'Fax': 'Fax',
    'Website': 'Website',
    'BusinessInformation': 'Business Information',
    'About': 'About',
    'Share': 'Share',
    'HireOnRenotive': 'Hire on Renotive',
    'EnterYourZIPCode': 'Enter Your ZIP Code:',
    'LikeToDesign': 'Like to Design or Renovate?',
    'JoinRenotive': 'Join Renotive Family as Professional:',
    'GetAccessToYourAccount': 'Get Access to Your Account:',
    'Company': 'Company',
    'RequestCall': 'Request a Call',
    'SearchCity': 'Search City',
    'Language': 'Language',
    'ProfessionalCategory': 'Professional Category',
    'ReadMore': 'Read More',
    'Socials': 'Socials',
    'LoginToSee': 'Login to see full contact information',
    'Reviews': 'Reviews',
    'SendMessage': 'Send Message',
    'reviews': 'reviews',
    'SeeAllProfessionals': 'See All Professionals',
    'ProjectsPageDescription': 'Professional Projects - Renotive',
    'OldestFirst': 'Oldest First',
    'NewestFirst': 'Newest First',
    'LowestRated': 'Lowest Rated',
    'HighestRated': 'Highest Rated',
    'SearchProfiles': 'Search Profession',
    'Styles': 'Styles',
    'Rating': 'Rating',
    'AnyRating': 'Any Rating',
    '3StarAndUp': '3 Star & Up',
    '4StarAndUp': '4 Star & Up',
    '5Star': '5 Star',
    'Categories': 'Categories',
    'Languages': 'Languages',
    'LowBudget': '$ Low Budget',
    'LowToMediumBudget': '$ Low to Medium Budget',
    'MediumToHighBudget': '$ Medium to High Budget',
    'UnlimitedBudget': '$ Unlimited Budget',
    'SearchExpertises': 'Search Profession & Expertises',
    'Room(s)': 'Room(s)',
    'WhichRoom': 'Which room do you like to design or renovate?',
    'home_image_text_3': 'PROJECT MANAGEMENT WITH CONSTRUCTION GUIDANCE',
    'home_image_text_2': 'DESIGN & PROCUREMENT SUPPORT',
    'home_image_text_1': 'MATCH WITH CURATED PROFESSIONALS',
    'Membership': 'Membership',
    'expired': 'Expired',
    'custom_package': 'Custom Package',
    'Order': 'Order',
    'PleaseProceedWithYour': 'Please proceed with your ({type}) plan payment and our team will contact you regrading your custom plan choice within 24 hours.',
    'ThanksForChoosingOurServices': 'Thanks for choosing our services. Our team will contact you within 24 hours.',
    'CustomPackage': 'Custom Package',
    'ProCannotCreateProject': 'You are professional and cannot create a project.',
    'EnterPhoneNumber': 'Enter your phone number to continue',
    'PhoneNumberText': 'Please enter your phone number',
    'Idea': 'Idea',
    'JobStatus': 'Job Status',
    'Continue': 'Continue',
    'PackagesDialog': 'Happy to see you are about to start a new project. Let\'s get your project set up!',
    'Editor': 'Editor',
    'Text': 'Text',
    'AllRightsReserved': 'All rights reserved',
    'CopyRight': '© Copyright',
    '30Days': '30 Day',
    'Payment': 'Payment',
    'Package': 'Package',
    'NewBlogs': 'New Blogs',
    'Summary': 'Summary',
    'OpenProject': 'Open project',
    'SelectAddress': 'Select Address',
    'lost': 'Lost',
    'won': 'Won',
    'contacted': 'Contacted',
    'lead': 'Lead',
    'new': 'New',
    'meeting_scheduled': 'Meeting Scheduled',
    'LeadStatus': 'Lead Status',
    'SelectJobTypForActivation': 'Select Job Typ For activation this section',
    'AssignProForSelectedJob': 'Assign Pro For Selected Job Type',
    'NotFoundProfessionals': '(This is the list of professionals you have previously added. In order to add more professional please go to your project page and add a new professional.)',
    'Expired': 'Expired',
    'YouNeedSubscription': 'You Need Subscribe!',
    'YouNeedSubscriptionText': 'For active all features you need purchase subscribe for your account.',
    'PackageAdded': 'Package Added',
    'CancelledPayment': 'Payment Cancelled',
    'closed': 'Closed',
    'on_hold': 'On Hold',
    'add_contract': 'Add Contract',
    'log_site_visit': 'Log Site Visit',
    'estimate_sent': 'Estimate Provided',
    'AddClient': 'Add Client',
    'StartProjectPageDescription': 'Remodeling Platform, Start your project with professionals and project, job and ideas management! rooms ideas',
    'ProfessionalsPageDescription': 'Remodeling Platform, Start your project with professionals and project, job and ideas management! rooms ideas',
    'BlogsDescription': 'Remodeling Platform, Start your project with professionals and project, job and ideas management! rooms ideas',
    'IdeasPageDescription': 'Remodeling Platform, Start your project with professionals and project, job and ideas management! rooms ideas',
    'RemodelingPlatformDescription': 'Remodeling Platform, Start your project with professionals and project, job and ideas management! rooms ideas',
    'AddTag': 'Add Tag',
    'ProjectManagement': 'Project Management',
    'UserLastLogin': 'User Last Login',
    'UserAgent': 'User Agent',
    'IP': 'IP',
    'Date': 'Date',
    'LastLogin': 'Last Login',
    'essential': 'Essential',
    'ultimate': 'Ultimate',
    'trial': 'Trial',
    'Lost': 'Lost',
    'Won': 'Won',
    'MeetingScheduled': 'Meeting Scheduled',
    'Contacted': 'Contacted',
    'New': 'New',
    'SelectRole': 'Select Role',
    'Pro': 'Pro',
    'NotFoundAnyProject': 'Not Found Any Project!',
    'TotalPay': 'Total Pay',
    'NumberOfProjects': 'Number Of Projects',
    'SendEmail': 'Send Email',
    'JobInfo': 'Job Info',
    'accepted': 'Accepted',
    'Reject': 'Reject',
    'Accept': 'Accept',
    'CreateDate': 'Created At',
    'rejected': 'Rejected',
    'pending': 'Pending',
    'Approved': 'Approved',
    'AddYourText': 'Add your text here ...',
    'NeedForNext': 'Professional Request',
    'MyCustomPro': 'My Added Pros',
    'CustomProfessionals': 'My Added Pros',
    'ProjectCost': 'Project Cost',
    'ProjectTime': 'Project Time',
    'material': 'Material',
    'labor_cost': 'Labor Cost',
    'SelectMultiPro': 'Select Multi Pro',
    'AssignPro': 'Assign Pro',
    'SuggestedPros': 'Suggested Pros',
    'SuggestPro': 'Suggest Pro',
    'SelectPro': 'Select Pro',
    'WorkCompletionStatus': 'Work Completion Status',
    'JobEdit': 'Edit Job',
    'DesignPackages': 'Design Packages',
    'Remove': 'Remove',
    'OK': 'OK',
    'AddJob': 'Add Job',
    'Connect': 'Connect',
    'Actions': 'Actions',
    'Name': 'Name',
    'Avatar': 'Avatar',
    'Call': 'Call',
    'AddCustomPro': 'Add Custom Pro',
    'ProNextNeeds': 'Pro Needs',
    'HasNotAnyDescription': 'No Description!',
    'NotFoundAnyJob': 'Not found any job!',
    'Jobs': 'Jobs',
    'AssignedJobs': 'Assigned Jobs',
    'Expertises': 'Expertises',
    'youtube': 'Youtube',
    'facebook': 'Facebook',
    'twitter': 'Twitter',
    'instagram': 'Instagram',
    'published': 'Published',
    'unpublished': 'Unpublished',
    'EditBlog': 'Edit Blog',
    'StartProjectTimeText': 'Select your project start date',
    'StartProjectTime': 'When does the project start?',
    'StartTime': 'Start Time',
    'StartDate': 'Start Date',
    'SelectClientText': 'Admin in this section can select project owner or create a new customer for project',
    'ProjectOwner': 'Project Owner',
    'Free': 'Free',
    'TotalFee': 'Total Fee',
    'Purchase': 'Purchase',
    'Note': 'Internal Note',
    'Comment': 'Comment',
    'Confirm': 'Confirm',
    'TestDialog': 'If you skip the questions we won\'t be able to give you rough project cost estimate.<br/> Would you like to skip and continue to start your project without rough estimate?',
    'QuestionsBudgetSubtitle': 'Does this estimate match your budget? Please adjust the roller to your budget.',
    'SelectCount': 'Select Count',
    'Packages': 'Packages',
    'design': 'Design',
    'subscription': 'Subscription',
    'project_management': 'Project Management',
    'AddToProject': 'Add To Project',
    'Clients' : "Clients",
    'Settings' : "Settings",
    'UserManagement' : "Users Management",
    'LeadJobs' : "Lead Jobs",
    'ProfessionalsManagement' : "Professionals Management",
    'Max' : "Max",
    'Min' : "Min",
    'Range' : "Range",
    'SelectRange' : "Select Range",
    'Pending' : "Pending",
    'CopyIdea' : "Copy Idea",
    'Renotive' : "Renotive",
    'QuestionsAuthFirstText' : "Our Renotive client manager will contact you shortly with the best professionals that match your project needs.",
    'WhatRoom' : "Which room do you need?",
    'Go' : "Go",
    'AcceptAddIdeaPolicy' : "If you do NOT include photo’s link you will only be able to upload the picture if you will take full responsility on right of publish. Please check box to accept.",
    'SelectOne' : "Select One",
    'UsersPictures' : "Users Pictures",
    'DateRange' : "Date Range",
    'Select' : "Select",
    'SelectDateRange' : "Select Date Range",
    'Pins' : "Pins",
    'User' : "User",
    'EditProfileImage' : "Edit Profile Image",
    'UnLock' : "Unlock",
    'UnArchive' : "Unarchive",
    'Archive' : "Archive",
    'SaveIdeaInProject' : "Add Idea To a Project",
    'Send' : "Send",
    'EditIdea' : "Edit Idea",
    'AddIdeaProject' : "Add Idea in Project",
    'AddedByPro' : "Added By Pro",
    'AddedByMe' : "Added By Client",
    'TimeLineText-5' : "- &nbsp;&nbsp;To improve the execution of your design and/or renovation project, a Renotive client manager will be following your journey and be standby if any issues pop-up.<br/><br/>- &nbsp;&nbsp;Consultation hours with Project Manager to discuss construction needs, execution plan, technical support etc. Select Gold Subscription tier.",
    'TimeLine-5' : "Get Support Along The Way: Project Management",
    'TimeLineText-4' : "- &nbsp;&nbsp;Gold subscription <span class='success--text'>provides a build handbook including time schedule, estimated cost for budgeting, material list.</span>" +
        "<br/><br/>- &nbsp;&nbsp;Pick your materials - Renotive can assist with procurement of finished materials. Renovative extends its trade discounts with vendors to drive savings and to improve the experience." +
        "<br/><br/>- &nbsp;&nbsp;Consultation hours with <b>Designer</b> to select your favorite design. Select Gold Subscription tier.",
    'TimeLine-4' : "Design & Procure",
    'TimeLineText-3' : "- &nbsp;&nbsp;<b>Silver and Gold subscription</b> offers <span class='success--text'>60 minutes consultation call with Renotive Client Manager to review our toolkit</span>. It grants you access to our professional database and offers contract templates.<br/><br/>" +
        "- &nbsp;&nbsp;Our algorithm matches professionals to your project based on the location, field of expertise, reviews, experience and availability. We’ll shortlist a few professionals that that match your project<br/><br/>" +
        "- &nbsp;&nbsp;Get estimates from your chosen professional. Connect with the firms you select.<br/><br/>" +
        "Benefit from our service and tools to compare and evaluate estimates. Based on years’ of experience in design, construction, we have bolstered relationships with professionals like technicians, contractors, handymen, painters, cleaning services, designers, carpenters, electricians, furniture repair etc..",
    'TimeLine-3' : "Find The Right Professional",
    'TimeLineText-2' : "- &nbsp;&nbsp;Take the quiz to understand your project needs, design preferences.<br/><br/>- &nbsp;&nbsp;Test results will summarize your design style.",
    'TimeLine-2' : "Take The Quiz",
    'CreateAnAccountText' : "- &nbsp;&nbsp;Signing up with Renotive is easy! Options to create your profile: 1/ Fill in your information 2/ Connect your Facebook or Google account.",
    'CreateAnAccount' : "Create an Account",
    'HowItWork' : "How It Works",
    'WePassOnOur' : "We pass on our trade discount<br/>so you save money ",
    'Panel' : "Panel",
    'RemodelingPlatform' : "Remodeling Platform",
    'NotFoundPage' : "Not Found Page",
    'ProjectIdeas' : "Project Ideas",
    'More' : "More",
    'LastProjectIdeas' : "The last 3 ideas added in the project",
    'Yes' : "Yes",
    'Cancel' : "Cancel",
    'AruYouSure' : "Are you sure?",
    'Copy' : "Copy",
    'ConvertToPublic' : "Convert To Public",
    'Telegram' : "Telegram",
    'LoadMore' : "Load More",
    'Blogs' : "Blogs",
    'OtherUserIdeas' : "Other ideas from this user",
    'MyIdeas' : "Picture to review",
    'Unlock' : "Unlock",
    'AddPackage' : "Add Package",
    'Tags' : "Tags",
    'Answers' : "Answers",
    'RoomsForProject' : "Rooms of this project:",
    'CostEstimate' : "Cost Estimate",
    'ProjectInfo' : "Project Info",
    'Creator' : "Creator",
    'Project' : "Project",
    'Completed' : "Completed",
    'Archived' : "Archived",
    'Active' : "Active",
    'SubscribeNow' : "Subscribe Now",
    'SignUpFreeManage' : "Sign up <br/>to manage your projects for free",
    'TakeOurQuiz' : "Take our style quiz",
    'ConnectWithUs' : "Connect with Us",
    'Users' : "Users",
    'Delete' : "Delete",
    'Edit' : "Edit",
    'Details' : "Details",
    'None' : "None",
    'estimate' : "Estimate",
    'plans' : "Plans",
    'materials' : "Materials",
    'work_schedule' : "Work Schedule",
    'pictures' : "Pictures",
    'CreatedAt': 'Created At',
    'Client': 'Client',
    'Tag': 'Tag',
    'Lead': 'Lead',
    'Trial': 'Trial',
    'Ultimate': 'Ultimate',
    'YourProjectCreated': 'Your project created successfully!',
    'GoToMyAccount': 'Go To My Account',
    'UploadFilesText': 'To receive a labor estimate and discover more design options for your renovation, book a free consultation with your Project Planner.<br/><br/>Upload photos of your space ahead of time to help refine your estimate. You can also take care of this later.',
    'NextSteps': 'Next steps',
    'QuestionsFilesText': 'I’d love to connect to get a better idea of your renovation goals and provide some resources along the way. Feel free to book a time that works best for you here.',
    'QuestionsFilesTitle': 'Thanks for getting started %s',
    'PerMonth': 'Per Month',
    'UltimateText': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, ',
    'EssentialText': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, ',
    'Essential': 'Essential',
    'FreeTrial': 'Free Trial',
    'StarterText': 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, ',
    'Starter': 'Starter',
    'QuestionsPackagesText': 'I’d love to connect to get a better idea of your renovation goals and provide some resources along the way. Feel free to book a time that works best for you here.',
    'QuestionsPackagesTitle': 'Thanks for getting started %s',
    'LetsDo': 'Let’s Do This!',
    'NoThanksSkip': 'No thanks! Please skip!',
    'Skip': 'Skip',
    'WannaGetQuick': 'Would you like to get a quick.<br/> estimation for your project?',
    'ShareYourStyle': 'Share your style',
    'AboutYourStyle': 'ABOUT YOUR STYLE',
    'SurveyAnalyzeTitle': 'Based on our super fancy algorithm™, your main style is %s.',
    'SignUp': 'Sign Up',
    'CallRequestText': 'Sign up or sign in to view your contractors, estimations, and schedule free consultation.',
    'CallRequestTitle': 'Do you know you can manage your project on our platform and save up to 20% on your renovation cost.',
    'By': 'By ',
    'HomeDesignIdeas': 'Home Design Ideas',
    'CallRequest': 'Call Request',
    'Analyzing': 'Analyzing...',
    'CreateNew': 'Create New',
    'SignInByGoogle': 'Sign in with Google',
    'QuestionsAuthText': 'Sign up or sign in to view your contractors, estimations, and schedule free consultation.',
    'QuestionsAuthTitle': 'Good news! You can manage your project on our platform and save up to 20% on your renovation cost.',
    'CalendlyText': 'I’d love to connect to get a better idea of your renovation goals and provide some resources along the way. Feel free to book a time that works best for you here.',
    'CalendlyTitle': 'Would you like to discuss your project budget with one of our experts?',
    'MyProjects': 'My Projects',
    'MyAccount': 'My Account',
    'ProjectCreatedSuccessfully': 'Your Project Created Successfully!',
    'ProjectCreatedText': 'You can manage the created project in your account',
    'QuestionsBudgetTitle': 'What budget do you have in mind for your project?',
    'QuestionsBudgetText': 'Average renovation budget for similar projects in your area',
    'ZipCode': 'Zip Code',
    'City': 'City',
    'State': 'State',
    'Address': 'Address',
    'ProjectAddressTitle': 'What is your address?',
    'ProjectAddressText': 'This address will be used for your home design project',
    'SelectStylesTitle': 'Select the Rooms that you Love?',
    'SelectStylesText': 'Start by selecting a minimum of 3 rooms that speak to you',
    'Next': 'Next',
    'SelectRoomTitle': 'Which rooms are you planning to give it some love?',
    'SelectRoomText': 'You can select one or more rooms. for select click in rooms.',
    'Back': 'Back',
    'GetStart': 'Get Start',
    'StartProjectTitle': 'We have a fun tool to find Your Interior Design Style and learn more about your project',
    'StartProjectText': 'Take our decorating style quiz to discover your unique style, so you can start designing your dream home.',
    'Password': 'Password',
    'Save': 'Save',
    'Rooms': 'Rooms',
    'Account': 'Account',
    'FindProfessionals': 'Find Professionals',
    'TelegramId': 'Telegram Id',
    'PhoneNumberHtml': 'Phone&nbsp;Number',
    'PhoneNumber': 'Phone Number',
    'Email': 'Email',
    'LastName': 'Last Name',
    'FirstName': 'First Name',
    'Welcome': 'Welcome!',
    'Profile': 'Profile',
    'Done': 'Done!',
    'Logout': 'Logout',
    'Dashboard': 'Dashboard',
    'Ideas': 'Ideas',
    'UploadFile': 'Upload File',
    'ClickForUpload': 'Click For Upload',
    'Submit': 'Submit',
    'Budget': 'Budget',
    'PhotographyCredit': 'Photography Credit',
    'DesignCredit': 'Design Credit',
    'OnlineLink': 'Online Link',
    'Title': 'Title',
    'WelcomeToRenotive': 'Welcome to Renotive!',
    'CreateNewIdea': 'Create New Idea',
    'Draft': 'Draft',
    'Published': 'Published',
    'Add': 'Add',
    'IdeasManagement': 'Ideas Management',
    'StartProject': 'Start Project',
    'GetIdeas': 'Browse Ideas',
    'Search': 'Search',
    'Filters': 'Filters',
    'Total': 'Total',
    'Home': 'Home',
    'Hired': 'Hired',
    'Login': 'Login',
    'All': 'All',
    'Status': 'Status',
    'Style': 'Style',
    'Size': 'Size',
    'Color': 'Color',
    'Reset': 'Reset',
    'Room': 'Room',
    'NotFoundData': 'Not found any data!',
    'RelatedIdeas': 'Related Ideas',
    'SignIn': 'Sign In',
    'ToRenotive': 'to continue to Renotive',
    'Professionals': 'Professionals',
    'Find': 'Find',
    'DownloadPortfolio': 'Download Portfolio',
    'Chat': 'Chat',
    'LogSiteVisit': 'Log Site Visit',
    'EstimateSent': 'Estimate Provided',
    'AddContract': 'Add Contract',
    'OnHold': 'On Hold',
    'Closed': 'Closed',
    'Projects': 'Projects',
    'DateAdded': 'Date Added',
    'ActiveProject': 'Active Project',
    'ArchivedProject': 'Archived Project',
    'UploadYourPicture': 'Upload Your Picture',
    'UploadPortfolio': 'Upload Portfolio',
    'Unpublished': 'Unpublished',
    'PublishIdea': 'Publish Idea',
    'CreateNewUser': 'Create new user',
    'Role': 'Role',
    'Phone': 'Phone',
    'AddNewUser': 'Add new user',
    'EditUser': 'Edit user',
    'ArchiveUser': 'Archive user',
    'ProfessionalsManagements': 'Professionals Managements',
    'CreateNewProfessional': 'Create New Professional',
    'AddNewPro': 'Add new pro',
    'ChangeOrder': 'Change Order',
    'Expertise': 'Expertise',
    'ManageExpertiseList': 'Manage expertise list',
    'OurPros': 'Our Pros',
    'NotApproved': 'Not approved',
    'AddByClient': 'Add by client',
    'ContractAmount': 'Contract Amount',
    'DesiredStartDate': 'Desired Start Date',
    'Invoices': 'Invoices',
    'Contracts': 'Contracts',
    'Description': 'Description',
    'ChooseOne': 'Choose One',
    'CustomExpertise': 'Custom Expertise',
    'Inactive': 'Inactive',
    'Create': 'Create',
    'AddPro': 'Add Pro',
    'ProAssigned': 'Suggested Professionals',
    'ChoosePro': 'Professional',
    'AddCustomExpertise': 'Add Custom Expertise',
    'ArchiveExpertise': 'Archive Expertise',
    'EditExpertise': 'Edit Expertise',
    'AddExpertise': 'Add Expertise',
    'Close': 'Close',
    'JobType': 'Job Type',
    'Portfolio': 'Portfolio',
    'EditPro': 'Edit Pro',
    'ArchivePro': 'Archive Pro',
    'RegistrationStatus': 'Registration Status',
    'AreYouSure': 'Are you sure?',
    'No': 'No',
    'Setting': 'Setting',
    'GoogleDrive': 'Google Drive',
    'Blog': 'Blog',
    'NewBlog': 'Add New Blog',

};

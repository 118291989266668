<template>
  <v-container>
    <page-title :text="__('CreateNewUser')"/>
    <users-add/>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import UsersAdd from "@/components/users/UsersAdd";

export default {
  name: "AddUserPage",
  components: {PageTitle, UsersAdd}
}
</script>

<style scoped>

</style>

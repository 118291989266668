import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[(_vm.horizontal)?_c(VSlideGroup,_vm._l((Number.parseInt(_vm.count)),function(n,index){return _c(VSlideItem,{key:'loader-horizontal'+index},[_c(VSkeletonLoader,_vm._b({key:index,staticClass:"ma-3",attrs:{"width":_vm.width,"type":_vm.type}},'v-skeleton-loader',_vm.attrs,false))],1)}),1):_vm._l((Number.parseInt(_vm.count)),function(n,index){return _c(VCol,{key:index,attrs:{"cols":_vm.cols,"sm":_vm.col_sm,"md":_vm.col_md,"lg":_vm.col_lg,"xl":_vm.col_xl}},[_c(VSkeletonLoader,_vm._b({key:index,staticClass:"mx-auto",attrs:{"width":_vm.width,"type":_vm.type}},'v-skeleton-loader',_vm.attrs,false))],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-list-item :class="{'package-item': true, 'secondary':selected } " two-line @click="$emit('click', data)" >
      <v-list-item-avatar size="70">
        <v-avatar color="secondary">
          {{ data.service_type }}
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="headline ">
          {{ data.title }}
          <v-list-item-subtitle>
            {{__(data.type)}}
          </v-list-item-subtitle>
        </v-list-item-title>
        {{ data.text }}
        <v-list-item-subtitle>
          <v-chip-group>
            <v-chip class="grey lighten-4" v-for="service in data.services" :key="service.service_id.id">{{service.service_id.title}}</v-chip>
          </v-chip-group>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action-text>
        <v-avatar
            color="secondary"
            class="font-weight-bold"
            size="50">
          <price :data="data.price" :default_price="__('Free')"/>
        </v-avatar>
      </v-list-item-action-text>
    </v-list-item>
  </div>
</template>

<script>
import Price from "../app/Price";
export default {
  name: "PackageInline",
  components: {Price},
  props: {
    data:{
      type: Object,
      default: null
    },
    selected:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
}
</script>

<style>

.package-item .v-slide-group__next--disabled,
.package-item .v-slide-group__prev--disabled {
  display: none;
}
</style>
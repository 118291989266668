<template>
  <v-row>

    <v-col cols="12" sm="12">
      <div class="text-h4 my-6 mt-16 ">{{ __('QuestionsPackagesTitle').replace('%s', user.first_name) }}</div>
      <div class="text--secondary" style="max-width: 690px">{{ __('QuestionsPackagesText') }}</div>
    </v-col>

    <v-col cols="12" sm="12">
      <user/>
    </v-col>

    <v-col cols="12" sm="12">
      <add-package
          :project_id="questions.project.id"
          v-if="questions.project"
          @then="questions.next()"
          unique_key="project-questions-packages"
          box
          set_selected
      />
    </v-col>
  </v-row>
</template>

<script>
import {Questions} from "../../models/Questions";
import User from "../users/User";
import AddPackage from "../packages/AddPackage";

export default {
  name: "QuestionsPackages",
  components: {AddPackage,  User},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  computed: {
    user() {
      return this.$store.state.user_info;
    }
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    select( ) {
      try {
        // if (subscribe_type === 'free') return this.questions.next();
        return this.questions.next();
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>

  <v-row>

    <!-- Loading -->
    <loading v-if="request.isLoading()"/>

    <!-- Data -->
    <v-col v-else>
      <div v-if="request.data && html" v-html="html"></div>
      <not-found-data v-else/>
    </v-col>
  </v-row>
</template>

<script>
import Loading from "../app/Loading";
import {GetRequest} from "../../models/GetRequest";
import NotFoundData from "../app/NotFoundData";

export default {
  name: "TemplateView",
  components: {NotFoundData, Loading},
  props: {
    name: {
      default: null,
      type: String,
    }
  },
  data() {
    return {
      request: new GetRequest('get_items', ['template']),
    }
  },
  computed:{
    html(){
      return this.request.data[0].text
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      this.request.addFilter('name', this.name);
      this.request.row();
    }
  }
}
</script>

<style scoped>

</style>
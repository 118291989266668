<template>
  <v-row justify="center">

    <v-col cols="12" sm="4"   v-if="!hide_free">
      <packages-box
          :image="require('@/assets/img/packages/free.png')"
          :title="__('Starter')"
          :text="__('StarterText')"
          @payment="e => payment(e)"
          :is_paying="is_paying"
          color="grey lighten-3"
      />
    </v-col>


    <v-col cols="12" sm="4" v-if="!hide_subscription">
      <packages-box

          :image="require('@/assets/img/packages/essential.png')"
          :title="__('Essential')"
          :text="__('EssentialText')"
          :types="['subscription']"
          @payment="e => payment(e)"
          :is_paying="is_paying"
          color="primary lighten-3"
      />
    </v-col>

    <v-col cols="12" sm="4"  v-if="!hide_ultimate">
      <packages-box
          :image="require('@/assets/img/packages/ultimate.png')"
          :title="__('Ultimate')"
          :text="__('UltimateText')"
          :types="[ 'project_management', 'design']"
          @payment="e => payment(e)"
          :is_paying="is_paying"
          color="primary"
      >
        <template #price="{price, selected}">

          <div v-if="!isCustom(selected, 'design') || !isCustom(selected, 'project_management')" class="text-center">
            <div class="text-h4">
              <price :data="price" :default_price="__('Free')"/>
            </div>
          </div>

          <div  v-if="isCustom(selected, 'design') && isCustom(selected, 'project_management')" class="text-center">
            {{ __('ThanksForChoosingOurServices')}}
          </div>
          <div v-else-if="isCustom(selected, 'design')" class="text-center mt-4">
            {{ __('PleaseProceedWithYour').replaceAll('{type}', getTitle(selected, 'project_management'))}}
          </div>
          <div v-else-if="isCustom(selected, 'project_management')" class="text-center mt-4">
            {{ __('PleaseProceedWithYour').replaceAll('{type}', getTitle(selected, 'design'))}}
          </div>
        </template>

        <template #button-title="{types, selected}">
          <span v-if="isCustom(selected, 'design') && isCustom(selected, 'project_management')">{{__('Order')}}</span>
          <span v-else-if="!types || !Array.isArray(types)">{{ __('FreeTrial') }}</span>
          <span v-else>{{ __('Payment') }}</span>
          <span v-if="is_paying">...</span>
        </template>
      </packages-box>
    </v-col>

  </v-row>
</template>

<script>
import PackagesBox from "./PackagesBox";
import Price from "../app/Price";

export default {
  name: "Packages",
  components: {Price, PackagesBox},
  props: {
    hide_free: {
      default: false,
      type: Boolean,
    },
    hide_subscription: {
      default: false,
      type: Boolean,
    },
    hide_ultimate: {
      default: false,
      type: Boolean,
    },
    is_paying: {
      default: false,
      type: Boolean,
    } ,
  },
  data() {
    return {}
  },
  mounted() {

  },
  computed: {},
  methods: {
    payment(e) {
      this.$emit('payment', e)
    },
    isCustom(selected, type){
      try {
        const custom_packages_ids = {'design': '0eb52e10-4798-48d5-88b3-639ff5a15720', 'project_management': '2438fc2f-d142-4a11-838f-89c0048c4d90'};
        return selected && selected[type] && selected[type].id === custom_packages_ids[type];
      } catch (e) {
        return false;
      }
    },
    getTitle(selected, type){
      try {
        return selected[type].title;
      } catch (e) {
        return null;
      }
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <v-card v-if="data && !_access('pro')" flat class=" rounded-lg pb-1" outlined>
    <v-list-item>
      <v-list-item-title class="title">
        {{ __('Answers') }} <span v-if="count">( {{ count }} )</span>
      </v-list-item-title>
      <v-list-item-action>
        <v-btn icon @click="show_more = !show_more">
          <v-icon v-if="!show_more">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>

    <div v-if="show_more" class="pb-4">
      <div v-if="data && Array.isArray(data.quiz_answers) && data.quiz_answers.length !==0">
        <v-list-item v-for="(item, key) in data.quiz_answers" :key="'project-detail-answer-'+key">
          <v-list-item-icon v-if="item.answer && typeof item.answer === 'object'">
            <assets-image :data="item.answer.icon" width="48"/>
          </v-list-item-icon>
          <v-list-item-content>
            <div v-if="item.item && typeof item.item === 'object'">
              <div v-html="item.item.question_title"></div>
            </div>
            <div v-if="item.answer && typeof item.answer === 'object'" class="text--disabled">
              <div v-html="item.answer.text"></div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-else>
        <not-found-data class="pb-16"/>
      </div>
    </div>
  </v-card>
</template>

<script>
import NotFoundData from "../app/NotFoundData";
import AssetsImage from "../assets/Image";

export default {
  name: "ProjectAnswers",
  components: {AssetsImage, NotFoundData},
  props: {
    data: {
      default: null,
      type: [Object, Array],
    }
  },
  computed: {
    count() {
      try {
        return this.data.quiz_answers.length;
      } catch (e) {
        return 0;
      }
    }
  },
  data() {
    return {
      show_more: false,
    }
  }
}
</script>

<style scoped>

</style>
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profiles-filter-item full-width"},[_c(VFadeTransition,[_c('div',{staticClass:"d-flex justify-space-between pointer",on:{"click":function($event){_vm.show = !_vm.show}}},[_vm._t("title",function(){return [_c('div',{staticClass:"font-weight-bold py-3"},[_vm._v(_vm._s(_vm.title))])]}),_c('div',{staticClass:"d-flex align-center"},[(_vm.count)?_c(VAvatar,{staticClass:"background--text mr-1",attrs:{"size":"20","color":"primary"}},[_c('div',{staticClass:"font-mt-2 font-size-12"},[_vm._v(_vm._s(_vm.count))])]):_vm._e(),(!_vm.show)?_c(VIcon,[_vm._v("mdi-chevron-down")]):_c(VIcon,[_vm._v("mdi-chevron-up")])],1)],2)]),_c(VScrollYTransition,[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:{'light-scroll-y': !_vm.without_light_scroll, 'mt-2 mb-4': true}},[_vm._t("default",function(){return [_c('from-list-selector',_vm._b({on:{"input":e => _vm.$emit('input', e)},scopedSlots:_vm._u([{key:"label",fn:function({item}){return [_vm._t("label",null,{"item":item})]}}],null,true)},'from-list-selector',_vm.$attrs,false))]})],2)]),_c(VDivider)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
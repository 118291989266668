<template>
  <div v-if="_is_object(data)">
    <v-fade-transition>
      <v-list class="mt-3 " dense>
        <v-list-item
            v-for="(item) in items"
            :class="active_item === item.element_id ? 'primary--text font-weight-bold profile-menu-active' : 'profile-menu-default'"
            @click="click(item.element_id)"
        >
          <span class="font-size-14">{{ item.text }}</span>
        </v-list-item>
      </v-list>
    </v-fade-transition>
  </div>
</template>

<script>

export default {
  name: "ProfileMenu",
  components: {},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      active_item: 'profile-about',
      items: [
        {
          text: this.__('AboutUs'),
          element_id: 'profile-about',
        },
        {
          text: this.__('Business'),
          element_id: 'profile-business',
        },
        {
          text: this.__('Projects'),
          element_id: 'profile-projects',
        },
        {
          text: this.__('OurStories'),
          element_id: 'profile-stories',
        },
        {
          text: this.__('Reviews'),
          element_id: 'profile-reviews',
        }
      ]
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll() {
      try {
        let scrollY = window.scrollY;
        this.items.forEach((item, index) => {
          let item_offset = this.getOffset(item.element_id) - 150;
          let next_item_offset = document.body.clientHeight;
          let next_item = this.items[index + 1];
          if (scrollY === 0) this.active_item = 'profile-about'
          if (this._is_object(next_item)) next_item_offset = this.getOffset(next_item.element_id)
          if (item_offset < scrollY && scrollY < next_item_offset) this.active_item = item.element_id;
        })
      } catch (e) {
        console.log(e);
      }
    },
    click(id) {
      try {
        window.scrollTo(0, this.getOffset(id));
      } catch (e) {
        console.log(e);
      }
    },
    getOffset(id) {
      try {
        return (document.getElementById(id)).offsetTop;
      } catch (e) {
        return 0;
      }
    }
  }
}
</script>

<style>
.profile-menu-active {
  border-right: 5px solid;
  border-color: var(--v-primary-base);
}
.profile-menu-default {
  border-right: 4px solid;
  border-color: #e0e0e0;
}
</style>
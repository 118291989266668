<template>
  <v-container>
    <project-details :id="$route.params.id"/>
  </v-container>
</template>

<script>
import ProjectDetails from "../../../components/projects/ProjectDetails";

export default {
  name: "ProjectPage",
  components: {ProjectDetails }
}
</script>

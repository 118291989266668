<template>
  <div>
    <confirm :title="__('ArchiveUser')"
             :text="__('AreYouSure')"
             @then="submit"
             :value="value"
             v-bind="$attrs"
    >
    </confirm>
  </div>
</template>

<script>

import Confirm from "@/components/modal/Confirm";
import {PostRequest} from "@/models/PostRequest";

export default {
  name: "UserArchiveModal",
  components: {Confirm},
  props: {
    data: {
      default: null,
      type: Object,
      require: true
    },
    value: {
      default: null,
      type: Boolean,
    },
    api_key: {
      default: 'archive_professional',
      type: String,
    },
  },
  data: () => {
    return {
      request: new PostRequest(),
    }
  },
  mounted() {
    this.request = new PostRequest(this.api_key)
    this.request.setApiParams([this.data.id]);
  },
  methods: {
    setBody() {
      this.request.setThen(this.submitted);
      this.request.setBody(
          {
            status: 'archive',
          },
      );
    },
    submit(e) {
      if (e) {
        this.setBody();
        this.request.patch();
      }
      this.$emit('input', e)
    },
    submitted(){
      this.data.status = 'archive';
      this.$emit('then', this.data);
    }
  },
}
</script>

<style scoped>

</style>

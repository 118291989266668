<template>
  <div>

    <v-row v-if="add_is_visible">
      <v-col class="d-flex justify-space-between">
        <div class="d-flex width-full">

          <!-- Add Button   -->
          <template>
            <v-btn
                color="primary" elevation="0" large class="hidden-sm-and-down rounded-lg"
                :to="{name:'AddBlog'}"
            >
              {{ __('NewBlog') }}
            </v-btn>
            <v-btn color="primary" fab elevation="0" small class="hidden-md-and-up" :to="{name:'AddBlog'}">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
            <space/>
          </template>

        </div>
      </v-col>
    </v-row>

    <!-- Loading -->
    <div v-if="request.isLoading()">
      <loading v-if="single"/>
      <loader
          v-else-if="list_item"
          type="list-item-three-line"
          cols="12"
          :count="loader_count ? loader_count : limit"
      />
      <loader
          v-else
          cols="12"
          col_sm="6"
          col_md="4"
          col_lg="4"
          col_xl="2"
          :count="loader_count ? loader_count : limit"
      />
    </div>

    <!-- List -->
    <v-row v-else>
      <!--   List Item Type   -->
      <template v-if="list_item">
        <v-col v-for="(item, key) in request.data" :key="'blog-item-'+key" cols="12">
          <blog-item
              :data="item"
              @edited="request.reload()"
              @archived="request.reload()"
              v-bind="item_attrs"
              :in_modal="in_modal"
          />
        </v-col>
      </template>

      <v-col v-else v-for="(item, key) in request.data" :key="'blog-'+key" cols="12" :sm="single ? 12 : 4">
        <blog-single
            :vertical="single"
            :summary="summary"
            :data="item"
            :height="single ? 370: 250"
            @edited="request.reload()"
            @archived="request.reload()"
            v-bind="item_attrs"
        />
      </v-col>
      <v-col cols="12">

        <!--   Not found data   -->
        <not-found-data v-if="!request.data" style="padding-top: 130px !important; padding-bottom: 130px !important"/>

        <!-- Load More -->
        <load-more pagination v-if="!hide_load_more && !single" :request="request"/>

      </v-col>
    </v-row>
  </div>
</template>

<script>

import BlogSingle from "./BlogSingle";
import {GetRequest} from "../../models/GetRequest";
import Space from "@/components/app/Space";
import Loader from "../app/Loader";
import Loading from "../app/Loading";
import NotFoundData from "../app/NotFoundData";
import LoadMore from "../app/LoadMore";
import BlogItem from "./BlogItem";

export default {
  name: "Blogs",
  components: {BlogItem, LoadMore, NotFoundData, Loading, Loader, Space, BlogSingle},
  props: {
    data: {
      default: null,
      type: Object
    },
    limit: {
      default: null,
      type: [String, Number]
    },
    single: {
      default: false,
      type: Boolean
    },
    list_item: {
      default: false,
      type: Boolean
    },
    status: {
      default: 'published',
      type: String
    },
    loader_count: {
      default: 12,
      type: [String, Number],
    },
    add_is_visible: {
      default: false,
      type: Boolean,
    },
    summary: {
      default: false,
      type: Boolean,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    sort: {
      default: 'date_created',
      type: String,
    },
    without: {
      default: null,
      type: Array,
    },
    in_modal: {
      default: false,
      type: Boolean,
    },
    item_attrs: {
      default: null,
      type: Object,
    },
  },
  data: () => {
    return {
      request: new GetRequest('get_items', ['blog']),
    }
  },
  watch: {
    'without'() {
      this.get();
    },
    'status'() {
      this.get();
    },
    'room_id'() {
      this.get();
    },
    'creator'() {
      this.get();
    },
    'api_params'() {
      this.get();
    },
    'api'() {
      this.get();
    },
    'sort'() {
      this.get();
    },
    'fields'() {
      this.get();
    },
    'filters'() {
      this.get();
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    get(){
      this.request.reset();
      this.request.setFields('*.*');
      this.request.setLimit(!this.single ? this.limit : 1);
      this.request.addFilter('status', this.status);
      this.request.setSort(this.sort)
      this.request.setNotIn('id', this.without)
      this.request.get();
    }
  },
}
</script>

<style scoped>

</style>
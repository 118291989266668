<template>
  <div class="reviews-actions">
    <!--  Sort  -->
    <v-select
        :items="sort_items"
        v-model="actions.sort"
        @input="reload"
        class="custom-selector-border rounded-lg font-size-14"
        outlined
        dense
    >
    </v-select>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";

export default {
  name: "ReviewsActions",
  components: {},
  props: {
    request: {
      default: new GetRequest(),
      type: GetRequest,
      required: true,
    }
  },
  data() {
    return {
      actions: {
        sort: {'filter': {"reply_to": {"_null": true}}, 'column': 'total_score', 'order': '-'}
      }
    }
  },
  computed: {

    sort_items() {
      return [
        {
          text: this.__('HighestRated'),
          value: {'filter': {"reply_to": {"_null": true}}, 'column': 'total_score', 'order': '-'},
        },
        {
          text: this.__('LowestRated'),
          value: {'filter': {"reply_to": {"_null": true}}, 'column': 'total_score', 'order': ''},
        },
        {
          text: this.__('NewestFirst'),
          value: {'filter': {"reply_to": {"_null": true}}, 'column': 'date_created', 'order': '-'},
        },
        {
          text: this.__('OldestFirst'),
          value: {'filter': {"reply_to": {"_null": true}}, 'column': 'date_created', 'order': ''},
        },
      ];
    },
  },
  mounted() {

  },
  methods: {
    reload() {
      this.set()
      this.request.reload(true, true);
    },
    set() {
      this.setSort();
    },
    setSort() {
      try {
        this.request.custom_filters.add('reply_to', this.actions.sort.filter);
        this.request.setSort(this.actions.sort.column, this.actions.sort.order)
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>

<style >
.reviews-actions .v-text-field__details {
  display: none !important;
}
</style>
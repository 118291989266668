<template>
  <div>
    <!--  List  -->
    <div v-if="list" class="py-0">
      <link-item
          v-for="(item, key) in links()"
          :key="'links-item-'+ key"
          :data="item"
      />
    </div>

    <!--  Chips list  -->
    <link-chip
        v-else
        v-for="(item, key) in links()"
        :data="item"
        v-bind:key="key"
        v-bind="$attrs"
    />
  </div>
</template>

<script>

import LinkItem from "./LinkItem";
import LinkChip from "./LinkChip";

export default {
  inheritAttrs: false,
  name: "GoogleLinks",
  components: {LinkChip, LinkItem},
  props: {
    data: {
      default: null,
      type: Array,
    },
    list: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    links() {
      try {
        let result = [];
        if (!this._access('pro')) {
          result.push(
              {
                key: 'estimate',
                text: this.__('estimate'),
                icon: 'mdi-timeline-check',
                link: null,
              },
          );
        }

        result.push.apply(result, [
          {
            key: 'plans',
            text: this.__('plans'),
            icon: 'mdi-projector-screen',
            link: null,
          },
          {
            key: 'materials',
            text: this.__('materials'),
            icon: 'mdi-palette-swatch',
            link: null,
          },
          {
            key: 'work_schedule',
            text: this.__('work_schedule'),
            icon: 'mdi-calendar-month',
            link: null,
          },
          {
            key: 'pictures',
            text: this.__('pictures'),
            icon: 'mdi-image-multiple',
            link: null,
          },
        ]);

        let links = this.data;
        if (!links || typeof links !== 'object') return result;

        result.forEach((item, key) => {
          let find_result = this.findLink(links, item.key)
          if (!find_result || !find_result.file_link) return;
          return result[key].link = find_result.file_link;
        })


        return result;
      } catch (e) {
        return null;
      }
    },
    findLink(links, file_name) {
      try {
        return links.find(item => item.file_name === file_name);
      } catch (e) {
        return null;
      }
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div id="profile-business" v-if="_is_object(data)">
    <profile-info-desktop :data="data" class="hidden-sm-and-down"/>
    <profile-info-mobile :data="data" class="hidden-md-and-up"/>
  </div>
</template>

<script>
import ProfileInfoDesktop from "@/components/profiles/ProfileInfoDesktop";
import ProfileInfoMobile from "@/components/profiles/ProfileInfoMobile";

export default {
  name: "ProfileInfo",
  components: {ProfileInfoMobile, ProfileInfoDesktop},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },

}
</script>

<style scoped>

</style>
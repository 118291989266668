<template>
  <upload
      @then="uploaded"
      accept="image/*"
      :width="width"
      :height="height"
      :text_classes="upload_text_classes"
      :show_done="show_done"
  >
    <template v-slot:default="{progress_size}"  >
      <slot :progress_size="progress_size"></slot>
    </template>
  </upload>
</template>

<script>
import AssetsImages from "@/components/assets/Images";
import Upload from "@/components/assets/Upload";

export default {
  name: "UploadImage",
  components: {Upload, AssetsImages},
  props: {
    value: {
      default: null,
      type: [String, Object],
    },
    add_directus_file_id: {
      default: false,
      type: Boolean,
    },
    show_done: {
      default: false,
      type: Boolean,
    },
    width: {
      default: 190,
      type: [String, Number],
    },
    height: {
      default: 188,
      type: [String, Number],
    },
    upload_text_classes: {
      default: 'pt-12',
      type: [String],
    }
  },
  watch: {
    'value'() {
      this.set()
    }
  },
  data() {
    return {
      result: null
    }
  },
  mounted() {
    this.set();
  },
  methods: {
    set() {
      this.result = this.value;
    },
    input() {
      let result = this.result;
      if (this.add_directus_file_id) result = {directus_files_id: this.result};
      this.$emit('input', result)
    },
    uploaded(response) {
      try {
        if (!this._is_object(response.data)) throw 'Not found any uploaded file id!';
        this.result = response.data.id;
        this.input()
      } catch (e) {
        console.log(e);
      }
    },
    removeImage(index) {
      try {
        this.result = null
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
import api from "../tools/Api";
import urls from "../tools/Urls";
import {_t} from "../tools/Utils";
import message from "../tools/Message";
import {Parameters} from "./Parameters";

export class PostRequest {

    api_key = null;
    api_params = null;
    data;
    body = {};
    headers = {};
    total;
    is_loading;
    then = null;
    finally = null;
    config = {};
    params = new Parameters();
    successfully_message = _t('Done');
    filter_body = null;

    constructor(api_key, api_params) {
        this.api_key = api_key;
        this.api_params = api_params;
        this.reset();
    }

    /**
     * Set fields param for request
     *
     * @param fields
     */
    setFields(fields) {
        this.params.setFields(fields)
    }

    /**
     * Post body data to server
     */
    post() {
        this._send();
    }

    patch() {
        this._patch();
    }

    /**
     * Check loading is true
     *
     * @returns {boolean}
     */
    isLoading() {
        return this.is_loading;
    }


    /**
     * Set api params
     *
     * @param array
     */
    setApiParams(array) {
        this.api_params = array;
    }

    /**
     * Set api
     *
     * @param key
     */
    setApi(key) {
        this.api_key = key;
    }

    /**
     * Reset request params and data to default values
     */
    reset() {
        this.data = null;
        this.total = null;
        this.is_loading = false;
        this.params = new Parameters();
    }

    /**
     * Set then method for send request
     *
     * @param then
     */
    setThen(then) {
        this.then = then;
    }

    /**
     * Set api config
     *
     * @param config
     */
    setApiConfig(config) {
        this.config = config;
    }

    /**
     * Set Finally method for send request
     *
     * @param method
     */
    setFinally(method) {
        this.finally = method;
    }


    /**
     * Set body data for request
     *
     * @param body
     */
    setBody(body) {
        this.body = body;
    }

    /**
     * Set successfully message
     *
     * @param message
     */
    setMessage(message) {
        this.successfully_message = message;
    }


    /**
     * Hide all message after request
     *
     * @param message
     */
    hideMessages() {
        this.successfully_message = null;
    }


    /**
     * Set headers for request
     *
     * @param headers
     */
    setHeaders(headers) {
        this.headers = headers;
    }

    /**
     * Set data from server response
     *
     * @param response
     */
    _setData(response) {
        try {
            if (!response || !response.data) return;
            this.data = response.data;
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Set total of items
     *
     * @param response
     */
    _setTotal(response) {
        try {
            if (!response || response.meta === undefined) return;
            if (response.meta.filter_count === undefined) return;
            this.total = response.meta.filter_count;
        } catch (e) {
            this.total = null;
        }
    }

    /**
     * Send get request
     */
    _send() {
        if (!this.api_key) return;
        if (this.is_loading) return;
        this.is_loading = true;

        api.post(this._getUrl(), this.getBody(), this._getConfig())
            .then((res) => this._thenRequest(res))
            .finally(() => this._finallyRequest())
    }

    /**
     * Patch a request
     */
    _patch() {
        if (!this.api_key) return;
        if (this.is_loading) return;
        this.is_loading = true;

        api.patch(this._getUrl(), this.getBody(), this._getConfig())
            .then((res) => this._thenRequest(res))
            .finally(() => this._finallyRequest())
    }

    /**
     * Run then method for requests
     *
     * @private
     */
    _thenRequest(response) {
        if (response instanceof Error) return;
        this._setData(response);
        this._setTotal(response);
        this._showMessage(response);
        this._runCustomThen(response);
    }

    /**
     * Run then method
     *
     * @private
     */
    _runCustomThen(response) {
        try {
            if (!this.then) return;
            this.then(response);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Run Finally method
     *
     * @private
     */
    _runCustomFinally(response) {
        try {
            if (!this.finally) return;
            this.finally(response);
        } catch (e) {
            console.log(e);
        }
    }

    /**
     * Run finally method for request
     *
     * @private
     */
    _finallyRequest(response) {
        this.is_loading = false;
        this._runCustomFinally(response);
    }


    /**
     * Show successfully message
     *
     * @private
     */
    _showMessage() {
        message.success(this.successfully_message)
    }

    /**
     * Get api url
     *
     * @returns {*}
     * @private
     */
    _getUrl() {
        return urls.api(this.api_key, this.api_params, this.headers);
    }

    /**
     * Get body items
     *
     * @returns {undefined}
     */
    getBody() {
        try {
            if (this.filter_body) return this.filter_body(this.body);
            return this.body;
        } catch (e) {
            console.log(e);
            return this.body;
        }
    }

    /**
     * Map Body before send request
     *
     * @param filter_method
     */
    filterBody(filter_method) {
        this.filter_body = filter_method;
    }

    _getConfig() {

        try {
            return {...this.params.getItems(), ...this.config}
        } catch (e) {
            return {};
        }
    }
}
<template>
  <div class="cities-selector full-width">
    <city-selector v-model="selected" return_name v-bind="$attrs"/>
    <v-chip v-if="!hide_chips" v-for="(item, key) in result" @click:close="remove(key)" close outlined class="mt-1 mr-1">
      {{ item }}
    </v-chip>
  </div>
</template>

<script>
import CitySelector from "@/components/selectors/CitySelector";

export default {
  name: "CitiesSelector",
  components: {CitySelector},
  props: {
    value: {
      default: null,
      type: [Array],
    },
    hide_chips: {
      default: null,
      type: Boolean,
    },
  },
  watch: {
    selected() {
      this.select();
    },
    value() {
      this.set();
    },
  },
  data() {
    return {
      selected: null,
      result: [],
    }
  },
  mounted() {
    this.set()
  },
  methods: {
    set() {
      this.result = this.value;
    },
    select() {
      if (!this.selected) return;
      if (!Array.isArray(this.result)) this.result = [];
      this.result.push(this.selected);
      this.selected = null;
      this.emitInput()
      this.filter()
    },
    filter() {
      try {
        this.result = [...new Set(this.result)];
      } catch (e) {

      }
    },
    emitInput() {
      this.$emit('input', this.result)
    },
    remove(key) {
      try {
        this.result.splice(key, 1);
        this.emitInput();
      } catch (e) {
      }
    },
  }
}
</script>

<style>
.cities-selector .v-text-field__details {
  display: none !important;
}
</style>
<template>
  <div>

    <!--  Tabs  -->
    <v-row v-if="!hide_tabs" v-show="_access('admin')">
      <v-col>
        <professionals-tabs :request="request"/>
      </v-col>
    </v-row>

    <v-row v-if="!hide_actions">
      <v-col class="d-flex justify-space-between">
        <div class="d-flex width-full">

          <!-- Add Button   -->
          <template v-if="add_is_visible">
            <v-btn color="primary" elevation="0" large class="hidden-sm-and-down rounded-lg"
                   :to="{name:'AddProfessional'}">
              {{ __('AddNewPro') }}
            </v-btn>
            <v-btn color="primary" fab elevation="0" small class="hidden-md-and-up" :to="{name:'AddProfessional'}"
                   :title="__('AddNewPro')">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
            <space/>
            <v-btn color="primary" elevation="0" large class="hidden-sm-and-down rounded-lg"
                   :to="{name:'Expertises'}">
              {{ __('ManageExpertiseList') }}
            </v-btn>

            <v-btn color="primary" fab elevation="0" small class="hidden-md-and-up"
                   :to="{name:'Expertises'}"
                   :title="__('ManageExpertiseList')">
              <v-icon dark>mdi-cogs</v-icon>
            </v-btn>
            <space/>
          </template>


          <!-- Search -->
          <items-search :request="request" columns="first_name"></items-search>
        </div>

        <space/>

        <div class="d-flex">
          <!-- Limit -->
          <items-limit :request="request"/>
          <space/>

          <!--   Filters button     -->
          <v-btn class="rounded-lg border-light" outlined @click="show_filters = !show_filters" large
                 :disabled="hide_filters">
            <v-icon left>mdi-filter-variant</v-icon>
            {{ __("Filters") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Filters -->
    <v-row v-show="show_filters" v-if="!hide_filters">
      <v-col>
        <professionals-filters
            @reset="getProfessionals"
            :request="request"
            v-bind="filters_attrs"
            :hide_status="_access('client')"
        >
        </professionals-filters>
      </v-col>
    </v-row>

    <!-- Loading -->
    <loading v-if="this.request.isLoading()"/>

    <!-- List -->
    <v-row v-else class="mt-4">
      <v-col cols="12" sm="2" v-for="(item, key) in request.data" :key="'pro'+key">
        <professional
            @then="request.reload()"
            :data="item"
            :minimal="minimal"
            class="mb-3"
            show_buttons
            card
        />
      </v-col>

      <v-col sm="12">
        <!--   Not found data   -->
        <not-found-data :text="not_found_message" v-if="!request.data" style="padding-top: 90px !important;"/>

        <!-- Load More -->
        <load-more v-if="!hide_load_more" :request="request" pagination/>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";

import LoadMore from "../app/LoadMore";
import NotFoundData from "../app/NotFoundData";
import Professional from "@/components/professionals/Professional"
import Space from "@/components/app/Space";
import ProfessionalsTabs from "@/components/professionals/ProfessionalsTabs";
import ItemsSearch from "@/components/items/ItemsSearch";
import ProfessionalsFilters from "@/components/professionals/ProfessionalsFilters";
import Loading from "../app/Loading";
import ItemsLimit from "../items/ItemsLimit";

export default {
  name: "Professionals",
  components: {
    ItemsLimit,
    Loading,
    ProfessionalsFilters,
    ProfessionalsTabs, Professional, NotFoundData, LoadMore, Space, ItemsSearch
  },
  props: {
    loader_count: {
      default: 5,
      type: [String, Number],
    },
    filters_attrs: {
      default: null,
      type: [Object],
    },
    limit: {
      default: null,
      type: [String, Number],
    },
    user_created: {
      default: null,
      type: [String],
    },
    api: {
      default: 'get_professionals_client',
      type: String,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    sort: {
      default: 'date_created',
      type: String,
    },
    fields: {
      default: '*, expertises.expertise_id.*',
      type: String,
    },
    not_found_message: {
      default: null,
      type: String,
    },
    add_is_visible: {
      default: false,
      type: Boolean,
    },
    hide_actions: {
      default: false,
      type: Boolean,
    },
    hide_filters: {
      default: false,
      type: Boolean,
    },
    hide_tabs: {
      default: false,
      type: Boolean,
    },
    minimal: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      request: new GetRequest(),
      loaderAttrs: {
        class: '',
        boilerplate: false,
        elevation: 1,
      },
      show_filters: false,
      manage_expertise: false,

    }
  },
  mounted() {
    this.request.setApi(this.api)
    this.getProfessionals();

  },
  computed: {},
  methods: {
    getProfessionals() {
      this.request.reset();
      this.setFilters();

      this.request.setLimit(this.limit)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get();
    },
    setFilters() {
      if (this.user_created) this.request.addFilter('user_created', this.user_created);
      if (this.status) this.request.addFilter('status', this.status);
    },

  }
}
</script>

<style scoped>

</style>

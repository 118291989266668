<template>
  <div>
    <job
        v-for="(item, key) in data"
        v-bind:key="key"
        :data="item"
        @reload="$emit('reload')"
        class="mb-3"
        :disabled="disabled"
    />
  </div>

</template>

<script>

import Job from "@/components/jobs/Job"

export default {
  name: "Jobs",
  components: {Job},
  props: {
    data: {
      default: null,
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
}
</script>

<style scoped>

</style>

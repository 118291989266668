<template>
  <div v-if="_is_object(data) && !deleted">
    <div :class="{'rounded-xl pa-5 grey lighten-4 my-5':reply}">
      <!--  Name   -->
      <review-user :data="data" :reply="reply"/>

      <!--  Text   -->
      <div class="py-3">
        <description :data="data.text"/>
      </div>

      <!--   Images   -->
      <review-images :data="data" class="py-3"/>

      <!--  Actions   -->
      <review-actions
          :data="data"
          :hide_reply="reply || hide_reply"
          @edit="(e) => $emit('edit', e)"
          @delete="remove()"
          @reply="(e) => $emit('reply', e)"
      />
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/account/Avatar";
import Rate from "@/components/app/Rate";
import DateText from "@/components/app/DateText";
import Space from "@/components/app/Space";
import Description from "@/components/app/Description";
import ReviewActions from "@/components/reviews/ReviewActions";
import ReviewImages from "@/components/reviews/ReviewImages";
import ReviewUser from "@/components/reviews/ReviewUser";

export default {
  name: "ReviewReply",
  components: {ReviewUser, ReviewImages, ReviewActions, Description, Space, DateText, Rate, Avatar},
  props: {
    data: {
      default: null,
      type: Object,
    },
    reply: {
      default: true,
      type: Boolean,
    },
    hide_reply: {
      default: false,
      type: Boolean,
    },
  },
  data(){
    return {
      deleted: false,
    }
  },
  methods:{
    remove(){
      if (!confirm(this.__('AreYouSure'))) return;
      this.$emit('delete', this.data);
      this.deleted = true;
    }
  }

}
</script>

<style scoped>

</style>
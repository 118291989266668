<template>

  <div v-if="_access('admin')">
    <v-menu offset-y left>

      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" :style="{'background':hover? 'rgba(0,0,0,0.08)': ''}" icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item  @click="edit_dialog = true">
          <v-list-item-title>{{ __("Edit") }}</v-list-item-title>
        </v-list-item>
        <v-list-item  @click="archive_dialog = true" disabled>
          <v-list-item-title>{{ __("Delete") }}</v-list-item-title>
        </v-list-item>
        <v-list-item  v-if="!hide_details" @click="$router.push( {name: 'BlogView', params: {id: data.id}})">
          <v-list-item-title>{{ __("Details") }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Edit Dialog -->
    <modal fullscreen v-model="edit_dialog">
      <template v-slot:title>
        <span>{{ __("EditIdea") }}</span>
      </template>
      <template v-slot:main>
        <blog-write :data="data" @then="()=>$emit('edited', null)"/>
      </template>
    </modal>

    <!-- Archive Dialog -->
    <confirm v-model='archive_dialog' @accepted="archive"/>

  </div>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";
import Modal from "../modal/Modal";
import BlogWrite from "./BlogWrite";
import Confirm from "../modal/Confirm";

export default {
  name: "BlogMenu",
  components: {Confirm, BlogWrite, Modal},
  props: {
    data: {
      default: null,
      type: Object
    },
    hover: {
      default: null,
      type: Boolean
    },
    hide_details: {
      default: false,
      type: Boolean
    },
  },
  data: () => {
    return {
      edit_dialog: false,
      archive_dialog: false,
      // todo fix this line
      archive_request: new PostRequest(),
    }
  },
  computed: {},
  mounted() {
    this.archive_request.setApiParams([this.data.id])
  },
  methods: {
    archive() {
      try {
        this.archive_dialog = false;
        this.archive_request.setBody({'status': 'archived'})
        this.archive_request.setThen(() => this.$emit('archived', this.data))
        this.archive_request.patch();
      } catch (e) {
        console.log(e);
      }
    },

    getId(item) {
      try {
        if (typeof item === 'object') return item.id;
        return item;
      } catch (e) {
        return null;
      }
    },
    picturesIds(pictures) {
      try {

        return pictures.map((item) => {
          if (item.id) delete item.id;
          if (item.idea_id) delete item.idea_id;
          return item;
        });
      } catch (e) {
        return pictures;
      }
    },

  },
}
</script>

<style scoped>

</style>
<template>
  <div v-if="_is_object(data)">
    <div>
      <v-card class="rounded-lg pa-0 pointer" @click="$emit('select', data)" flat>
        <assets-image :data="image" height="199" type="cover"/>
      </v-card>
      <div class="font-weight-bold px-3 pt-3 font-size-14">{{ data.title }}</div>
    </div>
  </div>
</template>

<script>

import AssetsImages from "@/components/assets/Images";
import AssetsImage from "@/components/assets/Image";
import Avatar from "@/components/account/Avatar";
import FullName from "@/components/account/FullName";
import IdeaSave from "@/components/ideas/IdeaSave";
import Space from "@/components/app/Space";
import IdeaInfo from "@/components/ideas/IdeaInfo";
import AssetsImagesBox from "@/components/assets/ImagesBox";
import ProfileContact from "@/components/profiles/ProfileContact";

export default {
  name: "PortfolioProjectPicture",
  components: {
    ProfileContact,
    AssetsImagesBox, IdeaInfo, Space, IdeaSave, FullName, Avatar, AssetsImage, AssetsImages
  },
  props: {
    data: {
      default: null,
      type: Object,
    },
    pro: {
      default: null,
      type: Object,
    },
  },
  computed: {
    image() {
      try {
        return this.data.pictures[0].directus_files_id;
      } catch (e) {
        return null;
      }
    }
  },


}
</script>

<style scoped>

</style>
<template>
  <div>

    <v-row v-if="!hide_actions">
      <v-col class="d-flex justify-space-between">
        <div class="d-flex width-full">

          <!-- Add Button   -->
          <template>
            <v-btn color="primary" elevation="0" large class="hidden-sm-and-down rounded-lg"
                   :to="{name:'AddClient'}">
              {{ __('Add') }}
            </v-btn>
            <v-btn color="primary" fab elevation="0" small class="hidden-md-and-up" :to="{name:'AddUser'}"
                   :title="__('AddNewUser')">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </template>

          <space/>

          <!-- Search -->
          <items-search :request="request" columns="first_name"></items-search>
        </div>

        <space/>

        <div class="d-flex">
          <!-- Limit -->
          <items-limit :request="request"/>
        </div>
      </v-col>
    </v-row>


    <!-- Loading -->
    <loading v-if="this.request.isLoading()"/>

    <!-- List -->
    <div v-else class="mt-4">

      <div  v-if="request.data">
        <client
            v-for="(item, key) in request.data "
            :key="key" :data="item" @then="request.reload()"
            class="mb-3"
        />
      </div>

      <v-col   sm="12">
        <!--   Not found data   -->
        <not-found-data v-if="!request.data" style="padding-top: 90px !important;"/>

        <!-- Load More -->
        <load-more v-if="!hide_load_more" :request="request" pagination/>
      </v-col>
    </div>

  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";

import LoadMore from "../app/LoadMore";
import NotFoundData from "../app/NotFoundData";
import Space from "@/components/app/Space";
import ItemsSearch from "@/components/items/ItemsSearch";
import Loading from "../app/Loading";
import ItemsLimit from "../items/ItemsLimit";
import Client from "./Client";

export default {
  name: "Clients",
  components: {
    Client,
    ItemsLimit,
    Loading,
    NotFoundData,
    LoadMore,
    Space,
    ItemsSearch
  },
  props: {
    loader_count: {
      default: 5,
      type: [String, Number],
    },
    filters_attrs: {
      default: null,
      type: [Object],
    },
    limit: {
      default: null,
      type: [String, Number],
    },
    user_created: {
      default: null,
      type: [String],
    },
    api: {
      default: 'get_clients',
      type: String,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    sort: {
      default: 'date_created',
      type: String,
    },
    fields: {
      default: '*, projects.*',
      type: String,
    },
    add_is_visible: {
      default: false,
      type: Boolean,
    },
    hide_actions: {
      default: false,
      type: Boolean,
    },
    hide_filters: {
      default: false,
      type: Boolean,
    },
    hide_tabs: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      request: new GetRequest(),
    }
  },
  mounted() {
    this.get();
  },
  computed: {},
  methods: {
    get() {
      this.request.setApi(this.api)

      this.request.reset();
      this.request.setLimit(this.limit)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.row();
    },
  }
}
</script>

<style scoped>

</style>

import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-width"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.title ? _vm.title : _vm.__('User')))]),_c(VAutocomplete,_vm._b({staticClass:"custom-selector-border rounded-lg",attrs:{"items":_vm.items(),"value":_vm.value,"multiple":_vm.multiple,"item-text":"text","item-value":"value","outlined":"","dense":""},on:{"input":(e) => _vm.$emit('input', e)},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c(VChip,_vm._b({attrs:{"input-value":data.selected},on:{"click":data.select}},'v-chip',data.attrs,false),[(data.item.item)?_c('avatar',{attrs:{"data":data.item.item}}):_vm._e(),_vm._v(" "+_vm._s(data.item.text)+" ")],1)]}},{key:"item",fn:function(data){return [(typeof data.item !== 'object')?[_c(VListItemContent,{domProps:{"textContent":_vm._s(data.item.text)}})]:[_c(VListItemAvatar,[(data.item.item)?_c('avatar',{attrs:{"data":data.item.item}}):_vm._e()],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"innerHTML":_vm._s(data.item.text)}})],1)]]}}])},'v-autocomplete',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ProfessionalsLanding.vue?vue&type=template&id=153d9d32&scoped=true&"
import script from "./ProfessionalsLanding.vue?vue&type=script&lang=js&"
export * from "./ProfessionalsLanding.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "153d9d32",
  null
  
)

export default component.exports
<template>
  <div id="profile-projects">
    <!--  Desktop  -->
    <profile-rating-desktop class="hidden-sm-and-down" v-bind="$attrs"/>


    <!-- Mobile   -->
    <profile-rating-mobile class="hidden-md-and-up" v-bind="$attrs"/>

  </div>
</template>

<script>

import ShowMore from "@/components/app/ShowMore";
import ProfileRatingDesktop from "@/components/profiles/ProfileRatingDesktop";
import ProfileRatingMobile from "@/components/profiles/ProfileRatingMobile";

export default {
  name: "ProfileRating",
  components: {ProfileRatingMobile, ProfileRatingDesktop, ShowMore},
}
</script>
<template>
  <div>
    <ideas hide_filters hide_actions v-bind="$attrs"/>
  </div>
</template>

<script>
import Ideas from "./Ideas";

export default {
  inheritAttrs: false,
  name: "IdeasHorizontal",
  components: {Ideas}
}
</script>

<style scoped>

</style>
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c('page-title',{attrs:{"text":_vm.__('IdeasManagement'),"heading":""}}),_c(VRow,[_c(VCol,[_c('ideas-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}})],1)],1),_c('ideas',{attrs:{"fields":"*,*.*,projects","add_is_visible":true,"item_attrs":{
        show_date: _vm.is_my_ideas,
        show_status: _vm.is_my_ideas,
        show_saved_count: _vm.is_my_ideas,
        in_modal: true,
      },"filters_attrs":{
       with_status:_vm.is_my_ideas,
       with_color:false,
       with_creator:true,
       with_date:true,
       with_project:true,
       with_size:false,
       with_style:false,
      },"status":_vm.is_users_pictures ? 'unpublished' : null,"creator":_vm.is_my_ideas ? '$CURRENT_USER' : null,"masonry":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({hover}){return [(_vm._is_object(_vm.data))?_c(VRow,{staticClass:"profile-project mb-6"},[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VCard,{class:{'rounded-lg overflow-hidden pa-0':true, 'opacity-6':_vm.data.status === 'draft'},attrs:{"flat":""}},[_c('router-link',{attrs:{"to":_vm.link}},[_c('assets-image',{attrs:{"data":_vm.picture,"height":_vm.height,"type":"cover"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VListItem,{staticClass:"px-3"},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold font-size-14"},[_c('router-link',{staticClass:"text--text font-size-14",attrs:{"to":_vm.link}},[_vm._v(_vm._s(_vm.data.title)+" ")])],1),(!_vm.hide_name)?_c(VListItemSubtitle,[_c('div',{staticClass:"font-size-12 grey--text"},[[_vm._v(" "+_vm._s(_vm.__('By'))+" "),(_vm.data.user_created)?_c('full-name',{attrs:{"data":_vm.data.user_created}}):_c('span',[_vm._v(_vm._s(_vm.__('Renotive')))])]],2)]):_vm._e()],1),(_vm.editable)?_c(VListItemAction,[(_vm.editable)?_c(VBtn,{staticClass:"btn-background-transparent text-transform-unset px-3 px-md-1 rounded-lg mb-1",attrs:{"to":{name:'ProfessionalBlogEdit', params:{blog_id:_vm.data.id}},"min-width":"25","height":"25","color":"primary","outlined":"","small":""}},[(_vm.data.status === 'draft')?_c('span',[_vm._v(_vm._s(_vm.__('Draft'))+" ")]):_vm._e(),_c(VIcon,{staticStyle:{"margin-top":"-1px"},attrs:{"small":""}},[_vm._v("mdi-pencil-outline")]),_c('div',{staticClass:"hidden-md-and-up"},[_vm._v(" "+_vm._s(_vm.__("Edit")))])],1):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"px-3 pb-3 font-size-12 overflow-hidden grey--text darken-1",staticStyle:{"max-height":"90px"}},[_vm._v(" "+_vm._s(_vm.data.summary)+" ")])],1)],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
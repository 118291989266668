<template>
  <div class="d-flex flex-wrap" v-if="data">
    <answer-selector-item
        v-for="(item, key) in data"
        :key="'answer-selector-'+key"
        :data="item"
        :selected="isSelected(item)"
        @select="select(item)"
    />
  </div>
</template>

<script>

import AnswerSelectorItem from "./AnswerSelectorItem";

export default {
  name: "AnswerSelector",
  components: {AnswerSelectorItem},
  props: {
    value: {
      default: null,
      type: [Object],
    },
    data: {
      default: null,
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      selected: null,
    }
  },
  mounted() {
  },
  methods: {
    isSelected(item) {
      try {
        if (this.value && typeof this.value === 'object' && this.value.selected)
          this.selected = this.value.selected.option;

        return this.selected === item.option;
      } catch (e) {
        return this.selected === item.option;
      }
    },
    select(item) {
      try {
        this.selected = item.option;
        this.$emit('input', item)
      } catch (e) {
        return false;
      }
    },
  }
}
</script>

<style scoped>
</style>
<template>
  <span>{{ data ? price : default_price }}</span>
</template>

<script>

import app from "../../tools/App";
import {formatNumber} from "../../tools/Utils";

export default {
  name: "Price",
  props: {
    data: {
      default: null,
      type: [String, Number]
    },
    default_price: {
      default: 0,
      type: [String, Number]
    },
    hide_currency: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    price() {
      try {
        let number = this.data;
        if (!number) return '';
        number = Number.parseInt(number);
        if (this.hide_currency) return formatNumber(number);
        return app.currency + formatNumber(number);
      } catch (e) {
        return this.data;
      }
    },
  },
  data() {
    return {}
  },

}
</script>

<style scoped>

</style>
<template>
  <!--  Edit Professional Form  -->
  <form @submit.prevent="submit" v-if="request.body.first_name !== undefined">
    <v-row>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('FirstName') }}</div>
        <v-text-field
            v-model="request.body.first_name"
            dense
            outlined
            required
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('LastName') }}</div>
        <v-text-field
            v-model="request.body.last_name"
            type="text"
            dense
            outlined
        />
      </v-col>
    </v-row>
    <v-row>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('Phone') }}</div>
        <v-text-field
            v-model="request.body.phone_number"
            type="number"
            dense
            outlined
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('Email') }}</div>
        <v-text-field
            v-model="request.body.email"
            type="email"
            dense
            outlined
        />
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('TelegramId') }}</div>
        <v-text-field
            v-model="request.body.telegram_id"
            type="text"
            dense
            outlined
        />
      </v-col>

      <v-col cols="12" md="6" v-if="_access('admin')">
        <status-selector :title="__('RegistrationStatus')" :items="registration_status_items"
                         v-model="request.body.pro_registration_status"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" v-if="_access('admin')">
        <status-selector :items="status_items"
                         v-model="request.body.status"/>
      </v-col>

      <v-col cols="12" md="6">
        <expertise-selector v-model="expertises" show_custom :multiple="true"/>
      </v-col>
    </v-row>
    <v-row>

      <v-col md="6" v-if="_access('pro')">
        <span>{{ __('UploadPortfolio') }}</span>
        <assets-images :data="request.body.portfolio" class="rounded-lg" max-height="188" max-width="190">
          <upload @then="uploaded" accept="application/pdf"></upload>
        </assets-images>
      </v-col>
      <v-col md="6">
        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Submit') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import {PostRequest} from "@/models/PostRequest";
import Upload from "@/components/assets/Upload";
import ExpertiseSelector from "@/components/selectors/ExpertiseSelector";
import StatusSelector from "@/components/selectors/StatusSelector";
import AssetsImages from "@/components/assets/Images";

export default {
  name: "ProfessionalEdit",
  components: {AssetsImages, StatusSelector, ExpertiseSelector, Upload},
  props:{
    id:{
      type: [Number, String],
      default: 0,
      require: true
    },
  },
  data() {
    return {
      request_get: new GetRequest('get_professional', [this.id]),
      request: new PostRequest('edit_professional', [this.id]),
      portfolio: null,
      expertises: [],
      status_items: [
        {
          text: this.__('Active'),
          value: 'active',
        },
        {
          text: this.__('Archive'),
          value: 'archive',
        },
      ],
      registration_status_items: [
        {
          text: this.__('AddByClient'),
          value: 'add_by_client',
        },
        {
          text: this.__('Hired'),
          value: 'hired',
        },
        {
          text: this.__('NotApproved'),
          value: 'not_approved',
        },
      ],
    }
  },
  mounted() {
    this.request_get.setThen(this.setBody);
    this.request_get.setFields('*, expertises.*')
    this.request_get.row();
  },
  watch:{},
  methods: {
    setBody() {
      this.request.setThen(this.submitted);
      let items = [];
      this.request_get.data.expertises.forEach(item => items.push(item.expertise_id))
      this.request.setBody(
          {
            first_name: this.request_get.data.first_name,
            last_name: this.request_get.data.last_name,
            phone_number: this.request_get.data.phone_number,
            email: this.request_get.data.email,
            status: this.request_get.data.status,
            expertises: [],
            telegram_id: this.request_get.data.telegram_id,
            avatar: null,
            portfolio: this.request_get.data.portfolio,
            role: "ebe9313f-150a-4331-98bf-af294eec69cf",
            pro_registration_status: this.request_get.data.pro_registration_status
          },
      );
      this.expertises = items;
    },
    setExpertise(){
      this.expertises.forEach(item => this.request.body.expertises.push({expertise_id: item}))
    },
    uploaded(response) {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        this.request.body.portfolio.push({directus_files_id: response.data.id});
      } catch (e) {
        console.log(e);
      }
    },
    submit() {
      this.setExpertise();
      this.request.patch();
    },
    submitted(data) {
      this.$emit('then', data);
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div class="full-width">
    <div class="font-weight-bold" v-if="!hide_title">{{ __('Languages') }}</div>
    <v-select
        v-if="!list"
        :items="items()"
        :value="value"
        @input="(e) => $emit('input', e)"
        class="custom-selector-border rounded-lg"
        outlined
        dense
    ></v-select>

    <template v-else>
      <v-skeleton-loader v-if="request.isLoading()" type="card" height="38"/>

      <from-list-selector
          v-else
          :items="items()"
          :value="value"
          @input="(e) => $emit('input', e)"
          v-bind="$attrs"
      >
        <template #label="{item}">
          <div class="d-flex align-center">
            <assets-image v-if="item.icon && !hide_flag" :data="item.icon" max-width="20" class="mr-2" style="margin-top:-2px;"/>
            <div>{{item.text}}</div>
          </div>
        </template>
      </from-list-selector>
    </template>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import FromListSelector from "@/components/selectors/FromListSelector";
import AssetsImage from "@/components/assets/Image";

export default {
  name: "languagesSelector",
  components: {AssetsImage, FromListSelector},
  props: {
    value: {
      default: null,
      type: [Array, String, Object],
    },
    list: {
      default: false,
      type: Boolean,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
    hide_first_item: {
      default: false,
      type: Boolean,
    },
    hide_flag: {
      default: false,
      type: Boolean,
    },
    first_label: {
      default: null,
      type: String,
    },
    data: {
      default: null,
      type: Array,
    }
  },
  data() {
    return {
      request: new GetRequest('get_items', ['language']),
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.setSort('name');
      this.request.setThen(() => this.$emit('load-data', this.request.data))
      this.request.get();
    },
    items() {
      try {
        let items = [];
        if (!this.hide_first_item) items = [{text: this.first_label ? this.first_label : this.__('All'), value: null,}];
        if (!this.request.data) return;

        this.request.data.forEach((item) => {
          items.push({
            text: item.name,
            value: item.id,
            icon: item.icon,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
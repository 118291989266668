<template>
  <v-row>

    <!--  Budget Form for Start Project  -->
    <v-col cols="12" sm="12" class="text-center pt-16">
      <div class="text-h4 my-6 ">{{ __('QuestionsBudgetTitle') }}</div>
      <div class="text--secondary">{{ __('QuestionsBudgetText') }}</div>

    </v-col>

    <v-col cols="12" sm="12">
      <div class="py-6 mx-auto" style="max-width: 690px">
        <loading v-if="questions.isCalculating()" margin="0"/>

        <div v-else>
          <div class="text-h4 text-center mb-6">
            <price :data="questions.budget"/>
          </div>

          <v-slider
              track-color="grey"
              :min="questions.getMinBudget()"
              :max="questions.getMaxBudget()"
              v-model="questions.budget"
          >

            <template v-slot:prepend>
              <price :data="questions.getMinBudget()"/>
            </template>

            <template v-slot:append>
              <price :data="questions.getMaxBudget()"/>
            </template>
          </v-slider>

          <div class="text-center pt-6">
            <div v-if="questions.getMinBudget()" class="text--secondary">{{ __('QuestionsBudgetSubtitle') }}</div>
          </div>
        </div>
      </div>
    </v-col>

    <!--    Calendly Calender -->
    <v-col cols="12" sm="12" v-show="!questions.isCalculating()" class="text-center">
      <div class="text-h4 my-6 mt-16 ">{{ __('CalendlyTitle') }}</div>
      <div class="text--secondary mx-auto" style="max-width: 690px">{{ __('CalendlyText') }}</div>

      <calendly :url="questions.getCalendlyUrl()"/>
    </v-col>

    <v-col cols="12" sm="12" class="text-center mt-6" v-show="!questions.isCalculating()">
      <questions-next-button :questions="questions" fixed/>
    </v-col>

  </v-row>
</template>

<script>
import {Questions} from "../../models/Questions";
import Loading from "../app/Loading";
import QuestionsNextButton from "./QuestionsNextButton";
import Calendly from "../calendly/Calendly";
import Price from "../app/Price";

export default {
  name: "QuestionsBudget",
  components: {Price, Calendly, QuestionsNextButton, Loading},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  mounted() {
    this.questions.calculate();
  },
  methods: {}
}
</script>

<style scoped>

</style>
<template>
  <div :title="__(is_lead ? 'LeadStatus' : 'Status')">
    <status-selector
        :title="''"
        :value="status"
        :items="items()"
        @input="e => update(e)"
        hide_label
    />
  </div>
</template>

<script>
import StatusSelector from "../selectors/StatusSelector";
import {PostRequest} from "../../models/PostRequest";

export default {
  name: "ProjectUpdateStatus",
  components: {StatusSelector},
  props: {
    data: {
      default: null,
      type: Object,
    },
    is_lead: {
      default: false,
      type: Boolean,
    }
  },
  computed: {
    status() {
      if (this.is_lead) return this.data.lead_status;
      return this.data.status;
    }
  },
  data() {
    return {
      request: new PostRequest('update_project'),
    }
  },
  mounted() {
    this.request.setApiParams([this.data.id])
  },
  methods: {
    items() {
      if (this.is_lead) {
        return [
          {
            text: this.__('New'),
            value: 'new'
          },
          {
            text: this.__('Lead'),
            value: 'lead'
          },
          {
            text: this.__('Contacted'),
            value: 'contacted'
          },
          {
            text: this.__('MeetingScheduled'),
            value: 'meeting_scheduled'
          },
          {
            text: this.__('Won'),
            value: 'won'
          },
          {
            text: this.__('Lost'),
            value: 'lost'
          },
          {
            text: this.__('Expired'),
            value: 'expired'
          },
          {
            text: this.__('CustomPackage'),
            value: 'custom_package'
          }
        ]
      }


      return [
        {
          text: this.__('Active'),
          value: 'active'
        },
        {
          text: this.__('Archived'),
          value: 'archived'
        },
        {
          text: this.__('Completed'),
          value: 'completed'
        },
        {
          text: this.__('Lead'),
          value: 'lead'
        }
      ];
    },
    update(status) {
      let field = this.is_lead ? 'lead_status' : 'status';
      let body = {};
      body[field] = status;

      this.request.setBody(body);
      this.request.setThen(() => this.$emit('reload'));
      this.request.patch();
    },
  }
}
</script>

<style scoped>

</style>
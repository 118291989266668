<template>
  <v-row>
    <v-col cols="12" sm="12" v-if="questions.hasTests()">
      <template v-for="(item, key ) in questions.getTests()">
        <v-row v-if="item && item.quiz" :key="'test-'+key" class="py-6" align="center">
          <v-col cols="12" sm="5" md="5">
            <div class="title" style="font-size: 22pt !important;" v-html="item.question_title"></div>
            <div v-if="item.question_subtitle" class="text--secondary mt-6" v-html="item.question_subtitle"></div>
          </v-col>

          <v-col cols="12" sm="7" md="7">
            <answer-selector
                :value="questions.selected_answers[item.quiz]"
                :data="item.answer_options"
                @input="(e) => questions.selectAnswer(item, e)"
            />
          </v-col>
        </v-row>
      </template>

    </v-col>

    <v-col sm="12">
      <questions-next-button :questions="questions" fixed>
        <v-btn
            large
            color="primary"
            class="rounded-lg"
            elevation="0"
            @click="click()"
            style="min-width: 160px"
        >
          {{ __('Next') }}
        </v-btn>
      </questions-next-button>

      <v-dialog v-model="dialog" persistent max-width="690">
        <v-card>
          <v-card-title class="text-h5">
            <div v-html=" __('Confirm')"></div>
          </v-card-title>
          <v-card-text class="text--secondary">
            <div v-html=" __('TestDialog')"></div>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="dialog = false">
              {{ __('Cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="next">
              {{ __('Yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import {Questions} from "../../models/Questions";
import AnswerSelector from "../selectors/AnswerSelector";
import QuestionsNextButton from "./QuestionsNextButton";

export default {
  name: "QuestionsTests",
  components: {QuestionsNextButton, AnswerSelector},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  data() {
    return {
      dialog: null,
    }
  },
  methods: {
    click() {
      try{
        if ( Object.keys(this.questions.selected_answers).length !== this.questions.getTests().length) return this.dialog = true;
        this.next()
      }catch (e){
        this.next()
      }
    },
    next() {
      this.dialog = false;
      if (this.questions.isStep(2) && this.questions.isNotFirst()) return this.questions.next(3)
      this.questions.next()
    }
  }
}
</script>

<style scoped>

</style>
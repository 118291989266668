<template>
  <v-card flat class="grey overflow-hidden lighten-4 rounded-lg pb-6">
    <v-row>
      <v-col v-if="data.project_cost_chart" cols="12" sm="6"  class="pa-6">
        <div class="title text-center py-3">{{ __('ProjectCost') }}</div>
        <div class="px-6">
          <chart id="project-cost" :object="data.project_cost_chart"/>
        </div>
      </v-col>
      <v-col v-if="data.project_time_chart" cols="12" sm="6" class="pa-6">
        <div class="title text-center py-3">{{ __('ProjectTime') }}</div>
        <div class="px-6">
          <chart id="project-time" :object="data.project_time_chart"/>
        </div>
      </v-col>
    </v-row>
  </v-card>

</template>

<script>
import Chart from "../chart/Chart";

export default {
  name: 'ProjectChart',
  components: {Chart},
  props: {
    data: {
      default: null,
      type: Object,
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <div class="d-flex flex-wrap justify-end">
    <project-room-answer v-for="(item, key) in data" :key="key" :data="item" />
  </div>
</template>

<script>

import ProjectRoomAnswer from "./ProjectRoomAnswer";

export default {
  name: "ProjectRoomAnswers",
  components: {ProjectRoomAnswer},
  props: {
    data: {
      default: null,
      type: [Object, Array],
    },
  },
  computed: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

<template>
  <v-app id="panel-app">

    <!--  App Bar  -->
    <v-app-bar
        fixed
        app
        color="background"
        elevation="0"
        clipped-left
        height="90"
    >

     <v-container>
       <v-row align="center">

         <!--   This button added for get center logo in header  -->
         <v-btn icon color="white" disabled></v-btn>

         <v-spacer></v-spacer>

         <v-toolbar-title>
           <router-link to="/">
             <logo/>
           </router-link>
         </v-toolbar-title>

         <v-spacer></v-spacer>

         <account>
           <template v-slot:gust>
             <v-btn large color="rounded-lg primary" :to="{name:'Login'}" elevation="0">
               {{ __('Login') }}
             </v-btn>
           </template>
         </account>
       </v-row>
     </v-container>
    </v-app-bar>


    <!--  Main  -->
    <v-main class="pb-7">
      <router-view></router-view>
    </v-main>
 
  </v-app>

</template>

<script>
import AppName from "../app/AppName";
import Logo from "../app/Logo";
import Account from "../account/Account";
import Space from "../app/Space";
import MainFooter from "../footers/MainFooter";

export default {
  name: 'PanelLayout',
  components: {MainFooter, Space, Account, Logo, AppName},
  data() {
    return {
      drawer: null,
      menu: [
        {title: this.__('Dashboard'), to: {name: 'Dashboard'}, icon: 'mdi-home'},
        {title: this.__('Projects'), to: {name: 'Projects'}, icon: 'mdi-archive-cog'},
        {title: this.__('Ideas'), to: {name: 'Ideas'}, icon: 'mdi-lightbulb'},
        {title: this.__('Lead'), to: {name: 'Lead'}, icon: ''},
        {title: this.__('Account'), to: {name: 'Profile'}, icon: 'mdi-cog'},
      ]
    }
  },
  methods: {}
}
</script>

<style>
#panel-app .v-navigation-drawer__border {
  background-color: rgba(0, 0, 0, 0.05);
  width: 1px;
  border: none;
  outline: none;
}
</style>

<template>
  <span v-if="_is_object(data)">
    <span v-if="_is_object(data.typical_job_cost)">
      <price :data="data.typical_job_cost.min"/> -
      <price :data="data.typical_job_cost.max" hide_currency/>
    </span>
  </span>
</template>

<script>
import Price from "@/components/app/Price";
export default {
  name: "ProfileTypicalCost",
  components: {Price},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"secondary"},[_c(VContainer,[_c('div',{staticClass:"text--text text--lighten-3 d-flex justify-space-between py-3"},_vm._l((_vm.items()),function(item,key){return _c('div',{key:'questions-steps-'+key,class:{'hidden-xs-only text--disabled': _vm.disabled(item)}},[_c('div',[_vm._v(_vm._s(item.title))])])}),0)])],1),_c(VContainer,[_c(VRow,[_c('div',{staticClass:"d-flex flex-wrap mt-3"},[_c(VBtn,{attrs:{"icon":"","disabled":_vm.questions.step === 1,"title":_vm.__('Back')},on:{"click":function($event){return _vm.questions.back()}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VBtn,{attrs:{"text":"","color":"grey"}},[_vm._v(_vm._s(_vm.questions.step)+" / "+_vm._s(_vm.questions.max_steps))])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
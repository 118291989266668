<template>
  <div v-if="_is_object(data)">
    <template v-if="!editable">
      <!-- Owner   -->
      <div v-if="_owner(data.pro_id)" class="text-center">
        <v-btn
            class="full-width rounded-lg font-size-14"
            :to="{name:'ProfessionalProfileForm',params: {id: data.id}}"
            color="primary"
            max-width="250"
            outlined
        >
          <v-icon small>mdi-pencil-outline</v-icon>&nbsp;
          <span class="font-mt-2">{{ __('EditProfile') }}</span>
        </v-btn>
      </div>

      <!-- Guest   -->
      <div v-else>
        <profile-contact :data="data"/>
      </div>
    </template>

    <!--  Editable Mode   -->
    <template v-else>
      <div class="text-center">
        <div class="mx-auto" style="max-width: 250px">
          <v-btn
              v-if="$route.params.id !== 'CREATE'"
              :to="{name:'ProfessionalProfile', params: {id: data.id}}"
              class="full-width rounded-lg mb-3 text-transform-unset"
              target="_blank"
              color="primary"
              max-width="250"
              outlined
          >
            {{ __('Preview') }}
          </v-btn>
          <v-btn
              type="submit"
              class="full-width rounded-lg mb-3 text-transform-unset"
              @mousedown="submit('published')"
              color="primary"
              max-width="250"
              elevation="0"
              :disabled="request.isLoading()"
          >
            <span v-if="!request.isLoading()">{{ __('SaveAndPublish') }}</span>
            <span v-else>...</span>
          </v-btn>
          <v-btn
              v-if="!request.isLoading()"
              type="button"
              @mousedown="show_draft_alert = true"
              class="full-width rounded-lg mb-3 text-transform-unset background--text text lighten-5"
              max-width="250"
              elevation="0"
          >

            <span v-if="!request.isLoading()">{{ __('SaveToDraft') }}</span>
            <span v-else>...</span>

          </v-btn>
        </div>
      </div>
    </template>


    <modal v-model="show_draft_alert" content-class="overflow-visible">
      <template #content>
        <v-card class="rounded-xl pa-9 ">
          <div class="mx-auto text-center mb-3" style="margin-top: -50px">
            <v-avatar color="#B63F3F" size="40" style="border: solid 5px white;">
              <v-icon color="background" size="17">mdi-close</v-icon>
            </v-avatar>
          </div>
          <div class="pb-3 text-center">{{ __('Alert') }}</div>
          {{ __('ContinuingWill') }}
          <div class="d-flex align-center text-center justify-center mt-6">
            <div
                class="primary px-6 py-2 background--text text-uppercase rounded-lg mr-2 pointer"
                @click="selectContinue"
            >
              <div class="font-mt-2">
                {{ __('Continue') }}
              </div>
            </div>
            <div
                class="grey background--text px-6 py-2 text-uppercase rounded-lg pointer"
                @click="show_draft_alert = false"
            >
              <div class="font-mt-2">
                {{ __('Cancel') }}
              </div>

            </div>
          </div>
        </v-card>
      </template>
    </modal>
  </div>
</template>

<script>

import ProfileContact from "@/components/profiles/ProfileContact";
import {PostRequest} from "@/models/PostRequest";
import Modal from "@/components/modal/Modal";

export default {
  name: "ProfileActions",
  components: {Modal, ProfileContact},
  props: {
    data: {
      default: null,
      type: Object,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    request: {
      default: null,
      type: PostRequest,
    },
  },
  data() {
    return {
      show_draft_alert: false,
    }
  },
  methods: {
    submit(status = 'draft') {
      this.request.body.status = status;
    },
    selectContinue() {
      this.submit('draft');
      this.$emit('submit');
      this.show_draft_alert = false;
    }
  }
}
</script>

<style>

</style>
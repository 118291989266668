<template>
  <v-container>
    <page-title :text="__('Blogs')" heading/>
    <blogs
        add_is_visible
        :status="null"
        :item_attrs="{
          in_modal: true,
        }"
    />
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Blogs from "@/components/blogs/Blogs";

export default {
  name: "BlogPage",
  components: {Blogs, PageTitle}
}
</script>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-width background"},[_c(VListItem,{attrs:{"dense":""}},[(_vm.data.logo)?_c(VListItemAvatar,{staticClass:"mr-3"},[_c('assets-image',{attrs:{"data":_vm.data.logo}})],1):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-size-14 font-mt-2 grey--text"},[_vm._v(" "+_vm._s(_vm.data.business_name)+" ")])],1),_c(VListItemAction,[(!_vm._owner(_vm.data.pro_id))?_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"rounded-lg mr-2",attrs:{"width":"36","height":"36","disabled":"","icon":"","outlined":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-comment")])],1),(_vm._user)?_c(VBtn,{staticClass:"rounded-lg mr-2",attrs:{"color":"#00B2A8","width":"36","height":"36","href":'tel:'+_vm.data.phone_number,"icon":"","outlined":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-phone")])],1):_vm._e(),(_vm._user)?_c(VBtn,{staticClass:"rounded-lg",attrs:{"color":"#0066FF","width":"36","height":"36","href":'mailto:'+_vm.data.email,"icon":"","outlined":""}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-email")])],1):_vm._e()],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div v-if="_is_array(items) && 1 < items.length">
    <v-breadcrumbs :items="items" divider=">" class="py-3 px-0">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
            :href="item.to"
            :disabled="item.disabled"
            :to="item.to"
        >
          <span class="font-size-11">{{ item.text }}</span>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",
  props: {
    data: {
      default: null,
      type: Array,
    },
    add_home: {
      default: true,
      type: Boolean,
    }
  },
  computed: {
    items() {
      try {
        let result = [];
        if (!this.data) return null;
        if (this.add_home) {
          result.push(
              {
                text: this.__('Home'),
                data: null,
                to: {name: 'Home'}
              },
          )
        }
        Array.prototype.push.apply(result, this.data)
        return result;
      } catch (e) {
        return [];
      }
    }
  }
}
</script>

<style scoped>

</style>
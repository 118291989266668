/**
 * Urls Class
 * this class handle all urls and apis address
 */

const urls = {
    apiParamsSupperKey: "@api_params:",

    urls: {
      base_url: "https://api.renotive.develop.greensoft.dev",
          // base_url: "https://api.renotive.com",
        default_calendly: "https://calendly.com/renotive-development/event-test",
        generate_site_map: "/generate_site_map?baseUrl=%s",

        item: "/items/%s/%s",
        items: "/items/%s",

        get_rooms: "/items/room", // GET
        get_ideas: "/items/idea", // GET
        get_list: "/items/list", // GET
        get_idea: "/items/idea/%s", // GET
        get_packages: "/items/package", // GET
        get_job_type: "/items/job_type", // GET
        get_current_user: "/users/me", // GET
        get_sizes: "/items/size", // GET
        get_styles: "/items/idea_style", // GET
        get_colors: "/items/color", // GET
        get_quiz: "/quiz", // GET
        get_items: "/items/%s", // GET
        get_item: "/items/%s/%s", // GET
        get_call_request_html: "/calendly/embed_code", // GET
        get_users: "/users", // GET
        get_user: "/users/%s", // GET
        get_professional: "/users/%s", // GET
        login_google: "/auth/login/google?redirect=%s", // GET
        assets_url: "/assets/%s", // GET
        get_professionals: "/professional", // Get
        get_pro_profiles: "/items/pro_profile", // Get
        get_professionals_admin: "/professional/admin_panel", // Get
        get_professionals_client: "/professional/client_panel", // Get
        get_professional_info: "/professional/%s", // Get
        get_expertise: "/items/expertise", // Get
        get_projects: "/items/project", // Get
        get_project: "/project/%s", // GET
        get_roles: "/roles", // GET
        get_project_ideas: "/project/%s/ideas", // GET
        get_settings: "/items/setting", // GET
        get_blog: "/items/blog/%s", // GET
        get_clients: "/user/clients", // GET
        get_payment_result: "/payment/stripe/fulfill/%s", // GET
        get_address_list: "/google/map/autocomplete", // GET
        get_place: "/google/map/details", // GET

        login: "/auth/login", // POST
        logout: "/auth/logout", // POST
        // add_portfolio: "/items/idea", // POST
        add_idea: "/items/idea", // POST
        add_blog: "/items/blog", // POST
        add_expertise: "/items/expertise", // POST
        edit_idea: "/items/idea/%s", // POST
        upload_file: "/files", // POST
        calculate_cost_range: "/quiz/cost_range", // POST
        create_project: "/items/project", // POST
        analyze_styles: "/quiz/style_test", // POST
        add_user: "/users", // POST
        add_professional: "/users", // POST
        add_project_idea: "/items/project_idea", // POST
        add_project_package: "/items/project_package", // POST
        add_personal_note: "/items/personal_note", // POST
        add_project_client: "/user/project_client", // POST
        refresh_token: "/auth/refresh", // POST
        stripe_checkout: "/payment/stripe/checkout", // POST

        update_job: "/items/job/%s", // PATCH
        update_settings: "/items/setting", // PATCH
        update_blog: "/items/blog/%s", // PATCH
        edit_professional: "/users/%s", // PATCH
        archive_professional: "/users/%s", // PATCH
        archive_expertise: "/items/expertise/%s", // PATCH
        assign_pro_to_job: "/items/job/%s", // PATCH
        update_user: "/users/%s", // PATCH
        update_project: "/items/project/%s", // PATCH
        upload_plans: "/project/upload_plans/%s", // PATCH
        update_profile: "/users/me", // PATCH
        job_pro_suggest: "/items/job_pro_suggest/%s", // PATCH
        update_items: "/items/%s/%s", // PATCH

        items_collection: "/items/%s",
        item_collection: "/items/%s/%s",
    },

    // Get api
    api(key, parameters = []) {
        return this.urls["base_url"] + this.url(key, parameters);
    },

    // Get url without base url
    url(key, parameters = []) {
        if (!Array.isArray(parameters)) parameters = [];
        let url = this.urls[key];
        if (parameters.length !== 0) url = this.set_url_parameters(url, parameters);
        return url;
    },

    // Set url parameters
    set_url_parameters(url, params) {
        try {
            params.forEach((param) => {
                url = url.replace('%s', param);
            })
            return url;
        } catch (e) {
            console.log();
            return url;
        }
    },
};

export default urls;

<template>
  <div class="profiles-filter-item full-width">
    <v-fade-transition>
      <div class="d-flex justify-space-between pointer" @click="show = !show">
        <slot name="title">
          <div class="font-weight-bold py-3">{{ title }}</div>
        </slot>
        <div class="d-flex align-center">
          <v-avatar v-if="count" size="20" color="primary" class="background--text mr-1" >
            <div class="font-mt-2 font-size-12">{{count}}</div>
          </v-avatar>
          <v-icon v-if="!show">mdi-chevron-down</v-icon>
          <v-icon v-else>mdi-chevron-up</v-icon>
        </div>
      </div>
    </v-fade-transition>

    <v-scroll-y-transition>
      <div v-show="show" :class="{'light-scroll-y': !without_light_scroll, 'mt-2 mb-4': true}">
        <slot>
          <from-list-selector v-bind="$attrs" @input="e => $emit('input', e)">
            <template #label="{item}">
              <slot name="label" :item="item"/>
            </template>
          </from-list-selector>
        </slot>
      </div>
    </v-scroll-y-transition>

    <v-divider/>
  </div>
</template>

<script>

import FromListSelector from "@/components/selectors/FromListSelector";

export default {
  name: "ProfilesFilter",
  components: {FromListSelector},
  props: {
    title: {
      default: null,
      type: String
    },
    without_light_scroll: {
      default: false,
      type: Boolean
    },
    show_badge: {
      default: false,
      type: Boolean
    },
    count: {
      default: 0,
      type: Number
    },
  },
  data() {
    return {
      show: false,
    }
  }
}
</script>
<style>
</style>

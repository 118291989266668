import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"border-light rounded-lg overflow-hidden",attrs:{"outlined":""}},[_c('form',{ref:"upload-form",on:{"submit":function($event){$event.preventDefault();return _vm.request.post()}}},[(_vm.request.isLoading())?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),_c('div',{staticClass:"pt-6 pb-3 px-3 text-center"},[_c('router-link',{staticClass:"mt-1 mx-auto",attrs:{"to":{name:'Home'}}},[_c('logo')],1),_c('h2',{staticClass:"font-weight-medium mt-2"},[_vm._v(_vm._s(_vm.__('SignIn')))]),_c('div',[_vm._v(_vm._s(_vm.__('ToRenotive')))])],1),_c(VCardText,[_c(VTextField,{attrs:{"label":_vm.__('Email'),"type":"email","id":"username","outlined":"","required":""},model:{value:(_vm.request.body.email),callback:function ($$v) {_vm.$set(_vm.request.body, "email", $$v)},expression:"request.body.email"}}),_c(VTextField,{attrs:{"label":_vm.__('Password'),"type":"password","outlined":"","required":""},model:{value:(_vm.request.body.password),callback:function ($$v) {_vm.$set(_vm.request.body, "password", $$v)},expression:"request.body.password"}})],1),_c(VCardActions,{staticClass:"justify-end px-4 pb-6"},[_c(VBtn,{attrs:{"type":"submit","disabled":_vm.request.isLoading() || _vm.user_info_request.isLoading(),"text":"","color":"primary","elevation":"0"}},[_vm._v(" "+_vm._s(_vm.__('SignIn'))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
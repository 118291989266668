import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"project-selector full-width"},[(!_vm.checkbox)?_c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('Project'))+" "+_vm._s(_vm.$attrs['required'] !== undefined ? '*' : ''))]),_c(VSelect,_vm._b({staticClass:"custom-selector-border rounded-lg",attrs:{"items":_vm.items(),"value":_vm.value,"outlined":"","dense":""},on:{"input":(e) => _vm.$emit('input', e)}},'v-select',_vm.$attrs,false))],1):_c('div',[(_vm.request.isLoading())?_c('div',{staticClass:"py-6"},[_c('loading')],1):_c('div',{staticClass:"px-6 pt-3"},[_c(VRow,_vm._l((_vm.request.data),function(item,key){return _c(VCol,{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('div',{key:'project-selector-item-'+key,staticClass:"px-3"},[_c(VCheckbox,{staticClass:"text-truncate py-3 pb-2 mt-0",attrs:{"value":item.id},on:{"click":_vm.select},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c(VListItem,{staticClass:"py-0 pl-1"},[_c(VListItemAvatar,{attrs:{"size":"40"}},[_c(VAvatar,{attrs:{"size":"40"}},[_c('assets-image',{attrs:{"data":item.picture}})],1)],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-head font-weight-bold font-size-14 text-truncate"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VListItemSubtitle,{staticClass:"mt-2 text-truncate font-size-12"},[_vm._v(" "+_vm._s(_vm.__('Client'))+" : "),_c('span',{staticStyle:{"color":"#8C7A54"}},[_c('full-name',{attrs:{"data":item.client_id}})],1)])],1)],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c(VDivider)],1)])}),1),(!_vm.request.data)?_c('not-found-data',{staticClass:"mt-9"}):_vm._e(),_c('load-more',{staticClass:"mt-6",attrs:{"request":_vm.request}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
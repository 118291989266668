<template>
  <v-row>
    <!--  Horizontal    -->
    <v-slide-group v-if="horizontal">
      <v-slide-item v-for="(n, index) in Number.parseInt(count)" :key="'loader-horizontal'+index">
        <v-skeleton-loader
            v-bind:key="index"
            v-bind="attrs"
            class="ma-3"
            :width="width"
            :type="type"
        ></v-skeleton-loader>
      </v-slide-item>
    </v-slide-group>

    <!--  Default    -->
    <v-col
        v-else
        :cols="cols" :sm="col_sm" :md="col_md" :lg="col_lg" :xl="col_xl"
        v-for="(n, index) in Number.parseInt(count)"
        v-bind:key="index"
    >
      <v-skeleton-loader
          v-bind:key="index"
          v-bind="attrs"
          class="mx-auto"
          :width="width"
          :type="type"
      ></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Loader",
  props: {
    horizontal: {
      default: false,
      type: Boolean,
    },
    type: {
      type: String,
      default: 'card',
    },
    width: {
      type: [String, Number],
      default: null,
    },
    cols: {
      type: [String, Number],
      default: 4,
    },
    count: {
      type: [String, Number],
      default: 12,
    },
    col_sm: {
      type: [String, Number],
      default: null,
    },
    col_md: {
      type: [String, Number],
      default: null,
    },
    col_lg: {
      type: [String, Number],
      default: null,
    },
    col_xl: {
      type: [String, Number],
      default: null,
    },
    attrs: {
      type: Object,
    },
  }
}
</script>

<style scoped>

</style>

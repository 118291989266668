<template>
  <!--  Add Professional Form  -->
  <div>
    <loading v-if="request.isLoading()"/>
    <form @submit.prevent="submit" v-else>
      <v-row>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('FirstName') }} *</div>
          <v-text-field
              v-model="request.body.first_name"
              dense
              outlined
              required
          />
        </v-col>

        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('LastName') }}</div>
          <v-text-field
              v-model="request.body.last_name"
              type="text"
              dense
              outlined
          />
        </v-col>
      </v-row>
      <v-row>

        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('Phone') }}</div>
          <v-text-field
              v-model="request.body.phone_number"
              type="number"
              dense
              outlined
          />
        </v-col>

        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('Email') }} *</div>
          <v-text-field
              v-model="request.body.email"
              type="email"
              dense
              outlined
              required
          />
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <div class="font-weight-bold">{{ __('TelegramId') }}</div>
          <v-text-field
              v-model="request.body.telegram_id"
              type="text"
              dense
              outlined
          />
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12" md="6" v-if="_access('admin')">
          <status-selector :items="status_items" v-model="request.body.status"/>
        </v-col>

        <v-col cols="12" md="6">
          <expertise-selector v-model="expertises" :show_custom="!_role('client')" multiple/>
        </v-col>
      </v-row>
      <v-row>

        <v-col md="6" v-if="_access('pro')">
          <span>{{ __('UploadPortfolio') }}</span>
          <assets-images :data="request.body.portfolio" class="rounded-lg" max-height="188" max-width="190">
            <upload @then="uploaded" accept="application/pdf"></upload>
          </assets-images>
        </v-col>
        <v-col md="6">
          <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
            <span v-if="!request.is_loading">{{ __('Submit') }}</span>
            <span v-else>...</span>
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import Upload from "@/components/assets/Upload";
import ExpertiseSelector from "@/components/selectors/ExpertiseSelector";
import StatusSelector from "@/components/selectors/StatusSelector";
import AssetsImages from "@/components/assets/Images";
import Loading from "../app/Loading";

export default {
  name: "ProfessionalAdd",
  components: {Loading, AssetsImages, StatusSelector, ExpertiseSelector, Upload},
  props: {
    api_key: {
      default: 'add_professional',
      type: String,
    },
    fields: {
      default: '*,*.*',
      type: String,
    },
    api_params: {
      default: null,
      type: Array,
    },
    is_patch: {
      default: false,
      type: Boolean,
    },
    body_map: {
      default: null,
      type: Function,
    },

  },
  data() {
    return {
      request: new PostRequest(),
      portfolio: null,
      expertises: [],
      status_items: [
        {
          text: this.__('Active'),
          value: 'active',
        },
        {
          text: this.__('Archive'),
          value: 'archive',
        },
      ],
    }
  },
  mounted() {
    this.request.setApi(this.api_key);
    this.request.setApiParams(this.api_params);
    this.request.setThen(this.submitted);
    this.request.setFields(this.fields);
    this.setBody();
  },
  watch: {
    expertises(newVal) {
      this.request.body.expertises = [];
      newVal.forEach(item => this.request.body.expertises.push({expertise_id: item}))
    }
  },
  methods: {
    setBody() {
      let body =
          {
            first_name: "",
            last_name: "",
            phone_number: "",
            email: "",
            status: 'active',
            expertises: [],
            telegram_id: "",
            avatar: null,
            portfolio: null,
            role: "ebe9313f-150a-4331-98bf-af294eec69cf",
            pro_registration_status: "hired"
          };
      this.request.setBody(body);

      if (this._access('admin')) {
        this.request.body.pro_registration_status = "hired";
      } else if (this._access('client')) {
        this.request.body.pro_registration_status = "add_by_client";
      }
    },
    uploaded(response) {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        this.request.body.portfolio = response.data.id;
      } catch (e) {
        console.log(e);
      }
    },
    submitted(data) {
      this.$emit('then', data);
      this.setBody();

    },
    submit() {
      if (this.body_map) this.request.setBody(this.body_map(this.request.getBody())) ;
      if (this.is_patch) return this.request.patch();
      this.request.post();
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <input
      :value="request ? request.search_keyword : value"
      @input="search"
      @keyup.enter="$emit('search')"
      :placeholder="placeholder ? placeholder : __('Search')"
      class="px-4 py-2 rounded-lg border-light width-full"
      :style="{'max-width': max_width}"
  />
</template>

<script>
import {GetRequest} from "@/models/GetRequest";

export default {
  name: "ItemsSearch",
  props: {
    request: {
      type: GetRequest,
      default: null,
    },
    columns: {
      default: null,
      type: [Array, String],
    },
    placeholder: {
      default: null,
      type: String,
    },
    max_width: {
      default: '350px',
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
  },
  data() {
    return {}
  },
  methods: {
    search(e) {
      if (this.request) {
        this.request.search_keyword = e.target.value;
        this.request.search(e.target.value, this.columns)
      }
      this.$emit('input', e.target.value)
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width">
    <div class="font-weight-bold" v-if="!hide_title">{{ __('Currency') }}</div>

    <v-autocomplete
        :items="items()"
        :value="value"
        rel="city-selector"
        :search-input.sync="input"
        @input="(e) => change(e)"
        class="custom-selector-border rounded-lg"
        item-text="name"
        item-value="value"
        outlined
        :placeholder="__('SelectOne')"
        v-bind="$attrs"
        dense
        return-object
    >

    </v-autocomplete>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "CurrencySelector",
  components: {},
  props: {
    value: {
      default: null,
      type: [String, Number],
    },
    place: {
      default: null,
      type: [Object],
    },
    return_object: {
      default: false,
      type: Boolean,
    },
    return_name: {
      default: false,
      type: Boolean,
    },
    hide_title: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    input() {
      this.get();
    },
    value() {
      if (!this.value) this.input = null;
    }
  },
  data() {
    return {
      request: new GetRequest('get_items',['country']),
      input: null,
      custom_items: [],
    }
  },
  mounted() {
    this.set()
  },
  methods: {
    set(){
      if (!this.place || typeof this.place !== "object" || !this.place.city) return this.get();
      this.setCity();
    },
    setCity(){
      try{
        if (!this.place.city || !this.place.city.id) return this.$emit('input', null);
        this.emitInput(this.place.city.id, this.place.city);
        this.custom_items.push(this.place.city)
      }catch (e){
        console.log(e)
      }
    },
    emitInput(data, item){
      if (this.return_object) data = item;
      if (this.return_name) data = item.name;
      this.$emit('input', data);
      this.$emit('input-object', item);
    },
    change(e){
      try{
        this.emitInput(e.value, e)
      }catch (e){
        console.log(e)
      }
    },
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.is_loading = false;
      this.request.setFields('currency');
      this.request.setGroupBy('currency');
      this.request.setLimit('-1');
      this.request.get();
    },
    items() {
      try {
        let items = [];

        if (this.request.data) {
          this.request.data.forEach((item) => {
            items.push({
              name: item.currency,
              value: item.currency,
            })
          })
        }

        items = items.concat(this.custom_items);
        return items;

      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
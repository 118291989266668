<template>
  <div v-if="data">
    <!--    Horizontal -->
    <v-slide-group v-if="horizontal" class="slide-group-hide-arrows slide-group-custom-arrows" multiple show-arrows>
      <v-slide-item v-for="(item, index) in data">
        <div :style="'width:'+ width + 'px;'">
          <v-img
              :key="index+ Math.random()"
              :src="getUrl(item)"
              @click="$emit('select', item, index)"
              class="rounded-lg ma-1 ml-0 secondary pointer"
              v-bind="$attrs"
          >
            <slot name="image" :item="item" :index="index"></slot>
          </v-img>
        </div>

      </v-slide-item>
    </v-slide-group>

    <!--  Slider  -->
    <v-carousel
        v-else-if="carousel"
        cycle
        hide-delimiter-background
        :height="height"
        v-bind="$attrs"
    >
      <v-carousel-item
          v-for="(item, i) in data"
          :key="'carousel-item'+i+Math.random()"
          :src="getUrl(item)"
      >
      </v-carousel-item>
    </v-carousel>

    <!--  Default Images  -->
    <div v-else class="d-flex flex-wrap">
      <v-img
          v-for="(item, index) in data"
          :key="index+ Math.random()"
          :src="getUrl(item)"
          class="rounded-lg ma-1 secondary"
          v-bind="$attrs"
      >
        <slot name="image" :item="item" :index="index"></slot>
      </v-img>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import app from "../../tools/App";
import urls from "../../tools/Urls";

export default {
  inheritAttrs: false,
  name: "AssetsImages",
  props: {
    data: {
      default: null,
      type: Array
    },
    width: {
      default: null,
      type: [Number, String]
    },
    height: {
      default: null,
      type: [Number, String]
    },
    quality: {
      default: 90,
      type: [Number, String]
    },
    type: {
      default: 'cover',
      type: String
    },
    carousel: {
      default: false,
      type: Boolean
    },
    horizontal: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      list: [],
    }
  },
  mounted() {

  },
  methods: {

    getUrl(item) {
      try {
        let id = item.directus_files_id;
        if (this._is_object(item.directus_files_id)) id = item.directus_files_id.id;
        let url = urls.api('assets_url', [id]);

        let params = `?fit=${this.type}`;
        if (this.width) params += `&width=${this.width}`;
        if (this.height) params += `&height=${this.height}`;
        if (this.quality) params += `&quality=${this.quality}`;
        return url + params;
      } catch (e) {
        return app.default_image;
      }
    }
  }
}
</script>

<style scoped>

</style>
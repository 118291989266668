<template>
  <v-container>
    <page-title :text="__('CustomProfessionals')" heading/>
    <div class="pt-3">
      <v-chip :to="{name:'Professionals'}" class="mr-2 secondary text--text" text>
        {{ __('Professionals') }}
      </v-chip>
      <v-chip :to="{name:'CustomProfessionals'}" class="mr-2 primary background--text" text>
        {{ __('MyCustomPro') }}
      </v-chip>
    </div>
    <professionals
        :not_found_message="__('NotFoundProfessionals')"
        user_created="$CURRENT_USER"
        :filters_attrs="{hide_status: _access('client')}"
    />
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Professionals from "../../../components/professionals/Professionals";

export default {
  name: "CustomProfessionalsPage",
  components: {Professionals, PageTitle,}
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width">
    <div class="font-weight-bold">{{ title ? title : __('User') }}</div>

    <v-autocomplete
        :items="items()"
        :value="value"
        :multiple="multiple"
        @input="(e) => $emit('input', e)"
        class="custom-selector-border rounded-lg"
        item-text="text"
        item-value="value"
        outlined
        v-bind="$attrs"
        dense
    >
      <template v-slot:selection="data">
        <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            @click="data.select"
        >
          <avatar v-if="data.item.item" :data="data.item.item"/>
          {{ data.item.text }}
        </v-chip>
      </template>

      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item.text"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-avatar>
            <avatar v-if="data.item.item" :data="data.item.item"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.text"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";
import Avatar from "../account/Avatar";

export default {
  name: "UserSelector",
  components: {Avatar},
  props: {
    title: {
      default: null,
      type: String,
    },
    data: {
      default: null,
      type: Array,
    },
    status: {
      default: null,
      type: String,
    },
    role: {
      default: null,
      type: String,
    },
    expertises: {
      default: null,
      type: [Object, String],
    },
    value: {
      default: null,
      type: [String, Array],
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    first_label: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      request: new GetRequest('get_users'),
    }
  },
  watch: {
    expertises() {
      this.get()
    },
    status() {
      this.get()
    },
    role() {
      this.get()
    },
  },
  mounted() {
    this.get()
  },
  methods: {
    setFilters() {
      if (this.role) this.request.addFilter('role', this.role);
      if (this.status) this.request.addFilter('status', this.status);
      if (this.expertises) this.request.addFilter('expertises', this.expertises);
    },
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.setFilters();
      this.request.setLimit(500);
      this.request.get();
    },
    items() {
      try {
        let items = [];

        if (!this.request.data) return;
        this.request.data.forEach((item) => {
          items.push({
            text: item.first_name + ' ' + item.last_name,
            value: item.id,
            item: item,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>

</style>
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.request.is_loading)?[_c('loading')]:(_vm.item)?[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('profile-breadcrumbs',{attrs:{"data":_vm.item.pro_profile_id,"advanced_items":_vm.breadcrumbs_items}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('div',{staticClass:"project-title text-center mb-2"},[_c('div',{staticClass:"px-3 pb-2 text-h5 font-head d-inline-block main-footer-title"},[_vm._v(_vm._s(_vm.item.title))]),_c('div',{staticClass:"pt-3 d-flex align-center justify-center text-center"},[_c('div',[_c(VListItem,{staticClass:"px-0"},[_c(VListItemAvatar,[_c('avatar',{attrs:{"data":_vm.item.user_created,"color":"secondary","size":"35"}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c('span',[_vm._v(_vm._s(_vm.item.user_created.full_name))])])],1)],1)],1),_c('div',{staticClass:"px-3 primary--text"},[_vm._v(" | ")]),_c('date-text',{staticClass:"text--disabled",attrs:{"data":_vm.item.date_created,"use_format":""}})],1)]),_c('assets-image',{staticClass:"rounded-lg",attrs:{"data":_vm.item.pictures}}),_c('div',{staticClass:"el-tiptap-editor__content py-6",domProps:{"innerHTML":_vm._s(_vm.item.text)}}),_c('space',{attrs:{"height":"30"}}),_c('div',{staticClass:"text-center py-3 font-weight-bold"},[_vm._v(_vm._s(_vm.__('OtherStoriesFromThisUser')))]),_c('space',{attrs:{"height":"15"}}),_c('div',{staticClass:"px-3"},[_c('profile-stories',{attrs:{"data":_vm.item.pro_profile_id,"without":[_vm.item.id],"loader_count":4,"limit":"15","hide_search":"","hide_title":"","horizontal":"","hide_load_more":""}})],1),_c('space',{attrs:{"height":"45"}})],1)],1)]:[_c('not-found-data',{staticClass:"py-16"})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card
      v-if="data && _access('client') && typeof data === 'object' && data.is_expired"
      class="yellow overflow-hidden lighten-4 rounded-lg pb-3"
      flat
  >
    <v-row  align="center">
      <v-col cols="12" md="8">
        <v-card-title class="title">
          {{ __('YouNeedSubscription') }}
        </v-card-title>
        <v-card-text>
          {{ __('YouNeedSubscriptionText') }}
        </v-card-text>
      </v-col>
      <v-col cols="12" md="4" align="end">
        <div class="pa-3">
          <unlock unique_key="project-expired-unlock-package" :project_id="data.id" @then="$emit('reload')">
            <v-btn text :title="__('Unlock')">
              <v-icon>mdi-lock-open-variant-outline</v-icon>
              <span>{{ __('Unlock') }}</span>
            </v-btn>
          </unlock>
        </div>
      </v-col>
    </v-row>

  </v-card>

</template>

<script>

import Unlock from "../packages/Unlock";

export default {
  name: "ProjectExpired",
  components: {Unlock},
  props: {
    data: {
      default: null,
      type: Object,
    },

  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    get() {
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-row  v-if="text">
    <v-col v-if="heading" class="d-flex flex-wrap align-center">
      <div class="heading font-head pr-3">{{ text }}</div>
      <slot></slot>
    </v-col>
    <v-col v-else class="d-flex flex-wrap align-center">
      <div class="text-h4 py-5 pr-3">{{ text }}</div>
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "PageTitle",
  props: {
    text: {
      default: null,
      type: [String, Number,]
    },
    heading: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {}
  }
}
</script>

<style scoped>

</style>
<template>
  <!--  Add Idea Form  -->
  <form @submit.prevent="submit" v-if="request.body">
    <v-row>
      <v-col cols="12" md="12">
        <assets-images :data="request.body.pictures" class="rounded-lg" max-height="188" max-width="190">
          <upload @then="uploaded" accept="image/*"></upload>

          <template v-slot:image="{item, index}">
            <v-btn icon @click="removeImage(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </assets-images>
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('Title') }} *</div>
        <v-text-field
            v-model="request.body.title"
            dense
            outlined
            required
        />
      </v-col>

      <v-col cols="12" md="6">
        <room-selector :first_label="__('SelectOne')" v-model="request.body.room_id"/>
      </v-col>


      <v-col cols="12" md="6">
        <style-selector :first_label="__('SelectOne')" v-model="request.body.style_id"/>
      </v-col>

      <v-col cols="12" md="6">
        <budget-selector :first_label="__('SelectOne')" v-model="request.body.budget_id"/>
      </v-col>

      <v-col cols="12" md="6">
        <size-selector :first_label="__('SelectOne')" v-model="request.body.size_id"/>
      </v-col>

      <v-col cols="12" md="6">
        <color-selector :first_label="__('SelectOne')" v-model="request.body.color_id" custom/>
      </v-col>


      <v-col cols="12" md="12">
        <div class="font-weight-bold">{{ __('OnlineLink') }}</div>
        <v-text-field
            v-model="request.body.online_link"
            type="url"
            dense
            outlined
        />
        <v-checkbox :label="__('AcceptAddIdeaPolicy')"  :required="!request.body.online_link">

        </v-checkbox>
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('DesignCredit') }}</div>
        <v-text-field
            v-model="request.body.design_credit"
            type="text"
            dense
            outlined
        />
      </v-col>

      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('PhotographyCredit') }}</div>
        <v-text-field
            v-model="request.body.photography_credit"
            type="text"
            dense
            outlined
        />
      </v-col>


      <v-col cols="12" md="6" v-if="_access('admin')">
        <status-selector v-model="request.body.status" :value="request.body.status" :items="status_items"/>
      </v-col>

      <v-col md="12">

        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Submit') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>

    </v-row>
  </form>
</template>

<script>
import {PostRequest} from "../../models/PostRequest";
import Upload from "../assets/Upload";
import SizeSelector from "../selectors/SizeSelector";
import RoomSelector from "../selectors/RoomSelector";
import AssetsImages from "../assets/Images";
import ColorSelector from "../selectors/ColorSelector";
import StyleSelector from "../selectors/StyleSelector";
import StatusSelector from "../selectors/StatusSelector";
import BudgetSelector from "../selectors/BudgetSelector";

export default {
  name: "IdeasWrite",
  components: {StatusSelector, BudgetSelector, ColorSelector, StyleSelector, AssetsImages, RoomSelector, SizeSelector, Upload},
  props: {
    data: {
      default: null,
      type: Object
    },
  },
  data() {
    return {
      request: new PostRequest(),
      pictures: null,
      status_items: [
        {
          text: this.__('Published'),
          value: 'published'
        },
        {
          text: this.__('Pending'),
          value: 'pending'
        },
        {
          text: this.__('Draft'),
          value: 'draft'
        },
      ]
    }
  },
  mounted() {
    this.request.setApi(this.data ? 'edit_idea' : 'add_idea');
    this.request.setApiParams(this.data ? [this.data.id] : null);
    this.request.setThen((e) => this.submitted(e));
    this.setBody();
  },
  methods: {
    setBody() {
      if (this.data) {

        this.request.setBody(
            {
              title: this.data.title,
              size_id: this.getId(this.data.size_id),
              room_id: this.getId(this.data.room_id),
              budget_id: this.getId(this.data.budget_id),
              color_id: this.getId(this.data.color_id),
              online_link: this.data.online_link,
              design_credit: this.data.design_credit,
              photography_credit: this.data.photography_credit,
              pictures: this.data.pictures,
              status: this.data.status,
            }
        );
        return;
      }

      this.request.setBody(
          {
            title: null,
            style_id: null,
            size_id: null,
            budget_id: null,
            color_id: null,
            room_id: null,
            online_link: null,
            design_credit: null,
            photography_credit: null,
            pictures: [],
          }
      );
    },
    getId(item) {
      try {
        if (typeof item === 'object') return item.id;
        return item;
      } catch (e) {
        return null;
      }
    },
    uploaded(response) {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        this.request.body.pictures.push({directus_files_id: response.data.id});
      } catch (e) {
        console.log(e);
      }
    },
    submitted(data) {
      this.$emit('then', data);
      this.setBody();
    },
    submit() {
      this.before()
      if (this.data) return this.request.patch();
      this.request.post();
    },
    before() {
      try {
        this.$emit('before', this.request)
      } catch (e) {
        console.log(e);
      }
    },
    removeImage(index) {
      try {
        this.$delete(this.request.body.pictures, index)
      } catch (e) {
        console.log(e);
      }
    }
  },
}
</script>

<style scoped>

</style>
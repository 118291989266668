<template>
  <v-container>
    <page-title :text="__('Blog')"/>
    <blog-write/>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import BlogWrite from "@/components/blogs/BlogWrite";

export default {
  name: "AddBlogPage",
  components: {BlogWrite, PageTitle}
}
</script>

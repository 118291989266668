<template>
  <v-container  class="container-max-width">
    <template-view :key="name" :name="name"/>
  </v-container>
</template>

<script>

import TemplateView from "../../../components/templates/TemplateView";
export default {

  name: "TemplatePage",
  components: {TemplateView},
  computed:{
    name(){
      try{
        return this.$route.params.id;
      }catch (e){
        return null
      }
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

<template>
  <v-hover v-slot="{hover}">
    <v-card outlined flat width="190" class="mx-3 mb-3 ml-0 text-center rounded-lg py-7 px-3">
      <div>

        <v-avatar size="90" color="secondary" max-width="100%">
          <assets-image v-if="data.avatar && (data.avatar.length > 0)" :data="data.avatar" width="64"/>
          <v-icon v-else color="grey lighten-1" size="64">mdi-account</v-icon>
        </v-avatar>

        <v-overlay class="justify-end" light style="height: 38px;" opacity="0" absolute z-index="1">
          <user-menu :data="data"/>
        </v-overlay>
      </div>

      <div v-if="data && data.id" class="mt-3">
        <div class="title text-truncate">
          {{ data.first_name }}
          {{ data.last_name }}
        </div>

        <div class="subtitle-2 text--disabled">
          <div v-if="data.role && typeof data.role === 'object'">{{ data.role.name }}</div>
        </div>
        <small class="grey--text text--lighten-1">
          {{ data.status }}
        </small>
      </div>
    </v-card>
  </v-hover>
</template>

<script>

import AssetsImage from "@/components/assets/Image";
import UserMenu from "./UserMenu";

export default {
  name: "UserCard",
  components: {UserMenu, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object
    },

  },

  mounted() {

  },
}
</script>

<style scoped>

</style>

import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.request.data)?_c(VList,[_vm._l((_vm.request.data),function(item){return _c(VListItem,{key:item.id,attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" "),(item.job_type_id && item.job_type_id.hasOwnProperty('title'))?_c(VListItemSubtitle,[_vm._v(" "+_vm._s(item.job_type_id.title)+" ")]):_vm._e()],1)],1),_c(VListItemActionText,[_c(VBtn,{attrs:{"disabled":item.status === 'archive',"color":"primary","icon":""},on:{"click":function($event){return _vm.selectItem(item)}}},[_c(VIcon,[_vm._v(" mdi-trash-can ")])],1)],1)],1)}),_c('expertise-archive-modal',{attrs:{"data":_vm.selected},on:{"then":_vm.thenArchive},model:{value:(_vm.archive_modal),callback:function ($$v) {_vm.archive_modal=$$v},expression:"archive_modal"}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
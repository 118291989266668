<template>
  <div>

    <template v-if="request.is_loading">
      <loading/>
    </template>

    <template v-else-if="item">
      <v-row>
        <v-col cols="12" class="hidden-md-and-down">
          <profile-breadcrumbs :data="item.pro_profile_id" :advanced_items="breadcrumbs_items"/>
        </v-col>

        <v-col
            cols="12"
            sm="3"
            class="hidden-md-and-down"
            v-if="_is_object(item.pro_profile_id) && !_owner(item.pro_profile_id.pro_id)"
        >
          <div class="position-sticky" style="top: 150px !important;">
            <profile-contact class="py-2" :data="item.pro_profile_id"/>
          </div>
        </v-col>

        <v-col cols="12" :sm=" _is_object(item.pro_profile_id) && !_owner(item.pro_profile_id.pro_id) ? 9: 12">

          <div class="px-3">
            <portfolio-project-header :data="item"/>

            <portfolio-project-text :data="item"/>

            <portfolio-project-pictures :data="item"/>

            <div class="hidden-md-and-up mt-7 text-center">
              <button-share
                  color="primary"
                  class="font-size-12 font-weight-unset rounded-lg"
                  outlined
                  :title="__('ShareThisProject')"
              />
            </div>

            <space height="30"/>

            <!--  Another projects          -->
            <div class="text-left text-md-center py-2 mt-md-4 font-weight-bold  font-size-18">
              {{ __('OtherProjectFromPro') }}
            </div>

            <v-divider class="hidden-md-and-up primary mb-1"/>

            <profile-projects-desktop
                :data="item.pro_profile_id"
                :without="[item.id]"
                :loader_count="3"
                hide_search
                hide_title
                limit="3"
                sm="4"
                hide_load_more
            />
          </div>
          <space height="45"/>
        </v-col>


      </v-row>

    </template>

    <template v-else>
      <not-found-data class="py-16"/>
    </template>

  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import Loading from "@/components/app/Loading";
import PortfolioProjectHeader from "@/components/portfolio/PortfolioProjectHeader";
import PortfolioProjectText from "@/components/portfolio/PortfolioProjectText";
import PortfolioProjectPictures from "@/components/portfolio/PortfolioProjectPictures";
import ProfileProjects from "@/components/profiles/ProfileProjects";
import NotFoundData from "@/components/app/NotFoundData";
import ProfileActions from "@/components/profiles/ProfileActions";
import ProfileContact from "@/components/profiles/ProfileContact";
import ProfileBreadcrumbs from "@/components/profiles/ProfileBreadcrumbs";
import Space from "@/components/app/Space";
import ButtonShare from "@/components/buttons/ButtonShare";
import ProfileProjectsDesktop from "@/components/profiles/ProfileProjectsDesktop";

export default {
  name: "PortfolioProject",
  components: {
    ProfileProjectsDesktop,
    ButtonShare,
    Space,
    ProfileBreadcrumbs,
    ProfileContact,
    ProfileActions,
    NotFoundData,
    ProfileProjects,
    PortfolioProjectPictures,
    PortfolioProjectText,
    PortfolioProjectHeader,
    Loading,
  },
  props: {
    id: {
      default: null,
      type: [String, Number],
      required: true
    }
  },
  watch: {
    'id'() {
      this.get()
    },
    'without'() {
      this.get()
    }
  },
  data() {
    return {
      request: new GetRequest('item'),
    }
  },
  computed: {
    breadcrumbs_items() {
      try {
        return [
          {
            text: this.item.title,
            disabled: true
          }
        ];
      } catch (e) {
        return [];
      }
    },
    item() {
      return this.request.data;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      if (!this.id) return;
      this.request.setApiParams(['pro_profile_project', this.id]);
      this.request.setFields('*,pro_profile_id.*,budget_id.name,ideas.*,ideas.color_id.*,ideas.budget_id.*,ideas.size_id.*,ideas.style_id.*,ideas.room_id.*,ideas.pictures.directus_files_id, budget_id.*, style_id.*')
      this.request.setParam('deep', {"ideas": {"_sort": ["pro_project_sort"]}});
      this.request.setThen((data) => this.$emit('onload', data));
      this.request.row();
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width">
    <inline-selector :value="value" :items="items()" @input="select">

    </inline-selector>
  </div>
</template>

<script>
import InlineSelector from "../selectors/InlineSelector";

export default {
  name: "IdeasTabs",
  components: {InlineSelector},
  props: {
    value: {
      default: null,
      type: String,
    },

  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    items() {
      return [
        {
          text: this.__('UsersPictures'),
          value: 'users_pictures',
        },
        {
          text: this.__('MyIdeas'),
          value: 'my_ideas'
        },
      ];
    },
    select(value) {
      try {
        this.$emit('input', value)
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>

</style>
<template>
  <div class="full-width">
    <inline-selector :value="request.getFilter('subscription_type')" :items="items()" @input="select"  :disabled="request.isLoading()"/>
  </div>
</template>

<script>
import InlineSelector from "../selectors/InlineSelector";
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "ProjectsSubscription",
  components: {InlineSelector},
  props: {
    request: {
      default: new GetRequest(),
      type: GetRequest,
      required: true,
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    items() {
      return [
        {
          text: this.__('All'),
          value: null,
        },
        {
          text: this.__('Trial'),
          value: 'trial'
        },
        {
          text: this.__('Essential'),
          value: 'essential'
        },
        {
          text: this.__('Ultimate'),
          value: 'ultimate'
        },
      ];
    },
    select(value) {
      try {
        this.request.is_loading = false;
        this.request.addFilter('subscription_type', value);
        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>

</style>
<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col cols="12">
        <page-title :text="__('Packages')"/>
      </v-col>

      <v-col cols="12">
        <packages @payment="payment"/>
      </v-col>

      <v-col cols="12">
        <space height="60"/>
      </v-col>
    </v-row>


    <v-dialog v-model="dialog" persistent max-width="690">
      <v-card>
        <div class="pa-6 py-12 text-center">
          <div v-html=" __('PackagesDialog')"></div>
          <div class="d-flex justify-center">
            <v-btn
                class="mt-6 rounded-lg"
                text
                @click="dialog = false"
            >
              {{ __('Cancel') }}
            </v-btn>

            <space/>

            <v-btn
                color="primary "
                class="mt-6 rounded-lg"
                elevation="0"
                :to="{name:'StartProject'}"
            >
              {{ __('Continue') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Packages from "../../../components/packages/Packages";
import PageTitle from "../../../components/app/PageTitle";
import Space from "../../../components/app/Space";

export default {

  name: "PackagesPage",
  components: {Space, PageTitle, Packages},
  data() {
    return {
      dialog: null,
    }
  },
  methods: {
    payment(e) {
      this.$store.state.package = e;
      this.dialog = true;
    }
  },
}
</script>

<style scoped>

</style>

<template>
  <div   v-if="_is_object(data)">
    <h3>{{ __('BusinessInformation') }}</h3>
    <v-divider class="my-3"/>

    <v-card class="pa-6 px-9 my-6 rounded-lg" outlined flat>
      <v-row align="center">
        <!--  Left Side Info      -->
        <v-col cols="12" sm="6">
          <div class="d-flex primary--text align-center">
            <v-avatar class="profile-logo" size="70">
              <assets-image :data="data.logo"/>
            </v-avatar>

            <div class="px-5">
              <span class="title font-head font-size-18 font-weight-bold">{{ data.business_name }}</span>
            </div>

            <v-divider class="mx-3" vertical/>

            <div class="px-3 font-size-14 ">
              <div>{{ data.address }}</div>
              <div>{{ data.state }}</div>
              <div>{{ data.city }}</div>
              <div>{{ data.country }}</div>
            </div>
          </div>
        </v-col>

        <!--   Right Side Info     -->
        <v-col cols="12" sm="6">
          <div class="d-flex primary--text">
            <div class="px-3 font-size-14">
              <div>
                <span class="text--disabled">{{ __('PhoneNumber') }}  : </span>
                <a :href="'tel:'+data.phone_number">{{data.phone_number ?? '-'}}</a>
              </div>
              <div>
                <span class="text--disabled">{{ __('Website') }}  : </span>
                <a :href="data.website" target="_blank">{{ data.website }}</a>
              </div>
              <div>
                <span class="text--disabled">{{ __('Fax') }}  : </span>
                {{ data.fax }}
              </div>
            </div>
            <div class="px-3">
              <div class="font-size-14">
                <span class="text--disabled">{{ __('TypicalJobCost') }}  : </span>
                <profile-typical-cost :data="data"/>
              </div>
              <div class="font-size-14">
                <span class="text--disabled">{{ __('LicenseNumber') }}  : </span>
                {{ data.license_number }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>


  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import ProfileTypicalCost from "@/components/profiles/ProfileTypicalCost";

export default {
  name: "ProfileInfoDesktop",
  components: {ProfileTypicalCost, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },

}
</script>

<style scoped>

</style>
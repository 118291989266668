<template>
  <v-row >
    <v-col cols="12" sm="8">{{ title }}</v-col>
    <v-col cols="12" sm="4">
      <input
          v-if="_access('admin')"
          class="border-light rounded-lg px-3 py-1"
          style="max-width: 120px;"
          v-model="request.body.status"
          @focusout="e => update()"
      />
      <div v-else>
        {{request.body.status ? request.body.status : __('None')}}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Space from "../app/Space";
import {PostRequest} from "../../models/PostRequest";
export default {
  name: "PackagesService",
  components: {Space},
  props: {
    data: {
      type: Object,
      default: null
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      status: null,
      request: new PostRequest('update_items'),
    }
  },
  computed: {
    title() {
      try {
        return this.data.service_id.title;
      } catch (e) {
        return null;
      }
    },
  },
  mounted() {
    this.set()
  },
  methods: {
    set() {
      try {
        this.request.setBody({
          'status' : this.data.status
        });
      } catch (e) {
        console.log(e)
      }
    },
    update() {
      this.request.setApiParams(['project_package_service', this.data.id]);
      this.request.setThen(() => {});
      this.request.patch();
    }
  }
}
</script>

<style>

.package-item .v-slide-group__next--disabled,
.package-item .v-slide-group__prev--disabled {
  display: none;
}
</style>
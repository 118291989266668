<template>
  <div id="profile-project-form">
    <template v-if="get_request.is_loading && id">
      <loading/>
    </template>

    <template v-else>
      <v-row>
        <v-col cols="12" sm="12" class="hidden-sm-and-down">
          <profile-breadcrumbs v-if="pro_profile" :data="pro_profile" :advanced_items="breadcrumbs_items"/>
        </v-col>

        <v-col cols="12" sm="12" lg="9">

          <form @submit.prevent="submit">
            <v-row v-if="!from_create" class="px-3 px-md-0 profile-project-form-fields hide-field-details">

                <v-col cols="12" sm="12">
                  <label class="font-weight-bold">{{ __('CreateNewProject') }}</label>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="font-weight-bold">{{ __('Title') }}</div>
                  <v-text-field
                      v-model="request.body.title"
                      class="custom-selector-border rounded-lg"
                      outlined
                      required
                      dense
                  />
                </v-col>
                <v-col cols="6" sm="3">
                  <budget-selector
                      v-model="request.body.budget_id"
                      outlined
                  />
                </v-col>
                <v-col cols="6" sm="3">
                  <div class="font-weight-bold">{{ __('Year') }}</div>
                  <v-text-field
                      v-model="request.body.year"
                      class="custom-selector-border rounded-lg"
                      outlined
                      dense
                  />
                </v-col>

                <v-col cols="6" sm="6">
                  <style-selector
                      v-model="request.body.style_id"
                      outlined
                  />
                </v-col>

                <v-col cols="6" sm="6">
                  <size-selector
                      v-model="request.body.size_id"
                      outlined
                  />
                </v-col>

                <v-col cols="12">
                  <address-selector v-model="request.body.address" @input-object="setPlace" required/>
                </v-col>

                <v-col cols="12" sm="4">
                  <city-selector
                      v-model.number="request.body.city"
                      :state="request.body.state"
                      :place="request.body._place"
                      @input-object="e => request.body._place.city = e"
                      return_name
                  />
                </v-col>

                <v-col cols="12" sm="4">
                  <state-selector
                      v-model="request.body.state"
                      :place="request.body._place"
                      @change="e => changeState()"
                      return_name
                  />
                </v-col>

                <v-col cols="12" sm="4">
                  <div class="font-weight-bold">{{ __('Country') }}</div>
                  <v-text-field
                      v-model="request.body.country"
                      class="custom-selector-border rounded-lg"
                      outlined
                      dense
                  />
                </v-col>

                <v-col cols="12">
                  <div class="font-weight-bold">{{ __('ProjectDescription') }}</div>
                  <v-textarea
                      class="rounded-lg"
                      v-model="request.body.description"
                      outlined
                      required
                  />
                </v-col>



            </v-row>


            <space height="25"/>

            <div class="px-3 px-md-0" v-if="id">

              <div class="hidden-md-and-up mb-4  pa-2 grey lighten-3 rounded-lg">
                <small>{{ __('MakeCoverImage') }}</small>
              </div>

              <v-divider v-if="!from_create"/>

              <ideas-editor :data="ideas" :project_id="id" :disabled="! id" @dragged="dragged">
                <template v-if="! id" #subtitle-add>
                  <div>
                    <v-icon color="warning" small>mdi-alert</v-icon>&nbsp;
                    <small>{{ __('ForAddImagesSaveProject') }}</small>
                  </div>
                </template>
              </ideas-editor>
            </div>

            <space height="35"/>


            <v-row v-if="!id">
              <v-col cols="12" class="text-center">
                <v-btn
                    type="submit"
                    color="primary"
                    elevation="0"
                    class="py-3 mx-auto rounded-lg mr-3 mb-3"
                    @mousedown="setStatus('draft')"
                    :disabled="request.isLoading()"
                >
                  {{ __('Next') }}
                </v-btn>
              </v-col>
            </v-row>

            <v-row v-else>
              <v-col cols="12" class="align-center text-center px-3">
                <v-btn
                    v-if="id"
                    type="button"
                    color="primary"
                    elevation="0"
                    class="py-3 mx-auto rounded-lg mr-3 mb-3"
                    target="_blank"
                    :to="{name: 'ProfessionalProject', params:[id]}"
                    :disabled="request.body.id"
                    outlined
                >
                  {{ __('Preview') }}
                </v-btn>
                <v-btn
                    type="submit"
                    color="primary"
                    elevation="0"
                    class="py-3 mx-auto rounded-lg mr-3 mb-3"
                    @mousedown="setStatus('published')"
                    :disabled="request.isLoading()"
                    ref="button-publish"
                >
                  <loading-dots :request="request" :text="__('Publish')"/>
                </v-btn>
                <v-btn
                    v-if="!request.isLoading()"
                    type="submit"
                    color="grey"
                    elevation="0"
                    class="py-3 mx-auto rounded-lg mb-3"
                    @mousedown="setStatus('draft')"
                >
                  <loading-dots :request="request" :text="__('SaveToDraft')"/>
                </v-btn>
              </v-col>
            </v-row>

          </form>
        </v-col>

        <v-col cols="12" class="hidden-md-and-down" sm="4" md="3">
          <div class="px-6 position-sticky">
            <profile-project-form-guide/>
          </div>
        </v-col>
      </v-row>
      <space height="45"/>
    </template>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import Loading from "@/components/app/Loading";
import NotFoundData from "@/components/app/NotFoundData";
import {PostRequest} from "@/models/PostRequest";
import BudgetSelector from "@/components/selectors/BudgetSelector";
import CitySelector from "@/components/selectors/CitySelector";
import StatusSelector from "@/components/selectors/StatusSelector";
import IdeasForm from "@/components/ideas/IdeasEditor";
import IdeasEditor from "@/components/ideas/IdeasEditor";
import StateSelector from "@/components/selectors/StateSelector";
import AddressSelector from "@/components/selectors/AddressSelector";
import ProfileBreadcrumbs from "@/components/profiles/ProfileBreadcrumbs";
import StyleSelector from "@/components/selectors/StyleSelector";
import SizeSelector from "@/components/selectors/SizeSelector";
import Space from "@/components/app/Space";
import LoadingDots from "@/components/app/LoadingDots";
import ProfileProjectFormGuide from "@/components/profiles/ProfileProjectFormGuide";

export default {
  name: "ProfileProjectForm",
  components: {
    ProfileProjectFormGuide,
    LoadingDots,
    Space,
    IdeasEditor,
    SizeSelector,
    StyleSelector,
    ProfileBreadcrumbs,
    AddressSelector,
    StateSelector,
    IdeasForm,
    StatusSelector,
    CitySelector,
    BudgetSelector,
    NotFoundData,

    Loading,
  },
  props: {
    profile_id: {
      default: null,
      type: [String, Number],
      required: true
    },
    id: {
      default: null,
      type: [String, Number],
    }
  },
  watch: {
    'id'() {
      this.set()
    },
    'without'() {
      this.getProject()
    }
  },
  data() {
    return {
      get_request: new GetRequest('item'),
      request: new PostRequest('items', ['pro_profile_project']),
      update_sort_request: new PostRequest('item'),
      status_items: [
        {
          text: this.__('Published'),
          value: 'published'
        },
        {
          text: this.__('Pending'),
          value: 'pending'
        },
        {
          text: this.__('Draft'),
          value: 'draft'
        }
      ]
    }
  },
  computed: {
    ideas() {
      try {
        return this.get_request.data.ideas;
      } catch (e) {
        return null;
      }
    },
    from_create() {
      try {
        return this.$route.query.from_create;
      } catch (e) {
        return false;
      }
    },
    breadcrumbs_items() {
      try {
        return [
          {
            text: this.__('Project'),
            disabled: true
          }
        ];
      } catch (e) {
        return [];
      }
    },
    project() {
      return this.get_request.data;
    },
    pro_profile() {
      try {
        if (!this.id) return this.get_request.data;
        return this.get_request.data.pro_profile_id;
      } catch (e) {
        return null;
      }
    },
  },
  mounted() {
    this.set();
  },
  methods: {
    setParams() {
      if (!this.id) return;
      this.request.setApi('item');
      this.request.setApiParams(['pro_profile_project', this.id]);
    },
    setStatus(status = 'published') {
      this.request.body.status = status;
    },
    submit() {
      this.$delete(this.request.body, '_place');
      this.request.setThen(response => this.then(response))
      if (this.id) return this.request.patch();
      this.request.post();
    },
    then(response) {
      try {
        if (!response || !response.data) return;
        if (response.data.status === 'published') return this.$router.back();

        if (!this.id)
          this.$router.replace({
            name: 'ProfessionalProjectEdit',
            params: {id: this.profile_id, project_id: response.data.id},
            query: {from_create: true}
          })
      } catch (e) {
        console.log(e);
      }
    },
    set() {
      this.setParams();
      if (!this.id && this.profile_id) this.getProfile();
      if (!this.id) return this.setBody();
      this.getProject();
    },
    setBody() {
      let data = {status: 'daft',}
      let body = {};

      if (this.id) {
        data = this.project;
      }

      body = {
        title: data.title,
        budget_id: data.budget_id,
        address: data.address,
        style_id: data.style_id,
        size_id: data.size_id,
        zip_code: data.zip_code,
        city: data.city,
        state: data.state,
        country: data.country,
        status: data.status,
        year: data.year,
        description: data.description,
        _place: {city: null},
      };

      if (!this.id) {
        body.pro_profile_id = this.profile_id;
      }

      this.request.setBody(body)
    },
    getProject() {
      if (!this.id) return;
      this.get_request.setApiParams(['pro_profile_project', this.id]);
      this.get_request.setFields('*,pro_profile_id.*,ideas.*,ideas.color_id.*,ideas.room_id.title,ideas.room_id.id,ideas.pictures.directus_files_id')
      this.get_request.setParam('deep', {"ideas": {"_sort": ["pro_project_sort"]}});
      this.get_request.setThen(() => this.setBody());
      this.get_request.row();
    },
    getProfile() {
      if (!this.profile_id) return;
      this.get_request.setApiParams(['pro_profile', this.profile_id]);
      this.get_request.setFields('*')
      this.get_request.row();
    },
    changeState() {
      this.request.body._place = {city: null}
      this.request.body.city = null;
      this.request.body.address = null;
    },
    setPlace(place) {
      try {
        this.request.body._place = place
        this.request.body.zip_code = place.zip_code
        this.request.body.country = this._is_object(place.country) ? place.country.name : place.country;
      } catch (e) {
        console.log(e);
      }
    },
    dragged(items) {
      this.update_sort_request.setApiParams(['pro_profile_project', this.id])
      this.update_sort_request.setBody(this.sortBody(items));
      this.update_sort_request.setMessage(this.__('SortUpdated'));
      this.update_sort_request.patch();
    },
    sortBody(items) {
      try {
        let result = [];
        let counter = 1;
        items.forEach((item) => {
          if (!this._is_object(item) || !item.id) return
          result.push({'pro_project_sort': counter, 'id': item.id})
          counter++;
        })
        return {'ideas': result};
      } catch (e) {
        return [];
      }
    },
  }
}
</script>

<style>
#profile-project-form .profile-project-form-fields div.font-weight-bold {
  font-weight: normal !important;
}
</style>
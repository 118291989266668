import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.circle)?_c('div',{staticClass:"pa-5"},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({hover}){return [(_vm.data && _vm.data.id)?_c('div',{class:{
          'rounded-circle text-center justify-center pt-8': true,
          'elevation-5': hover,
          'grey lighten-3': !_vm.selected,
        },style:('cursor: pointer; width: 160px; height: 160px;' + (_vm.selected ? 'background:#e594004d' : '')),on:{"click":_vm.onClick}},[(_vm.selected)?_c('div',{staticClass:"text-right",staticStyle:{"position":"absolute","width":"140px"}},[_c(VIcon,[_vm._v("mdi-checkbox-marked-circle")])],1):_vm._e(),(!_vm.image)?_c(VAvatar,{attrs:{"tile":_vm.tile,"color":"transparent","size":"64","title":_vm.data.title}},[_c('assets-image-simple',{staticStyle:{"max-width":"100%"},attrs:{"data":_vm.data.icon}})],1):_vm._e(),_c('div',{staticClass:"text-center text-subtitle-1 pt-2"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")])],1):_vm._e()]}}],null,false,2077462749)})],1):(_vm.data && _vm.data.id)?_c('div',{staticClass:"pa-2",staticStyle:{"cursor":"pointer"},on:{"click":_vm.onClick}},[(!_vm.image)?_c(VAvatar,{attrs:{"tile":_vm.tile,"color":"transparent","size":"125","title":_vm.data.title}},[_c('div',{class:_vm.selected ? 'ma-1 rounded-lg pa-5 selected-room' : 'pa-5'},[_c('assets-image-simple',{staticStyle:{"max-width":"100%"},attrs:{"data":_vm.data.icon}})],1)]):_c(VAvatar,{staticClass:"mb-2",class:{'rounded-lg':_vm.tile},attrs:{"tile":_vm.tile,"color":"transparent","size":"125","title":_vm.data.title}},[_c('assets-image',{class:_vm.selected ? 'selected-room' : '',attrs:{"data":_vm.data.image,"width":"150"}})],1),_c('div',{staticClass:"text-center text-subtitle-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-hover v-slot="{ hover }">
    <a v-if="data" :href="data.link" :disabled="!data.link" target="_blank">
      <div :class="{
        'rounded-lg pa-3 my-1  text--lighten-3': true,
        'primary background--text': hover && data.link,
        'secondary  ': !hover,
        'text--disabled': !data.link,
        'text--text': !hover && data.link,
      }">
        <v-icon> {{ data.icon }}</v-icon>&nbsp;&nbsp;
        {{ data.text }}
      </div>
    </a>
  </v-hover>
</template>

<script>

export default {
  name: "LinkListItem",
  components: {},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<style scoped>

</style>

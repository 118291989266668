<template>
    <v-select
        :label="title"
        :items="items"
        :value="value"
        @input="(e) => $emit('input', e)"
        class="custom-selector-border rounded-lg"
        dense
        outlined
        v-bind="$attrs"
    ></v-select>
</template>

<script>

export default {
  name: "JobStatusSelector",
  props: {
    value: {
      default: null,
      type: String,
    },
    title: {
      default: null,
      type: String,
    },
    items: {
      default: null,
      type: []
    }
  },
  data() {
    return {}
  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style scoped>

</style>

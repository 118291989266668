<template>
  <v-container>
    <page-title :text="__('EditUser')"/>
    <users-edit :id="$route.params.id"/>
  </v-container>
</template>

<script>
import PageTitle from "@/components/app/PageTitle";
import UsersEdit from "@/components/users/UsersEdit";

export default {
  name: "EditUserPage",
  components: {UsersEdit, PageTitle}
}
</script>

<style scoped>

</style>

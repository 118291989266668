import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.title ? _vm.title : _vm.__('Date')))]),_c(VDialog,{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VTextField,_vm._g(_vm._b({attrs:{"outlined":"","dense":"","append-icon":"mdi-calendar","readonly":""},on:{"click":function($event){_vm.modal = true}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c(VDatePicker,{attrs:{"scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(null)}}},[_vm._v(" "+_vm._s(_vm.__('Cancel'))+" ")]),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.select}},[_vm._v(" "+_vm._s(_vm.__('OK'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card
      v-if="data"
      class="rounded-lg  mb-3"
      :title="__('Tags')"
      outlined
  >
    <v-card-title class="title">
      {{ __('Tags') }}
    </v-card-title>

    <!-- Tags -->
    <v-card-text>
      <div>
        <tags
            :item_id="data.id"
            :data="data.personal_tags"
            :show_add="!expired"
            :deletable="!expired"
        />
      </div>
    </v-card-text>

  </v-card>
</template>

<script>
import Tags from "../tags/Tags";

export default {
  name: "ProjectTags",
  components: {Tags},
  props: {
    data: {
      default: null,
      type: Object,
    },
    expired: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>
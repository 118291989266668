<template>

  <div>
    <!-- Circle   -->
    <div v-if="circle" class="pa-5">
      <v-hover v-slot="{hover}">
        <div
            @click="onClick"
            v-if="data && data.id"
            :class="{
            'rounded-circle text-center justify-center pt-8': true,
            'elevation-5': hover,
            'grey lighten-3': !selected,
          }"

            :style="'cursor: pointer; width: 160px; height: 160px;' + (selected ? 'background:#e594004d' : '')"
        >
          <div v-if="selected" style="position: absolute; width: 140px" class=" text-right">
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </div>

          <v-avatar v-if="!image" :tile="tile" color="transparent" size="64" :title="data.title">
            <assets-image-simple :data="data.icon" style="max-width: 100%"/>
          </v-avatar>


          <div class="text-center text-subtitle-1   pt-2">
            {{ data.title }}
          </div>

        </div>
      </v-hover>
    </div>

    <!--  Default  -->
    <div @click="onClick" v-else-if="data && data.id" class="pa-2" style="cursor: pointer">
      <v-avatar v-if="!image" :tile="tile" color="transparent" size="125" :title="data.title">
        <div :class="selected ? 'ma-1 rounded-lg pa-5 selected-room' : 'pa-5'">
          <assets-image-simple :data="data.icon" style="max-width: 100%"/>
        </div>
      </v-avatar>

      <v-avatar
          v-else
          :tile="tile"
          :class="{'rounded-lg':tile}"
          color="transparent"
          size="125"
          :title="data.title"
          class="mb-2"
      >
        <assets-image :data="data.image" width="150" :class="selected ? 'selected-room' : ''"/>
      </v-avatar>

      <div class="text-center text-subtitle-1 font-weight-bold ">
        {{ data.title }}
      </div>
    </div>
  </div>

</template>

<script>
import AssetsImage from "../assets/Image";
import AssetsImageSimple from "../assets/ImageHtml";

export default {
  name: "Room",
  components: {AssetsImageSimple, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
      required: true,
    },
    selected: {
      default: false,
      type: Boolean,
    },
    tile: {
      default: false,
      type: Boolean,
    },
    image: {
      default: false,
      type: Boolean,
    },
    icon: {
      default: true,
      type: Boolean,
    },
    circle: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    onClick() {
      if (this.$listeners && this.$listeners.click) return this.$emit('click', this.data);
      this.$router.push({name: 'PublicIdeas', query: {room_id: this.data.id, page_title: this.data.title}})
    }
  }
}
</script>

<style scoped>
.selected-room {
  border: 5px rgba(0, 0, 0, 0.9) solid;
}
</style>
<template>
  <div class="project-selector full-width">
    <div v-if="!checkbox">
      <div class="font-weight-bold">{{ __('Project') }} {{ $attrs['required'] !== undefined ? '*' : '' }}</div>
      <v-select
          :items="items()"
          :value="value"
          @input="(e) => $emit('input', e)"
          class="custom-selector-border rounded-lg"
          outlined
          dense
          v-bind="$attrs"
      ></v-select>
    </div>

    <!-- Checkbox   -->
    <div v-else>
      <div v-if="request.isLoading()" class="py-6">
        <loading/>
      </div>
      <div v-else class="px-6 pt-3" >
        <v-row>
          <v-col cols="12" sm="12" v-for="(item, key) in request.data" class="pa-0">
            <div class="px-3" :key="'project-selector-item-'+key">
              <v-checkbox
                  v-model="selected"
                  class='text-truncate py-3 pb-2 mt-0'
                  :value="item.id"
                  @click="select"
              >
                <template #label>
                  <v-list-item class="py-0 pl-1">
                    <v-list-item-avatar size="40">
                      <v-avatar size="40">
                        <assets-image :data="item.picture"/>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>

                      <v-list-item-title class="font-head font-weight-bold font-size-14 text-truncate">
                        {{ item.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="mt-2 text-truncate font-size-12">
                        {{ __('Client') }} :
                        <span style="color:#8C7A54;"><full-name :data="item.client_id"/></span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                </template>
              </v-checkbox>
              <v-divider/>
            </div>
          </v-col>
        </v-row>
        <not-found-data class="mt-9" v-if="!request.data"/>
        <load-more class="mt-6" :request="request"/>
      </div>
    </div>
  </div>
</template>

<script>

import {GetRequest} from "../../models/GetRequest";
import LoadMore from "../app/LoadMore";
import NotFoundData from "../app/NotFoundData";
import Loading from "../app/Loading";
import AssetsImage from "@/components/assets/Image";
import FullName from "@/components/account/FullName";

export default {
  name: "ProjectSelector",
  components: {FullName, AssetsImage, Loading, NotFoundData, LoadMore,},
  props: {
    value: {
      default: null,
      type: [Array, Object],
    },
    status: {
      default: null,
      type: String,
    },
    ideas: {
      default: null,
      type: Object,
    },
    checkbox: {
      default: false,
      type: Boolean,
    },
    limit: {
      default: 500,
      type: Number,
    },
    placeholder: {
      default: null,
      type: String,
    },
  },
  watch: {
    'value'() {
      this.selected = this.value;
    }
  },
  data() {
    return {
      selected: [],
      request: new GetRequest('get_projects'),
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    items() {
      try {
        let items = [
          {
            text: this.placeholder ? this.placeholder : this.__('All'),
            value: null,
          },
        ];

        if (!this.request.data) return;

        this.request.data.forEach((item) => {
          items.push({
            text: item.title,
            value: item.id,
          })
        })

        return items;

      } catch (e) {
        console.log(e);
      }
    },
    get() {
      this.request.addFilter('status', this.status)
      this.request.addFilter('ideas', this.ideas)
      this.request.setFields('id,title,ideas.idea_id,ideas.id, status,client_id.*,picture')
      this.request.setSort('date_created')
      this.request.setLimit(this.limit)
      this.request.setThen((e) => this.$emit('then', e))
      this.request.get()
    },
    select() {
      try {
        this.$emit('input', this.selected)
      } catch (e) {
        return false;
      }
    },
  }
}
</script>

<style>
.project-selector .v-messages {
  display: none !important;
}

.project-selector .v-label {
  position: unset !important;
  display: unset !important;
  width: 100%;
}
</style>
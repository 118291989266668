<template>
  <div v-if="_is_object(value)" :key="'ideas-editor-row-'+value.id">

    <!-- Information    -->
    <ideas-editor-item
        v-if="!show_edit_form"
        :data="value"
        @edit="edited"
        @remove="remove"
        @selected-for-cover="$emit('selected-for-cover')"
        v-bind="$attrs"
    />

    <!-- Form     -->
    <ideas-editor-form
        v-else
        :data="value"
        @update="update"
        v-bind="$attrs"
    />

    <v-divider/>
  </div>
</template>

<script>
import Space from "@/components/app/Space";
import AssetsImage from "@/components/assets/Image";
import IdeasEditorForm from "@/components/ideas/IdeasEditorForm";
import IdeasEditorItem from "@/components/ideas/IdeasEditorItem";

export default {
  name: "IdeasEditorRow",
  components: {IdeasEditorItem, IdeasEditorForm, AssetsImage, Space},
  props: {
    value: {
      default: null,
      type: Object,
    },
  },
  computed: {
    show_edit_form() {
      try {
        if (!this.value.id && this.edit === null) return true;
        return this.edit;
      } catch (e) {
      }
    },
  },
  data() {
    return {
      edit: null,
    }
  },
  methods: {
    update(e) {
      this.$emit('update', e);
      this.edit = false;
    },
    remove() {
      this.$emit('remove')
    },
    edited() {
      this.edit = true;
      this.$emit('edit')
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <!--  Manage Expertise List  -->
  <div class="mt-3">
    <template>
      <v-btn color="primary" elevation="0" large class="rounded-lg"
             @click="custom_dialog = !custom_dialog">
        {{ __('Add') }}
      </v-btn>
      <space/>
    </template>

    <!-- Loading -->
    <loading v-if="this.request.isLoading()"/>

    <!-- List -->
    <v-col v-else class="mt-4">

      <expertise-list :request="request"/>

      <!--   Not found data   -->
      <not-found-data v-if="!request.data" style="padding-top: 90px !important;"/>

      <!-- Load More -->
      <load-more  :request="request" pagination/>

    </v-col>
    <custom-expertise v-model="custom_dialog" @then="(e) => request.data.push(e.data)"/>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import CustomExpertise from "@/components/expertises/CustomExpertise";
import ExpertiseList from "@/components/expertises/ExpertiseList";
import Space from "@/components/app/Space";
import NotFoundData from "@/components/app/NotFoundData";
import Loading from "../app/Loading";
import LoadMore from "../app/LoadMore";

export default {
  name: "ExpertiseManage",
  components: {LoadMore, Loading, NotFoundData, Space, ExpertiseList, CustomExpertise},
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      request: new GetRequest('get_expertise'),
      custom_dialog: false,
    }
  },
  mounted() {
    this.request.setFields('*,job_type_id.*');
    this.request.addFilter('status', 'published');
    this.request.get();
  },
  methods: {},
}
</script>

<style scoped>

</style>
<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col cols="12">
        <profile-review-form :id="$route.params.id"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Profile from "@/components/profiles/Profile";
import ProfileReviewForm from "@/components/profiles/ProfileReviewForm";

export default {
  name: "ProfessionalProfileReview",
  components: {ProfileReviewForm, Profile},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

import app from "../tools/App";
import {Filters} from "./Filters";
import Vue from "vue";


export class Parameters {

    default_data = {
        'filter': null,
        'fields': '*',
        'limit': 12,
        'page': '1',
        'meta': 'filter_count',
        'sort': null
    };

    constructor(data = null) {
        this.items = {...this.default_data, ...data};
        this.items.limit = app.limit;
    }

    /**
     * Set param value by a key
     *
     * @param key
     * @param value
     */
    set(key, value) {
        Vue.set(this.items, key, value)
    }

    /**
     * get param value by a key
     *
     * @param key
     */
    get(key) {
        try {
            return this.items[key];
        } catch (e) {
            return null;
        }
    }

    /**
     * Get items for GET method params
     *
     * @returns {{params: (*|{filter: null, meta: string, limit: number, page: string, fields: string})}}
     */
    getItems() {
        return {params: this.items};
    }

    /**
     * Set filter param
     *
     * @param filters
     * @returns {boolean}
     */
    setFilter(filters) {
        if (!(filters instanceof Filters)) return false;
        this.set('filter', filters.getJson());
    }

    /**
     * Set page param
     *
     * @param number
     * @returns {boolean}
     */
    setPage(number) {
        if (number < 1) number = 1;
        this.set('page', number);
    }

    /**
     * Set fields param
     *
     * @returns {boolean}
     * @param data
     */
    setFields(data) {
        if (!data) data = '*';
        this.set('fields', data);
    }

    /**
     * Set limit
     *
     * @param limit
     */
    setLimit(limit) {
        this.set('limit', limit);
    }

    /**
     * Get limit
     *
     */
    getLimit() {
        return Number.parseInt(this.get('limit'));
    }

    /**
     * Set sort column
     *
     * @param column
     * @param order
     */
    setSort(column, order = '-') {
        if (!column) return;
        this.set('sort', order + column)
    }

    /**
     * Set group by
     *
     * @param column
     */
    setGroupBy(column) {
        if (!column) return;
        this.set('groupBy', column)
    }
}

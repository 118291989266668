import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.request.isLoading())?_c('loading'):_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.request_update.patch()}}},[(_vm.request_update.body && _vm.social_media)?_c(VRow,[_vm._l((_vm.social_media),function(item,key){return _c(VCol,{key:'item-key-'+key,attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__(item.name)))]),_c(VTextField,{attrs:{"placeholder":item.link,"dense":"","outlined":"","required":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('assets-image',{attrs:{"data":item.icon,"width":"26"}})]},proxy:true}],null,true),model:{value:(_vm.request_update.body.social_media[key].link),callback:function ($$v) {_vm.$set(_vm.request_update.body.social_media[key], "link", $$v)},expression:"request_update.body.social_media[key].link"}})],1)}),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.__('GoogleDrive')))]),_c(VTextField,{attrs:{"dense":"","outlined":"","required":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VIcon,{staticClass:"mr-1"},[_vm._v("mdi-google-drive")])]},proxy:true}],null,false,160748514),model:{value:(_vm.request_update.body.default_files_folder_id),callback:function ($$v) {_vm.$set(_vm.request_update.body, "default_files_folder_id", $$v)},expression:"request_update.body.default_files_folder_id"}})],1),_c(VCol,{attrs:{"md":"12"}},[_c(VBtn,{attrs:{"type":"submit","color":"primary","elevation":"0","disabled":_vm.request_update.isLoading()}},[(!_vm.request_update.is_loading)?_c('span',[_vm._v(_vm._s(_vm.__('Submit')))]):_c('span',[_vm._v("...")])])],1)],2):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <assets-image css_class="rounded-lg" :data="selected_image" max-height="490"/>
    <assets-images
        v-if="1 < count"
        :data="data"
        @select="e => selected_image = e"
        class="mt-2"
        width="150"
        max-width="150"
        min-height="100"
        max-height="100"
        horizontal
    />
  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import AssetsImages from "@/components/assets/Images";

export default {
  name: "AssetsImagesBox",
  components: {AssetsImages, AssetsImage},
  props: {
    data: {
      default: null,
      type: Array,
    },
  },
  computed:{
    count(){
      try {
        return this.data.length;
      } catch (e) {
        return null;
      }
    }
  },
  data() {
    return {
      selected_image: null,
    }
  },
  mounted() {
    this.setImage();
  },
  methods: {
    setImage(index = 0) {
      try {
        this.selected_image = this.data[index]
      } catch (e) {
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div v-if="_is_object(data)">

    <!--  Banner  -->
    <v-card class="overflow-hidden profile-banner rounded-xl" flat>
      <assets-image
          :data="editable ? request.body.banner : data.banner"
          width="100%"
          type="cover"
          height="284"
          default_color
      />
      <v-overlay v-if="editable" absolute opacity="0" class="justify-end align-end">
        <upload-image v-model="request.body.banner" accept="image/*">
          <v-btn class="secondary text-transform-unset rounded-xl ma-6" small>
            <span class="text--text">{{ __('EditCoverPhoto') }}</span>&nbsp;
            <v-icon color="primary" small>mdi-pencil-outline</v-icon>
          </v-btn>
        </upload-image>
      </v-overlay>
    </v-card>

    <v-row>

      <!-- Left -->
      <v-col cols="4" class="d-flex">
        <div>
          <div class="profile-jobs-assigned primary rounded-sm py-2 rounded-lg rounded-b-0 text-center">
            <div class="title background--text pb-1 px-1 font-size-14">{{ data.number_of_jobs_assigned ?? 0 }}</div>
            <label class="background--text">
              <small class="font-size-10">{{ __('HireOnRenotive') }}</small>
            </label>
            <div class="profile-jobs-assigned-footer background"></div>
          </div>
        </div>
      </v-col>

      <!-- Center -->
      <v-col cols="4">
        <div class="text-center" style="margin-top:-44px;">

          <upload-image v-if="editable" v-model="request.body.logo" class="mx-auto" accept="image/*">
            <div>
              <div>
                <v-avatar class="pointer profile-logo " size="88">
                  <assets-image :data="request.body.logo"/>
                </v-avatar>
              </div>
              <v-btn
                  style="z-index: 999; margin-top: -40px; margin-left: 15px; position: absolute"
                  class="border-light"
                  color="grey lighten-3"
                  fab
                  width="32"
                  height="32"
                  elevation="0"
                  small
              >
                <v-icon color="grey" size="20">mdi-camera-outline</v-icon>
              </v-btn>
            </div>
          </upload-image>
          <v-avatar v-else class="profile-logo" size="88">
            <assets-image :data="data.logo"/>
          </v-avatar>


          <div>
            <div class="font-head profile-title title mt-3 d-inline py-1" v-if="name">
              <span class="font-weight-bold font-size-18">{{ name }}</span>&nbsp;
              <v-icon color="blue" small>mdi-check-circle</v-icon>
            </div>
          </div>
          <div class="d-flex justify-center align-center py-3 font-size-14">
            <rate :data="data.total_score" size="18"/>&nbsp;&nbsp;|&nbsp;&nbsp;
            <span class="text--disabled font-mt-2">{{ data.reviews_count }} {{ __('Reviews') }}</span>
          </div>
        </div>
      </v-col>

      <!-- Right     -->
      <v-col cols="4" class="text-right">
        <div class="mt-6  ">
          <v-btn
              :to="{name:'ProfessionalProfileReview', props:{id:data.id}}"
              color="primary"
              class="full-width mb-3 text-transform-unset font-weight-unset rounded-lg font-size-12"
              height="30"
              max-width="160"
              elevation="0"
              v-if="!_owner(data.pro_id)"
          >
            <v-icon small>mdi-comment-edit-outline</v-icon>&nbsp;
            {{ __('WriteReview') }}
          </v-btn>

          <button-share
              color="primary"
              class="text-transform-unset font-size-12 font-weight-unset rounded-lg"
              height="30"
              max-width="160"
              elevation="0"
              width="100%"
          />
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import UserCard from "@/components/users/UserCard";
import Rate from "@/components/app/Rate";
import Upload from "@/components/assets/Upload";
import Breadcrumbs from "@/components/app/Breadcrumbs";
import {PostRequest} from "@/models/PostRequest";
import UploadImage from "@/components/assets/UploadImage";
import ButtonShare from "@/components/buttons/ButtonShare";

export default {
  name: "ProfileHeadDesktop",
  components: {ButtonShare, UploadImage, Breadcrumbs, Upload, Rate, UserCard, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    request: {
      default: null,
      type: PostRequest,
    },
  },
  computed: {
    name() {
      return this.editable && this._is_object(this.request.body) ? this.request.body.business_name : this.data.business_name
    },
    breadcrumbs_items() {
      return [
        {
          text: this.__('Professionals'),
          to: {name: 'ProfessionalsLanding'}
        },
        {
          text: this.__('Professionals'),
          disabled: true
        },
      ]
    }
  },
  methods: {
    uploaded(response, key = 'banner') {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        if (key === 'banner') this.request.body.banner = response.data.id;
        if (key === 'logo') this.request.body.logo = response.data.id;
      } catch (e) {
        console.log(e);
      }
    },
  }

}
</script>

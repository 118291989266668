<template>
  <div   v-if="_is_object(data)">

    <v-divider/>

    <show-more>
      <template #title>
        <div class="font-weight-bold primary--text">{{ __('BusinessInformation') }}</div>
      </template>

      <template #more>
        <v-row class="py-3 ">

          <v-col cols="12" sm="4">
            <div class="px-3 font-size-14 ">
              <div>{{ data.address }}</div>
              <div>{{ data.state }}</div>
              <div>{{ data.city }}</div>
              <div>{{ data.country }}</div>
            </div>
          </v-col>

          <v-col cols="12" sm="6">
            <div class="d-flex primary--text">
              <div class="px-3 font-size-14">
                <div>
                  <span class="text--disabled">{{ __('PhoneNumber') }}  : </span>
                  <a :href="'tel:'+data.phone_number">{{data.phone_number ?? '-'}}</a>
                </div>
                <div v-if="data.website">
                  <span class="text--disabled">{{ __('Website') }}  : </span>
                  <a :href="data.website" target="_blank">{{ data.website }}</a>
                </div>
                <div v-if="data.fax">
                  <span class="text--disabled" >{{ __('Fax') }}  : </span>
                  {{ data.fax ?? '-' }}
                </div>
              </div>
              <div class="px-3">
                <div class="font-size-14">
                  <span class="text--disabled">{{ __('TypicalJobCost') }}  : </span>
                  <profile-typical-cost :data="data"/>
                </div>
                <div class="font-size-14">
                  <span class="text--disabled">{{ __('LicenseNumber') }}  : </span>
                  {{ data.license_number }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
    </show-more>




  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import ProfileTypicalCost from "@/components/profiles/ProfileTypicalCost";
import ShowMore from "@/components/app/ShowMore";

export default {
  name: "ProfileInfoMobile",
  components: {ShowMore, ProfileTypicalCost, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },

}
</script>

<style scoped>

</style>
<template>
  <form v-if="data && request.body.first_name !== undefined" @submit.prevent="request.patch()">

    <v-row>
      <v-col cols="12" md="12">
        <div class="d-flex flex-wrap align-center mb-3">
          <avatar :data="request.body" size="128" color="secondary"/>
          <space/>
          <upload @then="e => uploaded(e)" accept="image/*">
            <v-btn text>
              <v-icon>mdi-pencil</v-icon>&nbsp;
              {{ __('EditProfileImage') }}
            </v-btn>
          </upload>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="font-weight-bold">{{ __('FirstName') }}</div>
        <v-text-field
            v-model="request.body.first_name"
            dense
            outlined
            required
        />
      </v-col>
      <v-col cols="12" sm="6">
        <div class="font-weight-bold">{{ __('LastName') }}</div>
        <v-text-field
            v-model="request.body.last_name"
            dense
            outlined
            required
        />
      </v-col>
      <v-col cols="12" sm="6">
        <div class="font-weight-bold">{{ __('Email') }}</div>
        <v-text-field
            v-model="request.body.email"
            dense
            outlined
            required
        />
      </v-col>
      <v-col cols="12" sm="6">
        <div class="font-weight-bold">{{ __('PhoneNumber') }}</div>
        <v-text-field
            v-model="request.body.phone_number"
            dense
            outlined
        />
      </v-col>
      <v-col cols="12" sm="6">
        <div class="font-weight-bold">{{ __('TelegramId') }}</div>
        <v-text-field
            v-model="request.body.telegram_id"
            dense
            outlined
        />
      </v-col>

      <v-col cols="12" sm="12" v-if="_access('pro')">
        <div class="font-weight-bold mb-2">{{ __('UploadPortfolio') }}</div>
        <div>
          <upload @then="e => uploaded(e, 'portfolio')" accept="application/pdf"></upload>
        </div>
        <v-btn class="my-3 mx-1" v-if="request.body.portfolio && _access('pro')" type="link" outlined :href="portfolio_link" target="_blank">
          <span>{{ __('DownloadPortfolio') }}</span>
        </v-btn>
      </v-col>


      <v-col cols="12" sm="12" v-if="_access('pro')">
        <expertise-selector :value="expertises" @input="e => setExpertises(e)" multiple/>
      </v-col>

      <v-col md="12">
        <v-btn type="submit" color="primary" elevation="0" :disabled="request.isLoading()">
          <span v-if="!request.is_loading">{{ __('Submit') }}</span>
          <span v-else>...</span>
        </v-btn>
      </v-col>
    </v-row>
  </form>
</template>

<script>

import {PostRequest} from "../../models/PostRequest";
import Avatar from "./Avatar";
import Upload from "../assets/Upload";
import Space from "../app/Space";
import urls from "../../tools/Urls";
import ExpertiseSelector from "../selectors/ExpertiseSelector";

export default {
  name: "AccountEdit",
  components: {ExpertiseSelector, Space, Upload, Avatar},
  props: {
    data: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      request: new PostRequest('update_profile'),
    }
  },
  mounted() {
    this.setBody();
    this.request.setThen((res) => this.$emit('updated', res))
  },
  computed: {
    portfolio_link() {
      try {
        return urls.api('assets_url', [this.request.body.portfolio]);
      } catch (e) {
        return '';
      }
    },
    expertises() {
      try {
        let result = [];
        Object.values(this.request.body.expertises).forEach((item) => {
          if (!item || typeof item !== "object" || !item.expertise_id) return;
          result.push(item.expertise_id);
        });
        return result;
      } catch (e) {
        return null;
      }
    }
  },

  methods: {
    setBody() {
      if (!this.data) return;
      let body = {
        first_name: this.data.first_name,
        last_name: this.data.last_name,
        email: this.data.email,
        phone_number: this.data.phone_number,
        telegram_id: this.data.telegram_id,
        avatar: this.data.avatar,
        portfolio: this.data.portfolio,
      };
      if (this.data.expertises) body.expertises = this.data.expertises;
      this.request.setBody(body);
    },
    uploaded(response, key = 'avatar') {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        if (key === 'avatar') this.request.body.avatar = response.data.id;
        if (key === 'portfolio') this.request.body.portfolio = response.data.id;
      } catch (e) {
        console.log(e);
      }
    },
    setExpertises(items) {
      try {
        let result = [];
        items.forEach(item => result.push({expertise_id: item}))
        this.request.body.expertises = result;
      } catch (e) {
        console.log(e);
      }
    },

  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <div>
      <v-list-item class="px-0">
        <v-list-item-avatar>
          <v-icon color="#0095DA">mdi-note-check</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ __('PhotoGuidelines') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="subtitle-2 font-size-12  text--disabled">{{ __('PhotoGuidelinesText') }}</div>
    </div>
    <v-divider class="mt-3"/>
    <div>
      <v-list-item class="px-0">
        <v-list-item-avatar>
          <v-icon color="green">mdi-camera</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ __('PhotoDo') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="subtitle-2 font-size-12 text--disabled" v-html="__('PhotoDoText')"></div>
    </div>
    <v-divider class="mt-3"/>
    <div>
      <v-list-item class="px-0">
        <v-list-item-avatar>
          <v-icon color="red">mdi-camera</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ __('PhotoDont') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <div class="subtitle-2 font-size-12 text--disabled" v-html="__('PhotoDontText')"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProfileProjectFormGuide",
  components: {},

  data() {
    return {}
  },

}
</script>

<style>

</style>
<template>
  <v-container>
    <page-title :text="__('AddPro')"/>
    <professional-add/>
  </v-container>
</template>

<script>
import PageTitle from "@/components/app/PageTitle";
import ProfessionalAdd from "@/components/professionals/ProfessionalAdd";

export default {
  name: "AddProfessionalPage",
  components: {ProfessionalAdd, PageTitle }
}
</script>

<style scoped>

</style>

<template>
  <v-card class="border-light rounded-lg overflow-hidden" outlined>
    <form @submit.prevent="request.post()" ref="upload-form">

      <v-progress-linear v-if="request.isLoading()" indeterminate></v-progress-linear>

      <div class="pt-6 pb-3 px-3 text-center" >
        <router-link class="mt-1 mx-auto" :to="{name:'Home'}">
          <logo/>
        </router-link>
        <h2 class="font-weight-medium mt-2">{{ __('SignIn') }}</h2>
        <div>{{ __('ToRenotive') }}</div>
      </div>

      <v-card-text>
        <v-text-field
            v-model="request.body.email"
            :label="__('Email')"
            type="email"
            id="username"
            outlined
            required
        />

        <v-text-field
            v-model="request.body.password"
            :label="__('Password')"
            type="password"
            outlined
            required
        />


      </v-card-text>

      <v-card-actions class="justify-end px-4 pb-6">
        <v-btn type="submit" :disabled="request.isLoading() || user_info_request.isLoading()" text color="primary"
               elevation="0">
          {{ __('SignIn') }}
        </v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import auth from "../../tools/Auth";
import {PostRequest} from "../../models/PostRequest";
import {GetRequest} from "../../models/GetRequest";
import Logo from "../app/Logo";
import AuthGoogle from "./AuthGoogle";

export default {
  name: "AuthForm",
  components: { Logo},
  props:{

  },
  data() {
    return {
      request: new PostRequest('login'),
      user_info_request: new GetRequest('get_current_user')
    }
  },
  mounted() {
    this.setBody();
    this.request.setMessage(this.__('Welcome'))
    this.request.setThen(this.then);
  },
  methods: {
    then(response) {
      try {
        if (!response || !response.data || !response.data.access_token) throw 'Login failed!';

        // save token
        auth.saveToken(response.data.access_token)
        auth.saveRefreshToken(response.data.refresh_token)
        auth.saveExpiration(response.data.expires)

        this.getUser()

      } catch (e) {
        console.log(e);
      }
    },
    setBody() {
      this.request.setBody({
        'email': null,
        'password': null,
      })
    },
    getUser() {
      try {
        this.user_info_request.setThen((response) => {

          if (!response || !response.data || !response.data.id) throw 'Can not get user info!';
          auth.setUserInfo(response.data)
          this.$store.commit('setUserInfo', response.data)
          this.$emit('authorised')
        })
        this.user_info_request.setFields('*, role.*');
        this.user_info_request.get();
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

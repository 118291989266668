import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-details"},[(_vm.request.is_loading)?[_c('loading')]:(_vm.item || _vm.is_create)?[_c('form',{ref:"profile-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c(VRow,[_c(VCol,{staticClass:"hidden-sm-and-down",attrs:{"cols":"12"}},[_c('profile-breadcrumbs',{attrs:{"data":_vm.item,"advanced_items":!_vm.is_create
                ? [{text:_vm.__('Edit'), disabled: true}]
                : [{text:_vm.__('Create'), disabled: true},]}})],1),_c(VCol,{staticClass:"hidden-sm-and-down",attrs:{"cols":"12","md":_vm._owner(_vm.item.pro_id) ? 2 : 3}},[_c('div',{staticClass:"position-sticky",staticStyle:{"top":"150px !important"}},[_c('profile-actions',{attrs:{"data":_vm.item,"request":_vm.form_request,"editable":""},on:{"submit":_vm.submit}})],1)]),_c(VCol,{attrs:{"cols":"12","md":_vm._owner(_vm.item.pro_id) ? 10 : 9}},[_c('profile-head',{attrs:{"data":_vm.item,"request":_vm.form_request,"editable":""}}),_c('profile-form-about',{attrs:{"request":_vm.form_request}}),_c('profile-from-info',{attrs:{"request":_vm.form_request}}),_c('profile-from-contact',{attrs:{"request":_vm.form_request}}),_c('profile-from-social-media',{attrs:{"request":_vm.form_request}}),(!_vm.is_create)?_c('profile-projects',{attrs:{"data":_vm.item,"title":_vm.__('EditProjects'),"open":"","editable":"","limit":"5","addable":"","show_more":""},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VDivider,{staticClass:"grey lighten-1 mx-3"})]},proxy:true}],null,false,824214398)}):_vm._e(),(!_vm.is_create)?_c('profile-stories',{attrs:{"data":_vm.item,"title":_vm.__('EditStories'),"open":"","editable":"","addable":"","show_more":""},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VDivider,{staticClass:"grey lighten-1 mx-3"})]},proxy:true}],null,false,824214398)}):_vm._e(),_c('profile-go-top'),_c('profile-actions',{staticClass:"hidden-md-and-up mt-3",attrs:{"data":_vm.item,"request":_vm.form_request,"editable":""},on:{"submit":_vm.submit}}),_c('space',{attrs:{"height":"80"}})],1)],1)],1)]:[_c('not-found-data',{staticClass:"py-16"})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-chip v-for="(item, key) in items" @click:close="remove(item)" close outlined class="mt-1 mr-2 mb-3">
      <div class="text-truncate" style="max-width: 100%">{{ item.text }}</div>
    </v-chip>
  </div>
</template>

<script>
export default {
  name: "ProfileFiltersChips",
  props: {
    value: {
      default: null,
      type: [Object],
    },
  },
  watch: {
    value: {
      handler() {
        this.set();
      },
      deep: true,
    },
  },
  data() {
    return {
      selected: null,
      items: [],
    }
  },
  mounted() {
    // this.set();
  },
  methods: {
    remove(item) {
       try {
         let result = {...this.value};
         if (!item.index)  this.$delete(result, item.filter);
         else this.$delete(result[item.filter], item.index);
         this.$emit('input', result);
       }catch (e){
         console.log(e);
       }
    },
    set() {
      try {
        if (!this.value) return;
        this.items = [];

        this.setCities();
        this.setCategories();
        this.setBudgets();
        this.setItem(this.value.languages, 'languages');
        this.setItem(this.value.rating, 'rating');
        this.setItem(this.value.styles, 'styles');
      } catch (e) {
        return null;
      }
    },
    setCities() {
      try {
        if (!this.value.cities) return;
        this.value.cities.forEach((item, index) => {
          if (!item) return;
          this.items.push({
            text: item,
            value: item,
            filter: 'cities',
            index: index,
          });
        })
      } catch (e) {
        console.log(e);
      }
    },
    setCategories() {
      try {
        if (!this.value.categories) return;
        this.value.categories.forEach((item, index) => {
          this.items.push({
            text: item.text,
            value: item.value,
            filter: 'categories',
            index: index,
          })
        })
      } catch (e) {
        console.log(e);
      }
    },
    setBudgets() {
      try {
        if (!this.value.budgets) return;
        this.value.budgets.forEach((item, index) => {
          this.items.push({
            text: item.text,
            value: item.value,
            filter: 'budgets',
            index: index,
          })
        })
      } catch (e) {
        console.log(e);
      }
    },
    setItem(item, filter_key) {
      try {
        if (!item.value) return;
        this.items.push({
          text: item.text,
          value: item.value,
          filter: filter_key,
        });
      } catch (e) {
        console.log(e);
      }

    },
  }
}
</script>

<style scoped>

</style>
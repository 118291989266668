import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"panel-app"}},[_c(VAppBar,{attrs:{"fixed":"","app":"","color":"background","elevation":"0","clipped-left":"","height":"80"}},[_c(VAppBarNavIcon,{staticClass:"hidden-lg-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c(VToolbarTitle,[_c('router-link',{attrs:{"to":"/"}},[_c('logo')],1)],1),_c(VSpacer),_c('account')],1),_c(VNavigationDrawer,{attrs:{"clipped":"","width":"290","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VList,[_c(VListItemGroup,{attrs:{"color":"primary"}},[_vm._l((_vm.menu()),function(item,key){return [((item.not_role) ? !_vm._access(item.not_role) : _vm._access(item.role))?_c(VListItem,{key:'header-drawer-'+key,attrs:{"to":item.to,"disabled":!item.to,"active-class":"secondary "}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)],1):_vm._e()]})],2)],1)],1),_c(VMain,{staticClass:"pb-7"},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal"},[_c('div',{on:{"click":function($event){return _vm.toggle(true)}}},[_vm._t("button")],2),_c(VDialog,_vm._b({staticStyle:{"z-index":"9999 !important"},attrs:{"fullscreen":_vm.fullscreen,"max-width":_vm.max_width,"transition":"dialog-bottom-transition"},on:{"input":function($event){return _vm.$emit('input', _vm.modal)}},model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},'v-dialog',_vm.$attrs,false),[_vm._t("content",function(){return [_c(VCard,{class:{'rounded-lg': !_vm.fullscreen}},[_vm._t("default",function(){return [(_vm.fullscreen)?_c(VContainer,[_c(VToolbar,{attrs:{"color":"background","flat":""}},[_c(VBtn,{staticClass:"grey lighten-4",attrs:{"icon":""},on:{"click":function($event){return _vm.toggle(false)}}},[_c(VIcon,[_vm._v("mdi-close")])],1),_c(VToolbarTitle,[_vm._t("title")],2),_c(VSpacer),_vm._t("actions")],2)],1):_vm._e(),_c(VDivider),_c(VContainer,{attrs:{"fluid":!_vm.fullscreen}},[(_vm.fullscreen)?_c('space',{attrs:{"height":"15"}}):_vm._e(),_vm._t("main"),(_vm.fullscreen)?_c('space',{attrs:{"height":"90"}}):_vm._e()],2)]})],2)]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col>
        <h2 >{{ getTitle() }}</h2>
      </v-col>
    </v-row>
    <ideas
        :room_id="$route.query.room_id"
        :item_attrs="{hide_options: true}"
        :filters_attrs="{with_budget: true}"
        status="published"
        masonry
    />
  </v-container>
</template>

<script>

import Ideas from "../../../components/ideas/Ideas";

export default {
  name: "PublicIdeasPage",
  components: {Ideas },
  mounted() {
  },
  methods: {
    getTitle() {
      try {
        let result = this.$route.query.page_title;
        if (!result) this.__('Ideas')
        return result;
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div v-if="data">
    <v-chip-group v-if="horizontal" id="tags-horizontal" :title="__('Expertises')">
      <expertise v-for="(item, key) in data" v-bind:key="key" :data="item" v-bind="$attrs"></expertise>
    </v-chip-group>

    <div v-else :title="__('Expertises')">
      <expertise class="ma-1 ml-0 mr-2" v-for="(item, key) in data" v-bind:key="key" :data="item" v-bind="$attrs"></expertise>
    </div>
  </div>
</template>

<script>
import Expertise from "./Expertise";

export default {
  name: "Expertises",
  components: {Expertise},
  props: {
    data: {
      default: null,
      type: Array,
    },
    horizontal: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {}
  },
}
</script>


<style>

#tags-horizontal .v-slide-group__next--disabled,
#tags-horizontal .v-slide-group__prev--disabled {
  display: none;
}
</style>
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getData())?_c(VListItem,_vm._b({},'v-list-item',_vm.$attrs,false),[_c('div',{staticClass:"pr-3"},[_c('avatar',{attrs:{"data":_vm.getData(),"color":"secondary","to":_vm.to}})],1),_c(VListItemContent,[_c(VListItemTitle,[_c('full-name',{attrs:{"data":_vm.getData()}})],1),(!_vm.hide_email)?_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(_vm.getData('email')))])]):_vm._e()],1),_c(VListItemAction,[_c('div',{staticClass:"d-flex"},[(_vm.call && _vm.getData('phone_number'))?_c(VBtn,{attrs:{"href":'tel:'+_vm.getData('phone_number'),"title":_vm.getData('phone_number'),"icon":""}},[_c(VIcon,[_vm._v("mdi-phone")])],1):_vm._e(),(_vm.chat && _vm.getData('telegram_id'))?_c(VBtn,{attrs:{"href":'https://t.me/'+_vm.getData('telegram_id'),"title":_vm.__('Telegram')+' : @'+_vm.getData('telegram_id'),"target":"_blank","icon":""}},[_c(VIcon,[_vm._v("mdi-chat")])],1):_vm._e(),(_vm.email && _vm.getData('email'))?_c(VBtn,{attrs:{"href":'mailto:'+_vm.getData('email'),"title":_vm.__('Email')+' :  '+_vm.getData('email'),"target":"_blank","icon":""}},[_c(VIcon,[_vm._v("mdi-email")])],1):_vm._e(),_vm._t("default"),(_vm.role && _vm.getRole())?_c(VChip,{attrs:{"small":""}},[_vm._v(_vm._s(_vm.getRole()))]):_vm._e()],2)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
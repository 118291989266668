<template>
  <div v-if="_is_object(data)">
    <breadcrumbs :data="items"/>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/app/Breadcrumbs";

export default {
  name: "ProfileBreadcrumbs",
  components: {Breadcrumbs},
  props: {
    data: {
      default: null,
      type: Object,
    },
    advanced_items: {
      default: null,
      type: Array,
    },
  },
  computed: {
    items() {
      let items = [
        {
          text: this.__('Professionals'),
          to: {name: 'ProfessionalsLanding'}
        },
      ];

      if (this.$route.params.id !== 'CREATE'){
        items.push(
            {
              text: this.data.business_name,
              to: {name:'ProfessionalProfile', params: {id: this.data.id}},
            }
        )
      }
      if (!this.advanced_items) return items;
      return [...items, ...this.advanced_items]
    }
  }

}
</script>

<style scoped>

</style>
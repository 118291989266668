<template>
  <div>
    <!-- Lead List  -->
    <v-row v-if="!hide_status_selector">
      <v-col>
        <lead-jobs-status :request="request"/>
      </v-col>
    </v-row>


    <v-row v-if="!hide_actions">
      <v-col class="d-flex justify-space-between">
        <div class="d-flex width-full">

        </div>

        <space/>

        <div class="d-flex">

          <!-- Limit -->
          <items-limit :request="request"/>
          <space/>

          <!--   Filters button     -->
          <v-btn v-if="!hide_filters" class="rounded-lg border-light" outlined @click="show_filters = !show_filters"
                 large>
            <v-icon left>mdi-filter-variant</v-icon>
            {{ __("Filters") }}
          </v-btn>
        </div>

      </v-col>
    </v-row>

    <!-- Filters -->
    <v-row v-show="show_filters" v-if="!hide_filters">
      <v-col>
        <lead-jobs-filters :request="request"  @reset="get"/>
      </v-col>
    </v-row>

    <!-- Loading -->
    <loading v-if="request.isLoading()" />

    <!-- List -->
    <v-row v-else>
      <v-col cols="12"  v-for="(item, key) in request.data" v-bind:key="key">
        <lead-job
            :data="item"
            :hide_buttons="hide_buttons"
            :disabled="isLost"
            @reload="request.reload()"
        />
      </v-col>

      <v-col sm="12">
        <!--   Not found data   -->
        <not-found-data v-if="!request.data" style="padding-top: 90px !important;"/>

        <!-- Load More -->
        <load-more pagination v-if="!hide_load_more" :request="request"/>
      </v-col>

    </v-row>

  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";

import LoadMore from "../app/LoadMore";
import Space from "../app/Space";
import NotFoundData from "../app/NotFoundData";
import ItemsLimit from "../items/ItemsLimit";
import LeadJobsStatus from "./LeadJobsStatus";
import LeadJob from "./LeadJob";
import LeadJobsFilters from "./LeadJobsFilters";
import Loading from "../app/Loading";

export default {
  name: "LeadJobs",
  components: {
    Loading,
    LeadJobsFilters,
    LeadJob,
    LeadJobsStatus,
    ItemsLimit, NotFoundData,  Space, LoadMore,
  },
  props: {
    api: {
      default: 'items_collection',
      type: String,
    },
    api_params: {
      default: null,
      type: Array,
    },
    pro_id: {
      default: null,
      type: String,
    },

    loader_count: {
      default: 12,
      type: [String, Number],
    },
    status: {
      default: null,
      type: String,
    },
    client_id: {
      default: null,
      type: String,
    },
    add_is_visible: {
      default: false,
      type: Boolean,
    },
    hide_actions: {
      default: false,
      type: Boolean,
    },
    hide_buttons: {
      default: false,
      type: Boolean,
    },
    hide_status_selector: {
      default: false,
      type: Boolean,
    },
    hide_filters: {
      default: false,
      type: Boolean,
    },
    hide_load_more: {
      default: false,
      type: Boolean,
    },
    hide_subscribe: {
      default: false,
      type: Boolean,
    },
    filter_status: {
      default: true,
      type: Boolean,
    },
    without: {
      default: null,
      type: Array,
    },
    limit: {
      default: null,
      type: [String, Number],
    },
    sort: {
      default: 'date_created',
      type: String,
    },
    fields: {
      default: '*, *.*, job_id.job_type_id.*, job_id.project_id.*, job_id.project_id.user_created.*,job_id.project_id.client_id.*, job_id.tags.*, job_id.project_id.city_id.*, job_id.project_id.state_id.* ',
      type: String,
    }
  },
  data() {
    return {
      request: new GetRequest(),
      show_filters: false,
      selected_status: null,
    }
  },
  computed:{
    isLost(){
      try{
        return JSON.stringify(this.request.allFilters()) === JSON.stringify(this.lostFilters());
      }catch (e){
        return false;
      }
    }
  },
  mounted() {
    this.get();
  },
  watch: {
    "$route.query.page"() {
      this.get();
    }
  },
  methods: {
    defaultFilters() {
      return {
        "_and": [
          {
            "job_id": {
              "pro_id": {
                "_null": "true"
              }
            }
          },
          {
            "_or": [
              {
                "pro_status": {
                  "_eq": "pending"
                }
              },
              {
                "pro_status": {
                  "_eq": "accepted"
                }
              }
            ]
          }
        ]
      };
    },
    lostFilters() {
      return {
        "_or": [
          {
            "_and": [
              {
                "job_id": {
                  "pro_id": {
                    "_neq": "$CURRENT_USER"
                  }
                }
              },
              {
                "job_id": {
                  "pro_id": {
                    "_nnull": "true"
                  }
                }
              }
            ]
          },
          {
            "pro_status": {
              "_eq": "rejected"
            }
          }
        ]
      };
    },

    get() {
      this.request.reset();
      this.request.setApi(this.api)
      this.request.setApiParams(this.api_params ? this.api_params : ['job_pro_suggest'])

      this.setFilters();
      this.setQueries();
      this.setPage();


      this.request.setLimit(this.limit)
      this.request.setNotIn('id', this.without)
      this.request.setSort(this.sort)
      this.request.setFields(this.fields)
      this.request.get();
    },
    setFilters() {
      this.request.clearFilters();
      this.request.pushFilter(this.defaultFilters());
      if (this.status) this.request.addFilter('status', this.status);
    },
    setPage() {
      try {
        this.request.setPage(this.$route.query.page)
      } catch (e) {
        this.request.setPage(1)
      }
    },
    setQueries() {
      try {
        let queries = this.$route.query;
        if (!queries) return;

        if (queries.status) this.request.addFilter('status', queries.status)
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div v-if="data" class="px-0">
    <v-card class="grey lighten-4 rounded-lg" flat>
      <v-card-title class="title">
        {{ __('Packages') }}
      </v-card-title>

      <div v-if="Array.isArray(data.packages) && data.packages.length !== 0">
        <package v-for='(item, key) in data.packages' :key="'project-package-item-'+key" :data="item.package_id"/>
      </div>

      <not-found-data v-else style="padding-top: 130px !important; padding-bottom: 130px !important"/>
    </v-card>
  </div>
</template>

<script>

import NotFoundData from "../app/NotFoundData";
import Package from "../packages/Package";

export default {
  name: "ProjectPackages",
  components: {Package, NotFoundData,},
  props: {
    data: {
      default: null,
      type: Object,
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-hover v-slot="{hover}">
      <div>
        <!--  Desktop    -->
        <v-card
            v-if="_is_object(data)"
            class="hidden-sm-and-down grey  lighten-3 rounded-lg   overflow-hidden"
            flat
        >
          <v-row class="d-flex flex-row-reverse pa-0">
            <!--  Right   -->
            <v-col cols="12" sm="4" class="py-0 pl-0">
              <router-link :to="{name:'ProfessionalProfile', params: {id: data.id}}">
                <assets-image :data="data.banner" cover style="max-height: 230px" height="100%" width="100%">
                  <v-overlay absolute opacity="0" class="align-end justify-end mb-3">
                    <div class="pa-3"><small>{{ __('ReadMore') }}
                      <v-icon>mdi-chevron-right</v-icon>
                    </small></div>
                  </v-overlay>
                </assets-image>
              </router-link>
            </v-col>

            <!--  Left -->
            <v-col cols="12" sm="8" class="py-0">
              <div class="pa-4 py-6">
                <!-- Title -->
                <div class="d-flex justify-space-between align-center">
                  <router-link :to="{name:'ProfessionalProfile', params: {id: data.id}}">
                    <v-list-item class="px-0 full-width">
                      <v-list-item-avatar> 
                          <assets-image :data="data.logo"/>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="font-head font-weight-bold text-truncate font-size-18  ">
                          {{ data.business_name }}&nbsp;&nbsp;<v-icon small color="verify">mdi-checkbox-marked-circle
                        </v-icon>
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex mt-1 align-center" style="line-height: 15px;">
                          <rate :data="data.total_score" size="18"/>
                          <span class="color-primary-dark">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                          <span class="font-size-14" style="color: #949599 !important;">{{
                              data.reviews_count
                            }} {{ __('Reviews') }}</span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </router-link>
                  <div>
                    <v-btn disabled outlined color="primary" small>
                      <v-icon>mdi-email</v-icon>&nbsp;&nbsp;<span class="font-size-14 font-mt-2">{{
                        __('SendMessage')
                      }}</span>
                    </v-btn>
                    <v-btn class="hidden-md-and-up" disabled icon color="primary" :title="__('SendMessage')" small>
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </div>
                </div>

                <!--  Info -->
                <div class="d-flex justify-space-between mt-8">
                  <!-- Address -->
                  <div class="d-flex font-size-14">
                    <div class="pr-3 pt-3">
                      <v-icon size="15" color="primary">mdi-map-marker</v-icon>
                    </div>
                    <div style="line-height: 13pt">

                      <small class="font-size-14">
                        {{ data.country }},&nbsp;{{ data.state }},&nbsp;{{ data.city }}
                      </small>
                      <br/>
                      <small class="font-size-14">
                        {{ data.address }}
                      </small>
                    </div>
                  </div>

                  <!--  Contact          -->
                  <div class="d-flex ">
                    <div>
                      <div class="d-flex justify-end">
                        <v-icon small :color="!_user ? 'grey' :'verify'">mdi-phone</v-icon>&nbsp;&nbsp;
                        <a class="text--text" v-if="_user" :href="'tel:'+data.phone_number">
                          <small class="font-size-14"><span v-html="__('PhoneNumberHtml')"></span>&nbsp;:&nbsp;{{
                              data.phone_number
                            }}</small>
                        </a>
                      </div>
                      <div class="d-flex justify-end">
                        <v-icon small :color="!_user ? 'grey' :'verify'">mdi-email</v-icon>&nbsp;&nbsp;
                        <a class="text--text" v-if="_user" :href="'mailto:'+data.email">
                          <small class="font-size-14">{{ __('Email') }}&nbsp;:&nbsp;{{ data.email }}</small>
                        </a>
                      </div>
                    </div>
                    <div v-if="!_user" style="max-width: 150px;">
                      <router-link :to="{name:'Login'}" class="text--disabled">
                        <small class="font-size-14">{{ __('LoginToSee') }}</small>
                      </router-link>
                    </div>
                  </div>
                </div>

                <!-- Footer          -->
                <div class="d-flex justify-space-between">
                  <div>&nbsp;
                    <a class="text--text" target="_blank" :href="_link(data.website)">
                      <small class="font-size-14">{{ data.website }}</small>
                    </a>
                  </div>
                  <div class="d-flex align-center">
                    <small class="font-size-14">{{ __('Socials') }}</small>&nbsp;:
                    <socials :data="data.social_media"/>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>


        <!--  Mobile    -->
        <v-card
            class="hidden-md-and-up pointer px-2 pb-1 mb-4"
            v-if="_is_object(data)"
            flat
        >
          <v-row class="flex-row-reverse">

            <v-col cols="12" sm="6" class="pr-sm-4">
              <router-link :to="{name:'ProfessionalProfile', params: {id: data.id}}">
                <assets-image
                    :data="data.banner"
                    css_class="rounded-9"
                    cover
                    style="max-height: 290px"
                    height="100%"
                    width="100%"
                >
                  <v-overlay
                      absolute
                      opacity="0"
                      class="align-end justify-end hidden-sm-only"
                      style="    box-shadow: inset 0px -60px 70px #1f1f1fba !important;"
                  >
                    <div class="pa-3"><small>{{ __('ReadMore') }}
                      <v-icon>mdi-chevron-right</v-icon>
                    </small></div>
                  </v-overlay>
                </assets-image>
              </router-link>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0">
              <div class="px-0 px-sm-2">
                <v-list-item :to="{name:'ProfessionalProfile', params: {id: data.id}}" class="px-0 my-2">
                  <v-list-item-avatar size="50">
                    <v-avatar>
                      <assets-image :data="data.logo"/>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content class="pl-2">
                    <v-list-item-title class="font-head font-weight-bold text-truncate font-size-18  ">
                      {{ data.business_name }}&nbsp;&nbsp;<v-icon small color="verify">mdi-checkbox-marked-circle
                    </v-icon>
                    </v-list-item-title>
                    <v-list-item-subtitle class="d-flex mt-1 align-center" style="line-height: 15px;">
                      <rate :data="data.total_score" size="18"/>
                      <span class="color-primary-dark">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                      <span class="font-size-14" style="color: #949599 !important;">{{
                          data.reviews_count
                        }} {{ __('Reviews') }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <div class="text-center grey--text lighten-2 font-size-12 text-truncate">
                  <v-icon size="15" color="grey" style="margin-top:-2px;">mdi-map-marker</v-icon>
                  <span>{{ data.country }},&nbsp;{{ data.state }},&nbsp;{{ data.city }}</span>
                  <span>
              {{ data.address }}
            </span>
                </div>

                <div class="text-center py-2 pb-6 text-truncate">
                  <a class="primary--text" target="_blank" :href="_link(data.website)">
                    <small class="font-size-14">{{ data.website }}</small>
                  </a>
                </div>

                <profile-item-actions :data="data"/>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-hover>
  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import Rate from "@/components/app/Rate";
import Socials from "@/components/social/Socials";
import Space from "@/components/app/Space";
import ProfileItemActions from "@/components/profiles/ProfileItemActions";

export default {
  name: "ProfileItem",
  components: {ProfileItemActions, Space, Socials, Rate, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    card: {
      default: null,
      type: Boolean,
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <v-row>
    <v-col cols="12" md="5">
      <div class="text-h4  my-6">{{ __('SelectStylesTitle') }}</div>
      <div class="text--secondary">{{ __('SelectStylesText') }}</div>

    </v-col>

    <v-col cols="12" md="7">
      <room-styles-selector v-model="questions.selected_styles" :data="questions.getStyles()"/>
    </v-col>

    <v-col sm="12">
      <questions-next-button
          :questions="questions"
          :disabled="!questions.hasSelectedStyles()"
          fixed
      />
    </v-col>
  </v-row>
</template>

<script>

import {Questions} from "../../models/Questions";
import RoomStylesSelector from "../selectors/RoomStylesSelector";
import QuestionsNextButton from "./QuestionsNextButton";

export default {
  name: "SurveyStyles",
  components: {QuestionsNextButton, RoomStylesSelector},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  methods:{

  }
}
</script>

<style scoped>

</style>
<template>
  <v-row class="py-6">
    <!--  Address Form for Start Project  -->
    <v-col cols="12" md="6">
      <div class="text-h4  my-6">{{ __('ProjectAddressTitle') }}</div>
      <div class="text--secondary">{{ __('ProjectAddressText') }}</div>

    </v-col>
    <v-col cols="12" sm="7" md="6">
      <v-row>

        <v-col cols="12" sm="12">
          <address-selector v-model="questions.address" @input-object="setPlace" required/>
        </v-col>

        <v-col cols="12" sm="6">

          <city-selector
              v-model.number="questions.city"
              :state="questions.state"
              :place="questions.place"
              @input-object="e => questions.place.city = e"
              required
          />
        </v-col>

        <v-col cols="12" sm="6">
          <state-selector v-model="questions.state" :place="questions.place" @change="e => changeState()" required/>
        </v-col>

        <v-col cols="12" sm="4">
          <div class="font-weight-bold">{{ __('ZipCode') }}</div>
          <v-text-field
              v-model="questions.zip_code"
              outlined
              dense
          />
        </v-col>
      </v-row>
    </v-col>

    <!--   Start Time  -->
    <v-col cols="12" class="py-12">
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-h4  my-6">{{ __('StartProjectTime') }}</div>
          <div class="text--secondary">{{ __('StartProjectTimeText') }}</div>

        </v-col>

        <v-col cols="12" sm="7" md="6">
          <div class="font-weight-bold">{{ __('StartTime') }}</div>
          <v-text-field
              v-model="questions.desire_start_time"
              outlined
              dense
              type="date"
          />
        </v-col>
      </v-row>
    </v-col>

    <!--  Project Owner   -->
    <v-col v-if="_access('admin')" cols="12" class="pt-12" style="min-height: 250px">
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-h4  my-6">{{ __('ProjectOwner') }}</div>
          <div class="text--secondary">{{ __('SelectClientText') }}</div>
        </v-col>

        <v-col cols="12" sm="7" md="6" class="pt-md-6">
          <v-chip-group v-model="selected_type">
            <v-chip active-class="primary">{{ __('Select') }}</v-chip>
            <v-chip active-class="primary" @click="questions.client.id = null">{{ __('CreateNew') }}</v-chip>
          </v-chip-group>

          <div class="pt-3">
            <!--  Select        -->
            <div v-if="selected_type === 0">
              <user-selector
                  v-model="questions.client.id" :first_label="__('SelectOne')" status="active"
                  :role="_role('client')"
              />
            </div>

            <!--  Create New        -->
            <div v-if="selected_type === 1">
              <form @submit.prevent="add()">
                <v-row>
                  <v-col cols="12" md="6">
                    <div class="font-weight-bold">{{ __('FirstName') }} *</div>
                    <v-text-field
                        v-model="questions.client.first_name"
                        dense
                        outlined
                        required
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="font-weight-bold">{{ __('LastName') }} *</div>
                    <v-text-field
                        v-model="questions.client.last_name"
                        dense
                        outlined
                        required
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <div class="font-weight-bold">{{ __('Email') }} *</div>
                    <v-text-field
                        v-model="questions.client.email"
                        dense
                        outlined
                        required
                    />
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-btn type="submit" :disabled="questions.add_client_request.isLoading()" outlined>
                      <span v-if="questions.add_client_request.isLoading()">...</span>
                      <span v-else>{{ __('Add') }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </form>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>


    <!-- Phone Number -->
    <v-col v-if="!_user || !_user.phone_number" cols="12" class="pt-12">
      <v-row>
        <v-col cols="12" md="6">
          <div class="text-h4  my-6">{{ __('PhoneNumber') }}</div>
          <div class="text--secondary">{{ __('PhoneNumberText') }}</div>
        </v-col>

        <v-col cols="12" sm="7" md="6" class="pt-md-6">
          <div class="font-weight-bold">{{ __('PhoneNumber') }}</div>
          <v-text-field
              v-model="questions.phone_number"
              outlined
              dense
          />
        </v-col>
      </v-row>
    </v-col>

    <!-- Next Button   -->
    <v-col sm="12">
      <questions-next-button
          :questions="questions"
          :disabled="!questions.checkAddress() || (_access('admin') && !questions.checkClient())"
          fixed
      />
    </v-col>
  </v-row>

</template>

<script>
import {Questions} from "../../models/Questions";
import StateSelector from "../selectors/StateSelector";
import CitySelector from "../selectors/CitySelector";
import QuestionsNextButton from "./QuestionsNextButton";
import UserSelector from "../selectors/UserSelector";
import AddressSelector from "../selectors/AddressSelector";

export default {
  name: "QuestionsAddress",
  components: {AddressSelector, UserSelector, QuestionsNextButton, CitySelector, StateSelector},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  data() {
    return {
      selected_type: 0,
    }
  },
  created() {
    this.set();
  },
  methods: {
    set() {
      try {
        if (this._user) this.questions.phone_number = this._user.phone_number
      } catch (e) {
        console.log(e);
      }
    },
    add() {
      this.questions.addClient();
    },
    changeState() {
      this.questions.place = {city: null}
      this.questions.city = null;
      this.questions.address = null;
    },
    setPlace(place) {
      try {
        this.questions.place = place
        this.questions.zip_code = place.zip_code
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-btn v-if="data"  class="elevation-0 rounded-lg text--text secondary mr-2 mb-2" :href="data.link" target="_blank" v-bind="$attrs" :disabled="disabled ? true : !data.link">
    {{ data.text }}
  </v-btn>
</template>

<script>

export default {
  name: "LinkItem",
  components: {},
  props: {
    data: {
      default: null,
      type: Object,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<style scoped>

</style>

<template>
  <v-container>
    <page-title :text="__('CreateNewIdea')"/>
    <ideas-write/>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import IdeasWrite from "../../../components/ideas/IdeasWrite";

export default {
  name: "AddIdeaPage",
  components: {IdeasWrite, PageTitle }
}
</script>

<style scoped>

</style>
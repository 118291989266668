<template>
  <add-package
      :unique_key="unique_key"
      :value="value"
      :project_id="project_id"
      :title="__('Unlock')"
      :button_title="__('Unlock')"
      :in="['subscription']"
      @input="e => $emit('input', e)"
      @then="e => $emit('then', e)"
      hide_free
      hide_ultimate
      v-bind="$attrs"
      modal
  >
    <slot></slot>
  </add-package>
</template>

<script>
import AddPackage from "./AddPackage";


export default {
  name: "Unlock",
  components: {AddPackage},
  props: {
    project_id: {
      type: [String, Number],
      require: true
    },
    unique_key: {
      type: [String, Number],
      required: true
    },
    value: {
      default: null,
      type: Boolean,
    },
  },
}
</script>
<template>
  <div class="full-width">
    <div :class="title_classes">{{ __('State') }}</div>

    <v-autocomplete
        :items="items()"
        :value="value"
        :search-input.sync="input"
        @input="e => change(e)"
        class="custom-selector-border font-size-14 rounded-lg"
        item-text="text"
        item-value="value"
        outlined
        :placeholder="__('SelectOne')"
        v-bind="$attrs"
        dense
    >

    </v-autocomplete>
  </div>
</template>

<script>
import {GetRequest} from "../../models/GetRequest";

export default {
  name: "StateSelector",
  components: {},
  props: {
    value: {
      default: null,
      type: String,
    },
    title_classes: {
      default: 'font-weight-bold',
      type: String,
    },
    place: {
      default: null,
      type: [Object],
    },
  },
  watch: {
    input() {
      this.get();
    },
    place() {
      this.set();
    }
  },
  data() {
    return {
      request: new GetRequest('get_items', ['city']),
      custom_items: [],
      input: null,
    }
  },
  mounted() {
    this.set()
  },
  methods: {
    set() {
      if (!this.place || typeof this.place !== "object" || !this.place.city) return this.get();
      this.setState();
    },
    setState() {
      try {
        let state = null;
        if (!this.place.city || !this.place.city.id) return this.$emit('input', null);
        if (this.place.state) state = this._is_object(this.place.state) ? this.place.state.name : this.place.state;
        else state = this.place.city.state;

        this.$emit('input', state);
        this.custom_items.push({
          'text': state,
          'value': state,
        })
      } catch (e) {
        console.log(e)
      }
    },
    get() {
      if (this.data) {
        this.request.data = this.data;
        return;
      }
      this.request.is_loading = false;
      this.request.setGroupBy('state');
      this.request.addFilter('state', this.input, '_contains');
      this.request.setLimit(100);
      this.request.get();
    },
    items() {
      try {
        let items = [];
        if (this.request.data) {
          this.request.data.forEach((item) => {
            items.push({
              text: item.state,
              value: item.state,
            })
          })
        }

        items = items.concat(this.custom_items);

        return items;

      } catch (e) {
        console.log(e);
      }
    },
    change(e) {
      this.$emit('input', e)
      this.$emit('change', e)
    }
  }
}
</script>

<style scoped>

</style>
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VListItem,{staticClass:"pointer",on:{"click":_vm.click}},[_c(VListItemAvatar,{staticClass:"rounded-lg",attrs:{"size":"64","tile":""}},[_c('assets-image',{staticClass:"rounded-lg",attrs:{"data":_vm.picture,"height":_vm.height}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"pb-3 title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(_vm.__('By'))+" "),(_vm.data.user_created)?_c('full-name',{attrs:{"data":_vm.data.user_created}}):_c('span',[_vm._v(_vm._s(_vm.__('Renotive')))])],1)],1)],1),(_vm.in_modal)?_c('modal',{attrs:{"fullscreen":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.__('Blog'))+" ")]},proxy:true},{key:"main",fn:function(){return [_c('blog-view',{attrs:{"id":_vm.data.id}})]},proxy:true}],null,false,661421257),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
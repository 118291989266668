import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && _vm.data.id)?_c(VCard,{staticClass:"rounded-lg mb-3",attrs:{"title":_vm.__('Note'),"outlined":""}},[_c(VCardTitle,{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.__('Note'))+" ")]),_c(VCardText,[_c(VTextarea,{staticClass:"rounded-lg",attrs:{"auto-grow":"","outlined":"","rows":"1","row-height":"15","disabled":_vm.expired},model:{value:(_vm.request.body.text),callback:function ($$v) {_vm.$set(_vm.request.body, "text", $$v)},expression:"request.body.text"}})],1),_c(VCardActions,{staticClass:"px-4 pb-4"},[_c(VSpacer),_c(VBtn,{staticClass:"elevation-0 text--text",attrs:{"color":"secondary","disabled":_vm.expired},on:{"click":function($event){return _vm.request.post()}}},[_vm._v(_vm._s(_vm.__('Save')))])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
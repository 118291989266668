<template>
  <div v-if="data && Array.isArray(data)">

    <!-- Desktop -->
    <div class="hidden-sm-and-down  mt-5  ">
      <div class="primary--text text-center">
        <a class=" pointer pa-3" @click="show_more = !show_more" style="font-size: 15px">
          {{ __('SeeAllProfessionals') }}
          <v-icon color="primary" v-if="show_more">mdi-chevron-up</v-icon>
          <v-icon color="primary" v-else>mdi-chevron-down</v-icon>
        </a>
      </div>


      <v-scroll-y-transition>
        <v-row v-if="show_more" class="pt-5 px-5 pb-1">
          <template v-for="(group_item, index) in data">
            <v-col cols="12" sm="4" v-if="group_item" :key="'exp-section-list-'+group_item.id">
              <div class="primary--text pb-3 font-size-16">{{ group_item.title }}</div>

              <!-- Items -->
              <div v-if="group_item && Array.isArray(group_item.expertises)" v-for="item in sort(group_item.expertises)">
                <div
                    v-if="item && item.title"
                    @click="$emit('select', {item: item, group: group_item})"
                    class="expertises-section-item py-1 pointer"
                >
                  {{ item.title }}
                </div>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-scroll-y-transition>
    </div>

    <!--  Mobile  -->
    <v-row class="hidden-md-and-up mb-2">
      <v-col cols="12"  class="px-0 pb-0">
        <v-divider/>
        <v-list-item class="grey lighten-3 justify-center" @click="clicked = !clicked">
          <v-list-item-content style="max-width: 360px;">
            <v-list-item-title class="pt-1 primary--text font-size-18 pl-2 font-weight-bold ">
              {{ __('SeeAllProfessionals') }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon color="primary" v-if="clicked">mdi-chevron-up</v-icon>
              <v-icon color="primary" v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-col>

      <v-col cols="12" class="pa-0">
        <div v-if="clicked" class="expertises-section-list light-scroll-y grey lighten-3 px-sm-2 px-md-1 px-1 pb-6 mb-6 ">
          <v-row  class="pt-5 px-5 pb-1">
            <template v-for="(group_item, index) in data">
              <v-col cols="12" sm="4" v-if="group_item" :key="'exp-section-list-mobile-'+group_item.id">
                <div class="primary--text pb-3 font-size-16">{{ group_item.title }}</div>

                <!-- Items -->
                <div v-if="group_item && Array.isArray(group_item.expertises)" v-for="item in sort(group_item.expertises)">
                  <div
                      v-if="item && item.title"
                      @click="$emit('select', {item: item, group: group_item})"
                      class="expertises-section-item py-2 pointer"
                  >
                    {{ item.title }}
                  </div>
                </div>
              </v-col>
            </template>
          </v-row>
        </div>
      </v-col>
    </v-row>


  </div>
</template>

<script>
export default {
  name: "ExpertisesSectionLists",
  props: {
    data: {
      default: null,
      type: Array,
    },
  },
  data() {
    return {
      show_more: false,
      clicked: false,
    }
  },
  methods: {
    sort(items) {
      try {
        return items.slice().sort((a, b) => (a.title > b.title) ? 1 : -1)
      } catch (e) {
        return [];
      }
    }
  }
}
</script>

<style>
.expertises-section-item:hover {
  font-weight: bold;
}
</style>
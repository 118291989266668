import { render, staticRenderFns } from "./ProfessionalBlog.vue?vue&type=template&id=2fa9cc4c&scoped=true&"
import script from "./ProfessionalBlog.vue?vue&type=script&lang=js&"
export * from "./ProfessionalBlog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fa9cc4c",
  null
  
)

export default component.exports
<template>
  <img :src="image" :alt="app_name" :width="width" v-bind="$attrs"/>
</template>

<script>
import app from "../../tools/App";

export default {
  name: "Logo",
  props:{
    width: {
      default: '135',
      type: [String, Number,]
    },
    white: {
      default: false,
      type: Boolean
    },
    small: {
      default: false,
      type: Boolean
    }
  },
  computed:{
    image(){
      if (this.small) return require('@/assets/img/logo-small.png');
      if (this.white) return require('@/assets/img/logo-text-align-left-white.png');
      return require ('@/assets/img/logo-text-align-left.png')
    }
  },
  data() {
    return {
      app_name: app.app_name,
    }
  }
}
</script>

<style scoped>

</style>
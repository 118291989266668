<template>
  <v-card v-if="isVisible()" class="grey lighten-4 rounded-lg" flat>
    <v-card-title class="align-start">
      <div>
        <div>
          {{ item.title ? item.title : item.job_type_id.title.toUpperCase() }}
        </div>
        <div class="subtitle-2 text--disabled">
          <div>
            {{ __('ContractAmount') }}:
            <price :data="item.contract_amount" :default_price="__('Free')"/>
          </div>
          <div>{{ __('DesiredStartDate') }} : {{ item.desired_start_date || __('None') }}</div>
        </div>
      </div>
      <v-spacer/>
      <div class="mt-4" style="max-width: 190px">
        <job-status-selector
            v-if="!_access('pro')"
            @input="(e) => change(e, 'status')"
            :value="item.status"
            :items="status_items"
            :title="__('Status')"
            :disabled="disabled"
        />
        <small v-else class="heading text--disabled">{{__('Status')}} : {{__(item.status)}}</small>
      </div>

      <job-menu
          v-if="_access('client') || _access('admin')"
          class="mt-5 ml-2"
          :data="data"
          @updated="e => updated_data = e"
          @archived="() => archived = true"
          :disabled="disabled"
      />
    </v-card-title>

    <!--  Job Pros    -->
    <job-pros v-if="!_access('pro')" :data="data" @reload="$emit('reload')" :disabled="disabled"/>

    <v-row>
      <!--  Description -->
      <v-col cols="12" md="12" v-if="item.description">
        <v-divider class="grey lighten-3 mb-3"/>
        <div class="px-3">
          <div class="font-weight-bold">{{ __('Description') }}</div>
          <div class="text--disabled subtitle-1">
            {{ item.description || __('HasNotAnyDescription') }}
          </div>
        </div>
      </v-col>

      <!--  Needs for Next    -->
      <v-col cols="12" md="12" v-if="item.pro_next_needs && !_access('pro')">
        <v-divider class="grey lighten-3 mb-3"/>
        <div class="px-3">
          <div class="font-weight-bold">{{ __('ProNextNeeds') }}</div>
          <div class="text--disabled subtitle-1">
            {{ item.pro_next_needs || __('HasNotAnyDescription') }}
          </div>
        </div>
      </v-col>

      <!--   Pro Need for Next Textarea   -->
      <v-col cols="12" md="12" v-if="_access('pro')">
        <job-need :data="data"/>
      </v-col>
    </v-row>

    <div class="text-center pa-4">
      <template v-if="item.pro_id && !disabled">
        <v-divider class="grey lighten-3 mb-3"/>
        <div>
          <v-btn
              :disabled="!item.invoice_file_url"
              :href="item.invoice_file_url"
              class="elevation-0 rounded-lg mr-2"
              color="primary"
              outlined
              small
              target="_blank"
          >
            {{ __('Invoices') }}
          </v-btn>
          <v-btn
              :disabled="!item.change_order_file_url"
              :href="item.change_order_file_url"
              class="elevation-0 rounded-lg mr-2"
              color="primary"
              outlined
              small
              target="_blank"
          >
            {{ __('ChangeOrder') }}
          </v-btn>
          <v-btn
              :disabled="!item.contract_file_url"
              :href="item.contract_file_url"
              class="elevation-0 rounded-lg mr-2"
              color="primary"
              outlined
              small
              target="_blank"
          >
            {{ __('Contracts') }}
          </v-btn>
        </div>
      </template>

      <v-spacer/>

    </div>

  </v-card>
</template>

<script>

import JobStatusSelector from "@/components/selectors/JobStatusSelector";
import {PostRequest} from "@/models/PostRequest";
import Price from "../app/Price";
import JobPros from "./JobPros";
import JobMenu from "./JobMenu";
import JobNeed from "./JobNeed";

export default {
  name: "Job",
  components: {JobNeed, JobMenu, JobPros, Price, JobStatusSelector},
  props: {
    data: {
      default: null,
      type: Object,
    },
    disabled: {
      default: false,
      type: Boolean,
    }
  },
  computed: {
    item() {
      if (this.updated_data) return this.updated_data;
      return this.data;
    }
  },
  data() {
    return {
      updated_data: null,
      request: new PostRequest('update_job'),
      archived: false,
      disable_description: true,
      status_items: [
        {
          text: this.__('Pending'),
          value: 'pending',
        },
        {
          text: this.__('LogSiteVisit'),
          value: 'log_site_visit',
        },
        {
          text: this.__('EstimateSent'),
          value: 'estimate_sent',
        },
        {
          text: this.__('AddContract'),
          value: 'add_contract',
        },
        {
          text: this.__('OnHold'),
          value: 'on_hold',
        },
        {
          text: this.__('Closed'),
          value: 'closed',
        },
      ]
    }
  },
  mounted() {
    this.request.setApiParams([this.data.id]);
    this.setBody();
  },
  watch: {},
  methods: {
    setBody() {
      this.request.setBody({
        status: this.data.status,
        description: this.data.description
      });
    },
    change(value, key) {
      this.request.body.status = value;
      this.request.patch();
      return value + key;
    },
    isVisible() {
      try {
        if (this.archived) return false;
        if (!this.item || typeof this.item.job_type_id !== 'object') return false;
        if (this.item.status === 'archived') return false;

        // check for pro
        if (this._access('pro')) {
          if (!this.item.pro_id) return false;
          let pro_id = this.item.pro_id;
          if (typeof pro_id === 'object') pro_id = pro_id.id;
          if (!this._owner(pro_id)) return false;
        }

        return true;
      } catch (e) {
        return false;
      }
    },
  },
}
</script>

<style scoped>

</style>

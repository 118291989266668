<template>
  <v-row>
    <v-col class="d-flex px-5 px-sm-6 px-md-3 justify-space-between">
      <!-- Search -->
      <items-search
          v-model="keyword" @search="search"
          :placeholder="__('SearchProfiles')"
          class="full-width"
          max_width="unset"
          timer
      />

      <space/>

      <div class="d-flex">
        <!--  Search Button       -->
        <v-btn class="rounded-lg primary" @click="search" icon large>
          <v-icon color="background" size="23">mdi-magnify</v-icon>
        </v-btn>

        <template v-if="!hide_limit"  >
          <space class="hidden-sm-and-down"/>

          <!-- Limit -->
          <items-limit  :request="request" class="hidden-sm-and-down"/>
        </template>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import Space from "@/components/app/Space";
import ItemsSearch from "@/components/items/ItemsSearch";
import ItemsLimit from "@/components/items/ItemsLimit";

export default {
  name: "ProfilesActions",
  components: {ItemsLimit, ItemsSearch, Space},
  props: {
    request: {
      default: new GetRequest(),
      type: GetRequest,
      required: true,
    },
    hide_limit: {
      default:null,
      type: Boolean
    }
  },
  data() {
    return {
      keyword: null,
    }
  },
  methods: {
    search() {
      let object = null;
      if (this.keyword) object = {"_or": [
          {"state": {"_contains": this.keyword}},
          {"country": {"_contains": this.keyword}},
          {"city": {"_contains": this.keyword}},
          {"address": {"_contains": this.keyword}},
          {"business_name": {"_contains": this.keyword}},
          {"pro_id": {"full_name": {"_contains": this.keyword}}}
        ]}
      this.request.custom_filters.add('search', object);
      this.request.is_loading = false;
      this.request.reload();
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-row>
    <v-col cols="12" :sm="sm *2 ">
      <v-btn    height="42" class="rounded-lg" disabled large color="primary" width="100%">
        <v-icon>mdi-comment</v-icon>&nbsp;&nbsp;
        <span class="font-size-14 font-mt-2">{{ __('SendMessage') }}</span>
      </v-btn>
    </v-col>

    <v-col cols="6" :sm="sm">
      <v-btn
          class="rounded-lg"
          color="#00B2A8"
          large
          height="42"
          v-if="_user"
          :href="'tel:'+data.phone_number"
          width="100%"
          outlined
      >
        <v-icon small color="#00B2A8">mdi-phone</v-icon>&nbsp;&nbsp;
        <span v-html="__('Call')"></span>
      </v-btn>
    </v-col>
    <v-col cols="6" :sm="sm">
      <v-btn
          class="rounded-lg"
          large
          height="42"
          color="#0066FF"
          v-if="_user"
          :href="'mailto:'+data.email"
          width="100%"
          outlined
      >
        <v-icon style="margin-top:-3px;" small>mdi-email</v-icon>&nbsp;&nbsp;
        <span v-html="__('SendEmail')"></span>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: "ProfileItemActions",
  components: {},
  props: {
    data: {
      default: null,
      type: Object,
    },
    sm: {
      default: 6,
      type: [String, Number],
    },
  },
}
</script>

<style scoped>

</style>
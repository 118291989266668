import VueRouter from 'vue-router'
import Login from "../pages/auth/Login";
import NotFound from "../pages/site/errors/NotFound";
import Home from "../pages/site/home/Home";
import PanelLayout from "../components/layouts/PanelLayout";
import SiteLayout from "../components/layouts/SiteLayout";
import DefaultLayout from "../components/layouts/DefaultLayout";

import Dashboard from "../pages/panel/dashboard/Dashboard";
import Profile from "../pages/panel/account/Profile";
import AddIdea from "../pages/panel/ideas/Add";
import PanelIdeas from "../pages/panel/ideas/Ideas";
import PanelProfessionals from "../pages/panel/professionals/Professionals";
import ProfessionalsPage from "../pages/panel/professionals/Professionals";
import AddProfessional from "../pages/panel/professionals/Add";
import EditProfessional from "../pages/panel/professionals/Edit";
import Expertises from "../pages/panel/professionals/Expertises";
import AddUser from "../pages/panel/users/Add";
import EditUser from "../pages/panel/users/Edit";


import Vue from 'vue'
import IdeasLanding from "../pages/site/ideas/Landing";
import ProfessionalsLandingPage from "../pages/site/professionals/ProfessionalsLanding";
import PackagesPage from "../pages/site/packages/Packages";
import IdeaDetailsPage from "../pages/site/ideas/Details";
import IdeasPage from "../pages/site/ideas/Ideas";
import StartProjectPage from "../pages/site/projects/Start";
import Projects from "../pages/panel/projects/Projects";
import Project from "../pages/panel/projects/Project";
import Lead from "../pages/panel/projects/Lead";
import CallRequest from "../pages/site/call/CallRequest";
import Users from "../pages/panel/users/Users"
import CenterLayout from "../components/layouts/CenterLayout";
import ProjectIdeas from "../pages/panel/projects/ProjectIdeas";

import LeadJobs from "../pages/panel/jobs/LeadJobs";

import ClientsPage from "../pages/panel/clients/Clients";
import AddClientPage from "../pages/panel/clients/Add";
import Settings from "../pages/panel/settings/Settings";
import Blog from "@/pages/panel/blogs/Blogs";
import BlogViewPage from "@/pages/site/blogs/View";
import PublicBlogsPage from "@/pages/site/blogs/Blogs";
import AddBlogPage from "@/pages/panel/blogs/Add";
import LeadDetails from "../pages/panel/projects/LeadDetails";
import LeadIdeas from "../pages/panel/projects/LeadIdeas";
import CustomProfessionals from "../pages/panel/professionals/CustomProfessionals";
import CompleteRegister from "../pages/auth/CompleteRegister";
import Template from "../pages/site/template/Template";
import CompletePhone from "../pages/auth/CompletePhone";
import {_t} from "@/tools/Utils";
import ProfessionalProfilePage from "@/pages/site/professionals/ProfessionalProfile";
import ProfessionalProfileFormPage from "@/pages/site/professionals/ProfessionalProfileForm";
import ProfessionalProfileReview from "@/pages/site/professionals/ProfessionalProfileReview";
import ProfessionalProjectPage from "@/pages/site/professionals/ProfessionalProject";
import ProfessionalProjectFormPage from "@/pages/site/professionals/ProfessionalProjectForm";
import FindProfessionalsPage from "@/pages/site/professionals/Professionals";
import ProfessionalBlogForm from "@/pages/site/professionals/ProfessionalBlogForm";
import ProfessionalBlog from "@/pages/site/professionals/ProfessionalBlog";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Site',
        component: SiteLayout,
        redirect: {name: "Home"},
        children: [
            {
                path: 'landing',
                name: 'Home',
                component: Home,
                meta: {
                    title: _t('RemodelingPlatform'),
                    headTags: [
                        {
                            name: 'description',
                            content: _t('RemodelingPlatformDescription')
                        },
                        {
                            property: 'og:description',
                            content: _t('RemodelingPlatformDescription')
                        },
                        {
                            name: 'robots',
                            content: 'index, follow'
                        },
                        {
                            'http-equiv': 'Content-Type',
                            content: 'text/html;charset=UTF-8'
                        }
                    ]
                },
            },
            {
                path: '/ideas',
                component: DefaultLayout,
                redirect: {name: 'IdeasLanding'},
                meta: {
                    title: _t('Ideas'),
                    headTags: [
                        {
                            name: 'description',
                            content: _t('IdeasPageDescription')
                        },
                        {
                            property: 'og:description',
                            content: _t('IdeasPageDescription')
                        },
                        {
                            name: 'robots',
                            content: 'index, follow'
                        },
                        {
                            'http-equiv': 'Content-Type',
                            content: 'text/html;charset=UTF-8'
                        }
                    ]
                },
                children: [
                    {
                        path: '/',
                        name: 'IdeasLanding',
                        component: IdeasLanding,
                    },
                    {
                        path: ':id/details',
                        name: 'IdeaDetails',
                        component: IdeaDetailsPage,
                    },
                    {
                        path: 'filter',
                        name: 'PublicIdeas',
                        component: IdeasPage,
                    },
                ]
            },
            {
                path: '/blogs',
                component: DefaultLayout,
                redirect: {name: '/'},
                meta: {
                    title: _t('Blogs'),
                    headTags: [
                        {
                            name: 'description',
                            content: _t('BlogsDescription')
                        },
                        {
                            property: 'og:description',
                            content: _t('BlogsDescription')
                        },
                        {
                            name: 'robots',
                            content: 'index, follow'
                        },
                        {
                            'http-equiv': 'Content-Type',
                            content: 'text/html;charset=UTF-8'
                        }
                    ]
                },
                children: [

                    {
                        path: ':id',
                        name: 'BlogView',
                        component: BlogViewPage,
                    },
                    {
                        path: '/',
                        name: 'PublicBlogs',
                        component: PublicBlogsPage,
                    },
                ]
            },
            {
                path: '/template',
                component: DefaultLayout,
                meta: {
                    title: _t('RemodelingPlatform'),
                    headTags: [
                        {
                            name: 'description',
                            content: _t('RemodelingPlatformDescription')
                        },
                        {
                            property: 'og:description',
                            content: _t('RemodelingPlatformDescription')
                        },
                        {
                            name: 'robots',
                            content: 'index, follow'
                        },
                        {
                            'http-equiv': 'Content-Type',
                            content: 'text/html;charset=UTF-8'
                        }
                    ]
                },
                children: [
                    {
                        path: ':id',
                        name: 'Template',
                        component: Template,
                    },
                ]
            },
            {
                path: '/professionals',
                redirect: {name: 'FindProfessionals'},
                component: DefaultLayout,
                meta: {
                    title: _t('Professionals'),
                    headTags: [
                        {
                            name: 'description',
                            content: _t('ProfessionalsPageDescription')
                        },
                        {
                            property: 'og:description',
                            content: _t('ProfessionalsPageDescription')
                        },
                        {
                            name: 'robots',
                            content: 'index, follow'
                        },
                        {
                            'http-equiv': 'Content-Type',
                            content: 'text/html;charset=UTF-8'
                        }
                    ]
                },
                children: [
                    {
                        path: ':id/profile',
                        name: 'ProfessionalProfile',
                        component: ProfessionalProfilePage,
                    },
                    {
                        path: ':id/form',
                        name: 'ProfessionalProfileForm',
                        component: ProfessionalProfileFormPage,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'profile/:id/review',
                        name: 'ProfessionalProfileReview',
                        component: ProfessionalProfileReview,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'profile/:id/project',
                        name: 'ProfessionalProjectCreate',
                        component: ProfessionalProjectFormPage,
                        meta: {
                            requiresAuth: true,
                        }

                    },
                    {
                        path: 'profile/:id/blog',
                        name: 'ProfessionalBlogCreate',
                        component: ProfessionalBlogForm,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'profile/:id/project/:project_id',
                        name: 'ProfessionalProjectEdit',
                        component: ProfessionalProjectFormPage,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'profile/:id/blog/:blog_id',
                        name: 'ProfessionalBlogEdit',
                        component: ProfessionalBlogForm,
                        meta: {
                            requiresAuth: true,
                        }
                    },
                    {
                        path: 'landing',
                        name: 'ProfessionalsLanding',
                        component: ProfessionalsLandingPage,
                    },
                    {
                        path: '/',
                        name: 'FindProfessionals',
                        component: FindProfessionalsPage,
                    },
                    {
                        path: 'profile/project/:project_id',
                        name: 'ProfessionalProject',
                        component: ProfessionalProjectPage,
                    },
                    {
                        path: 'profile/blog/:id',
                        name: 'ProfessionalBlog',
                        component: ProfessionalBlog,
                    },
                ]
            },
            {
                path: '/packages',
                name: 'Packages',
                component: PackagesPage,
                meta: {
                    title: _t('Packages'),
                    headTags: [
                        {
                            name: 'description',
                            content: _t('RemodelingPlatformDescription')
                        },
                        {
                            property: 'og:description',
                            content: _t('RemodelingPlatformDescription')
                        },
                        {
                            name: 'robots',
                            content: 'index, follow'
                        },
                        {
                            'http-equiv': 'Content-Type',
                            content: 'text/html;charset=UTF-8'
                        }
                    ]
                },
            },


        ]
    },
    {
        path: '/panel',
        name: 'Panel',
        component: PanelLayout,
        redirect: {name: "Projects"},
        meta: {
            title: _t('Panel'),
            requiresAuth: true,
            requiresRole: true,
        },
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
            },
            {
                path: 'profile',
                name: 'Profile',
                component: Profile,
            },
            {
                path: 'ideas',
                redirect: {name: 'Ideas'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Ideas',
                        component: PanelIdeas,
                    },
                    {
                        path: 'add',
                        name: 'AddIdea',
                        component: AddIdea,
                    },
                ]
            },
            {
                path: 'lead',
                redirect: {name: 'Lead'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Lead',
                        component: Lead,
                    },
                    {
                        path: ':id/details',
                        name: 'LeadDetails',
                        component: LeadDetails,
                    },
                    {
                        path: ':id/ideas',
                        name: 'LeadIdeas',
                        component: LeadIdeas,
                    },
                ]
            },
            {
                path: 'professionals',
                redirect: {name: 'Professionals'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Professionals',
                        component: PanelProfessionals,
                    },
                    {
                        path: 'professionals/add',
                        name: 'AddProfessional',
                        component: AddProfessional,
                    },
                    {
                        path: ':id/edit',
                        name: 'EditProfessional',
                        component: EditProfessional,
                    },
                    {
                        path: 'expertises',
                        name: 'Expertises',
                        component: Expertises,
                    },
                    {
                        path: 'custom',
                        name: 'CustomProfessionals',
                        component: CustomProfessionals,
                    },
                ]
            },
            {
                path: 'projects',
                redirect: {name: 'Projects'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Projects',
                        component: Projects,
                    },
                    {
                        path: ':id/details',
                        name: 'Project',
                        component: Project,
                    },
                    {
                        path: ':id/ideas',
                        name: 'ProjectIdeas',
                        component: ProjectIdeas,
                    },
                ]
            },
            {
                path: 'users',
                redirect: {name: 'Users'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Users',
                        component: Users,
                        children: []
                    },
                    {
                        path: 'add',
                        name: 'AddUser',
                        component: AddUser,
                    },
                    {
                        path: ':id/edit',
                        name: 'EditUser',
                        component: EditUser,
                    },
                ]
            },
            {
                path: 'professionals',
                redirect: {name: 'Professionals'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Professionals',
                        component: ProfessionalsPage,
                        children: []
                    },

                ]
            },
            {
                path: 'jobs',
                redirect: {name: 'LeadJobs'},
                component: DefaultLayout,
                children: [
                    {
                        path: 'lead',
                        name: 'LeadJobs',
                        component: LeadJobs,

                    },

                ]
            },
            {
                path: 'settings',
                redirect: {name: 'Settings'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Settings',
                        component: Settings,
                    },

                ]
            },
            {
                path: 'blogs',
                redirect: {name: 'Blogs'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Blogs',
                        component: Blog,
                    },
                    {
                        path: '/add',
                        name: 'AddBlog',
                        component: AddBlogPage,
                    },
                ]
            },
            {
                path: 'clients',
                redirect: {name: 'Clients'},
                component: DefaultLayout,
                children: [
                    {
                        path: '/',
                        name: 'Clients',
                        component: ClientsPage,
                    },
                    {
                        path: 'add',
                        name: 'AddClient',
                        component: AddClientPage,
                    },

                ]
            },
        ]
    },
    {
        path: '/center',
        redirect: {name: 'StartProject'},
        component: CenterLayout,
        children: [
            {
                path: '/call',
                name: 'CallRequest',
                component: CallRequest,
                meta: {
                    title: _t('CallRequest'),
                },
            },
            {
                path: '/project',
                name: 'StartProject',
                component: StartProjectPage,
                meta: {
                    title: _t('Project'),
                    headTags: [
                        {
                            name: 'description',
                            content: _t('StartProjectPageDescription')
                        },
                        {
                            property: 'og:description',
                            content: _t('StartProjectPageDescription')
                        },
                        {
                            name: 'robots',
                            content: 'index, follow'
                        },
                        {
                            'http-equiv': 'Content-Type',
                            content: 'text/html;charset=UTF-8'
                        }
                    ]
                },
            },

        ]
    },
    {
        path: '/auth',
        component: DefaultLayout,
        redirect: {name: "Login"},
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
                meta: {requiresGuest: true,},
            },
            {
                path: 'register',
                name: 'CompleteRegister',
                component: CompleteRegister,
                meta: {requiresAuth: true,},
            },
            {
                path: 'phone_number',
                name: 'CompletePhone',
                component: CompletePhone,
                meta: {requiresAuth: true,},
            },
        ],
    },
    {
        path: "*",
        name: '404',
        component: NotFound,
        meta: {
            title: '404',
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
    routes
})

export default router


<template>
  <div id="home-page" class="px-3">
    <v-container class="container-max-width ">
      <v-row class="hidden-sm-and-down py-6" dense>
        <v-col cols="12" sm="4" style="padding:2px !important;">
          <v-card
              class="rounded-0   overflow-hidden"
              style="    border-top-left-radius: 33px !important; border-bottom-left-radius: 33px !important;"
              flat
          >
            <v-img src="@/assets/img/home/image-1.jpg" min-height="290"/>
            <v-overlay opacity="0.5" absolute>
              <div class="background--text px-3 font-head font-size-18 text-uppercase text-center pt-3">
                {{ __('home_image_text_1') }}
              </div>
            </v-overlay>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" style="padding:2px !important;">
          <v-card class="rounded-0 overflow-hidden" flat>
            <v-img src="@/assets/img/home/image-2.jpg" min-height="290"/>
            <v-overlay opacity="0.5" absolute>
              <div class="background--text px-3 font-head font-size-18 text-uppercase text-center pt-3">
                {{ __('home_image_text_2') }}
              </div>
            </v-overlay>
          </v-card>
        </v-col>
        <v-col cols="12" sm="4" style="padding:2px !important;">
          <v-card
              class="rounded-0   overflow-hidden"
              style="    border-top-right-radius: 33px !important; border-bottom-right-radius: 33px !important;"
              flat
          >
            <v-img src="@/assets/img/home/image-3.jpg" min-height="290"/>
            <v-overlay opacity="0.5" absolute>
              <div class="background--text px-3 font-head font-size-18 text-uppercase text-center pt-3">
                {{ __('home_image_text_3') }}
              </div>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>

      <!--  Tablet    -->
      <v-row class="hidden-xs-only hidden-md-and-up" style="margin-top:-30px;" dense>
        <v-col cols="12">
          <v-card class="rounded-0   overflow-hidden" style="border-radius: 99px !important" flat>
            <v-img src="@/assets/img/home/image-2.jpg" height="342"/>
            <v-overlay opacity="0.5" absolute>
              <div
                  class="background--text px-3 font-head font-size-14 text-uppercase text-center  "
                  style="max-width: 350px;"
              >
                {{ __('home_image_text_1') }}
              </div>
              <div
                  class="background--text px-3 font-head font-size-14 text-uppercase text-center pt-6"
                  style="max-width: 350px;"
              >
                {{ __('home_image_text_2') }}
              </div>
              <div
                  class="background--text px-3 font-head font-size-14 text-uppercase text-center pt-6"
                  style="max-width: 350px;"
              >
                {{ __('home_image_text_3') }}
              </div>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>

      <!--  Mobile    -->
      <v-row class="hidden-sm-and-up" style="margin-top:-30px;" dense>
        <v-col cols="12" class="text-center">
          <v-card class="  overflow-hidden mx-auto" style="border-radius: 50% !important;" elevation="0" max-width="342" width="100%">
            <v-img src="@/assets/img/home/image-2.jpg" height="342"/>
            <v-overlay opacity="0.5" absolute>
              <div
                  class="background--text px-3 font-head font-size-14 text-uppercase text-center  "
                  style="max-width: 350px;"
              >
                {{ __('home_image_text_1') }}
              </div>
              <div
                  class="background--text px-3 font-head font-size-14 text-uppercase text-center pt-6"
                  style="max-width: 350px;"
              >
                {{ __('home_image_text_2') }}
              </div>
              <div
                  class="background--text px-3 font-head font-size-14 text-uppercase text-center pt-6"
                  style="max-width: 350px;"
              >
                {{ __('home_image_text_3') }}
              </div>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>

      <!--    HOW IT WORKS -->
      <v-row>
        <v-col cols="12">
          <div class=" font-size-18 title py-6  text-center">{{ __('HowItWork') }}</div>
          <v-divider/>
          <v-timeline class="hidden-xs-only" reverse>
            <v-timeline-item
                v-for="(item, i) in items()"
                :key="i"
                small
            >
              <template v-slot:opposite>
                <div class="px-5">
                  <div class="font-size-15 font-head primary--text pb-2" v-html="item.title"></div>
                  <div class="font-size-14" v-html="item.text"></div>
                </div>
              </template>

              <template v-slot:icon>
                <div class="d-flex">
                  <div v-if="i === 0" class="time-line-item-first"></div>
                  <div v-else-if="i % 2 === 0" class="time-line-item-left"></div>
                  <v-avatar color="#BDB18C" class="background--text" size="28">
                    <div class="font-mt-2">{{ i + 1 }}</div>
                  </v-avatar>
                  <div v-if="i % 2 !== 0" class="time-line-item-right"></div>
                </div>
              </template>

              <div>
                <v-img :src="item.image" height="251" class="rounded-18"/>
              </div>
            </v-timeline-item>
          </v-timeline>

          <!--   Mobile       -->
          <div class="hidden-sm-and-up">
            <v-card flat v-for="(item, i) in items()" :key="i" class="py-6 pb-3">
              <div class="text-center">
                <v-avatar color="#BDB18C" class="mx-auto background--text" size="28">
                  <div class="font-mt-2">{{ i + 1 }}</div>
                </v-avatar>
                <div class="font-size-15 font-head primary--text py-6" v-html="item.title"></div>
              </div>
              <div class="font-size-14 pb-5" v-html="item.text"></div>
              <v-img class="rounded-18" :src="item.image" height="251"/>
            </v-card>
          </div>

          <div class="text-center mt-3 mt-sm-0">
            <v-avatar color="#BDB18C" class="background--text" size="33">
              <v-icon size="18">mdi-party-popper</v-icon>
            </v-avatar>
          </div>
        </v-col>
      </v-row>


      <!--  Start Project    -->
      <div class="home-start-project hide-field-details">
        <div class="my-5">
          <v-divider class=" hidden-sm-and-down"/>
        </div>
        <div class="font-head font-size-18 text-center">{{ __('StartProjectNow') }}</div>
        <v-card class="mt-7 rounded-18 pa-8" outlined>
          <v-row>
            <v-col cols="12" sm="7" md="8">
              <room-selector
                  :first_label="__('WhatRoomRenovate')"
                  css_class="font-size-14"
                  v-model="query.room_id"
                  hide_title
                  multiple
                  height="32"
              />
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                  v-model="query.zip_code"
                  :placeholder="__('ZipCode')"
                  class="custom-selector-border font-size-14 rounded-lg"
                  type="text"
                  dense
                  outlined
              />
            </v-col>
            <v-col cols="12" sm="2" md="1" class="text-center">
              <v-btn class="px-0 font-size-12 font-mt-2 rounded-lg" @click="start" color="primary" elevation="0 " max-width="145" width="100%">
                {{ __('Go') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </div>

      <space :height="80"/>


      <!--  Brands  -->
      <v-row>
        <v-col cols="12" class="text-center">
          <div class="hidden-xs-only font-head font-size-18 py-5 text-center" v-html=" __('WePassOnOur') "></div>
          <v-img src="@/assets/img/home/brands.png"/>
        </v-col>
      </v-row>
    </v-container>


  </div>
</template>

<script>

import RoomSelector from "@/components/selectors/RoomSelector";
import Space from "@/components/app/Space";

export default {
  name: "HomePage",
  components: {Space, RoomSelector},
  data: () => ({
    query: {
      room_id: null,
      zip_code: null,
    }
  }),
  methods: {
    items() {
      return [
        {
          title: this.__('CreateAnAccount'),
          text: this.__('CreateAnAccountText'),
          image: require('@/assets/img/home/time-line-1.jpg'),
        },
        {
          title: this.__('TimeLine-2'),
          text: this.__('TimeLineText-2'),
          image: require('@/assets/img/home/time-line-2.jpg'),
        },
        {
          title: this.__('TimeLine-3'),
          text: this.__('TimeLineText-3'),
          image: require('@/assets/img/home/time-line-3.jpg'),
        },
        {
          title: this.__('TimeLine-4'),
          text: this.__('TimeLineText-4'),
          image: require('@/assets/img/home/time-line-4.jpg'),
        },
        {
          title: this.__('TimeLine-5'),
          text: this.__('TimeLineText-5'),
          image: require('@/assets/img/home/time-line-5.jpg'),
        },
      ];
    },
    start() {
      try {
        this.$router.push({name: 'StartProject', query: this.query})
      } catch (e) {
        console.log(e);
      }
    }
  }
}
</script>

<style>
#home-page .v-timeline-item__dot.v-timeline-item__dot--small,
#home-page .v-timeline-item__inner-dot {
  width: 0 !important;
}

#home-page .v-timeline::before {
  background: #BDB18C !important;
  right: 50% !important;
}

#home-page .v-timeline-item {
  align-items: center !important;
}

.time-line-item-first {
  border-top-left-radius: 30px;
  border-top: 2px solid #BDB18C;
  border-left: 2px solid #BDB18C;
  width: 23px;
  height: 15px;
  margin-top: 13px;
  margin-left: 42px;
}

.time-line-item-left {
  border-bottom-left-radius: 30px;
  border-bottom: 2px solid #BDB18C;
  border-left: 2px solid #BDB18C;
  width: 23px;
  height: 15px;
  margin-left: 42px;
}

.time-line-item-right {
  border-bottom-right-radius: 30px;
  border-bottom: 2px solid #BDB18C;
  border-right: 2px solid #BDB18C;
  width: 23px;
  height: 15px;
  margin-right: 57px;
}

.home-start-project .theme--light.v-sheet--outlined {
  border: 1px solid #000;
}
</style>
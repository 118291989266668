<template>
  <div>
    <div class="pointer" @click="toggle(true)">
      <slot>
      </slot>
    </div>

    <!-- In Modal   -->
    <modal v-if="modal" v-model="dialog" fullscreen @input="(e) => toggle(e)">
      <template v-slot:button>
        <slot name="button"></slot>
      </template>
      <template v-slot:title>
        <span>{{ title ? title : __("AddPackage") }}</span>
      </template>
      <template v-slot:main>
        <div>
          <packages
              v-model="selected"
              :is_paying="request.isLoading()"
              v-bind='$attrs'
              @payment="e => add(e)"
          />
        </div>
      </template>
      <template v-slot:actions>

      </template>
    </modal>

    <div v-else-if="$store.state.package">
      <loading/>
    </div>

    <!--  In Section  -->
    <div v-else>
      <packages
          v-model="selected"
          :is_paying="request.isLoading()"
          v-bind='$attrs'
          @payment="e => add(e)"
      />

      <div class="py-3 text-center ">

      </div>
    </div>
  </div>

</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import Packages from "../packages/Packages";
import Modal from "../modal/Modal";
import message from "../../tools/Message";
import {GetRequest} from "../../models/GetRequest";
import Loading from "../app/Loading";


export default {
  name: "AddPackage",
  components: {Loading, Modal, Packages},
  props: {
    unique_key: {
      default: null,
      type: String,
      required: true,
    },
    value: {
      default: null,
      type: Boolean,
    },
    project_id: {
      type: [String, Number],
      require: true
    },
    title: {
      default: null,
      type: String
    },
    button_title: {
      default: null,
      type: String
    },
    modal: {
      default: false,
      type: Boolean
    },
    set_selected: {
      default: false,
      type: Boolean
    },
  },

  data() {
    return {
      request: new PostRequest('stripe_checkout'),
      check_request: new GetRequest('get_payment_result'),
      add_request: new PostRequest('add_project_package'),
      selected: [],
      dialog: null,
    }
  },
  mounted() {
    this.check()
  },
  watch: {
    'value'() {
      this.dialog = this.value;
    }
  },
  methods: {
    getBody() {
      let data = [];
      this.selected.forEach(i => {
        data.push({
          "project_id": this.project_id,
          "package_id": i.id
        });
      });
      return data;
    },
    getCheckoutBody() {
      let packages = [];
      this.selected.forEach(i => {
        packages.push(i.id);
      });

      let success_url = new URL(window.location.href);
      success_url.searchParams.set('payment_status', 'success');
      success_url.searchParams.set('unique_key', this.unique_key);

      let cancel_url = new URL(window.location.href);
      cancel_url.searchParams.set('payment_status', 'cancel');
      cancel_url.searchParams.set('unique_key', this.unique_key);

      return {
        "project": this.project_id,
        "packages": packages,
        "success_url": success_url.href,
        "cancel_url": cancel_url.href,
      };
    },
    thenCheck(response) {
      try {
        if (!response || !response.status) throw 'Not found status for check !';
        if (response.status !== 'complete') return;
        message.show(this.__('PackageAdded'))
        this.$emit('then')
      } catch (e) {
        console.log(e)
      }
    },
    setSelected() {
      try {
        if (!this.set_selected) return;

        let selected_package = this.$store.state.package;
        if (!selected_package) return;

        if (!Array.isArray(selected_package) || selected_package.length === 0) return this.$store.state.package;

        this.add(selected_package);
      } catch (e) {
        console.log(e);
      }
    },
    check() {
      try {
        if (!this.$route.query || !this.$route.query.payment_status) return this.setSelected();
        let status = this.$route.query.payment_status;
        let checkout_key = this.$route.query.checkout;
        let unique_key = this.$route.query.unique_key;

        if (unique_key !== this.unique_key) return;

        if (status === 'cancel') return this.cancelled()
        if (status !== 'success') return;

        this.check_request.setApiParams([checkout_key]);
        this.check_request.setThen((e) => this.thenCheck(e));
        this.check_request.row();
      } catch (e) {
        console.log(e)
      }
    },
    add(items) {
      if (!Array.isArray(items) || items.length === 0) return  this.$emit('then');

      this.selected = items;
      /*this.add_request.setBody(this.getBody())
      this.add_request.setThen(() => this.then())
      this.add_request.post();*/

      this.request.setBody(this.getCheckoutBody());
      this.request.hideMessages();
      this.request.setThen((e) => this.then(e))
      this.request.post();
    },
    toggle(value) {
      this.dialog = value;
      this.$emit('input', value)
    },
    then(response) {
      try {
        if (!response || !response.data || !response.data.url) throw 'Not found data!';
        window.location.href = response.data.url;
      } catch (e) {
        console.log(e)
      }
    },
    cancelled() {
      message.show(this.__('CancelledPayment'))
    },
  },
}
</script>

<style scoped>

</style>
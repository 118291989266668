<template>
  <div>

    <loading v-if="questions.isAnalyzing()" margin="0">
      <div class="text-h4 my-16 pt-16">{{ __('Analyzing') }}</div>
    </loading>

    <v-row v-else>
      <v-col cols="12" md="7">
        <div class="text-h4  my-6">
          {{ (__('SurveyAnalyzeTitle')).replace('%s', questions.getAnalyzedData('main_style')) }}
        </div>


        <div class="title mt-11">
          {{ __('AboutYourStyle') }}
        </div>

        <div class="text--secondary">
          {{ questions.getAnalyzedData('description') }}
        </div>

        <div class="mt-16">
          <div>{{ __('ShareYourStyle') }}</div>

          <social-links/>
        </div>

        <v-row v-if="questions.getAnalyzedData('styles')" class="mt-16">
          <template v-for="(item, index) in questions.getAnalyzedData('styles')">
            <v-col  v-if="item" cols="12" sm="6" :key="'styles-item-'+index">
              <div>
                <div class="font-weight-bold mb-2">{{ item.type }}</div>
                <div>{{ item.description }}</div>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-col>

      <v-col cols="12" md="5">
        <room-styles-selector :data="questions.selected_styles" :width="170" unselectable/>
      </v-col>

      <v-col sm="12">
        <div class="border-light rounded-lg my-6 px-16 py-9 d-flex flex-wrap justify-space-between align-center" >
          <div class="text-h4 py-6" v-html="__('WannaGetQuick')"></div>
          <questions-next-button :text="__('LetsDo')" large :questions="questions" fixed/>
        </div>
      </v-col>

      <v-col sm="12">
        <questions-skip-button :questions="questions"/>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import {Questions} from "../../models/Questions";
import Loading from "../app/Loading";
import QuestionsNextButton from "./QuestionsNextButton";
import RoomStylesSelector from "../selectors/RoomStylesSelector";
import QuestionsSkipButton from "./QuestionsSkipButton";
import SocialLinks from "../social/SocialLinks";

export default {
  name: "SurveyAnalyze",
  components: {SocialLinks, QuestionsSkipButton, RoomStylesSelector, QuestionsNextButton, Loading},
  props: {
    questions: {
      default: new Questions(),
      type: Questions,
      required: true,
    },
  },
  mounted() {
    this.questions.analyze();
  },
  methods: {}
}
</script>

<style scoped>

</style>
<template>
  <div>
    <!-- Loading -->
    <loading v-if="request.isLoading()"/>

    <div v-else-if="request.data">

      <assets-images :data="request.data.pictures" :max-height="490" class="rounded-lg my-3" carousel/>
      <div class="my-3 mt-6 text-h4 d-flex justify-space-between">
        {{ request.data.title }}
        <blog-menu :data="request.data" v-if="_access('admin')" @edited="request.reload()" hide_details/>
      </div>
      <div class=" text--disabled">
        {{ __('By') }}
        <full-name v-if="request.data.user_created" :data="request.data.user_created"/>
        <span v-else>{{ __('Renotive') }}</span>
      </div>

      <div v-html="request.data.text" class="my-6" style="white-space: pre-wrap"></div>

    </div>
  </div>
</template>

<script>

import {GetRequest} from "@/models/GetRequest";
import FullName from "../account/FullName";
import Loading from "../app/Loading";
import AssetsImages from "../assets/Images";
import Blogs from "./Blogs";
import BlogMenu from "./BlogMenu";

export default {
  name: "BlogView",
  components: {BlogMenu, AssetsImages,   Loading, FullName},
  props: {
    id: {
      default: null,
      type: [String, Number],
      require: true,
    },
    data: {
      default: null,
      type: Object
    },
    status: {
      default: 'published',
      type: String
    },
  },
  computed: {},
  watch: {
    'id'() {
      this.get();
    }
  },
  data: () => {
    return {
      request: new GetRequest('get_blog'),
    }
  },
  mounted() {
    this.get()
  },
  methods: {
    get() {
      this.request.setApiParams([this.id]);
      this.request.setFields('*.*');
      this.request.row();
    }
  },
}
</script>

<style scoped>

</style>
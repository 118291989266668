import { render, staticRenderFns } from "./ProfileReviews.vue?vue&type=template&id=137dc41f&scoped=true&"
import script from "./ProfileReviews.vue?vue&type=script&lang=js&"
export * from "./ProfileReviews.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137dc41f",
  null
  
)

export default component.exports
<template>
  <span v-if="data" :title="title">{{ date }}</span>
</template>

<script>
import moment from 'moment';

export default {
  name: "DateText",
  props: {
    data: {
      default: null,
      type: [String, Object]
    },
    use_format: {
      default: false,
      type: Boolean
    },
    format: {
      default: 'MMMM D, YYYY',
      type: String
    },
  },
  computed: {
    date() {
      try {
        if (this.use_format) return moment(this.data).format(this.format);
        return moment(this.data).fromNow();
      } catch (e) {
        return '-'
      }
    },
    title() {
      try {
        let created_at = new Date(this.data);
        let date = created_at.getFullYear() + '-' + (created_at.getMonth() + 1) + '-' + created_at.getDate();
        let time = created_at.getHours() + ":" + created_at.getMinutes() + ":" + created_at.getSeconds();

        return date + ' ' + time;
      } catch (e) {
        return '-'
      }
    }
  },
  data() {
    return {}
  },

}
</script>

<style scoped>

</style>
<template>
  <div v-if="_is_object(data)" class="profile-project mb-1">
    <router-link :to="{name: 'ProfessionalProject', params:{project_id: data.id}}">
      <v-card :class="{'rounded-lg pa-0':true, 'opacity-6':data.status === 'draft'}" flat>
        <assets-image :data="image" :height="image_height" type="cover"/>
      </v-card>

      <v-list-item class="px-0">
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold font-size-14">{{ data.title }}</v-list-item-title>
          <v-list-item-subtitle class="text--disabled pt-1 font-size-12">
            <span>{{ data.city }}, {{ data.country }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
            <span>{{ data.year }}</span>&nbsp;&nbsp;|&nbsp;&nbsp;
            <span>
              <span>{{ data.ideas_count }}</span>&nbsp;
              <v-icon class="text--disabled" style="margin-top:-3px;" small>mdi-image</v-icon>
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="editable">
          <v-btn
              :to="{name:'ProfessionalProjectEdit', params:{id: pro_id, project_id: data.id}}"
              min-width="25"
              class="btn-background-transparent align-center text-transform-unset px-3 px-md-1 rounded-lg"
              color="primary"
              outlined
              small
          >
            <v-icon small>mdi-pencil-outline</v-icon>
            <span v-if="data.status === 'draft'">{{__('Draft')}}&nbsp;</span>
            <div v-else class="hidden-md-and-up" >&nbsp;{{__("Edit")}}</div>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </router-link>
  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";

export default {
  name: "ProfileProjectItem",
  components: {AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },

    editable: {
      default: false,
      type: Boolean,
    },
    pro_id: {
      default: null,
      type: String,
    },
    image_height: {
      default: '199',
      type: [String, Number],
    },
  },
  computed: {
    image() {
      try {
        return this.data.ideas[0].pictures[0].directus_files_id;
      } catch (e) {
        return null;
      }
    }
  }
}
</script>

<style>

</style>
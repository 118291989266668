<template>
  <v-hover v-slot="{ hover }">
    <v-row v-if="data && data.id" :class="{'py-2 px-4': true, 'grey lighten-3': hover}" align="center" >

      <v-col sm="4" class="d-flex flex-wrap align-center">
        <v-avatar :tile="tile" :class="{'rounded-lg':tile}" color="transparent" size="32" :title="data.title">
          <assets-image :data="data.icon" width="150" :class="selected ? 'selected-room' : ''"/>
        </v-avatar>
        <space/>
        <div class="font-weight-bold">
          {{ data.title }}
        </div>
      </v-col>

      <v-col sm="8" >
        <slot></slot>
      </v-col>
    </v-row>
  </v-hover>
</template>

<script>
import AssetsImage from "../assets/Image";
import Space from "../app/Space";

export default {
  name: "RoomItem",
  components: {Space, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    selected: {
      default: false,
      type: Boolean,
    },
    tile: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    onClick() {
      if (this.$listeners && this.$listeners.click) return this.$emit('click', this.data);
      this.$router.push({name: 'PublicIdeas', query: {room_id: this.data.id, page_title: this.data.title}})
    }
  }
}
</script>

<style scoped>
.selected-room {
  border: 5px rgba(0, 0, 0, 0.09) solid;
}
</style>
<template>
  <div class="d-flex">

    <status-selector
        v-if="!hide_status"
        @input="(e) => change(e, 'status')"
        :value="getDefault('status')"
        :items="status_items"
    />
    <space v-if="!hide_status"/>

    <expertise-selector
        v-if="!hide_expertise"
        :value="getDefault('expertises')"
        @input="(e) => change( e ?  {'expertise_id': {'_eq': e }  }  : null, 'expertises')"
    />
    <space v-if="!hide_expertise"/>

    <div class="pt-6">
      <v-btn class="border-light rounded-lg" @click="reset" large outlined>
        {{ __('Reset') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import ExpertiseSelector from "@/components/selectors/ExpertiseSelector";
import StatusSelector from "@/components/selectors/StatusSelector";
import Space from "@/components/app/Space";

export default {
  name: "ProfessionalsFilters",
  components: {Space, StatusSelector, ExpertiseSelector},
  props: {
    request: {
      type: GetRequest,
      default: new GetRequest()
    },
    hide_expertise: {
      type: Boolean,
      default: false
    },
    hide_status: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      filter: {},
      status_items: [
        {
          text: this.__('All'),
          value: null,
        },
        {
          text: this.__('Active'),
          value: 'active',
        },
        {
          text: this.__('Inactive'),
          value: 'inactive',
        },
      ]
    }
  },
  mounted() {
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
    change(value, key) {
      try {
        this.request.addFilter(key, value);
        this.request.reload();
      } catch (e) {
        console.log(e);
      }
    },
    getDefault(key) {
      try {
        if (key === 'expertises') {
          let result = this.request.getFilter(key);
          if (!result) return null;
          return result._eq;
        }

        return this.request.getFilter(key);
      } catch (e) {
        console.log(e);
      }
    },
  },
}
</script>

<style scoped>

</style>
<template>
  <div class="profile-details">

    <template v-if="request.is_loading">
      <loading/>
    </template>

    <template v-else-if="item || is_create">
      <form ref="profile-form" @submit.prevent="submit">
        <v-row>
          <v-col cols="12" class="hidden-sm-and-down">
            <profile-breadcrumbs
                :data="item"
                :advanced_items="!is_create
                  ? [{text:__('Edit'), disabled: true}]
                  : [{text:__('Create'), disabled: true},]"
            />
          </v-col>
          <v-col cols="12" class="hidden-sm-and-down" :md="_owner(item.pro_id) ? 2 : 3">
            <div class="  position-sticky " style="top: 150px !important;">
              <profile-actions :data="item" :request="form_request" @submit="submit" editable/>
            </div>
          </v-col>
          <v-col cols="12" :md="_owner(item.pro_id) ? 10 : 9">
            <profile-head :data="item" :request="form_request" editable/>

            <profile-form-about :request="form_request"/>

            <profile-from-info :request="form_request"/>

            <profile-from-contact :request="form_request"/>

            <profile-from-social-media :request="form_request"/>

            <profile-projects v-if="!is_create" :data="item" :title="__('EditProjects')" open editable limit="5" addable show_more>
              <template #divider>
                <v-divider class="grey lighten-1 mx-3"/>
              </template>
            </profile-projects>

            <profile-stories v-if="!is_create" :data="item" :title="__('EditStories')" open editable addable show_more>
              <template #divider>
                <v-divider class="grey lighten-1 mx-3"/>
              </template>
            </profile-stories>

            <profile-go-top/>

            <profile-actions
                class="hidden-md-and-up mt-3"
                :data="item"
                :request="form_request"
                @submit="submit"
                editable
            />

            <space height="80"/>

          </v-col>

        </v-row>
      </form>
    </template>


    <template v-else>
      <not-found-data class="py-16"/>
    </template>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import ProfileHead from "@/components/profiles/ProfileHead";
import ProfileInfo from "@/components/profiles/ProfileInfo";
import ProfileAbout from "@/components/profiles/ProfileAbout";
import ProfileProjects from "@/components/profiles/ProfileProjects";
import ProfileStories from "@/components/profiles/ProfileStories";
import ProfileSkeleton from "@/components/profiles/ProfileSkeleton";
import ProfileGoTop from "@/components/profiles/ProfileGoTop";
import ProfileActions from "@/components/profiles/ProfileActions";
import ProfileFormAbout from "@/components/profiles/ProfileFormAbout";
import ProfileFromInfo from "@/components/profiles/ProfileFromInfo";
import {PostRequest} from "@/models/PostRequest";
import ProfileFromContact from "@/components/profiles/ProfileFromContact";
import Loading from "@/components/app/Loading";
import ProfileFromSocialMedia from "@/components/profiles/ProfileFromSocialMedia";
import ProfileBreadcrumbs from "@/components/profiles/ProfileBreadcrumbs";
import Space from "@/components/app/Space";
import NotFoundData from "@/components/app/NotFoundData";
import auth from "@/tools/Auth";

export default {
  name: "ProfileForm",
  components: {
    NotFoundData,
    Space,
    ProfileBreadcrumbs,
    ProfileFromSocialMedia,
    Loading,
    ProfileFromContact,
    ProfileFromInfo,
    ProfileFormAbout,
    ProfileActions,
    ProfileGoTop,
    ProfileSkeleton, ProfileStories,
    ProfileProjects, ProfileAbout, ProfileInfo, ProfileHead
  },
  props: {
    id: {
      default: null,
      type: [String, Number],
    }
  },
  watch: {
    'id'() {
      this.get()
    }
  },
  data() {
    return {
      request: new GetRequest('get_item'),
      form_request: new PostRequest(),
      editable: true,
    }
  },
  computed: {
    item() {
      if (this.id === 'CREATE') return {
        pro_id: {id: this._user.id},
        typical_job_cost: {min: null, max: null},
        social_media: [],
      };
      return this.request.data ?? {};
    },
    is_create() {
      return this.id === 'CREATE';
    },
  },
  mounted() {
    this.check();

  },
  methods: {
    check() {
      if (!this.is_create) {
        this.form_request.setApi('item')
        this.form_request.setApiParams(['pro_profile', this.id]);
        this.get();
        return;
      }

      this.form_request.setApi('items')
      this.form_request.setApiParams(['pro_profile']);
      this.setBody();
    },
    setBody() {
      try {
        let data = this.item;

        return this.form_request.setBody({
          address: data.address,
          banner: data.banner,
          city: data.city,
          description: data.description,
          fax: data.fax,
          fax_country_code: data.fax_country_code,
          email: data.email,
          languages: this.getLanguages(),
          latitude: data.latitude,
          license_number: data.license_number,
          longitude: data.longitude,
          logo: data.logo,
          business_name: data.business_name,
          currency: data.currency,
          state: data.state,
          website: data.website,
          unlimited_budget: data.unlimited_budget,
          typical_job_cost: data.typical_job_cost,
          social_media: data.social_media,
          phone_number: data.phone_number,
          phone_country_code: data.phone_country_code,
          medium_to_high_budget: data.medium_to_high_budget,
          low_to_medium_budget: data.low_to_medium_budget,
          low_budget: data.low_budget,
          country: data.country,
          pro_id: data.pro_id,
        });
      } catch (e) {
        console.log(e);
      }
    },
    getLanguages() {
      try {
        let result = this.item.languages;
        const found = this.item.languages.some(el => el.language_id === 1);
        if (!found) result.unshift({language_id: 1});
        return result
      } catch (e) {
        return [{language_id: 1}];
      }
    },
    submit() {
      try {
        let form = this.$refs['profile-form'];
        if (!form.checkValidity()) return;
        this.form_request.setThen(this.thenSubmit)
        if (this.is_create) return this.form_request.post();
        this.form_request.patch();
      } catch (e) {
        console.log(e);
      }
    },
    thenSubmit(response) {

      // control after create
      if (!this.is_create) return;
      if (!this._is_object(response)) return;

      let user = this._user;
      user.profile_id = [response.data.id];

      auth.setUserInfo(user)
      this.$store.commit('setUserInfo', user)

      setTimeout(()=>{
        this.$router.push({name: 'ProfessionalProfileForm', params: {id: response.data.id}});
      }, 600);
    },
    thenGet() {
      this.setBody();
      this.$emit('onload', this.request.data);
    },
    get() {
      if (!this.id || this.is_create) return;
      this.request.setApiParams(['pro_profile', this.id]);
      this.request.setFields('*,count(reviews) count(projects),projects.*, projects.count(ideas), projects.ideas.pictures.directus_files_id, blogs.*, blogs.pictures.directus_files_id, count(blogs), pro_id.expertises.expertise_id, languages.language_id,pro_id.id')
      this.request.setParam('deep', {
        "reviews": {"_filter": {"reply_to": {"_null": true}}, "_limit": 4},
        "projects": {"ideas": {"_limit": 1, "_sort": ["pro_project_sort"]}, "_limit": 6}
      });
      this.request.setThen(() => this.thenGet());
      this.request.row();
    }
  }
}
</script>

<style scoped>

</style>
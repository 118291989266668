<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col cols="12" md="12">
        <idea-details :id="$route.params.id" @onload="onload"/>
      </v-col>

      <v-col v-if="detail_loaded" md="12">
        <div class="pb-3 pt-7 font-weight-bold">{{ __('OtherUserIdeas') }}</div>
        <ideas-horizontal
            :without="not_in"
            :item_attrs="{hide_options: true}"
            :add_by="user_id"
            hide_load_more
            limit="4"
            loader_count="4"
            status="published"
        />
      </v-col>

      <v-col v-if="detail_loaded" md="12">
        <div class="mb-16">
          <div class="pb-3 pt-7 font-weight-bold">{{ __('Blogs') }}</div>
          <blogs limit="3"  hide_load_more/>
        </div>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import IdeaDetails from "../../../components/ideas/IdeaDetails";
import IdeasHorizontal from "../../../components/ideas/IdeasHorizontal";
import Blogs from "../../../components/blogs/Blogs";

export default {
  name: "IdeaDetailsPage",
  components: {Blogs, IdeasHorizontal, IdeaDetails},
  data() {
    return {
      detail_loaded: false,
      user_id: null,
      room_id: null,
      not_in: [],
    }
  },
  methods: {
    onload(response) {
      try {
        this.not_in = [response.data.id];
        this.room_id = response.data.room_id.id;
        this.user_id = response.data.add_by.id;
        this.detail_loaded = true;
      } catch (e) {
        this.room_id = null;
        this.detail_loaded = true;
      }
    }
  }
}
</script>

<style scoped>

</style>
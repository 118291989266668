<template>
  <v-container>
    <page-title :text="__('Settings')" heading/>
    <settings/>
  </v-container>
</template>

<script>
import PageTitle from "../../../components/app/PageTitle";
import Settings from "../../../components/setting/Settings";

export default {
  name: "SettingsPage",
  components: {Settings, PageTitle}
}
</script>

<template>
  <span>{{ currency }}</span>
</template>

<script>

import app from "../../tools/App";

export default {
  name: "Currency",
  props: {},
  computed: {
    currency() {
      return app.currency;
    },
  },
  data() {
    return {}
  },

}
</script>

<style scoped>

</style>
<template>
  <v-card v-if="data && data.last_login && _access('admin')" class="rounded-lg  mb-3" :title="__('UserLastLogin')" outlined>
    <v-card-title class="title">
      {{ __('LastLogin') }}
    </v-card-title>

    <v-card-text>
      <div>
        {{ __('Date') }} : <date-text :data="data.last_login.timestamp"/>
      </div>
      <div>
        {{ __('IP') }} : {{ data.last_login.ip }}
      </div>
      <div>
      {{ __('UserAgent') }} : {{ data.last_login.user_agent }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import DateText from "../app/DateText";
import CityName from "../app/CityName";
import StateName from "../app/StateName";

export default {
  name: "ProjectLastLogin",
  components: {  DateText},
  props: {
    data: {
      default: null,
      type: Object,
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div>


    <v-hover v-slot="{hover}">
      <v-row v-if="_is_object(data)" class="profile-project mb-6">

        <v-col cols="12" sm="6">
          <v-card :class="{'rounded-lg overflow-hidden pa-0':true, 'opacity-6':data.status === 'draft'}"   flat>
            <router-link :to="link">
              <assets-image
                  :data="picture"
                  :height="height"
                  type="cover"
              />
            </router-link>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6">

          <v-list-item  class="px-3">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold font-size-14">
                <router-link :to="link" class="text--text font-size-14">{{ data.title }} </router-link>
              </v-list-item-title>

              <v-list-item-subtitle v-if="!hide_name">
                <div class=" font-size-12  grey--text">
                  <template >
                    {{ __('By') }}
                    <full-name v-if="data.user_created" :data="data.user_created"/>
                    <span v-else>{{ __('Renotive') }}</span>
                  </template>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="editable">
              <v-btn
                  v-if="editable"
                  :to="{name:'ProfessionalBlogEdit', params:{blog_id:data.id}}"
                  min-width="25"
                  height="25"
                  class="btn-background-transparent text-transform-unset px-3 px-md-1 rounded-lg mb-1   "
                  color="primary"
                  outlined
                  small
              >
                <span v-if="data.status === 'draft'">{{ __('Draft') }}&nbsp;</span>
                <v-icon small style="margin-top:-1px;">mdi-pencil-outline</v-icon>
                <div class="hidden-md-and-up" >&nbsp;{{__("Edit")}}</div>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <div class="px-3 pb-3 font-size-12 overflow-hidden grey--text darken-1" style="max-height: 90px;" >
            {{ data.summary }}
          </div>
        </v-col>
      </v-row>
    </v-hover>
  </div>
</template>

<script>

import AssetsImage from "../assets/Image";
import FullName from "../account/FullName";
import AssetsImages from "../assets/Images";
import BlogMenu from "./BlogMenu";
import Modal from "../modal/Modal";
import BlogView from "./BlogView";
import DateText from "@/components/app/DateText";

export default {
  name: "BlogCard",
  components: {DateText, BlogView, Modal, BlogMenu, AssetsImages, FullName, AssetsImage,},
  props: {
    data: {
      default: null,
      type: Object
    },
    height: {
      default: '160',
      type: [String, Number]
    },
    editable: {
      default: false,
      type: Boolean
    },
    hide_name: {
      default: false,
      type: Boolean
    },
    in_modal: {
      default: false,
      type: Boolean
    },
    horizontal: {
      default: false,
      type: Boolean
    },
    route_name: {
      default: 'BlogView',
      type: String
    }
  },
  computed: {
    picture() {
      try {
        return this.data.pictures[0].directus_files_id;
      } catch (e) {
        return null;
      }
    },
    link() {
      return {name: this.route_name, params: {id: this.data.id}}
    }
  },
  data: () => {
    return {
      modal: null,
    }
  },
  mounted() {

  },
  methods: {
    click() {
      try {
        if (this.in_modal) return this.modal = true;
        return this.$router.push(this.link)
      } catch (e) {
        return '';
      }
    },
  },
}
</script>

<style>

</style>
<template>
  <div v-if="data" @click="$emit('select', data)" :key="data.id" class="expertise-card mr-3">
    <v-avatar
        class="rounded-9 pointer"
        color="transparent"
        size="150"
        :title="data.title"
    >
      <assets-image :data="data.image" width="150" class="selected-room"/>
      <v-overlay absolute opacity="0.1" class="expertise-card-shadow"></v-overlay>
      <v-overlay opacity="0" class="v-overlay-full-width  justify-left align-start text-left rounded-0" style="" absolute>
        <div class="font-weight-light text-uppercase text-left pa-2 px-3 font-size-14 ">{{ data.title }}</div>
      </v-overlay>
    </v-avatar>

  </div>
</template>

<script>
import AssetsImageSimple from "../assets/ImageHtml";
import AssetsImage from "@/components/assets/Image";

export default {
  name: "ExpertiseCard",
  components: {AssetsImage, AssetsImageSimple},
  props: {
    data: {
      default: null,
      type: Object,
      required: true,
    },
  },
  methods: {}
}
</script>

<style>
.expertise-card .v-overlay-full-width {
  height: 58px; justify-content:left !important;; bottom:0; top: unset; overflow: hidden !important; margin-bottom: 15px;
}
.expertise-card-shadow {
  box-shadow: inset 0px -33px 25px 0 rgba(9, 40, 68, 0.65), inset 0 66px 15px 0px #b5b5b554, inset 0 99px 5px 0px #6d6d6d3b
}
</style>


<template>
  <div>
    <div class="hidden-sm-and-down px-3 ">
      <div
          v-if="data && typeof data === 'object' && data.title"
          @click="()=> $emit('select', data)"
          v-bind="$attrs"
          :class="classes"
      >
        {{ data.title }}
      </div>
    </div>

    <!-- Mobile   -->
    <div class="hidden-md-and-up ">
      <v-divider/>
      <v-list-item
          class="justify-center"
          v-if="data && typeof data === 'object' && data.title"
          @click="select"
          v-bind="$attrs"
      >
        <v-list-item-content style="max-width: 360px">
          <v-list-item-title class="pt-1 font-size-18 pl-2 grey--text lighten-2">
            {{ data.title }}
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon>
            <v-icon v-if="!show">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-up</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <div v-if="show">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpertisesSectionTab",
  props: {
    data: {
      default: null,
      type: Object,
    },
    selected: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    classes() {
      let result = ['font-size-18 text-center py-2 pointer'];
      if (this.selected) result.push('grey lighten-3 rounded-t-lg primary--text')
      return result;
    },
  },
  methods: {
    select() {
      this.$emit('select', this.data);
      this.show = !this.show;
    },
  }
}
</script>

<style scoped>

</style>
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(_vm.data)?_c('a',{attrs:{"href":_vm.data.link,"disabled":!_vm.data.link,"target":"_blank"}},[_c('div',{class:{
      'rounded-lg pa-3 my-1  text--lighten-3': true,
      'primary background--text': hover && _vm.data.link,
      'secondary  ': !hover,
      'text--disabled': !_vm.data.link,
      'text--text': !hover && _vm.data.link,
    }},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.data.icon))]),_vm._v("   "+_vm._s(_vm.data.text)+" ")],1)]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"scrollable":"","width":"100%","max-width":"890"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_vm._t("button",function(){return [_c(VBtn,_vm._g({staticClass:"rounded-lg",attrs:{"dark":"","color":"primary","elevation":"0"}},on),[(!_vm.button_title)?_c('span',[_vm._v(_vm._s(_vm.__('Save')))]):_c('span',[_vm._v(_vm._s(_vm.button_title))])])]})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"rounded-lg overflow-visible"},[_c(VCardTitle,[_c('div',{staticClass:"full-width d-flex justify-space-between"},[_c('span',{staticClass:"font-size-18"},[_vm._v(_vm._s(_vm.__("SaveIdeaInProject")))]),_c('div',[_c(VBtn,{attrs:{"icon":"","outlined":"","small":""},on:{"click":_vm.close}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1)])]),_c('div',{staticClass:"px-6"},[_c(VDivider)],1),_c(VCardText,{staticClass:"light-scroll px-0 overflow-x-hidden",staticStyle:{"max-height":"400px"}},[_c('project-selector',{attrs:{"status":"active","checkbox":""},on:{"then":(e)=>_vm.then(e)},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c(VCardActions,{staticClass:"align-center text-center justify-center"},[_c('div',{staticClass:"text-center my-3"},[_c(VBtn,{staticClass:"rounded-lg",attrs:{"color":"primary","elevation":"0","disabled":_vm.add_request.isLoading()},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.__('Send'))+" ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
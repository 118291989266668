<template>
  <div>
    <!-- Loading -->
    <loading v-if="request.isLoading()"/>


    <v-row v-else-if="request.data">
      <v-col cols="12" md="8">

        <!--  Breadcrumbs -->
        <project-breadcrumbs :data="item" @reload="reset" :is_lead="isLead"/>

        <!--  Title     -->
        <project-title :data="item" @reload="request.reload()" :is_lead="isLead"/>

        <!--  Expired -->
        <project-expired :data="item" @reload="reset"/>

        <!-- Google Drive Links -->
        <google-links v-if="!isLead" class=" my-3" :data="item.google_drive_links" :disabled="expired"/>

        <!-- Project Chart -->
<!--        <project-chart v-if="_access('client')" class="mt-6" :data="item"></project-chart>-->

        <!-- Project Work -->
        <project-work class="mt-6" :data="item" :is_lead="isLead" :expired="expired"></project-work>

        <!-- Last 3 Idea -->
        <project-last-ideas class="my-6" :data="item" :expired="expired"/>

        <!-- Packages Services -->
        <project-packages-services v-if="!_access('pro')" class="my-6" :data="item" type="design"/>

        <!-- Packages Services -->
        <project-packages-services
            v-if="!_access('pro')"
            class="my-6"
            :data="item"
            type="project_management"
            :title="__('ProjectManagement')"
        />
      </v-col>

      <v-col cols="12" md="4">

        <!-- Cost Estimate -->
        <project-cost :data="item"/>

        <!--   Creator     -->
        <v-card class="rounded-lg my-3" :title="__('Creator')" outlined>
          <user :data="item.client_id" call chat/>
        </v-card>

        <!-- Project info and Address -->
        <project-info :data="item"/>

        <!--   Questions Answers     -->
        <project-answers class="my-6" :data="item"/>

        <!-- Tags -->
        <project-tags :data="item" :expired="expired"/>


        <!-- Comment -->
        <project-comment :data="item" @reload="request.reload()" :expired="expired"/>


      </v-col>
    </v-row>

    <div v-else>
      <not-found-data/>
    </div>

  </div>
</template>

<script>

import {GetRequest} from "../../models/GetRequest";
import Loading from "../app/Loading";
import ProjectWork from "@/components/projects/ProjectWork"
import GoogleLinks from "../links/GoogleLinks";
import User from "../users/User";
import ProjectAnswers from "./ProjectAnswers";
import ProjectTitle from "./ProjectTitle";
import ProjectBreadcrumbs from "./ProjectBreadcrumbs";
import ProjectCost from "./ProjectCost";
import ProjectInfo from "./ProjectInfo";
import ProjectTags from "./ProjectTags";
import ProjectPackages from "./ProjectPackages";
import ProjectLastIdeas from "./ProjectLastIdeas";
import NotFoundData from "../app/NotFoundData";
import ProjectComment from "./ProjectComment";
import ProjectPackagesServices from "./ProjectPackagesServices";
import ProjectChart from "./ProjectChart";
import ProjectLastLogin from "./ProjectLastLogin";
import ProjectExpired from "./ProjectExpired";

export default {
  name: "ProjectDetails",
  components: {
    ProjectExpired,
    ProjectLastLogin,
    ProjectChart,
    ProjectPackagesServices,

    ProjectComment,
    NotFoundData,
    ProjectLastIdeas,
    ProjectPackages,
    ProjectTags,
    ProjectInfo,
    ProjectCost,
    ProjectBreadcrumbs,
    ProjectTitle,
    ProjectAnswers,
    User,
    GoogleLinks,
    ProjectWork,
    Loading
  },
  props: {
    id: {
      default: null,
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      request: new GetRequest('get_project', [this.id]),
    }
  },
  computed: {
    item() {
      return this.request.data;
    },
    expired() {
      try {
        return this._access('client') && this.item.is_expired;
      } catch (e) {
        return false;
      }
    },
    status() {
      try {
        return this.request.data.status;
      } catch (e) {
        return null;
      }
    },
    isLead() {
      try {
        return this.$route.name === 'LeadDetails';
      } catch (e) {
        return null;
      }
    },
  },
  watch: {
    'id'() {
      if (!this.id) return;
      this.request.setApiParams([this.id]);
      this.request.row();
    }
  },
  mounted() {
    if (!this.id) return;
    this.request.setFields('*, *.*,subscription_expiration,desire_start_time, packages.package_services.*, packages.package_services.service_id.*,packages.package_id.*, packages.package_id.services.service_id.*, rooms.room_id.*, jobs.suggested_pros.pro_id.*, ideas.idea_id.*, ideas.idea_id.*.*, jobs.suggested_pros.pro_id.expertises.expertise_id.*, jobs.pro_id.expertises.expertise_id.*,jobs.suggested_pros.*,  jobs.suggested_pros.pro_id.personal_comment.*, jobs.job_type_id.*, jobs.pro_id.*.*, personal_tags.*')
    this.request.setThen((data) => this.$emit('onload', data));
    this.request.row();
  },
  methods: {
    reset() {
      this.$router.replace({path: this.$route.path, query: {payment_status: 'completed'}});
      this.request.reload();
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card v-if="data" class="rounded-lg  mb-3" :title="__('ProjectInfo')" outlined>
    <v-card-title class="title">
      {{ __('Address') }}
    </v-card-title>

    <v-card-text>
      <div>
        {{ __('Address') }} : {{ data.address }}
      </div>
      <div>
        {{ __('ZipCode') }} : {{ data.zip_code }}
      </div>
      <div>
        {{ __('City') }} :
        <city-name :data="data"/>
      </div>
      <div>
        {{ __('State') }} :
        <state-name :data="data"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import DateText from "../app/DateText";
import CityName from "../app/CityName";
import StateName from "../app/StateName";

export default {
  name: "ProjectInfo",
  components: {StateName, CityName, DateText},
  props: {
    data: {
      default: null,
      type: Object,
    }
  },
}
</script>

<style scoped>

</style>
<template>
  <div v-if="_is_object(data)">
    <div class="font-size-10" v-if="!hide_title">{{ __('ProfessionalInProject') }}</div>
    <div class="py-4">
      <router-link :to="{name:'ProfessionalProfile', params: {id: data.id}}">
        <v-list-item class="px-0">
          <v-list-item-avatar class="mr-1">
            <v-avatar class="profile-logo" size="35">
              <assets-image :data="data.logo"/>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.business_name }}
            </v-list-item-title>
            <v-list-item-subtitle class="text--disabled font-size-12">
              <v-icon color="grey" small>mdi-map-marker</v-icon>&nbsp;
              <span>{{ data.city }}, {{ data.country }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </div>

    <template v-if="!hide_buttons">
      <v-btn class="full-width letter-normal mb-2 text-transform-unset rounded-lg font-size-15" color="primary" outlined disabled>
        {{__('Message')}}
      </v-btn>
      <v-btn
          v-if="data.phone_number"
          class="full-width letter-normal mb-2 text-transform-unset rounded-lg font-size-15"
          color="primary"
          elevation="0"
          :href="'tel:'+data.phone_number"
      >
        {{__('Call')}}
      </v-btn>
    </template>
  </div>
</template>

<script>

import AssetsImage from "@/components/assets/Image";

export default {
  name: "ProfileContact",
  components: {AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    hide_title:{
      default: null,
      type: Boolean,
    },
    hide_buttons:{
      default: null,
      type: Boolean,
    }
  },

}
</script>

<style scoped>

</style>
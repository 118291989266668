<template>
  <div   v-if="_is_object(data)" class="px-3">

    <!--  Banner  -->
    <v-card class="overflow-hidden profile-banner rounded-lg" flat>
      <assets-image :data="editable ? request.body.banner : data.banner" width="100%" type="cover" height="184"/>
      <v-overlay v-if="editable" absolute opacity="0" class="justify-end align-end">
        <upload-image v-model="request.body.banner" accept="image/*">
          <v-btn class="secondary text-transform-unset rounded-xl ma-6" small>
            <span class="text--text">{{ __('EditCoverPhoto') }}</span>&nbsp;
            <v-icon color="primary" small>mdi-pencil-outline</v-icon>
          </v-btn>
        </upload-image>
      </v-overlay>
    </v-card>

    <v-row align="center">
      <v-col cols="12" sm="6">
        <div class="text-center" style="margin-top:-30px;">
          <upload-image v-if="editable" v-model="request.body.logo" class="mx-auto" accept="image/*">
            <div>
              <div>
                <v-avatar class="pointer profile-logo " size="60">
                  <assets-image :data="request.body.logo"/>
                </v-avatar>
              </div>
              <v-btn
                  style="z-index: 999; margin-top: -40px; margin-left: 15px; position: absolute"
                  class="border-light"
                  color="grey lighten-3"
                  fab
                  width="32"
                  height="32"
                  elevation="0"
                  small
              >
                <v-icon color="grey" size="20">mdi-camera-outline</v-icon>
              </v-btn>
            </div>
          </upload-image>
          <v-avatar v-else class="profile-logo" size="60">
            <assets-image :data="data.logo"/>
          </v-avatar>


          <div>
            <div class="font-head profile-title title mt-3 d-inline py-1">
              <span class="font-weight-bold font-size-18">{{
                  editable && _is_object(request.body) ? request.body.business_name : data.business_name
                }}</span>&nbsp;
              <v-icon color="blue" small>mdi-check-circle</v-icon>
            </div>
          </div>
          <div class="d-flex justify-center align-center py-3 font-size-14">
            <rate :data="data.total_score" size="18"/>&nbsp;&nbsp;|&nbsp;&nbsp;
            <span class="text--disabled font-mt-2">{{ data.reviews_count }} {{ __('Reviews') }}</span>
          </div>
        </div>

        <div class="d-flex align-center justify-center pt-2 pb-6">

          <div
              class="font-weight-bold font-size-14 text-uppercase"
              @click="share"
          >
            <v-btn small icon class="primary white--text">
              <v-icon small style="margin-right: 3px">mdi-share-variant</v-icon>
            </v-btn>
            &nbsp;
            {{ __('Share') }}
          </div>

          <space/>

          <router-link
              :to="{name:'ProfessionalProfileReview', props:{id:data.id}}"
              class="font-weight-bold font-size-14 text-uppercase text--text"
              v-if="!_owner(data.pro_id)"
          >
            <v-btn small icon class="primary white--text">
              <v-icon small>mdi-comment-edit-outline</v-icon>
            </v-btn>
            &nbsp;
            {{ __('WriteReview') }}
          </router-link>
        </div>
      </v-col>

      <!-- Owner   -->
      <v-col cols="12" sm="6" v-if="_owner(data.pro_id)">
        <div  class="text-center">
          <v-btn
              class="full-width rounded-lg font-size-14"
              :to="{name:'ProfessionalProfileForm',params: {id: data.id}}"
              color="primary"
              max-width="250"
              outlined
          >
            <v-icon small>mdi-pencil-outline</v-icon>&nbsp;
            <span class="font-mt-2">{{ __('EditProfile') }}</span>
          </v-btn>
        </div>
      </v-col>

      <v-col v-else cols="12" sm="6"   class="pt-4">
        <v-btn class="rounded-lg" disabled large color="primary" width="100%">
          <v-icon>mdi-comment</v-icon>&nbsp;&nbsp;
          <span class="font-size-14 font-mt-2">{{ __('SendMessage') }}</span>
        </v-btn>

        <v-row class="mt-2">

          <v-col>
            <v-btn
                class="rounded-lg"
                color="#00B2A8"
                large
                v-if="_user"
                :href="'tel:'+data.phone_number"
                width="100%"
                outlined
            >
              <v-icon small color="#00B2A8">mdi-phone</v-icon>&nbsp;&nbsp;
              <span v-html="__('Call')"></span>
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                class="rounded-lg"
                large
                color="#0066FF"
                v-if="_user"
                :href="'mailto:'+data.email"
                width="100%"
                outlined
            >
              <v-icon style="margin-top: -3px;" small>mdi-email</v-icon>&nbsp;&nbsp;
              <span v-html="__('SendEmail')"></span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>


    </v-row>

  </div>
</template>

<script>
import AssetsImage from "@/components/assets/Image";
import UserCard from "@/components/users/UserCard";
import Rate from "@/components/app/Rate";
import Upload from "@/components/assets/Upload";
import Breadcrumbs from "@/components/app/Breadcrumbs";
import message from "@/tools/Message";
import {PostRequest} from "@/models/PostRequest";
import UploadImage from "@/components/assets/UploadImage";
import Space from "@/components/app/Space";
import ProfileHeadFixed from "@/components/profiles/ProfileHeadFixed";

export default {
  name: "ProfileHeadTablet",
  components: {ProfileHeadFixed, Space, UploadImage, Breadcrumbs, Upload, Rate, UserCard, AssetsImage},
  props: {
    data: {
      default: null,
      type: Object,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    request: {
      default: null,
      type: PostRequest,
    },
  },
  computed: {
    breadcrumbs_items() {
      return [
        {
          text: this.__('Professionals'),
          to: {name: 'ProfessionalsLanding'}
        },
        {
          text: this.__('Professionals'),
          disabled: true
        },
      ]
    }
  },
  methods: {
    async share() {
      try {
        let text = window.location.href;
        await navigator.clipboard.writeText(text);
        message.show(this.__('UrlCopiedForShare'))
      } catch (e) {
        console.log(e);
      }

    },
    uploaded(response, key = 'banner') {
      try {
        if (!response || !response.data || !response.data.id) throw 'Not found any uploaded file id!';
        if (key === 'banner') this.request.body.banner = response.data.id;
        if (key === 'logo') this.request.body.logo = response.data.id;
      } catch (e) {
        console.log(e);
      }
    },
  }

}
</script>

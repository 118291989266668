<template>
  <v-container>
    <page-title :text="__('ManageExpertiseList')" heading/>
    <expertise-manage/>
  </v-container>
</template>

<script>
import PageTitle from "@/components/app/PageTitle";
import ExpertiseManage from "@/components/expertises/ExpertiseManage";

export default {
  name: "ExpertisesPage",
  components: {ExpertiseManage, PageTitle,}
}
</script>

<style scoped>

</style>
<template>
  <!--  Add or Edit expertise  -->
  <modal fullscreen v-bind="$attrs" @input="e=>$emit('input', e)">
    <template v-slot:title>
      <span>{{ __("EditExpertise") }}</span>
    </template>
    <template v-slot:main>
      <form v-if="request.body">
        <v-col cols="12" md="12">
          <div class="font-weight-bold">{{ __('Title') }} *</div>
          <v-text-field
              v-model="request.body.title"
              dense
              outlined
              required
          />
        </v-col>

        <v-col cols="12" md="12">
          <job-type-selector v-model="request.body.job_type_id"/>
        </v-col>

        <v-col cols="12" md="12" v-if="data">
          <status-selector :items="status_items" v-model="request.body.status"/>
        </v-col>
      </form>
    </template>

    <template v-slot:actions>
      <v-btn @click="submit" :disabled="!request.body.title || !request.body.job_type_id" type="submit" color="primary">{{ __('Submit') }}</v-btn>
    </template>
  </modal>
</template>

<script>
import {PostRequest} from "@/models/PostRequest";
import Modal from "@/components/modal/Modal";
import JobTypeSelector from "@/components/selectors/JobTypeSelector";
import StatusSelector from "@/components/selectors/StatusSelector";

export default {
  name: "CustomExpertise",
  components: {StatusSelector, JobTypeSelector, Modal},
  props: {
    data: {
      default: null,
      type: Object
    },
  },
  data() {
    return {
      request: new PostRequest('add_expertise'),
      status_items: [
        {
          text: this.__('Published'),
          value: 'published',
        },
        {
          text: this.__('Archived'),
          value: 'archived',
        },
      ]
    }
  },
  watch: {
    data() {
      this.setBody();
    }
  },
  mounted() {
    this.request.setThen(this.submitted);
    this.setBody();
  },
  methods: {
    setBody() {
      if (this.data) return this.setData();
      this.request.setBody(
          {
            title: null,
            job_type_id: null,
          }
      );
    },
    setData() {
      this.request = new PostRequest('archive_expertise', [this.data.id])
      this.request.setBody(
          {
            status: this.data.status,
            title: this.data.title,
            job_type_id: this.data.job_type_id.id
          }
      );
    },
    submitted(data) {
      this.$emit('then', data);
      this.setBody();
      this.closeModal();
    },
    submit() {
      this.before()
      if (this.data) return this.request.patch();
      this.request.post();
    },
    before() {
      try {
        this.$emit('before', this.request)
      } catch (e) {
        console.log(e);
      }
    },
    closeModal() {
      this.$emit('input', false);
    }
  },
}
</script>

<style scoped>

</style>
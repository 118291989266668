<template>
  <div v-if="items">
    <masonry :gutter="gap"  :column-width="width">
      <div v-for="(item, index) in items" :key="index" class="mb-3">
        <div v-if="item" class="style-item" @click="select(item)">
          <div v-if="isSelected(item)" style="position: absolute;" class="pa-3">
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </div>
          <img
              v-if="item.picture && item.id"
              :src="image(item.picture)"
              :class="{ 'pointer': !unselectable}"
              alt=""
          />
        </div>
      </div>
    </masonry>
  </div>
</template>

<script>

import app from "../../tools/App";
import urls from "../../tools/Urls";

export default {
  name: "RoomStylesSelector",
  components: { },
  props: {
    value: {
      default: null,
      type: [Array, Object],
    },
    data: {
      default: null,
      type: [Array, Object]
    },
    width: {
      default: 270,
      type: [Number, String]
    },
    gap: {
      default: 10,
      type: [Number, String]
    },
    unselectable: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    items() {
      try {
        if (!Array.isArray(this.data)) {
          return Object.values(this.data);
        }
        return this.data;
      } catch (e) {
        return [];
      }
    },
  },
  data() {
    return {
      selected: {},
    }
  },
  mounted() {
    this.selected = this.value;
  },
  methods: {
    select(item) {
      try {
        if (!item || !item.id) return;

        if (this.isSelected(item)) this.$delete(this.selected, item.id);
        else this.$set(this.selected, item.id, item)

        this.$emit('input', this.selected)
      } catch (e) {
        console.log(e);
        return null;
      }
    },
    isSelected(item) {
      try {
        if (this.unselectable) return false;
        return this.selected[item.id] !== undefined;
      } catch (e) {
        return false;
      }
    },
    image(data) {
      try {

        // check is string
        if (!Array.isArray(data)) {
          if (data == null) throw 'Not found image url!';
          return this.getUrl(data);

        }

        // get first image from array
        return this.getUrl(data[0].directus_files_id);
      } catch (e) {
        return app.default_image;
      }
    },
    getUrl(id) {
      try {
        return urls.api('assets_url', [id]);
      } catch (e) {
        return '';
      }
    },

  }

}
</script>

<style scoped>
.un-selected {
  border-radius: 10px;
}

.selected {
  border: 5px rgba(0, 0, 0, 0.9) solid !important;
  border-radius: 10px;
}

.style-item {
  overflow: hidden;

  width: 100%;
  background: #F5F5F5;
}

.style-item .Content {
  padding: 20px;
}

.style-item img {

  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  line-height: 0;
  display: block;
}
</style>
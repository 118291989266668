<template>
  <div>
    <v-skeleton-loader type="card" height="190" class="mb-2"/>
    <v-skeleton-loader type="card" height="20" max-width="190" class="mb-2"/>
    <v-skeleton-loader type="card" height="90" class="mb-2"/>
    <v-skeleton-loader type="card" height="20" max-width="190" class="mb-2"/>
    <v-skeleton-loader type="card" height="90" class="mb-2"/>
    <v-skeleton-loader type="card" height="20" max-width="190" class="mb-2"/>
    <loader cols="12" col_sm="4" type="card" class="mb-2"/>
  </div>
</template>

<script>
import Loader from "@/components/app/Loader";
export default {
  name: "ProfileSkeleton",
  components: {Loader}
}
</script>

<style scoped>

</style>
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,{attrs:{"id":"panel-app"}},[_c(VAppBar,{attrs:{"fixed":"","app":"","color":"background","elevation":"0","clipped-left":"","height":"90"}},[_c(VContainer,[_c(VRow,{attrs:{"align":"center"}},[_c(VBtn,{attrs:{"icon":"","color":"white","disabled":""}}),_c(VSpacer),_c(VToolbarTitle,[_c('router-link',{attrs:{"to":"/"}},[_c('logo')],1)],1),_c(VSpacer),_c('account',{scopedSlots:_vm._u([{key:"gust",fn:function(){return [_c(VBtn,{attrs:{"large":"","color":"rounded-lg primary","to":{name:'Login'},"elevation":"0"}},[_vm._v(" "+_vm._s(_vm.__('Login'))+" ")])]},proxy:true}])})],1)],1)],1),_c(VMain,{staticClass:"pb-7"},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
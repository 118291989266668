import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        dark: false,
        themes: {
            light: {
                primary: '#7A6A48',
                secondary: '#F6F2EF',
                accent: '#8c9eff',
                verify: '#1A978F',
                error: '#b71c1c',
                background: '#FFF',
                text: '#131313',
                polar: '#000',
            },
        },
    },

});

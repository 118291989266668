<template>
  <span>{{ app_name }}</span>
</template>

<script>
import app from "../../tools/App";

export default {
  name: "AppName",
  data() {
    return {
      app_name: app.app_name
    }
  }
}
</script>

<style scoped>

</style>
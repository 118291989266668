<template>
  <v-container  class="container-max-width">
    <v-row>
      <v-col cols="12">
        <h1 class="font-head hidden-sm-and-down font-weight-regular text-center pt-4" style="font-size: 28px">
          {{ __('FindProfessionals') }}
        </h1>
        <h1 class="font-head hidden-md-and-up font-size-18 font-weight-regular text-center" >
          {{ __('FindProfessionals') }}
        </h1>
      </v-col>

      <v-col cols="12">
        <profiles
            :filters_attrs="{'hide_status': true}"
            hide_limit
            hide_filters
            search_in_up
        />
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
import Professionals from "../../../components/professionals/Professionals";
import Blogs from "../../../components/blogs/Blogs";
import Profiles from "@/components/profiles/Profiles";

export default {
  name: "ProfessionalsLandingPage",
  components: {Profiles, Blogs, Professionals},
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>

</style>

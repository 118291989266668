<template>
  <div class="profile-details">

    <template v-if="request.is_loading">
      <loading/>
    </template>

    <template v-else-if="item">
      <v-row>
        <v-col cols="12" class="hidden-md-and-down">
          <profile-breadcrumbs :data="item"/>
        </v-col>
        <v-col cols="12" :md="_owner(item.pro_id) ? 2 : 3" class="hidden-sm-and-down">
          <div class="position-sticky" style="top: 150px !important;">
            <profile-actions  :data="item" />
            <profile-menu :data="item"/>
          </div>
        </v-col>
        <v-col cols="12" :md="_owner(item.pro_id) ? 10 : 9">
          <profile-head :data="item"/>
          <profile-about :data="item"/>
          <profile-info :data="item"/>
          <profile-projects v-if="_is_object(item.projects)" :data="item"/>
          <profile-stories v-if="_is_object(item.blogs)" :data="item"/>
          <profile-rating :data="item"/>
          <profile-reviews id="profile-reviews" class="hidden-sm-and-down" :data="item"/>
          <profile-go-top/>
          <space height="35"/>
        </v-col>
      </v-row>
    </template>

    <template v-else>
      <not-found-data class="py-16" />
    </template>
  </div>
</template>

<script>
import {GetRequest} from "@/models/GetRequest";
import ProfileHead from "@/components/profiles/ProfileHead";
import ProfileInfo from "@/components/profiles/ProfileInfo";
import ProfileAbout from "@/components/profiles/ProfileAbout";
import ProfileProjects from "@/components/profiles/ProfileProjects";
import ProfileStories from "@/components/profiles/ProfileStories";
import ProfileRating from "@/components/profiles/ProfileRating";
import ProfileReviews from "@/components/profiles/ProfileReviews";
import ProfileSkeleton from "@/components/profiles/ProfileSkeleton";
import ProfileGoTop from "@/components/profiles/ProfileGoTop";
import ProfileActions from "@/components/profiles/ProfileActions";
import Loading from "@/components/app/Loading";
import NotFoundData from "@/components/app/NotFoundData";
import ProfileBreadcrumbs from "@/components/profiles/ProfileBreadcrumbs";
import Space from "@/components/app/Space";
import ProfileMenu from "@/components/profiles/ProfileMenu";

export default {
  name: "Profile",
  components: {
    ProfileMenu,
    Space,
    ProfileBreadcrumbs,
    NotFoundData,
    Loading,
    ProfileActions,
    ProfileGoTop,
    ProfileSkeleton,
    ProfileReviews,
    ProfileRating, ProfileStories,
    ProfileProjects, ProfileAbout, ProfileInfo, ProfileHead
  },
  props: {
    id: {
      default: null,
      type: [String, Number],
      required: true
    }
  },
  watch: {
    'id'() {
      this.get()
    }
  },
  data() {
    return {
      request: new GetRequest('get_item'),

    }
  },
  computed: {
    item() {
      return this.request.data;
    },
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      if (!this.id) return;
      this.request.setApiParams(['pro_profile', this.id]);
      this.request.setFields('*,count(reviews), count(projects),projects.*, projects.ideas.pictures.directus_files_id, blogs.*, blogs.pictures.directus_files_id, count(blogs), pro_id.id,pro_id.expertises.id,pro_id.expertises.expertise_id.id,pro_id.expertises.expertise_id.job_type_id,pro_id.first_name,pro_id.last_name,reviews.*,reviews.images.directus_files_id,reviews.replies.*,reviews.replies.user_created.full_name,reviews.user_created.full_name,reviews.user_created.avatar')
      this.request.setParam('deep', {
        "reviews": {"_filter": {"reply_to": {"_null": true}, 'pro_profile_id': {'_eq': this.id}}, "_limit": 4},
        "projects": {"_limit": 6},
        "blogs": {"_limit": 3}
      });
      this.request.setThen((data) => this.$emit('onload', data));
      this.request.row();
    }
  }
}
</script>

<style >

</style>